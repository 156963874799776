<template>
    <div>
        <page-template py="3">
            <row>
                <col-12>
                    <h2 class="">Typography</h2>
                    <p class="lead text-4 mb-5">
                        Font Sizes and other properties.
                    </p>
                </col-12>
                <col-12 lg="6">
                    <h4 class="text-primary">Html Headings</h4>
                    <component-wrapper :code="codeStore.htmlHeadings.code">
                        <h1>Heading 1</h1>
                        <h2>Heading 2</h2>
                        <h3>Heading 3</h3>
                        <h4>Heading 4</h4>
                        <h5>Heading 5</h5>
                        <h6>Heading 6</h6>
                    </component-wrapper>

                </col-12>
                <col-12 lg="6">
                    <h4 class="text-primary">Font Weights & Styles</h4>
                    <component-wrapper :code="codeStore.fontWeightsAndStyles.code">
                        <p class="font-weight-lighter">font-weight-lighter</p>
                        <p class="font-weight-normal">font-weight-normal</p>
                        <p class="font-weight-bold">font-weight-bold</p>
                        <p class="font-weight-bolder">font-weight-bolder</p>
                        <p class="font-italic">font-italic</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h4 class="text-primary">Font Size Variants</h4>
                    <component-wrapper :code="codeStore.otherRegularFontSizes.code">
                        <p class="text-fs--1">text-fs--1</p>
                        <p class="text-fs-0">text-fs-0</p>
                        <p class="text-fs-1">text-fs-1</p>
                        <p class="text-fs-2">text-fs-2</p>
                        <p class="text-fs-3">text-fs-3</p>
                        <p class="text-fs-4">text-fs-4</p>
                        <p class="text-fs-5">text-fs-5</p>
                        <p class="text-fs-6">text-fs-6</p>
                        <p class="text-fs-7">text-fs-7</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h4 class="text-primary">Display font Sizes</h4>
                    <component-wrapper :code="codeStore.displayFontSizes.code">
                        <p class="display-1">display-1</p>
                        <p class="display-2">display-2</p>
                        <p class="display-3">display-3</p>
                        <p class="display-4">display-4</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h4 class="text-primary">Other Large font Sizes</h4>
                    <component-wrapper :code="codeStore.otherLargeFontSizes.code">
                        <p class="text-2x mb-5">text-2x</p>
                        <p class="text-3x mb-5">text-3x</p>
                        <p class="text-4x mb-5">text-4x</p>
                        <p class="text-5x mb-5">text-5x</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h4 class="text-primary">Text Cases</h4>
                    <component-wrapper :code="codeStore.textCases.code">
                        <p class="text-lowercase">text-lowercase</p>
                        <p class="text-uppercase">text-uppercase</p>
                        <p class="text-capitalize">text-capitalize</p>
                    </component-wrapper>
                </col-12>
                <col-12 mt="4">
                    <h4 class="text-primary">Text Alignments</h4>
                    <component-wrapper :code="codeStore.textAlignments.code">
                        <p class="text-left">
                            text aligned on left side
                        </p>
                        <p class="text-center">
                            text aligned on center
                        </p>
                        <p class="text-right">
                            text aligned on right side
                        </p>
                        <p class="text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Aliquid at blanditiis commodi corporis dolor inventore
                            labore provident.faf afa fadsfasfasfasdfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                        </p>
                        <p class="text-center text-lg-left">
                            text aligned left on large screens, aligned center on other screens

                        </p>
                        <p class="text-left text-lg-center">
                            text aligned center on large screens, aligned left on other screens
                        </p>
                        <p class="text-center text-lg-right">
                            text aligned right on large screens, aligned center on other screens
                        </p>
                        <p class="text-center text-lg-justify">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Aliquid at blanditiis commodi corporis dolor inventore
                            labore provident.faf afa fadsfasfasfasdfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                        </p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h4 class="text-primary">Other Classes</h4>
                    <component-wrapper :code="codeStore.otherClasses.code">
                        <p class="text-monospace">text-monospace</p>
                        <p class="text-muted">text-muted</p>
                        <p class="text-black-50">text-black-50</p>
                    </component-wrapper>
                </col-12>
                <col-12 mt="4">
                    <h4>Text Element Component</h4>
                    <p class="lead">Text properties can be set using this component</p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import TextElement from 'lego-framework/src/components/typography/TextElement';</p>
                    <p>Vue.component('text-el', TextElement);</p>
                    <p>&#60;text-el&#62;&#60;/text-el&#62;</p>
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="props"/>
                </col-12>
                <col-12 lg="12" mt="4">
                    <div>
                        <h3>Typography :SCSS Variables </h3>
                        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                    </div>
                </col-12>
                <col-12 lg="12" mt="4">
                    <div>
                        <h3>Fonts & Typography: SCSS Variables </h3>
                        <simple-table :fields="fields1" :data="data1" class="b-1"></simple-table>
                    </div>
                </col-12>
            </row>
        </page-template>
    </div>
</template>

<script>
import helpersMixin from '../helpersMixin';
import Col12 from 'lego-framework/src/containers/grid/col-12';
import Row from 'lego-framework/src/containers/Row';
// import Container from 'lego-framework/src/containers/layout/Container';
import typography from './code-store/typography.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'design-system/typography/',
        title: 'Typography'
    },
    components: { PropTable, ComponentWrapper, Col12, Row },
    data () {
        return {
            codeStore: typography,
            props: [
                ['tag', 'String', 'html tag name', ['any html tag, eg:div,section,main, footer,aside,...'], 'div'],
                ['align', 'String', 'text alignment', ['l', 'r', 'c', 'justify'], ''],
                ['mdAlign', 'String', 'text alignment on medium screens', ['l', 'r', 'c', 'justify'], ''],
                ['lgAlign', 'String', 'text alignment on large screens', ['l', 'r', 'c', 'justify'], ''],
                ['fontWeight', 'String', 'font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder'], ''],
                ['fs', 'String', 'font size variangs', ['-1 to 7']],
                ['color', 'String', 'color variants', ['primary', 'secondary', '...', '1', '2', '...']],
                ['textWrap', 'Boolean', 'Set property { white-space: normal !important; }', ['true', 'false'], 'false'],
                ['textNoWrap', 'Boolean', 'Set property { white-space: nowrap !important; }', ['true', 'false'], 'false'],
                ['textTruncate', 'Boolean', 'set property { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }', ['true', 'false'], 'false'],
                ['textLowercase', 'Boolean', 'set property { text-transform: lowercase !important; }', ['true', 'false'], 'false'],
                ['textUppercase', 'Boolean', 'set property { text-transform: uppercase !important; }', ['true', 'false'], 'false'],
                ['textUppercase', 'Boolean', 'set property { text-transform: uppercase !important; }', ['true', 'false'], 'false'],
                ['textCapitalize', 'Boolean', 'set property { text-transform: capitalize !important; }', ['true', 'false'], 'false'],
                ['textMuted', 'Boolean', 'tex-muted (dim color) is set ', ['true', 'false']],
                ['textDecorationNone', 'Boolean', 'Set property { text-decoration: none !important; }', ['true', 'false'], 'false'],
                ['textBreak', 'Boolean', 'Set property {\n' +
                '    word-break: break-word !important; // Deprecated, but avoids issues with flex containers\n' +
                '    word-wrap:  break-word !important; // Used instead of `overflow-wrap` for IE & Edge Legacy\n' +
                '}', ['true', 'false'], 'false'],
                ['textReset', 'Boolean', 'Set property { color: inherit !important; }', ['true', 'false'], 'false'],
                ['textHide', 'Boolean', 'Set properties set to hide text, font:             0/0 a;\n' +
                '    color:            transparent;', ['true', 'false'], 'false'],
                ['additionalClasses', 'String', 'Additional classes to be added']
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--font-family-base',
                    description: 'Font family base set to body. Depended on other variable.<br>1. --font-family-sans-serif',
                    defaultvalue: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'
                },
                {
                    slno: '2',
                    name: '--font-size-base',
                    description: 'Base font size set to body',
                    defaultvalue: '1rem'
                },
                {
                    slno: '3',
                    name: '--font-weight-base',
                    description: 'Base font weight set to the body. Depended on variables.<br>1. --font-weight-normal',
                    defaultvalue: '400'
                },
                {
                    slno: '4',
                    name: '--line-height-base',
                    description: 'Base line height set to body',
                    defaultvalue: '1.5'
                },
                {
                    slno: '5',
                    name: '--body-text-color',
                    description: 'Body text color set. Depended on variable.<br>1. --color-gray-900',
                    defaultvalue: '#212529'
                },
                {
                    slno: '6',
                    name: '--body-bg-color',
                    description: 'Body background color set. Depended on variable.<br>1. --color-white',
                    defaultvalue: '#FFFFFF'
                }
            ],
            fields1: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data1: [
                {
                    slno: '1',
                    name: '--font-family-sans-serif',
                    description: 'set default font families',
                    defaultvalue: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                },
                {
                    slno: '2',
                    name: '--font-family-monospace',
                    description: 'set monospace and related font families',
                    defaultvalue: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
                },
                {
                    slno: '3',
                    name: '--font-family-base',
                    description: 'assigned value of variable  --font-family-sans-serif',
                    defaultvalue: 'var(--font-family-sans-serif)'
                },
                {
                    slno: '4',
                    name: '--font-size-base',
                    description: 'base font size',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--font-size-sm',
                    description: 'Derived using base font size',
                    defaultvalue: ' calc(var(--font-size-base) * var(--sm-factor))'
                },
                {
                    slno: '6',
                    name: '--font-size-lg',
                    description: 'Derived using base font size',
                    defaultvalue: 'calc(var(--font-size-base) * var(--lg-factor))'
                }
            ]
        };
    }

};
</script>
