<template>
    <page-template>
        <div class="mb-5">
            <h2>Color System</h2>
            <p class="lead text-4">
                The colors are divided in to 5 main categories.
            </p>
            <ul class="list-inline-item ml-5">
                <li>Theme colors / Brand Colors</li>
                <li>Semantic Colors</li>
                <li>Base Colors - Black, White and gray</li>
                <li>Grays Colors</li>
                <li>Additional Colors</li>
            </ul>
            <p>The colors are defined in the theme.json file.</p>

            <div class="row">
                <div class="col-md-4">
                    <h4>Theme Colors</h4>
                    <p class="text-3">
                        Theme color may have one to three colors. These will be the brand colors. And other colors which will be used.
                    </p>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Name</td>
                            <td>Contrast</td>
                            <td>hex value</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, name) in theme['theme-colors']" :key="name" :class="'bg-' + name">
                            <td v-html="name"></td>
                            <td v-html="theme['theme-colors'][name]['contrast']"></td>
                            <td v-html="theme['theme-colors'][name]['color']"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-4">
                    <h4>Semantic Colors</h4>
                    <p>
                        Theme color may have one to three colors. These will be the brand colors. And other colors which will be used.
                    </p>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Name</td>
                            <td>Contrast Color</td>
                            <td>hex value</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, name) in theme['semantic-colors']" :key="name" :class="'bg-' + name">
                            <td v-html="name"></td>
                            <td v-html="theme['semantic-colors'][name]['contrast']"></td>
                            <td v-html="theme['semantic-colors'][name]['color']"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md-4">
                    <h4>Additional Colors</h4>
                    <p>
                        Additional colors are optional.
                    </p>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Name</td>
                            <td>hex value</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(value, name) in theme['additional-colors']" :key="name" :class="'bgc-' + name">
                            <td v-html="name"></td>
                            <td v-html="value"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

        <div class="mb-5">
            <h3>Colored Variations</h3>
            <div>
                <p>
                    Automatic color variations will be created for each color in the <b>theme-colors</b>.
                    There will be 4 shades and 4 tints. All the colors are generated using scss variations and tint methods.
                </p>
                <p>
                    A variations is a darker version of the current color and tint is a lighter version of the current color.
                </p>
                <div class="color-container">
                    <div class="color" v-for="color in theme['key-colors']" :key="color">
                        <div class="variations">
                            <div :class="'bgc-' + color + '-' + j" v-for="j in [100, 200, 300, 400]" :key="color + j">
                                var(--color-{{ color }}-{{ j }})
                            </div>
                        </div>
                        <div :class="'bg-' + color">
                            var(--color-{{ color }})
                        </div>
                        <div class="variations">
                            <div :class="'bgc-' + color + '-' + j" v-for="j in [600, 700, 800, 900]" :key="color + j">
                                var(--color-{{ color }}-{{ j }})
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="mb-5">
            <h3>CSS Color Variables</h3>
            <p>CSS variables will be generated for all the colors in the <b>theme-colors</b>, <b>theme-color-variations</b> and <b>grays</b>. Also </p>
            <ColorCSSVariables/>
        </div>
    </page-template>
</template>

<script>
import helpersMixin from '../helpersMixin';
import ColorCSSVariables from 'lego-framework/src/views/docs/code/colors/ColorCSSVariables';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'design-system/colors/',
        title: 'Colors'
    },
    components: { ColorCSSVariables },
    mounted () {
        console.log(this.theme);
    }
};
</script>
