<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="">Faq Accordion</h2>
                    <p class="lead">Simple Accordion component. icons and text styles can be customized</p>
                    <h4 class="my-3">Usage</h4>
                    <code-container code-str="import FaqAccordion from 'lego-framework/src/web-components/faq/FaqAccordion';"
                                    show-code-no-toggle/>
<!--                    <p>import FaqAccordion from 'lego-framework/src/web-components/faq/FaqAccordion';</p>-->
                    <heading tag="h5" class="my-4 text-2">With Icon on left</heading>
                    <faq-accordion
                        icon="fa fa-question text-2"
                        title="Is there a free trail available"
                        content="Yes you can try us for free for 30 days.
                        Our friendly team will work with you to get you up
                        and running as soon as possible"/>
                    <code-container :code-str="codeStore.withLeftIcon.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-3">
                    <heading tag="h5" class="my-4 text-2">Using custom close and open icons images</heading>
                    <faq-accordion
                        :open-icon-img="chevronDown"
                        :close-icon-img="chevronUp"
                        title="Can I change my plan later?"
                        content="Of course our pricing scales with your company.
                        Chat to our friendly team to find a solution that works for you"/>
                    <code-container :code-str="codeStore.openAndCloseIconImages.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-3">
                    <heading tag="h5" class="my-4 text-2">Top and Bottom Border Color changes</heading>
                    <faq-accordion
                        :open-icon-img="chevronDown"
                        :close-icon-img="chevronUp"
                        border-color="primary"
                        title="What is your Cancellation Policy?"
                        content="We understand that things change.
                        You can cancel your plan at any time and
                        we will refund you the difference already paid"/>
                    <code-container :code-str="codeStore.borderColorPrimary.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-3">
                    <heading tag="h5" class="my-4 text-2">Title Fonts Customizations</heading>
                    <faq-accordion
                        title-font-weight="normal"
                        title-color="primary"
                        content-color="2"
                        title="Can other info be added to an invoice ?"
                        content="At the moment the only way to add additional information to
                                invoice is to add the information to the workspace's name."/>
                    <code-container :code-str="codeStore.textCustomization.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-3">
                    <heading tag="h5" class="my-4 text-2">Title and content Fonts Customizations</heading>
                    <faq-accordion
                        title-font-weight="normal"
                        icon-border-color="primary"
                        title-color="primary"
                        content-color="2"
                        icon="fa fa-question text-2"
                        title="Is there a free trail available"
                        content="Yes you can try us for free for 30 days.
                        Our friendly team will work with you to get you up
                        and running as soon as possible"/>
                    <code-container :code-str="codeStore.iconBorderCustomization.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-3">
                    <heading tag="h5" class="my-4 text-2">design: basic-b , action button design changes</heading>
                    <faq-accordion
                        design="basic-b"
                        title="Can I change my plan later?"
                        content="Of course our pricing scales with your company.
                        Chat to our friendly team to find a solution that works for you"/>
                    <code-container :code-str="codeStore.designBasicB.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import FaqAccordion from 'lego-framework/src/web-components/faq/FaqAccordion';
import heading from 'lego-framework/src/components/Heading';
import chevronUp from '../../assets/images/icons/icons8-chevron-up-24.png';
import chevronDown from '../../assets/images/icons/icons8-chevron-down-24.png';
import codeStore from './code-storage/faq-accordion.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'FaqAccordionVariantsDocs',
    metaInfo: {
        path: 'web-components/faq-accordion/',
        title: 'FAQ Accordion'
    },
    components: { FaqAccordion, heading, PropTable },
    data () {
        return {
            chevronDown: chevronDown,
            chevronUp: chevronUp,
            codeStore: codeStore,
            propsInfo: [
                ['title', 'String', 'Faq Question title text', '', ''],
                ['content', 'String', 'Answer Content text', '', ''],
                ['icon', 'String', 'Left Icon before title ', ['any fa class name'], ''],
                ['openIcon', 'String', 'Open Button Icon', ['any open icon fa class name'], 'fa fa-plus'],
                ['closeIcon', 'String', 'Open Button Icon', ['any close icon fa class name'], 'fa fa-minus'],
                ['openIconImg', 'String', 'Open Icon Image', ['any open icon image'], ''],
                ['closeIconImg', 'String', 'Open Icon Image', ['any close icon image'], ''],
                ['contentVisibility', 'Boolean', 'Answer visibility (can be used for showing an answer when loading page)', ['true', 'false'], 'false'],
                ['borderColor', 'String', 'Top and Bottom Borders color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['borderSize', 'String, Number', 'Top and Bottom Borders Size', ['1', '2', '3', '4', '5', '..upto 10'], '2'],
                ['iconBorderColor', 'String', 'Button Icon Borders color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['design', 'String', 'Design Variant', ['basic-a', 'basic-b'], 'basic-a'],
                ['iconColor', 'String', 'Icon Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4', 'etc'], ''],
                ['titleFontSize', 'String/Number', 'Title Font Size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['titleFontWeight', 'String', 'Title Font Size', ['light', 'lighter', 'normal', 'bold', 'bolder'], 'bold'],
                ['titleColor', 'String', 'Title Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4', 'etc'], ''],
                ['titleClasses', 'String,Array', 'Title Additional Classes', '', ''],
                ['contentFontSize', 'String/Number', 'Content Font Size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['contentFontWeight', 'String', 'Content Font Size', ['light', 'lighter', 'normal', 'bold', 'bolder'], 'bold'],
                ['contentColor', 'String', 'Content Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4', 'etc'], ''],
                ['contentClasses', 'String,Array', 'Content Additional Classes', '', ''],
                ['containerClasses', 'String,Array', 'Container Additional Classes', '', ''],
                ['buttonClasses', 'String,Array', 'Button Additional Classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
