<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Color Variations</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--btn-main-color',
                    description: 'main color of the button',
                    defaultvalue: 'primary format :' +
                        '<br>1. var(--color-#{$name})'
                },
                {
                    slno: '2',
                    name: '--btn-text-color',
                    description: 'button text color ',
                    defaultvalue: 'contrast color of the main color ' +
                        '<br>1. var(--color-#{$name}-contrast'
                },
                {
                    slno: '3',
                    name: '--btn-main-color-darken',
                    description: 'button color darken. for active state of the button',
                    defaultvalue: 'var(--color-primary-active)           Format : ' +
                        '<br>1. var(--color-#{$name}-active)'
                },
                {
                    slno: '4',
                    name: '--btn-main-color-lighten',
                    description: 'button color lighten. for setting hover color of the button',
                    defaultvalue: 'var(--color-primary-hover)           Format : ' +
                        '<br>1. var(--color-#{$name}-hover)'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
