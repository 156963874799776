import { render, staticRenderFns } from "./MetricsItem4.vue?vue&type=template&id=2044b75e&scoped=true&"
import script from "./MetricsItem4.vue?vue&type=script&lang=js&"
export * from "./MetricsItem4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2044b75e",
  null
  
)

export default component.exports