<template>
    <page-template class="py-4 bg-2">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Vue Table Card</h3>
                    <p class="lead">
                        A Table extended from Vue Table. Contains a parent card containing the Vue Table and a header section for the table.
                        Header slots 'title' and 'buttons' are added. Other Attributes and Props are similar to Vue Table
                    </p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import SimpleTable from 'lego-framework/src/tables/VueTableCard';</p>
                    <p>Vue.component('vue-table', VueTable);</p>
                    <p>&#60;vue-table-card :fields="fields" url="api-url"&#62;&#60;vue-table-card/&#62;</p>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mb-4">Default</h4>
                    <component-wrapper :code="codeStore.default.code">
                        <!--Data Mode-->
                        <vue-table-card :table-data="data" :per-page="2" :fields="fields"></vue-table-card>
                        <!--API Mode-->
                        <vue-table-card url="api-url" :per-page="2" :fields="fields"></vue-table-card>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mb-4">Table with Title</h4>
                    <component-wrapper :code="codeStore.withTitle.code">
                        <vue-table-card title="Table Title" :table-data="data" :per-page="2" :fields="fields"></vue-table-card>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mb-4">Slots - title,buttons</h4>
                    <component-wrapper :code="codeStore.withSlots.code">
                        <vue-table-card :table-data="data" :per-page="2" :fields="fields">
                            <template #title>
                                <lego-heading tag="h4" class="">Table Title</lego-heading>
                            </template>
                            <template #buttons>
                                <btn>Add Item</btn>
                            </template>
                        </vue-table-card>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import codeStore from './code-store/vue-table-card.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'VueTableCardDocs',
    components: { ComponentWrapper, PropTable },
    data () {
        return {
            codeStore: codeStore,
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    titleClass: '',
                    dataClass: 'w-10r'
                },
                {
                    name: 'item',
                    title: 'Item Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'batch_no',
                    title: 'Batch No.',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'remarks',
                    title: 'Remarks',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    date: '05-02-2021',
                    item: 'Item 1',
                    batch_no: 'S5',
                    quantity: '975.56 Kg',
                    remarks: 'Lorem ipsum dolor sit amet'
                },
                {
                    date: '06-03-2021',
                    item: 'Item 2',
                    batch_no: 'S1',
                    quantity: '898.36 Kg',
                    remarks: 'Sed ut perspiciatis unde omniserror sit voluptatem accusanti'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 3',
                    batch_no: 'S2',
                    quantity: '958.12 Kg',
                    remarks: 'Nil'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 4',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 5',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-09-2021',
                    item: 'Item 6',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '15-09-2021',
                    item: 'Item 7',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '19-09-2021',
                    item: 'Item 8',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '23-09-2021',
                    item: 'Item 9',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '28-09-2021',
                    item: 'Item 10',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '30-09-2021',
                    item: 'Item 11',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-10-2021',
                    item: 'Item 12',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '08-10-2021',
                    item: 'Item 13',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '12-10-2021',
                    item: 'Item 14',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '23-09-2021',
                    item: 'Item 15',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '28-09-2021',
                    item: 'Item 16',
                    batch_no: 'S11',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '30-09-2021',
                    item: 'Item 17',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-10-2021',
                    item: 'Item 18',
                    batch_no: 'S9',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '08-10-2021',
                    item: 'Item 19',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '12-10-2021',
                    item: 'Item 20',
                    batch_no: 'S2',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                }
            ],
            propsInfo: [
                ['loadOnStart', 'Boolean', '', '', ''],
                ['fields', 'Array of Objects', 'Define fields(columns) in the table ', '', ''],
                ['tableData', 'Array of Objects', 'table data to be passed as array here', '', ''],
                ['url', 'String', 'Table data - api request url', '', ''],
                ['extraParams', 'Object', '', '', 'null'],
                ['perPage', 'Number', 'Data items rows to be shown per page', '', 'null'],
                ['showPagination', 'Boolean', 'show or hide pagination', ['true', 'false'], 'true'],
                ['pageSizes', 'Array', '', '', '() => [4, 8, 12]'],
                ['showBorder', 'Boolean', '', ['true', 'false'], 'false'],
                ['showSearchBox', 'Boolean', 'Show or hide search box input', ['true', 'false'], 'true'],
                ['searchPlaceholder', 'String', 'Search placeholder text', '', '() => \'Search\''],
                ['showCurrentPageDataIndexes', 'Boolean', 'Show data indexes in the current page text - formatted like "7 of 15"', ['true', 'false'], 'true'],
                ['filters', 'Array', '', '', 'default () {\n' +
                    '   return [];\n' +
                    '}'],
                ['containerClasses', 'String, Array', 'Component outer container, additional classes to be added', '', ''],
                ['activeShadow', 'String', 'Pagination active page item shadow', ['\'1\' - \'29\''], ''],
                ['firstIcon', 'String', 'Pagination First icon (leftmost icon) - icon class', ['\'fa fa-arrow-left,...\''], ''],
                ['lastIcon', 'String', 'Pagination Last icon (rightmost icon) - icon class', ['\'fa fa-arrow-right\',...etc'], ''],
                ['nextIcon', 'String', 'Pagination Next page button - icon class', ['\'fa fa-arrow-right\',...etc'], ''],
                ['prevIcon', 'String', 'Pagination Previous page button - icon class', ['\'fa fa-arrow-left\',...etc'], ''],
                ['paginationDesign', 'String', 'Pagination design type set', ['design-1', 'design-2', 'design-3'], 'design-1'],
                ['paginationPrevNextButtonDesign', 'String', 'pagination design-2- previous and next buttons( First Page & Last Page buttons in design-3) designs (refer lego-button docs - design prop)', ['basic-a', 'basic-b', 'basic-c & others'], 'basic-f'],
                ['paginationPrevNextButtonClasses', 'Array,String', 'pagination design-2 - previous and next buttons ( First Page & Last Page buttons in design-3) additional classes', '', ''],
                ['paginationContainerClasses', 'Array,String', 'pagination design-2 & 3 - pagination container additional classes', '', '']
            ]

            // propsData: [
            //     ['loadOnStart', 'Boolean', 'Related to data loading', ['true', 'false'], 'true'],
            //     ['fields', 'Array', 'Table fields (array of field data objects)', '', ''],
            //     ['tableData', 'Array', 'Array of data objects', '', ''],
            //     ['url', 'String', 'Data API Url', '', ''],
            //     ['extraParams', 'String', 'Extra params to be added to API Url', '', ''],
            //     ['perPage', 'Number', 'No of Data items shown per page', '', ''],
            //     ['showPagination', 'Boolean', 'Show or hide pagination', ['true', 'false'], 'true'],
            //     ['containerClasses', 'String, Array', 'root element additional classes', '', ''],
            //     ['searchPlaceholder', 'String', 'Search placeholder text', '', 'Search'],
            //     ['activeShadow', 'String', 'Active shadow', '', 'Search']
            // ]
        };
    }
};

</script>

<style scoped>

</style>
