<template>
    <page-template class="bg-2 py-4">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Vue Table</h3>
                    <p class="lead">Table build on top of plugin Vuetable-2. Advanced Table with API mode and Data mode for retrieving data. pagination, options to add filters and
                        other properties</p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import SimpleTable from 'lego-framework/src/tables/VueTable';</p>
                    <p>Vue.component('vue-table', VueTable);</p>
                    <p>&#60;vue-table :fields="fields" url="api-url"&#62;&#60;vue-table/&#62;</p>
                </div>
                <div class="col-12 mt-4">
                    <h4>API Mode and Data Mode</h4>
                    <p>
                        If it is working with an API endpoint to retrieve the data, it is called API mode. If it is working with an existing data supplied manually, it is called
                        Data mode.
                    </p>
                </div>
                <div class="col-12 mt-4">
                    <lego-heading tag="h5">API Mode</lego-heading>
                    <p>
                        In API mode, which is the default mode, Vuetable will interact with an API endpoint to request data from it via AJAX request.
                    </p>
                    <p>
                        Set prop 'url' with API url for fetching data <br><br>
                        &#60;vue-table :fields="fields" :url="api-url"&#62;&#60;vue-table/&#62;<br><br>
                        (Data is not loading now..set proper API url for retrieving data)
                    </p>
                    <component-wrapper :code="codeStore.apiMode.code">
                        <vue-table :per-page="2" :fields="fields" url="api-url"></vue-table>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <lego-heading tag="h5">Data Mode</lego-heading>
                    <p>
                        In Data mode, you tell Vuetable that you don't want it to handle data request, but you will supply that data to it instead.
                    </p>
                    <component-wrapper :code="codeStore.dataMode.code">
                        <vue-table :per-page="2" :fields="fields" :table-data="data"></vue-table>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-6">
                    <h4>Slots</h4>
                </div>
                <div class="col-12 mt-4">
                    <lego-heading tag="h5">Slot for adding Additional Filters</lego-heading>
                    <component-wrapper :code="codeStore.slotAdditionalFilters.code">
                        <vue-table :per-page="2" :fields="fields" :table-data="data">
                            <template slot="additional-filters">
                                <validated-input size="sm" placeholder="Status"></validated-input>
                            </template>
                        </vue-table>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-5">
                    <lego-heading tag="h5">Defining Custom Slots for modifying columns in Table</lego-heading>
                    <pre>{{codeStore.customSlots.code}}</pre>
                </div>
                <div class="col-12 mt-5">
                    <lego-heading tag="h5">Passing Classes for Styling Table</lego-heading>
                    <pre>{{codeStore.passingModifierClasses.code}}</pre>
                </div>
                <div class="col-12 mt-6">
                    <h4>Pagination Variants</h4>
                </div>
                <div class="col-12 mt-4">
                    <lego-heading tag="h5">Default</lego-heading>
                    <component-wrapper :code="codeStore.paginationDefault.code">
                        <vue-table :per-page="2" :fields="fields" :table-data="data"></vue-table>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4 bg-3">
                    <lego-heading tag="h5">Defaulfasdfasdfasdft</lego-heading>
                    <!--                    <component-wrapper class="bg-1" :code="codeStore.paginationDefault.code">-->
                    <vue-table-card title="tile" :per-page="2" :fields="auctionFields" url="https://venturaadmin.xeoscript.com/api/auth/bank/list/"></vue-table-card>
                    <!--                    </component-wrapper>-->
                </div>
                <div class="col-12 mt-4">
                    <lego-heading tag="h5">Design 2</lego-heading>
                    <p>Set prop <br><br> <b>paginationDesign="design-2"</b></p>
                    <component-wrapper :code="codeStore.paginationDesign2.code">
                        <vue-table pagination-design="design-2" :per-page="2" :fields="fields" :table-data="data"></vue-table>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <lego-heading tag="h5">Design 3</lego-heading>
                    <p>Set prop <br><br> <b>paginationDesign="design-3"</b></p>
                    <component-wrapper :code="codeStore.paginationDesign3.code">
                        <vue-table pagination-design="design-3" :per-page="2" :fields="fields" :table-data="data"></vue-table>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import codeStore from './code-store/vue-table.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'VueTableDocs',
    components: { ComponentWrapper, PropTable },
    data () {
        return {
            codeStore: codeStore,
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    titleClass: '',
                    dataClass: 'w-10r'
                },
                {
                    name: 'item',
                    title: 'Item Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'batch_no',
                    title: 'Batch No.',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'remarks',
                    title: 'Remarks',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    date: '05-02-2021',
                    item: 'Item 1',
                    batch_no: 'S5',
                    quantity: '975.56 Kg',
                    remarks: 'Lorem ipsum dolor sit amet'
                },
                {
                    date: '06-03-2021',
                    item: 'Item 2',
                    batch_no: 'S1',
                    quantity: '898.36 Kg',
                    remarks: 'Sed ut perspiciatis unde omniserror sit voluptatem accusanti'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 3',
                    batch_no: 'S2',
                    quantity: '958.12 Kg',
                    remarks: 'Nil'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 4',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 5',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-09-2021',
                    item: 'Item 6',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '15-09-2021',
                    item: 'Item 7',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '19-09-2021',
                    item: 'Item 8',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '23-09-2021',
                    item: 'Item 9',
                    batch_no: 'S5',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '28-09-2021',
                    item: 'Item 10',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '30-09-2021',
                    item: 'Item 11',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-10-2021',
                    item: 'Item 12',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '08-10-2021',
                    item: 'Item 13',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '12-10-2021',
                    item: 'Item 14',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '23-09-2021',
                    item: 'Item 15',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '28-09-2021',
                    item: 'Item 16',
                    batch_no: 'S11',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '30-09-2021',
                    item: 'Item 17',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '05-10-2021',
                    item: 'Item 18',
                    batch_no: 'S9',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '08-10-2021',
                    item: 'Item 19',
                    batch_no: 'S7',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                },
                {
                    date: '12-10-2021',
                    item: 'Item 20',
                    batch_no: 'S2',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                }
            ],
            auctionFields: [
                {
                    name: 'sl_no',
                    dataClass: 'aligned text-center',
                    title: 'Sl. No'
                },
                {
                    name: '__slot:bank_logo',
                    dataClass: 'aligned text-center',
                    title: 'Bank Logo'
                },
                {
                    name: 'bank_name',
                    dataClass: 'aligned text-center',
                    title: 'Bank Name'
                },
                {
                    name: 'active_users',
                    dataClass: 'aligned text-center',
                    title: 'Active Users'
                },
                {
                    name: 'inactive_users',
                    dataClass: 'aligned text-center',
                    title: 'Inactive Users'
                },
                {
                    name: '__slot:status',
                    dataClass: 'aligned text-center',
                    title: 'Status'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right',
                    title: 'Actions'
                }
            ],
            propsInfo: [
                ['loadOnStart', 'Boolean', '', '', ''],
                ['fields', 'Array of Objects', 'Define fields(columns) in the table ', '', ''],
                ['tableData', 'Array of Objects', 'table data to be passed as array here', '', ''],
                ['url', 'String', 'Table data - api request url', '', ''],
                ['extraParams', 'Object', '', '', 'null'],
                ['perPage', 'Number', 'Data items rows to be shown per page', '', 'null'],
                ['showPagination', 'Boolean', 'show or hide pagination', ['true', 'false'], 'true'],
                ['pageSizes', 'Array', '', '', '() => [4, 8, 12]'],
                ['showBorder', 'Boolean', '', ['true', 'false'], 'false'],
                ['showSearchBox', 'Boolean', 'Show or hide search box input', ['true', 'false'], 'true'],
                ['searchPlaceholder', 'String', 'Search placeholder text', '', '() => \'Search\''],
                ['showCurrentPageDataIndexes', 'Boolean', 'Show data indexes in the current page text - formatted like "7 of 15"', ['true', 'false'], 'true'],
                ['filters', 'Array', '', '', 'default () {\n' +
                    '   return [];\n' +
                    '}'],
                ['containerClasses', 'String, Array', 'Component outer container, additional classes to be added', '', ''],
                ['activeShadow', 'String', 'Pagination active page item shadow', ['\'1\' - \'29\''], ''],
                ['firstIcon', 'String', 'Pagination First icon (leftmost icon) - icon class', ['\'fa fa-arrow-left,...\''], ''],
                ['lastIcon', 'String', 'Pagination Last icon (rightmost icon) - icon class', ['\'fa fa-arrow-right\',...etc'], ''],
                ['nextIcon', 'String', 'Pagination Next page button - icon class', ['\'fa fa-arrow-right\',...etc'], ''],
                ['prevIcon', 'String', 'Pagination Previous page button - icon class', ['\'fa fa-arrow-left\',...etc'], ''],
                ['paginationDesign', 'String', 'Pagination design type set', ['design-1', 'design-2', 'design-3'], 'design-1'],
                ['paginationPrevNextButtonDesign', 'String', 'pagination design-2- previous and next buttons( First Page & Last Page buttons in design-3) designs (refer lego-button docs - design prop)', ['basic-a', 'basic-b', 'basic-c & others'], 'basic-f'],
                ['paginationPrevNextButtonClasses', 'Array,String', 'pagination design-2 - previous and next buttons ( First Page & Last Page buttons in design-3) additional classes', '', ''],
                ['paginationContainerClasses', 'Array,String', 'pagination design-2 & 3 - pagination container additional classes', '', '']
            ]
        };
    }
}
;
</script>

<style scoped>
    .bg-gray-100 {
        background-color: var(--rgb-danger-100) !important;
    }
</style>
