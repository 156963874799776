<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <h3 class="my-3">Theme.json Settings</h3>
                    <h4>Basics</h4>
                    <p>Basic colors, black white and gray</p>
                    <code-container :code-str="baseColors" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-12">
                    <h4>Gray Colors</h4>
                    <p>Gray color variants based on thickness/opacity</p>
                    <code-container :code-str="defautTheme['gray-colors']" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-12">
                    <h4>Background Colors</h4>
                    <p>Background color variants, additional items can be added</p>
                    <code-container :code-str="defautTheme['background-colors']" show-code-no-toggle></code-container>
                    <h5>Related classes</h5>
                    <p>bg-1, bg-2, bg-3, bg-4, bg-1-h, bg-2-h bg-3-h</p>
                </div>
                <div class="col-lg-12">
                    <h4>Border Colors</h4>
                    <p>Border colors default, light and dark variants</p>
                    <code-container :code-str="defautTheme['border-color-levels']" show-code-no-toggle></code-container>
                    <h5>Usage</h5>
                    <div class="p-2 b-1 mt-2"></div>
                    <div class="p-2 b-1 b-light mt-2"></div>
                    <div class="p-2 b-1 b-dark mt-2"></div>
                    <code-container :code-str="codeStore.borders.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-12">
                    <h4>Text Colors</h4>
                    <p>Text color variants, additional items can be added as text-5,text-6,..etc</p>
                    <code-container :code-str="defautTheme['text-colors']" show-code-no-toggle></code-container>
                    <h5>Usage</h5>
                    <div class="text-1 mt-2">this is color text-1</div>
                    <div class="text-2 mt-2">this is color for text-2</div>
                    <div class="text-3 mt-2">this is color for text-3</div>
                    <div class="text-4 mt-2">this is color for text-4</div>
                    <code-container :code-str="codeStore.textColor.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-12">
                    <h4 class="my-2">Widths</h4>
                    <code-container :code-str="defautTheme.widths" show-code-no-toggle></code-container>
                    <h5>Usage</h5>
                    <p>Width will be added to an element on adding the class</p>
                    <p>eg: <span class="text-4">class:</span>  w-20r</p>
                    <p> <span class="text-4">style</span> - width:20rem;</p>
                </div>
                <div class="col-lg-12">
                    <h4 class="my-2">Spacers</h4>
                    <code-container :code-str="defautTheme.spacers" show-code-no-toggle></code-container>
                    <p>Spacer levels can be added here</p>
                    <h5>spacer levels</h5>
                    <div v-for="i in 9" :key="i"  class="py-1 mt-3"><span class="bg-primary text-fs-0" :class="['px-' + i]">{{'spacer-' + i}}</span></div>
                    <p>eg: <span class="text-4">class:</span>  w-20r</p>
                    <p> <span class="text-4">style</span> - width:20rem;</p>
                </div>
                <div class="col-lg-12">
                    <h4 class="my-2">Border Sizes</h4>
                    <code-container :code-str="defautTheme.spacers" show-code-no-toggle></code-container>
                    <p>Border size levels can be added here</p>
                    <h5>size levels</h5>
                    <div class="mt-3"><span v-for="i in 10" :key="i" class="w-10r p-2 mt-5 ml-3" :class="['b-' + i]">{{'b-' + i}}</span></div>
                </div>
                <div class="col-lg-12">
                    <h4 class="my-3">Border Radiuses</h4>
                    <code-container :code-str="defautTheme['border-radius']" show-code-no-toggle></code-container>
                    <p>Border Radius levels can be added here</p>
                    <h5 class="my-3">Border radius variants</h5>
                    <div><span v-for="i in 10" :key="i" class="w-10r p-2 b-1 mt-5 ml-3" :class="['round-' + i]">{{'round-' + i}}</span></div>
                </div>
                <div class="col-lg-12">
                    <h4 class="my-3">Grid Breakpoints</h4>
                    <code-container :code-str="defautTheme['grid-breakpoints']" show-code-no-toggle></code-container>
                    <p>Responsive grid breakpoints can be set here</p>
                </div>
                <div class="col-lg-12">
                    <h4 class="my-3">Container Max Widths</h4>
                    <code-container :code-str="defautTheme['container-max-widths']" show-code-no-toggle></code-container>
                    <p>Container max widths can be set here</p>
                </div>
                <div class="col-lg-12 mt-4">
                    <h3>Gray Colors - SCSS Variables</h3>
                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>
                 <div class="col-lg-12 mt-4">
                    <h3>Spacers - SCSS Variables</h3>
                    <simple-table :fields="fields1" :data="data1" class="b-1"></simple-table>
                </div>
                 <div class="col-lg-12 mt-4">
                    <h3>Theme Colors - SCSS Variables</h3>
                    <simple-table :fields="fields2" :data="data2" class="b-1"></simple-table>
                </div>
                <div class="col-lg-12 mt-4">
                    <h3>Base Variable - SCSS Variables</h3>
                    <simple-table :fields="fields3" :data="data3" class="b-1"></simple-table>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';
import defautTheme from './default-theme.json';
import codeStore from './code-store/theme-docs.json';

export default {
    name: 'StylesThemeDocs',
    components: { CodeContainer },
    data () {
        return {
            fields3: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data3: [
                {
                    slno: '1',
                    name: '--body-bg-color',
                    description: 'depended on variable ( --color-white )',
                    defaultvalue: 'var(--color-white), #FFFFFF'
                },
                {
                    slno: '2',
                    name: '--body-text-color',
                    description: 'depended on variable ( --color-gray-900 )',
                    defaultvalue: 'var(--color-gray-900), darker color of #adb5bd'
                }
            ],
            fields2: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data2: [
                {
                    slno: '1',
                    name: '--color-primary',
                    description: 'Primary color',
                    defaultvalue: 'set on project'
                },
                {
                    slno: '2',
                    name: '--color-primary-100',
                    description: 'primary color with thickness value 100 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '3',
                    name: '--color-primary-200',
                    description: 'primary color with thickness value 200 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '4',
                    name: '--color-primary-300',
                    description: 'primary color with thickness value 300 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '5',
                    name: '--color-primary-400',
                    description: 'primary color with thickness value 400 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '6',
                    name: '--color-primary-600',
                    description: 'primary color with thickness value 600 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '7',
                    name: '--color-primary-700',
                    description: 'primary color with thickness value 700 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '8',
                    name: '--color-primary-800',
                    description: 'primary color with thickness value 800 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '9',
                    name: '--color-primary-900',
                    description: 'primary color with thickness value 900 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '10',
                    name: '--rgb-primary-100',
                    description: 'primary color with thickness value 100 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '11',
                    name: '--rgb-primary-200',
                    description: 'primary color with thickness value 200 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '12',
                    name: '--rgb-primary-300',
                    description: 'primary color with thickness value 300 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '13',
                    name: '--rgb-primary-400',
                    description: 'primary color with thickness value 400 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '14',
                    name: '--rgb-primary-600',
                    description: 'primary color with thickness value 600 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '15',
                    name: '--rgb-primary-700',
                    description: 'primary color with thickness value 700 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '16',
                    name: '--rgb-primary-800',
                    description: 'primary color with thickness value 800 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '17',
                    name: '--rgb-primary-900',
                    description: 'primary color with thickness value 900 ',
                    defaultvalue: 'derived from primary colors'
                }

            ],
            fields1: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data1: [
                {
                    slno: '1',
                    name: '--spacer-0',
                    description: 'spacer 0',
                    defaultvalue: '0rem'
                },
                {
                    slno: '2',
                    name: '--spacer-1',
                    description: 'spacer 1',
                    defaultvalue: '0.25rem'
                },
                {
                    slno: '3',
                    name: '--spacer-2',
                    description: 'spacer 2',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '4',
                    name: '--spacer-3',
                    description: 'spacer 3',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--spacer-4',
                    description: 'spacer 4',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '6',
                    name: '--spacer-5',
                    description: 'spacer 5',
                    defaultvalue: '3rem'
                },
                {
                    slno: '7',
                    name: '--spacer-6',
                    description: 'spacer 6',
                    defaultvalue: '5rem'
                },
                {
                    slno: '8',
                    name: '--spacer-7',
                    description: 'spacer 7',
                    defaultvalue: '7rem'
                },
                {
                    slno: '9',
                    name: '--spacer-8',
                    description: 'spacer 8',
                    defaultvalue: '9rem'
                },
                {
                    slno: '10',
                    name: '--spacer-9',
                    description: 'spacer 9',
                    defaultvalue: '12rem'
                }
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--gray-100',
                    description: 'gray color thickness 100',
                    defaultvalue: '#f8f9fa'
                },
                {
                    slno: '2',
                    name: '--gray-200',
                    description: 'gray color thickness 200',
                    defaultvalue: '#e9ecef'
                },
                {
                    slno: '3',
                    name: '--gray-300',
                    description: 'gray color thickness 300',
                    defaultvalue: '#dee2e6'
                },
                {
                    slno: '4',
                    name: '--gray-400',
                    description: 'gray color thickness 400',
                    defaultvalue: '#ced4da'
                },
                {
                    slno: '5',
                    name: '--gray-600',
                    description: 'gray color thickness 600',
                    defaultvalue: '#6c757d'
                },
                {
                    slno: '6',
                    name: '--gray-700',
                    description: 'gray color thickness 700',
                    defaultvalue: '#495057'
                },
                {
                    slno: '7',
                    name: '--gray-800',
                    description: 'gray color thickness 800',
                    defaultvalue: '#343a40'
                },
                {
                    slno: '8',
                    name: '--gray-900',
                    description: 'gray color thickness 900',
                    defaultvalue: '#212529'
                }
            ],
            defautTheme: defautTheme,
            baseColors: '    "base-colors": {\n' +
                    '        "color-black": "#000000",\n' +
                    '        "color-white": "#FFFFFF",\n' +
                    '        "color-gray": "#adb5bd"\n' +
                    '    }',
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
