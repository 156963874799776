<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Activity Gauge Items</h2>
                    <p class="lead">activity gauge components</p>
                    <h4 class="my-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.activityGauge.code"/>
                </div>
                <div class="col-12">
                    <h2 class="my-3">Variants</h2>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Small</h5>
                    <activity-gauge-sm
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.small.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Small, design: basic-b</h5>
                    <activity-gauge-sm
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"
                        design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.smallBasicB.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">Medium</h5>
                    <activity-gauge-md
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.medium.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">Medium, design: basic-b</h5>
                    <activity-gauge-md
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"
                        design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.mediumBasicB.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">Large</h5>
                    <activity-gauge-lg
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.large.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">Large, design: basic-b</h5>
                    <activity-gauge-lg
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"
                        design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.largeBasicB.code"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4 class="my-3">Circles Variants</h4>
                    <h5 class="my-2">Usage</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.circles.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">circle - sm</h5>
                    <activity-gauge-circle-sm
                        :value1="75"
                        :value2="85"
                        :value3="80"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.circleSm.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">circle - md</h5>
                    <activity-gauge-circle-md
                        :value1="75"
                        :value2="85"
                        :value3="80"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.circleMd.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">circle - lg</h5>
                    <activity-gauge-circle-lg
                        label-font-size="12px"
                        label-font-weight="normal"
                        :value1="75"
                        :value2="85"
                        :value3="80"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.circleLg.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">circle - customizable</h5>
                    <h5 class="my-3">Customizable</h5>
                    <activity-gauge-circle-customizable
                        series1-color="info"
                        series2-color="danger"
                        series3-color="success"
                        :total-value="800"
                        total-value-font-size="24px"
                        label-font-size="12px"
                        label="Active Users"
                        storke-width="12px"
                        lg-radius="100"
                        md-radius="74"
                        sm-radius="50"
                        :value1="75"
                        :value2="85"
                        :value3="80"/>
                    <code-container show-code-no-toggle :code-str="codeStore.circleCustomizable.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
            <div class="mt-4">
                <h3>SCSS Variable</h3>

                <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
            </div>
        </div>
    </page-template>
</template>

<script>
import ActivityGaugeCircleSm from '../../../dashboard-components/activity-gauges/circles/ActivityGaugeCircleSm';
import ActivityGaugeCircleMd from '../../../dashboard-components/activity-gauges/circles/ActivityGaugeCircleMd';
import ActivityGaugeCircleLg from '../../../dashboard-components/activity-gauges/circles/ActivityGaugeCircleLg';
import ActivityGaugeCircleCustomizable
    from '../../../dashboard-components/activity-gauges/circles/ActivityGaugeCircleCustomizable';
import ActivityGaugeSm from '../../../dashboard-components/activity-gauges/ActivityGaugeSm';
import ActivityGaugeMd from '../../../dashboard-components/activity-gauges/ActivityGaugeMd';
import ActivityGaugeLg from '../../../dashboard-components/activity-gauges/ActivityGaugeLg';
import codeStore from '../code-storage/activity-gauge.json';
import CodeContainer from '../../../components/doc-items/CodeContainer';
import PropTable from '../../docs-components/Props';

export default {
    name: 'ActivityGaugesDocsPage',
    metaInfo: {
        path: 'dashboard-components/activity-gauge/',
        title: 'Activity Gauge'
    },
    components: {
        ActivityGaugeLg,
        ActivityGaugeMd,
        ActivityGaugeSm,
        ActivityGaugeCircleCustomizable,
        ActivityGaugeCircleLg,
        ActivityGaugeCircleMd,
        ActivityGaugeCircleSm,
        CodeContainer,
        PropTable
    },
    data () {
        return {
            value1: 75,
            value2: 75,
            value3: 75,
            value4: 75,
            value5: 75,
            value6: 75,
            value7: 75,
            value8: 75,
            value9: 75,
            codeStore: codeStore,
            propsInfo: [
                ['value1', 'String', 'inner circle value', ['Numbers  0 - 100'], '75'],
                ['value2', 'String', 'second circle value', ['Numbers  0 - 100'], '60'],
                ['value3', 'String', 'outer circle value', ['Numbers  0 - 100'], '80'],
                ['totalValue', 'String', 'total value shown on center', '', '1000'],
                ['label', 'String', 'Label on center', '', 'Users'],
                ['color', 'String', 'base color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'secondary'],
                ['design', 'String', 'design based on alignment of the indicators ( not on ActivityGaugeCircle component)', ['basic-a', 'basic-b'], 'basic-a'],
                ['labelFontSize', 'String', 'font size of label on center', ['value in px unit'], '16px'],
                ['labelFontWeight', 'String', 'font weight of label on center', ['normal', 'bold'], 'bold'],
                ['totalValueFontSize', 'String', 'font size of total value on center', ['value in px unit'], '30px'],
                ['totalValueFontWeight', 'String', 'font weight of total value on center', ['normal', 'bold'], 'bold']
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--activity-gauge-circle-sm-stoke-dasharray',
                    description: 'inner circle stroke dasharray value',
                    defaultvalue: '0'
                },
                {
                    slno: '2',
                    name: '--activity-gauge-circle-sm-stoke-dash-offset',
                    description: 'inner circle stroke dash offset value',
                    defaultvalue: '0'
                },
                {
                    slno: '3',
                    name: '--activity-gauge-circle-md-stoke-dasharray',
                    description: 'middle circle stroke dasharray value',
                    defaultvalue: '0'
                },
                {
                    slno: '4',
                    name: '--activity-gauge-circle-md-stoke-dash-offset',
                    description: 'middle circle stroke dash offset value',
                    defaultvalue: '0'
                },
                {
                    slno: '5',
                    name: '--activity-gauge-circle-lg-stoke-dasharray',
                    description: 'outer circle stroke dasharray value',
                    defaultvalue: '0'
                },
                {
                    slno: '6',
                    name: '--activity-gauge-circle-lg-stoke-dash-offset',
                    description: 'outer circle stroke dash offset value',
                    defaultvalue: '0'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
