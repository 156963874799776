<template>
    <component class="custom-spacing-element-container" :is="tag"
               :style="[setMarginProperties,setPaddingProperties, setDisplayProperties]"
               :class="[bgColorClasses, containerClasses]">
        <slot>{{text}}</slot>
    </component>
</template>

<script>
import BgColorThicknessMixin from '../mixins/BgColorThicknessMixin';

export default {
    name: 'CustomSpacingElement',
    props: {
        tag: { type: String, default: 'div' },
        text: { type: String, default: '' },
        display: { type: String, default: 'block' },
        p: { type: String, default: '' },
        pl: { type: String, default: '' },
        pr: { type: String, default: '' },
        pt: { type: String, default: '' },
        pb: { type: String, default: '' },
        m: { type: String, default: '' },
        ml: { type: String, default: '' },
        mr: { type: String, default: '' },
        mt: { type: String, default: '' },
        mb: { type: String, default: '' },
        width: { type: String, default: '' },
        height: { type: String, default: '' },
        containerClasses: { type: [String, Array], default: '' }
    },
    computed: {
        setMarginProperties () {
            return {
                '--custom-spacing-container-m': this.m,
                '--custom-spacing-container-mt': this.mt,
                '--custom-spacing-container-mr': this.mr,
                '--custom-spacing-container-mb': this.mb,
                '--custom-spacing-container-ml': this.ml
            };
        },
        setPaddingProperties () {
            return {
                '--custom-spacing-container-p': this.p,
                '--custom-spacing-container-pt': this.pt,
                '--custom-spacing-container-pr': this.pr,
                '--custom-spacing-container-pb': this.pb,
                '--custom-spacing-container-pl': this.pl
            };
        },
        setDisplayProperties () {
            let heightVal = this.height;
            let widthVal = this.width;
            if (this.height.slice('-1') === 'r') {
                heightVal = this.height + 'em';
            }
            if (this.width.slice('-1') === 'r') {
                widthVal = this.width + 'em';
            }
            return {
                '--custom-spacing-container-display': this.display,
                '--custom-spacing-container-width': widthVal,
                '--custom-spacing-container-height': heightVal
            };
        }
    },
    mixins: [BgColorThicknessMixin]
};
</script>

<style scoped>

</style>
