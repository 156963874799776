<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Tag Item Two Texts</h2>
                    <p class="lead">Tag item with two texts</p>
                    <h4 class="my-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Default</h6>
                    <tag-item text1="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">bullet color : danger</h6>
                    <tag-item bullet-color="danger" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.bulletColorDanger.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border Radius: 2</h6>
                    <tag-item border-radius="2" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderRadius2.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border : 2</h6>
                    <tag-item border="2" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.border2.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Shadow : 5</h6>
                    <tag-item shadow="5" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.shadow5.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border Radius : 0</h6>
                    <tag-item border-radius="0" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderRadius0.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border Color : info</h6>
                    <tag-item border-color="info" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderColorInfo.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Font Customization</h6>
                    <tag-item text1-font-size="0" text1-font-weight="500"
                              text1-color="2" text2-color="info" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.fontCustomization.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import TagItem from 'lego-framework/src/web-components/tag-items/TagItemTwoTexts';
import codeStore from '../code-storage/tag-item-two-texts.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'TagItemTwoTextsVariationsDocumentation',
    metaInfo: {
        path: 'ui-elements/tag-item-two-texts/',
        title: 'Tag with Two Texts'
    },
    components: { TagItem, CodeContainer, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'Main Text', '', ''],
                ['text2', 'String', 'sub text', '', ''],
                ['border', 'String', 'border variant', ['1 - 10'], '1'],
                ['borderColor', 'String', 'bg Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['borderRadius', 'String', 'border radius', ['0 - 10'], ''],
                ['bulletColor', 'String', 'bullet color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['shadow', 'String', 'shadow variant', ['1- 29'], ''],
                ['showBullet', 'Boolean', 'Show/Hide bullet', ['true', 'false'], 'true'],
                ['text1Color', 'String', 'text 1 color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text1FontSize', 'String', 'text 1 font size', ['-1 - 7'], '1'],
                ['text1FontWeight', 'String', 'text 1 font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder', '100 - 900'], '500'],
                ['text1Classes', 'String, Array', ' text 1 additional classes', '', ''],
                ['text2Color', 'String', 'text 2 color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text2FontSize', 'String', 'text 2 font size', ['-1 - 7'], '1'],
                ['text2FontWeight', 'String', 'text 2 font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder', '100 - 900'], '500'],
                ['text2Classes', 'String, Array', ' text 2 additional classes', '', ''],
                ['containerClasses', 'String,Array', 'Container additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
