<template>
    <page-template class="p-5">
        <h2>Check box</h2>
        <p class="lead">Check box does not support all the properties of input. It just takes v-model and label</p>
        <div class="mb-5">
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import FormCheckbox from 'lego-framework/src/forms/FormCheckbox';</p>
            <p>Vue.component('validated-checkbox', FormCheckbox);</p>
            <p>&#60;validated-checkbox/&#62;</p>
        </div>
        <component-wrapper :code="codeStore.withAndWithoutLabel.code">
            <div class="row">
                <div class="col-md-3">
                    <b>Without Label</b>
                    <FormCheckbox/>
                </div>
                <div class="col-md-6">
                    <b>With Label</b>
                    <FormCheckbox label="I agree to terms and conditions"/>
                </div>
            </div>
            <div class="card bg-info"><b>Note</b> The checkbox takes the height of a input without label so it can be easily placed adjacent to it.</div>
        </component-wrapper>

        <component-wrapper class="mt-4" :code="codeStore.alignment1.code">
            <div class="row mt-3">
                <div class="col-md-3">
                    <b>Form Input</b>
                    <FormInput label=""/>
                </div>
                <div class="col-md-6">
                    <b>Checkbox</b>
                    <FormCheckbox label="I agree to terms and conditions"/>
                </div>
            </div>
            <div class="card bg-info"><b>Note</b> In order to use it with form control with label use flex alignments to the parent. <b>fl-x-b</b> will do.</div>
        </component-wrapper>

        <component-wrapper class="mt-4" :code="codeStore.alignment2.code">
            <div class="row fl-x-b mt-3">
                <div class="col-md-3">
                    <FormInput label="Username"/>
                </div>
                <div class="col-md-6">
                    <FormCheckbox label="I agree to terms and conditions"/>
                </div>
            </div>
        </component-wrapper>

        <div>
            <h2 class="my-3">Props</h2>
            <prop-table :items="props"/>
        </div>

        <div>
            <h2>Slots</h2>
            <p class="lead">Currently no slots are implemented in form input</p>
        </div>

        <div>
            <h2>Events</h2>
            <p class="lead">All the standard events are supported in button</p>
        </div>

    </page-template>
</template>

<script>
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import FormCheckbox from 'lego-framework/src/forms/FormCheckbox';
import FormInput from 'lego-framework/src/forms/FormInput';
import helpersMixin from '../helpersMixin';
import codeStore from './code-storage/checkbox.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'components/check-box/',
        title: 'Check box'
    },
    name: 'FileInputDocumentation',
    data () {
        return {
            props: [
                ['label', 'String', 'The label for the control']
            ],
            codeStore: codeStore
        };
    },
    components: { ComponentWrapper, FormCheckbox, FormInput, PropTable }
};
</script>

<style scoped>

</style>
