<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Theme Colors</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--color-primary',
                    description: 'Primary color',
                    defaultvalue: 'set on project'
                },
                {
                    slno: '2',
                    name: '--color-primary-100',
                    description: 'primary color with thickness value 100 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '3',
                    name: '--color-primary-200',
                    description: 'primary color with thickness value 200 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '4',
                    name: '--color-primary-300',
                    description: 'primary color with thickness value 300 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '5',
                    name: '--color-primary-400',
                    description: 'primary color with thickness value 400 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '6',
                    name: '--color-primary-600',
                    description: 'primary color with thickness value 600 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '7',
                    name: '--color-primary-700',
                    description: 'primary color with thickness value 700 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '8',
                    name: '--color-primary-800',
                    description: 'primary color with thickness value 800 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '9',
                    name: '--color-primary-900',
                    description: 'primary color with thickness value 900 ',
                    defaultvalue: 'derived from primary colors'
                }, {
                    slno: '10',
                    name: '--rgb-primary-100',
                    description: 'primary color with thickness value 100 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '11',
                    name: '--rgb-primary-200',
                    description: 'primary color with thickness value 200 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '12',
                    name: '--rgb-primary-300',
                    description: 'primary color with thickness value 300 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '13',
                    name: '--rgb-primary-400',
                    description: 'primary color with thickness value 400 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '14',
                    name: '--rgb-primary-600',
                    description: 'primary color with thickness value 600 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '15',
                    name: '--rgb-primary-700',
                    description: 'primary color with thickness value 700 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '16',
                    name: '--rgb-primary-800',
                    description: 'primary color with thickness value 800 ',
                    defaultvalue: 'derived from primary colors'
                },
                {
                    slno: '17',
                    name: '--rgb-primary-900',
                    description: 'primary color with thickness value 900 ',
                    defaultvalue: 'derived from primary colors'
                }

            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
