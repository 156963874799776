<template>
    <page-template>
        <div class="container-fluid py-3 bg-2">
            <div>
                <h2>Image and Background</h2>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value) in classNames" :key="value.title">
                        <td>
                            {{ value.title }}
                        </td>
                        <td>{{ value.desc }}</td>
                    </tr>
                </table>
                <div class="bg-4 p-4">
                    <h2>Image and Background Utilities</h2>
                    <p>These utility classes help you manage images and backgrounds effectively in your web design. They
                        provide flexibility for responsive images and various background properties.</p>

                    <h5>Image Utilities</h5>
                    <p>The following classes can be used to control image properties:</p>

                    <ul class="pl-3">
                        <li><strong>.img-fluid</strong>: Makes the image responsive, ensuring it fits the width of its
                            container with <code>max-width: 100%</code> and maintains the aspect ratio with <code>height:
                                auto</code>.
                        </li>
                    </ul>

                    <h5 class="mt-4">Background Utilities</h5>
                    <p>Background utilities allow you to customize the appearance of backgrounds:</p>

                    <ul class="pl-3">
                        <li class="pt-2"><strong>.bg</strong>: Sets the background to not repeat using <code>background-repeat:
                            no-repeat;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-cover</strong>: Makes the background image cover the entire
                            container
                            while
                            maintaining its aspect ratio using <code>background-size: cover;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-contain</strong>: Ensures the background image is fully contained
                            within the
                            container, using <code>background-size: contain;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-auto</strong>: Resets the background size to auto, using <code>background-size:
                            auto;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-pos-t</strong>: Positions the background at the top using <code>background-position:
                            top;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-pos-c</strong>: Centers the background image using <code>background-position:
                            center;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-pos-a</strong>: Sets the background position to auto, using <code>background-position:
                            auto;</code>.
                        </li>
                        <li class="pt-2"><strong>.bg-pos-u</strong>: Unsets the background position, using <code>background-position:
                            unset;</code>.
                        </li>
                    </ul>
                </div>
                <div class="mt-4">
                    <h3>Example Usage</h3>
                    <p>Here’s how to apply these classes in your HTML:</p>
                    <code-container show-code-no-toggle :code-str="codeStore.imgandbackground.code"/>

                    <div class="img-fluid">
                        <img src="../../assets/img/banner/photo-1619425234456-55a97b272611.jpg" alt="Example Image"
                             class="img-fluid">
                    </div>
                    <div class="bg bg-cover"
                         style="background-image: url('../../assets/img/banner/blue-layer-1.jpg'); height: 300px;">
                        This background image covers the entire div.
                    </div>
                    <div class="bg bg-contain"
                         style="background-image: url('../../assets/img/banner/blue-layer-1.jpg'); height: 300px;">
                        This background image is contained within the div.
                    </div>
                    <div class="bg" style="background-image: url('../../assets/img/banner/banner.jpg'); height: 300px;">
                        This background does not repeat.
                    </div>

                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../code-storage/imgandbackground.json';

export default {
    name: 'ImageAnbBackgroundHelpers',
    components: { CodeContainer },
    metaInfo: {
        path: 'helpers/image-and-bg/',
        title: 'Image And Background'
    },
    data () {
        return {
            classNames: [
                { title: '.img-fluid', desc: 'image width fits to the container ( max-width:100%, height:auto)' },
                { title: '.bg', desc: 'background-repeat: no-repeat;' },
                { title: '.bg-cover', desc: 'background-size:cover;' },
                { title: '.bg-contain', desc: 'background-size:cover;' },
                { title: '.bg-auto', desc: 'background-size:auto;' },
                { title: '.bg-auto', desc: 'background-size:unset;' },
                { title: '.bg-pos-t', desc: 'background-position: top;' },
                { title: '.bg-pos-c', desc: 'background-position: center;' },
                { title: '.bg-pos-a', desc: 'background-position: auto;' },
                { title: '.bg-pos-u', desc: 'background-position: unset;' }
            ],
            codeStore: codeStore

        };
    }
};
</script>

<style scoped>

</style>
