<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <div>
                        <h3 class=" py-2">Team Avatar Item</h3>
                        <p class="lead">Team Avatar display component</p>
                    </div>
                </div>
                <div class="col-12">
                    <h4 class="my-3">Basic Usage</h4>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.basicUse.code"/>
                </div>
                <div class="col-12">
                    <h3 class="my-3"><span class="text-italic">Variations</span></h3>
                    <h4 class="my-3">1. Team Avatar item horizontal</h4>
                    <h5 class="my-3">Usage</h5>
                    <code-container class="mb-3" :show-code-no-toggle="true" :code-str="codeStore.horizontal.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>Default</h6>
                    <team-avatar-item-horizontal
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>Image shape - round</h6>
                    <team-avatar-item-horizontal
                        img-shape="round"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgShapeRound.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>Image border radius</h6>
                    <team-avatar-item-horizontal
                        img-border-radius="3"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgBorderRadius.code"/>
                </div>
                <div class="col-12">
                    <h4 class="my-4">Size Variants</h4>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 1</h6>
                    <team-avatar-item-horizontal
                        img-shape="round"
                        img-size="1"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgSize1.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 2</h6>
                    <team-avatar-item-horizontal
                        img-shape="round"
                        img-size="2"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgSize2.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 3</h6>
                    <team-avatar-item-horizontal
                        :img="avatarImg"
                        img-shape="round"
                        img-size="3"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgSize3.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 4</h6>
                    <team-avatar-item-horizontal
                        :img="avatarImg"
                        img-shape="round"
                        img-size="4"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgSize4.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 5</h6>
                    <team-avatar-item-horizontal
                        content-container-classes="mt-4"
                        img-shape="round" img-size="5"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.imgSize5.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>Card Design</h5>
                    <team-avatar-item-horizontal
                        card-design
                        img-size="3"
                        img-border-radius="2"
                        border-radius="2"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.cardDesign.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>Text Fonts Customization</h5>
                    <team-avatar-item-horizontal
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."
                        text1-font-size="1"
                        text1-font-weight="500"
                        text2-font-size="0"
                        text2-color="primary"
                        text3-font-size="1"
                        text3-classes="font-weight-light"
                        text3-color="2"
                        img-size="2"
                        img-border-radius="2"
                        social-icon-color="secondary"
                        card-design/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.fontCustomization.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>Slots</h5>
                    <team-avatar-item-horizontal
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."
                        card-design>
                        <template slot="top">
                            <a href="" target="_blank"><i class="fa fa-linkedin"></i></a>
                        </template>
                        <template #img>
                            <img :src="avatarImg" alt="" class="team-avatar-img-size-3">
                        </template>
                        <template #text1>
                            <p class="text-primary mb-1">Nicolas Horn</p>
                        </template>
                        <template #text2>
                            <p class="text-secondary mb-1">Founder & CEO</p>
                        </template>
                        <template #text3>
                            <p class="text-2 mb-1">Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Eligendi, esse.</p>
                        </template>
                        <template #bottom>
                            <btn design="basic-b" text="View more" class="mt-2" size="xs"/>
                        </template>
                    </team-avatar-item-horizontal>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.horizontal.slots.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-3">2. Team Avatar Item Vertical</h4>
                    <h5 class="my-3">Usage</h5>
                    <code-container class="mb-3" :show-code-no-toggle="true" :code-str="codeStore.vertical.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>default</h5>
                    <team-avatar-item-vertical
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>align: center</h5>
                    <team-avatar-item-vertical
                        align="center"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.alignCenter.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>Image shape - round</h6>
                    <team-avatar-item-vertical
                        img-shape="round"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.shapeRound.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>Image border radius</h6>
                    <team-avatar-item-vertical
                        img-border-radius="3"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.imgBorderRadius.code"/>
                </div>
                <div class="col-12">
                    <h4>Size Variants</h4>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 1</h6>
                    <team-avatar-item-vertical
                        img-shape="round"
                        img-size="1"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.imgSize1.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 2</h6>
                    <team-avatar-item-vertical
                        img-shape="round"
                        img-size="2"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.imgSize2.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 3</h6>
                    <team-avatar-item-vertical
                        img-shape="round"
                        img-size="3"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.imgSize3.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 4</h6>
                    <team-avatar-item-vertical
                        img-shape="round"
                        img-size="4"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.imgSize4.code"/>
                </div>
                <div class="col-lg-6">
                    <h6>imgSize - 5</h6>
                    <team-avatar-item-vertical
                        img-shape="round"
                        img-size="5"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.imgSize5.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Card Design</h5>
                    <div class="row">
                        <div class="col-lg-9">
                            <team-avatar-item-vertical
                                card-design img-size="3"
                                img-border-radius="2"
                                border-radius="2"
                                align="center"
                                :img="avatarImg"
                                text1="Nicolas Horn"
                                text2="Founder & CEO"
                                text3="Lorem ipsum dolor sit amet, consectetur
                                 adipisicing elit. Doloribus, et."/>
                            <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.cardDesign.code"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Text Fonts Customization</h5>
                    <div class="row">
                        <div class="col-lg-7">
                            <team-avatar-item-vertical
                                :img="avatarImg"
                                text1="Nicolas Horn"
                                text2="Founder & CEO"
                                text3="Lorem ipsum dolor sit amet, consectetur
                                 adipisicing elit. Doloribus, et."
                                text1-font-size="1"
                                text1-font-weight="500"
                                text2-font-size="0"
                                text2-color="primary"
                                text3-font-size="1"
                                text3-classes="font-weight-light"
                                text3-color="2"
                                img-size="2"
                                img-border-radius="2"
                                social-icon-color="secondary"
                                align="center"
                                card-design/>
                            <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.fontCustomization.code"/>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h5 class="my-3">Slots</h5>
                    <team-avatar-item-vertical
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."
                        card-design>
                        <template slot="top">
                            <a href="" target="_blank"><i class="fa fa-linkedin"></i></a>
                        </template>
                        <template #img>
                            <img :src="avatarImg" alt="" class="team-avatar-img-size-3">
                        </template>
                        <template #text1>
                            <p class="text-primary mb-1">Nicolas Horn</p>
                        </template>
                        <template #text2>
                            <p class="text-secondary mb-1">Founder & CEO</p>
                        </template>
                        <template #text3>
                            <p class="text-2 mb-1">Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Eligendi, esse.</p>
                        </template>
                        <template #bottom>
                            <btn design="basic-b" text="View more" class="mt-2" size="xs"/>
                        </template>
                    </team-avatar-item-vertical>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.vertical.slots.code"/>
                </div>
                <div class="col-12">
                    <h4 class="my-4">3. Team Avatar item Glass Design</h4>
                    <h5 class="my-3">Usage</h5>
                    <code-container class="mb-3" :show-code-no-toggle="true" :code-str="codeStore.glassDesign.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Default</h5>
                    <team-avatar-item-glass-design
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.glassDesign.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">imgSize: 2</h5>
                    <team-avatar-item-glass-design
                        img-size="2"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.glassDesign.imgSize2.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">align: center</h5>
                    <team-avatar-item-glass-design
                        align="center"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.glassDesign.alignCenter.code"/>
                </div>
                <div class="col-6">
                    <h5 class="my-3">Slots</h5>
                    <team-avatar-item-glass-design
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."
                        card-design>
                        <template slot="top">
                            <a href="" target="_blank"><i class="fa fa-linkedin"></i></a>
                        </template>
                        <template #text1>
                            <p class="text-primary font-weight-600 mb-1">Nicolas Horn</p>
                        </template>
                        <template #text2>
                            <p class="text-secondary mb-1">Founder & CEO</p>
                        </template>
                        <template #text3>
                            <p class="text-white mb-1">Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Eligendi, esse.</p>
                        </template>
                        <template #bottom>
                            <btn text="View more" class="mt-2" size="xs"/>
                        </template>
                    </team-avatar-item-glass-design>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.glassDesign.slots.code"/>
                </div>
                <div class="col-12">
                    <h4 class="my-4">4. Team Avatar item Small</h4>
                    <h5 class="my-3">Usage</h5>
                    <code-container class="mb-3" :show-code-no-toggle="true" :code-str="codeStore.smallAvatar.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>Default</h5>
                    <team-avatar-item-small
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.smallAvatar.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>imgShape: round</h5>
                    <team-avatar-item-small
                        img-shape="round"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"
                        text3="Lorem ipsum dolor sit amet, consectetur
                         adipisicing elit. Doloribus, et."/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.smallAvatar.imgShapeRound.code"/>
                </div>
                <div class="col-lg-6">
                    <h5>align: vertical</h5>
                    <team-avatar-item-small
                        align="vertical"
                        img-shape="round"
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.smallAvatar.alignVertical.code"/>
                    <!--                    <code-container :code-str="codeStore.borders.code" show-code-no-toggle></code-container>-->
                </div>
                <div class="col-lg-6">
                    <h5>Slots</h5>
                    <team-avatar-item-small
                        :img="avatarImg"
                        text1="Nicolas Horn"
                        text2="Founder & CEO">
                        <template #text1>
                            <p class="mb-1">User Name</p>
                        </template>
                        <template #text2>
                            <p class="mb-1 text-fs-0">role</p>
                        </template>
                        <template #bottom>
                            <btn size="xs" design="basic-b" text="view more"/>
                        </template>
                    </team-avatar-item-small>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.smallAvatar.slots.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <col-12>
                    <h3>SCSS Variables</h3>
                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </col-12>
            </div>
        </div>
    </page-template>
</template>

<script>
import avatarImg from '../../../assets/img/avatar/avatar-img-nicolas-horn-MTZTGvDsHFY-unsplash.jpg';
import codeStore from '../../../data/codes/web-components/team-avatar-item-splitted.json';
import TeamAvatarItemHorizontal from 'lego-framework/src/web-components/team-avatar/TeamAvatarItemHorizontal';
import TeamAvatarItemVertical from 'lego-framework/src/web-components/team-avatar/TeamAvatarItemVertical';
import TeamAvatarItemGlassDesign from 'lego-framework/src/web-components/team-avatar/TeamAvatarItemGlassDesign';
import TeamAvatarItemSmall from 'lego-framework/src/web-components/team-avatar/TeamAvatarItemSmall';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
export default {
    name: 'TeamAvatarItemSplittedVariantsPage',
    components: { TeamAvatarItemSmall, TeamAvatarItemGlassDesign, TeamAvatarItemVertical, TeamAvatarItemHorizontal, PropTable },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-35r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-50r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--avatar-img-border-radius-top-left',
                    description: 'avatar image border radius top left value ',
                    defaultvalue: '0'
                },
                {
                    slno: '2',
                    name: '--avatar-img-border-radius-top-right',
                    description: 'avatar image border radius top-right value ',
                    defaultvalue: '0'
                },
                {
                    slno: '3',
                    name: '--avatar-img-border-radius-bottom-right',
                    description: 'avatar image border radius bottom-right value ',
                    defaultvalue: '0'
                },
                {
                    slno: '4',
                    name: '--avatar-img-border-radius-bottom-left',
                    description: 'avatar image border radius bottom-left value ',
                    defaultvalue: '0'
                },
                {
                    slno: '5',
                    name: '--avatar-img-md-border-radius-top-left',
                    description: 'avatar image border radius top-left value for medium screens',
                    defaultvalue: '0'
                },
                {
                    slno: '6',
                    name: '--avatar-img-md-border-radius-top-right',
                    description: 'avatar image border radius top-right value for medium screens',
                    defaultvalue: '0'
                },
                {
                    slno: '7',
                    name: '--avatar-img-md-border-radius-bottom-right',
                    description: 'avatar image border radius bottom-right value for medium screens ',
                    defaultvalue: '0'
                },
                {
                    slno: '8',
                    name: '--avatar-img-md-border-radius-bottom-left',
                    description: 'avatar image border radius bottom-left value for medium screens',
                    defaultvalue: '0'
                },
                {
                    slno: '9',
                    name: '--avatar-img-lg-border-radius-top-left',
                    description: 'avatar image border radius top-left value for large screens',
                    defaultvalue: '0'
                },
                {
                    slno: '10',
                    name: '--avatar-img-lg-border-radius-top-right',
                    description: 'avatar image border radius top-right value for large screens',
                    defaultvalue: '0'
                },
                {
                    slno: '11',
                    name: '--avatar-img-lg-border-radius-bottom-right',
                    description: 'avatar image border radius bottom-right value for large screens',
                    defaultvalue: '0'
                },
                {
                    slno: '12',
                    name: '--avatar-img-lg-border-radius-bottom-left',
                    description: 'avatar image border radius bottom-left value for large screens',
                    defaultvalue: '0'
                }
            ],
            avatarImg: avatarImg,
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'Name Text', '', 'Nicolas Horn'],
                ['text2', 'String', 'role Text', '', 'Founder & CEO'],
                ['text3', 'String', 'info Text', '', 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, et.'],
                ['img', 'String', 'avatar image url', '', ''],
                ['imgSize', 'String', 'Image Size', ['0', '1', '2', '3', '4', '5', '6', '7'], '1'],
                ['imgShape', 'String', 'Image Size', ['round'], ''],
                ['shadow', 'String', 'Box Shadow variants', ['1 - 29'], ''],
                ['imgBorderRadius', 'String', 'image Border radius Variants', ['0 - 10'], '0'],
                ['borderRadius', 'String', 'container Border radius Variants', ['0 - 10'], '0'],
                ['text1Color', 'String', 'text 1 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text1FontSize', 'String', 'text 1 font size', ['-1 - 7'], '0'],
                ['text1Classes', 'String', 'text1 color variant', ['-1 - 7'], '0'],
                ['text2Color', 'String', 'text2 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text2FontSize', 'String', 'text 2 font size', ['-1 - 7'], ''],
                ['text2Classes', 'String', 'text2 additional classes', '', ''],
                ['text3Color', 'String', 'name text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text3FontSize', 'String', 'text 2 font size', '', ''],
                ['text3Classes', 'String', 'text3 additional classes', '', ''],
                ['socialIconColor', 'String', 'social icon color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['align', 'Boolean', 'Align image and text items center horizontally ( only on TeamAvatarItemVertical)', ['left', 'center'], 'left'],
                ['cardDesign', 'Boolean', 'Wrap items inside a card', ['true', 'false'], 'false'],
                ['linkUrl', 'String', 'Forward link url', '', ''],
                ['containerClasses', 'String,Array', 'Items main container( contains image and text ) additional classes', '', ''],
                ['imgClasses', 'String,Array', 'Image additional classes', '', ''],
                ['contentContainerClasses', 'String,Array', 'Content Container additional classes', '', ''],
                ['minHeight', 'String', 'Minimum height of the card', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
