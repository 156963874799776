<template>
    <page-template>
   <div class="bg-2 p-5">
        <div>
            <h3>Dropper</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Dropper',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-10r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-35r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--bs-base',
                    description: 'box shadow base color set,it is modified inside modifier classes ' +
                        '<br>eg: in class .bs-primary -it is set as primary color</br>',
                    defaultvalue: '#000000 (--rgb-black).value changes insiide modifier class .bs-primary'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
