<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Button Group</h3>
                    <p class="lead">Buttons container - Aligning multiple buttons inline</p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import ButtonGroup from 'lego-framework/src/components/ButtonGroup';</p>
                    <p>Vue.component('btn-group', ButtonGroup);</p>
                    <p>&#60;btn-group&#62;&#60;/btn-group&#62;</p>
                </div>
                <div class="col-12">
                    <lego-heading tag="h4" align="left" class="mb-5">mode : basic</lego-heading>
                </div>
                <div class="col-lg-6">
                    <component-wrapper :code="codeStore.basic1.code" title="">
                        <btn-group>
                            <btn>Add</btn>
                            <btn design="basic-b">Edit</btn>
                        </btn-group>

                    </component-wrapper>

                </div>
                <div class="col-lg-6">
                    <component-wrapper :code="codeStore.basic2.code" title="">
                        <btn-group>
                            <btn icon="fa fa-eye" size="xs"></btn>
                            <btn icon="fa fa-pencil" size="xs"></btn>
                            <btn color="danger" icon="fa fa-trash" size="xs"></btn>
                        </btn-group>

                    </component-wrapper>
                </div>
            </div>
            <row class="mt-5">
                <div class="col-12">
                    <lego-heading tag="h4" align="left" class="mb-5">mode : tight</lego-heading>
                </div>
                <div class="col-lg-6">
                    <component-wrapper :code="codeStore.tight1.code" title="">
                        <btn-group mode="tight">
                            <btn design="basic-b">View</btn>
                            <btn>Edit</btn>
                            <btn color="danger">Delete</btn>
                        </btn-group>
                    </component-wrapper>

                </div>
                <div class="col-lg-6">
                    <component-wrapper :code="codeStore.tight2.code" title="">
                        <btn-group mode="tight">
                            <btn icon="fa fa-pencil" size="xs"></btn>
                            <btn color="danger" icon="fa fa-trash" size="xs"></btn>
                            <btn design="basic-b" icon="fa fa-eye" size="xs"></btn>
                        </btn-group>
                    </component-wrapper>

                </div>
            </row>
        </div>
    </div>
</template>
<script>
import codeStore from './code-storage/ButtonGroups.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import LegoHeading from 'lego-framework/src/components/Heading';

export default {
    name: 'ButtonGroupsDocs',
    components: { LegoHeading, ComponentWrapper },
    metaInfo: {
        path: 'components/button-groups/',
        title: 'Button Groups'
    },
    data () {
        return {
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
