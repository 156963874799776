<template>
    <div class="fl-y">
        <template v-if="items">
            <progress-step-item
                v-for="(item,i) in items" :key="i"
                :text1="item.text1" :text2="item.text2"
                :status="item.status" :show-line="i!=0"
                line-height="2rem"/>
        </template>
    </div>
</template>

<script>
import ProgressStepItem from './ProgressStepItem4';
// import LineItem from './line-item';
export default {
    name: 'ProgressStepsDesign3',
    components: { ProgressStepItem },
    props: {
        items: { type: Array, default: null }
    }
};
</script>

<style scoped>

</style>
