<template>
    <page-template>
     <div class="bg-2 p-5">
    <div>
        <h3>Grid</h3>
    </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'BorderRadius',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--container-max-width-sm',
                    description: 'container max width sm is set',
                    defaultvalue: '540px'
                },
                {
                    slno: '2',
                    name: '--container-max-width-md',
                    description: 'container max width md is set',
                    defaultvalue: '720px'
                },
                {
                    slno: '3',
                    name: '--container-max-width-lg',
                    description: 'container max width lg is set',
                    defaultvalue: '960px'
                },
                {
                    slno: '4',
                    name: '--container-max-width-xl',
                    description: 'container max width xl is set',
                    defaultvalue: '1140px'
                },
                {
                    slno: '5',
                    name: '--grid-gutter-width',
                    description: 'grid gutter width value',
                    defaultvalue: '30px'
                },
                {
                    slno: '6',
                    name: 'grid breakponts - sm ',
                    description: 'grid breakpoint small screens',
                    defaultvalue: '576px'
                },
                {
                    slno: '7',
                    name: 'grid breakponts - md',
                    description: 'grid breakpoint medium screens',
                    defaultvalue: '768px'
                },
                {
                    slno: '8',
                    name: 'grid breakponts - lg',
                    description: 'grid breakpoint large screens',
                    defaultvalue: '992px'
                },
                {
                    slno: '9',
                    name: 'grid breakponts - xl',
                    description: 'grid breakpoint extra large screens',
                    defaultvalue: '1200px'
                },
                {
                    slno: '10',
                    name: 'grid-columns',
                    description: 'grid columns numbers',
                    defaultvalue: '12'
                },
                {
                    slno: '11',
                    name: 'grid-row-columns',
                    description: 'grid-row-columns',
                    defaultvalue: '6'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
