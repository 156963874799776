<template>
    <page-template class="bg-2">
        <h3>Banners</h3>
        <h4 class="my-3">Design 1</h4>
        <code-container show-code-no-toggle code-str="import BanneSection1 from 'lego-framework/src/web-components/banner-sections/design-1/BannerDesign1';"/>
        <banne-section1></banne-section1>
        <code-container show-code-no-toggle :code-str="codeStore.design1.code"></code-container>
        <div class="row">
            <div class="col-12 mt-4">
                <h4 class="mt-3">Props</h4>
                <prop-table :items="propsInfo1"/>
            </div>
        </div>
        <h4 class="my-3">Design 2</h4>
        <code-container show-code-no-toggle code-str="import BanneSection2 from 'lego-framework/src/web-components/banner-sections/design-2/BannerSectionDesign2';"/>
        <banne-section2 ref="design2"></banne-section2>
        <code-container show-code-no-toggle :code-str="codeStore.design2.code"></code-container>
        <div class="row">
            <div class="col-12 mt-4">
                <h4 class="mt-3">Props</h4>
                <prop-table :items="propsInfo2"/>
            </div>
        </div>
        <h4 class="my-3">Design 3</h4>
        <code-container show-code-no-toggle code-str="import BanneSection3 from 'lego-framework/src/web-components/banner-sections/design-3/BannerSectionDesign3';"/>
        <banne-section3 ref="design3">
            <div class="bg-2 bs-5 round-2 p-4">
                <text-el fs="3" font-weight="700">User Over Time</text-el>
                <img src="chart-img.png" alt="" class="img-fluid">
                <activity-gauge :value1="75" :value2="85" :value3="70" label="Active users" label-translate-x="-14%"
                                :total-value="1000" design="basic-a" class="pos-a right-0 bottom-0 mr-n9 mb-n6"/>
            </div>
        </banne-section3>
        <code-container show-code-no-toggle :code-str="codeStore.design3.code"></code-container>
        <div class="row">
            <div class="col-12 mt-4">
                <h4 class="mt-3">Props</h4>
                <prop-table :items="propsInfo3"/>
            </div>
        </div>
    </page-template>
</template>
<script>
import BanneSection1 from 'lego-framework/src/web-components/banner-sections/design-1/BannerDesign1';
import BanneSection2 from 'lego-framework/src/web-components/banner-sections/design-2/BannerSectionDesign2';
import BanneSection3 from 'lego-framework/src/web-components/banner-sections/design-3/BannerSectionDesign3';
// import GraphItem from '../dashboard-components/metrics/GraphItem';
import TextEl from '../../../components/typography/TextElement';
import ActivityGauge from '../../../dashboard-components/activity-gauges/circles/ActivityGaugeCircleCustomizable';
import codeStore from '../code-storage/banner-sections.json';
import CodeContainer from '../../../components/doc-items/CodeContainer';
import PropTable from '../../docs-components/Props';
import design2Props from './props-data/design-2';
import design3Props from './props-data/design-3';

export default {
    name: 'BannerSections',
    metaInfo: {
        title: 'Banner Sections',
        path: 'web-components/banner-sections-docs/'
    },
    components: { CodeContainer, BanneSection1, BanneSection2, BanneSection3, ActivityGauge, TextEl, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo1: [
                ['text1', 'String', 'Text 1 ordered from top-bottom, left-right', '', ''],
                ['text2', 'String', 'Text 2 ordered from top-bottom, left-right', '', ''],
                ['text3', 'String', 'Text 3 ordered from top-bottom, left-right', '', ''],
                ['text4', 'String', 'Text 4 ordered from top-bottom, left-right', '', ''],
                ['text5', 'String', 'Text 5 ordered from top-bottom, left-right', '', ''],
                ['text6', 'String', 'Text 6 ordered from top-bottom, left-right', '', ''],
                ['text7', 'String', 'Text 7 ordered from top-bottom, left-right', '', ''],
                ['bgColor', 'String', 'Base color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['img', 'String', 'Side Image', '', ''],
                ['height', 'String', 'Banner height', '', '100vh'],
                ['buttonDesign', 'String', 'Button Design', ['basic', 'basic-a', 'basic-b', 'basic-c', 'basic-d', 'basic-e', 'basic-f'], 'basic-a'],
                ['text1Color', 'String', 'Text 1 color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text1FontSize', 'String', 'Text 1 Font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text1FontWeight', 'String', 'Text 1 Font weight', ['100', '200', '300', '400', '500', '600', '700', 'light', 'bold', 'bolder'], ''],
                ['text2Classes', 'String,Array', 'Text 1 Additional Classes', '', ''],
                ['text2Color', 'String', 'Text 2 color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text2FontSize', 'String', 'Text 2 Font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text2FontWeight', 'String', 'Text 2 Font weight', ['100', '200', '300', '400', '500', '600', '700', 'light', 'bold', 'bolder'], ''],
                ['text2Classes', 'String,Array', 'Text 2 Additional Classes', '', ''],
                ['Similar font props ', 'String,Array', 'Similar props like above upto text7', '', '']
            ],
            propsInfo2: [
                ['text1', 'String', 'Text 1 ordered from top-bottom, left-right', '', 'New Feature'],
                ['text2', 'String', 'Text 2 ordered from top-bottom, left-right', '', 'Checkout the team Dashboard'],
                ['text3', 'String', 'Text 3 ordered from top-bottom, left-right', '', 'High-performing remote teams. The future of work.'],
                ['text4', 'String', 'Text 4 ordered from top-bottom, left-right', '', 'Powerful, self-serve product and growth analytics to help you convert'],
                ['text5', 'String', 'Text 5 ordered from top-bottom, left-right', '', 'Demo'],
                ['text6', 'String', 'Text 6 ordered from top-bottom, left-right', '', 'Sign up'],
                ['videoUrl', 'String', 'Video url', '', 'https://www.youtube.com/embed/Qmi-Xwq-MEc'],
                ['btn1Icon', 'String', 'Text 6 ordered from top-bottom, left-right', '', 'Sign up'],
                ['btn1Design', 'String', 'Button 1 design', '', 'basic-g'],
                ['btn2Design', 'String', 'Button 2 design', '', 'basic-a'],
                ['bgColor', 'String', 'Bg color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['bgColorThickness', 'String', 'Bg color thickness', ['100', '200', '300', '400', '600', '700', '800', '900'], '100'],
                ['bgGradient', 'String', 'Bg color thickness', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], '2'],
                ['text1Color', 'String', 'Text 1 color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text1FontSize', 'String', 'Text 1 Font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text1FontWeight', 'String', 'Text 1 Font weight', ['100', '200', '300', '400', '500', '600', '700', 'light', 'bold', 'bolder'], ''],
                ['text2Classes', 'String,Array', 'Text 1 Additional Classes', '', ''],
                ['text2Color', 'String', 'Text 2 color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text2FontSize', 'String', 'Text 2 Font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text2FontWeight', 'String', 'Text 2 Font weight', ['100', '200', '300', '400', '500', '600', '700', 'light', 'bold', 'bolder'], ''],
                ['text2Classes', 'String,Array', 'Text 2 Additional Classes', '', ''],
                ['Similar font props ', 'String,Array', 'Similar props like above upto text7', '', ''],
                ['text1Link', 'String', 'Text 1 Link value', '', ''],
                ['text2Link', 'String', 'Text 2 Link value', '', ''],
                ['text3Link', 'String', 'Text 3 Link value', '', ''],
                ['text4Link', 'String', 'Text 4 Link value', '', ''],
                ['text5Link', 'String', 'Text 5 Link value', '', ''],
                ['text6Link', 'String', 'Text 6 Link value', '', ''],
                ['text1Clickable', 'String', 'Text 1 clickable or not', '', ''],
                ['text2Clickable', 'String', 'Text 2 clickable or not', '', ''],
                ['text3Clickable', 'String', 'Text 3 clickable or not', '', ''],
                ['text4Clickable', 'String', 'Text 4 clickable or not', '', ''],
                ['text5Clickable', 'String', 'Text 5 clickable or not', '', ''],
                ['text6Clickable', 'String', 'Text 6 clickable or not', '', '']
            ],
            propsInfo3: [
                ['text1', 'String', 'Text 1 ordered from top-bottom, left-right', '', 'New Feature'],
                ['text2', 'String', 'Text 2 ordered from top-bottom, left-right', '', 'Checkout the team Dashboard'],
                ['text3', 'String', 'Text 3 ordered from top-bottom, left-right', '', 'High-performing remote teams. The future of work.'],
                ['text4', 'String', 'Text 4 ordered from top-bottom, left-right', '', 'Powerful, self-serve product and growth analytics to help you convert'],
                ['text5', 'String', 'Text 5 ordered from top-bottom, left-right', '', 'Demo'],
                ['text6', 'String', 'Text 6 ordered from top-bottom, left-right', '', 'Sign up'],
                ['videoUrl', 'String', 'Video url', '', 'https://www.youtube.com/embed/Qmi-Xwq-MEc'],
                ['btn1Icon', 'String', 'Text 6 ordered from top-bottom, left-right', '', 'Sign up'],
                ['btn1Design', 'String', 'Button 1 design', '', 'basic-g'],
                ['btn2Design', 'String', 'Button 2 design', '', 'basic-a'],
                ['bgColor', 'String', 'Bg color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['bgColorThickness', 'String', 'Bg color thickness', ['100', '200', '300', '400', '600', '700', '800', '900'], '100'],
                ['bgGradient', 'String', 'Bg color thickness', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], '2'],
                ['text1Color', 'String', 'Text 1 color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text1FontSize', 'String', 'Text 1 Font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text1FontWeight', 'String', 'Text 1 Font weight', ['100', '200', '300', '400', '500', '600', '700', 'light', 'bold', 'bolder'], ''],
                ['text2Classes', 'String,Array', 'Text 1 Additional Classes', '', ''],
                ['text2Color', 'String', 'Text 2 color', ['1', '2', '3', '4', 'primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text2FontSize', 'String', 'Text 2 Font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text2FontWeight', 'String', 'Text 2 Font weight', ['100', '200', '300', '400', '500', '600', '700', 'light', 'bold', 'bolder'], ''],
                ['text2Classes', 'String,Array', 'Text 2 Additional Classes', '', ''],
                ['Similar font props ', 'String,Array', 'Similar props like above upto text7', '', ''],
                ['text1Link', 'String', 'Text 1 Link value', '', ''],
                ['text2Link', 'String', 'Text 2 Link value', '', ''],
                ['text3Link', 'String', 'Text 3 Link value', '', ''],
                ['text4Link', 'String', 'Text 4 Link value', '', ''],
                ['text5Link', 'String', 'Text 5 Link value', '', ''],
                ['text6Link', 'String', 'Text 6 Link value', '', ''],
                ['text1Clickable', 'String', 'Text 1 clickable or not', '', ''],
                ['text2Clickable', 'String', 'Text 2 clickable or not', '', ''],
                ['text3Clickable', 'String', 'Text 3 clickable or not', '', ''],
                ['text4Clickable', 'String', 'Text 4 clickable or not', '', ''],
                ['text5Clickable', 'String', 'Text 5 clickable or not', '', ''],
                ['text6Clickable', 'String', 'Text 6 clickable or not', '', '']
            ],
            design2Props: design2Props,
            design3Props: design3Props
        };
    }
};
</script>

<style scoped>

</style>
