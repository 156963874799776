<template lang="html">
    <div :class="classNames">
        <ul class='tabs-header' :class="headerClasses">
            <slot name="pre-head"></slot>
            <li v-for='(tab, index) in tabs' :key='index' @click='selectTab(index)' :class='[{"tab-selected": (index === selectedIndex) }, tabTitleClasses]'>
                <i v-if="tab.icon" :class="tab.icon" class="space-right"></i>
                <!--                <span :class="index !== selectedIndex ? tabTitleClasses: ''">{{ tab.title }}</span>-->
                <span>{{ tab.title }}</span>
            </li>
            <slot name="post-head"></slot>
        </ul>
        <div class="tabs-container" :class="tabsContainerClasses">
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        vertical: { type: Boolean, default: false },
        color: { type: String, default: 'primary' },
        mode: { type: String, default: 'line' },
        align: { type: String, default: 'left' },
        headerClasses: { type: [String, Array], default: '' },
        tabTitleClasses: { type: [String, Array], default: '' },
        tabsContainerClasses: { type: [String, Array], default: '' },
        selectedIndex: { type: Number, default: 0 } // the index of the selected tab,
    },
    data () {
        return {
            // selectedIndex : 0, // the index of the selected tab,
            tabs: [] // all the tabs
        };
    },
    created () {
        this.tabs = this.$children;
    },
    mounted () {
        // Default value is considered to be 0 for selectedIndex
        this.selectTab(this.selectedIndex, true);
    },
    computed: {
        classNames () {
            const names = [];
            if (this.color) {
                names.push('tabs-' + this.color);
            }
            if (this.mode) {
                names.push('tabs-' + this.mode);
            }
            if (this.align) {
                names.push('tabs-' + this.align);
            }
            if (this.vertical) {
                names.push('tabs-vertical');
            }
            return names;
        },
        tabTitleClassNames () {
            console.log('tabTitleClasses', this.tabTitleClasses);
            const names = [];
            names.push();
            return this.tabTitleClasses;
        }
    },
    methods: {
        hideTab (i) {
            this.tabs[i].isActive = false;
        },

        showTab (i) {
            this.tabs[i].isActive = true;
        },

        selectTab (i, force = false) {
            if (i === this.selectedIndex && force === false) {
                return;
            }
            this.hideTab(this.selectedIndex);
            this.selectedIndex = i;
            this.$emit('tabChange', this.selectedIndex);
            const that = this;
            setTimeout(function () {
                that.showTab(that.selectedIndex);
            }, 210);
        }
    }
};
</script>
