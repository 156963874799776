<template>
    <div :class="[classNames, marginClasses, paddingClasses]">
        <component :is="tag" class="heading-inner" :class="titleClasses">
            <slot>{{title}}</slot>
            <div v-if="tagImage" class="heading-tag-img" :style="setBgImage(tagImage)"></div>
        </component>
        <div class="mt-2" :class="subTitleClasses">
            <slot name="info"><p>{{subTitle}}</p></slot>
        </div>
    </div>
</template>

<script>
import marginsAndPaddingsMixin from '../mixins/margin-and-padding/marginsAndPaddingsMixin';
export default {
    name: 'lego-heading',
    data () {
        return {};
    },
    props: {
        variation: {
            type: String,
            default: '1'
        },
        tag: {
            type: String, default: 'h1'
        },
        color: {
            type: String,
            default: ''
        },
        tagImage: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        subTitle: {
            type: String,
            default: ''
        },
        subTitleClasses: {
            type: [String, Array],
            default: ''
        },
        titleClasses: {
            type: [String, Array],
            default: ''
        },
        textAlign: {
            type: String,
            default: 'left'
        }
    },
    computed: {
        classNames () {
            const names = ['lego-heading'];
            names.push(`heading-style-${this.variation}`);
            if (this.color) {
                names.push('text-' + this.color);
            }
            if (this.textAlign) {
                names.push('text-' + this.textAlign);
            }
            return names;
        }
    },
    methods: {
        setBgImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    },
    mixins: [marginsAndPaddingsMixin]
};
</script>
