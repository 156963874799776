<template>
    <div class="b-1 px-3 py-3 round-1">
        <div class="fl-te-t">
            <div class="fl-x">
                <div>
                    <icon-img size="10" :image="fileIcon"/>
                    <custom-h-el height="1.3rem" width="1.9rem" style="margin-top:-2rem;" class="bg-danger ml-n1 text-fs--1 px-1 text-uppercase">{{fileExtension}}</custom-h-el>
                    <!--                    <custom-spacing-el pt="0.1rem" pb="0.2rem" pl="0.2rem" pr="0.2rem" mt="-6rem" ml="-3rem" class="bg-danger text-fs&#45;&#45;1">{{fileExtension}}</custom-spacing-el>-->
                </div>
                <div class="ml-2">
                    <div class="">{{fileName}}</div>
                    <div class="text-fs-1 mt-1" v-if="fileSize !==''">{{fileSize}}</div>
                </div>
            </div>
            <div class="fl-x">
                <input type="checkbox" input-classes="mb-0" class="mb-0 accent-primary" v-if="status ==='done'" :checked="true"/>
                <slot name="buttons">
                    <i class="fa fa-trash" v-if="status ==='done'" @click="deleteItem"></i>
                </slot>
            </div>
        </div>
        <div class="fl-x fl-a-c pr-3 mt-2">
            <custom-h-el width="3.4rem"/>
            <progress-bar-html class="w-100p"/>
            <span class="text-fs-0 ml-1" v-if="status==='done'">100%</span>
        </div>
    </div>
</template>

<script>
import fileIcon from './assets/icons8-file-50.png';
import CustomHEl from '../../containers/CustomHeightContainer';
import IconImg from '../../components/IconImage';
import ProgressBarHtml from '../../dashboard-components/animations/ProgressBarHtml';
// import CustomSpacingEl from '../../containers/CustomSpacingElement';
export default {
    name: 'FileItem',
    components: { ProgressBarHtml, IconImg, CustomHEl },
    props: {
        fileName: { type: String, default: 'Tech design requirements.pdf' },
        fileSize: { type: String, default: '' },
        fileExtension: { type: String, default: 'pdf' },
        status: { type: String, default: 'ongoing' }
    },
    data () {
        return {
            fileIcon: fileIcon
        };
    },
    methods: {
        deleteItem () {
            this.$emit('delete-item');
        }
    }
};
</script>

<style scoped>

</style>
