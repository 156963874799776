<template>
    <div class="bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <h2>Lego Section</h2>
                    <p class="lead">
                        Section container for aligning content inside and set background properties..etc, Commonly used for creating banners in website
                    </p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import Section from 'lego-framework/src/containers/Section';</p>
                    <p>Vue.component('lego-section', Section);</p>
                    <p>&#60;lego-section&#62;&#60;/lego-section&#62;</p>
                </div>
                <div class="col-12 mt-4">
                    <h5>Section - (Default)</h5>
                    <component-wrapper :code="codeStore.default.code">
                        <lego-section>
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Section - With Background Image</h5>
                    <component-wrapper :code="codeStore.withBgImage.code">
                        <lego-section
                            bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80">
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Section - Overlay color</h5>
                    <component-wrapper :code="codeStore.withOverlayColor.code">
                        <lego-section overlay-color="rgba(0,0,0,0.4)"
                                      bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80">
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Show More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>

                <div class="col-12 mt-4">
                    <h5>Section Full Height (default)</h5>
                    <component-wrapper :code="codeStore.fullHeight.code">
                        <lego-section
                            height="full"
                            overlay-color="rgba(0,0,0,0.4)"
                            bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                        >
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Show More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Section Half Height</h5>
                    <component-wrapper :code="codeStore.halfHeight.code">
                        <lego-section height="half" overlay-color="rgba(0,0,0,0.4)"
                                      bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80">
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Show More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Section one-third Height</h5>
                    <component-wrapper :code="codeStore.oneThirdHeight.code">
                        <lego-section height="one-third" overlay-color="rgba(0,0,0,0.4)"
                                      bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80">
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Show More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Section - Quarter Height</h5>
                    <component-wrapper :code="codeStore.quarterHeight.code">
                        <lego-section height="quarter" overlay-color="rgba(0,0,0,0.4)"
                                      bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80">
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Show More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Vertical Align Top</h5>
                    <component-wrapper :code="codeStore.verticalAlignTop.code">
                        <lego-section
                            verticalAlign="top"
                            height="half"
                            overlay-color="rgba(0,0,0,0.7)"
                            bgImage="https://images.unsplash.com/photo-1506905925346-21bda4d32df4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bW91bnRhaW5zfGVufDB8fDB8fHww"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Vertical Align Center</h5>
                    <component-wrapper :code="codeStore.verticalAlignCenter.code">
                        <lego-section
                            verticalAlign="center"
                            height="half"
                            overlay-color="rgba(0,0,0,0.7)"
                            bg-image="https://images.unsplash.com/photo-1506905925346-21bda4d32df4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bW91bnRhaW5zfGVufDB8fDB8fHww"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Vertical Align Bottom</h5>
                    <component-wrapper :code="codeStore.verticalAlignBottom.code">
                        <lego-section
                            verticalAlign="bottom"
                            height="half"
                            overlay-color="rgba(0,0,0,0.7)"
                            bg-image="https://images.unsplash.com/photo-1506905925346-21bda4d32df4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8bW91bnRhaW5zfGVufDB8fDB8fHww"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Horizontal Align left</h5>
                    <component-wrapper :code="codeStore.horizontalAlignLeft.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="left"
                            height="half"
                            overlay-color="rgba(0,0,0,0.7)"
                            bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p class="w-25r">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Horizontal Align center</h5>
                    <component-wrapper :code="codeStore.horizontalAlignCenter.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="center"
                            height="half"
                            overlay-color="rgba(0,0,0,0.7)"
                            bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p class="w-25r">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Horizontal Align right</h5>
                    <component-wrapper :code="codeStore.horizontalAlignRight.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            bgColor="transparent"
                            image-alignment="bottom"
                            height="half"
                            overlay-color="rgba(0,0,0,0.7)"
                            bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p class="w-25r">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Bg Color - transparent</h5>
                    <component-wrapper :code="codeStore.bgColorTrasnparent.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="center"
                            bgColor="transparent"
                            image-alignment="bottom"
                            height="half"
                        >
                            <div class="p-2">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Bg Color - var(--color-primary)</h5>
                    <component-wrapper :code="codeStore.bgColorPrimary.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            bgColor="var(--color-primary)"
                            height="half"
                        >
                            <div class="p-2" style="color:white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" color="secondary" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Bg Color - #000</h5>
                    <component-wrapper :code="codeStore.bgColorBlack.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            bgColor="#000"
                            height="one-third"
                        >
                            <template #image>
                                fdas fadfa
                                <img
                                    src="https://images.unsplash.com/photo-1465447142348-e9952c393450?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                                    alt="">
                            </template>
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" color="secondary" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Parallax Effect ( parallax: true )</h5>
                    <component-wrapper :code="codeStore.parallaxEffect.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            height="half"
                            :parallax="true"
                            overlay-color="rgba(0,0,0,0.4)"
                            bg-image="https://images.unsplash.com/photo-1465447142348-e9952c393450?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Padding - 5</h5>
                    <component-wrapper :code="codeStore.padding5.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            height="half"
                            overlay-color="rgba(0,0,0,0.6)"
                            padding="5"
                            bg-image="https://images.unsplash.com/photo-1465447142348-e9952c393450?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Image and content</h5>
                    <component-wrapper :code="codeStore.imageAndContent.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            bgColor="#000"
                            image-alignment="left"
                            height="half"
                            content-size="6"
                            padding="5"
                            image="https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1794&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Image and content: Content size : 8</h5>
                    <component-wrapper :code="codeStore.imageAndContentSize8.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            bgColor="#000"
                            image-alignment="left"
                            height="half"
                            content-size="8"
                            padding="5"
                            image="https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1794&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Image and content: Content size : 8 (image parallax)</h5>
                    <component-wrapper :code="codeStore.imageAndContentImageParallax.code">
                        <lego-section
                            verticalAlign="center"
                            horizontalAlign="right"
                            bgColor="#000"
                            image-alignment="left"
                            height="half"
                            content-size="8"
                            padding="5"
                            :image-parallax="true"
                            image="https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1794&q=80"
                        >
                            <template #default>
                                <div class="p-2" style="color: white;">
                                    <h1>Heading</h1>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                        ex?
                                    </p>
                                </div>
                            </template>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Image and content: Content size : 6</h5>
                    <component-wrapper :code="codeStore.imageAndContentSize6.code">
                        <lego-section
                            verticalAlign="top"
                            horizontalAlign="center"
                            image-alignment="left"
                            height="half"
                            content-size="6"
                            padding="5"
                            :image-parallax="true"
                            image="https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1794&q=80"
                        >
                            <template #default>
                                <div class="p-2">
                                    <h1>Heading</h1>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus facilis labore nobis placeat porro tempora totam voluptatem
                                        voluptatibus!
                                        Fuga iste minima nostrum quisquam vitae! Aut blanditiis consequuntur, dicta distinctio dolorem, doloribus eius esse hic labore modi nesciunt
                                        odio porro quaerat quia, similique vel vitae! Assumenda corporis deserunt dolor dolore dolorem est, molestiae optio quae quaerat soluta
                                        tempore
                                        tenetur vero voluptas voluptatibus voluptatum. Accusamus ad adipisci, asperiores aut consectetur consequatur corporis deleniti dolor
                                        doloribus
                                        ea eligendi error eum expedita facilis impedit in incidunt ipsa ipsam maiores molestias, non odio officia officiis quisquam quod reiciendis
                                        reprehenderit suscipit temporibus ullam voluptate voluptatem.
                                    </p>
                                </div>
                            </template>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h5>Customized with container modifier classes( prop - containerClasses)</h5>
                    <component-wrapper :code="codeStore.customized.code">
                        <lego-section
                            container-classes="h-lg-60vh h-40vh round-3 of-h"
                            verticalAlign="center"
                            horizontalAlign="left"
                            image-alignment="bottom"
                            overlay-color="rgba(0,0,0,0.7)"
                            bgImage="https://images.unsplash.com/photo-1696395050055-eb7a315bb1cb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
                        >
                            <div class="p-2" style="color: white;">
                                <h1>Heading</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto,
                                    ex?
                                </p>
                                <btn text="Learn More" size="lg"/>
                            </div>
                        </lego-section>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4>Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import codeStore from './codes/lego-section.json';
import LegoSection from '../containers/Section';
import ComponentWrapper from '../../components/doc-items/ComponentWrapper';
import PropTable from '../../views/docs-components/Props';

export default {
    name: 'TheSections',
    components: { PropTable, ComponentWrapper, LegoSection },
    metaInfo: {
        path: 'containers/lego-section/',
        title: 'Lego Section'
    },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['verticalAlign', 'String', 'content vertical alignment', ['top', 'center', 'bottom'], 'top'],
                ['horizontalAlign', 'String', 'content horizontal alignment', ['left', 'center', 'right'], 'left'],
                ['bgColor', 'String', 'section bg color', ['#000', 'var(--color-primary)', 'other colors/variables'], 'transparent'],
                ['bgImage', 'String', 'section bg image', ['any image url'], ''],
                ['overlayColor', 'String', 'section Overlay property', ['rgba' + '(0,0,0,0.6)', 'var(--color-primary-600)', 'other colors/variables'], ''],
                ['padding', 'String', 'padding spacer value', ['1-9 (based on available options)'], '0'],
                ['height', 'String', 'set section height related to viewport', ['full', 'half', 'one-third', 'quarter'], 'full'],
                ['parallax', 'Boolean', 'set parallax effect for background', ['true', 'false'], 'false'],
                ['image', 'String', 'Image is set along side the content', ['image url'], ''],
                ['imageAlignment', 'String', 'image position when image and content inside section', ['left', 'right'], 'left'],
                ['imageParallax', 'Boolean', 'set side image parallax effect', ['true', 'false'], 'false'],
                ['contentSize', 'String,Number', 'content grid width is set', ['1 - 12(based on grid columns)'], '6'],
                ['bgSize ', 'String', 'bg-size css property', ['cover', 'contain', 'auto', 'other css property values'], 'cover'],
                ['bgPosition ', 'String', 'bg-position css property', ['top', 'center', 'bottom', 'other css property values'], 'unset'],
                ['containerClasses ', 'String, Array', 'container modifier classes set here', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
