<template>
    <div class="fl-x mt-4">
        <img src="../../assets/web/icons/location-icon.svg" alt="" class="mb-5">
        <div class="col-lg-2">
          <h2 class="font-weight-bold text-secondary">{{number}}</h2>
          <p class="font-weight-bold">{{text}}</p>
      </div>
         <img src="../../assets/web/icons/location-icon.svg" alt="" class="mb-5">
        <div class="col-lg-2">
          <h2 class="font-weight-bold text-secondary">{{number}}</h2>
          <p class="font-weight-bold">{{text}}</p>
      </div>
    </div>
</template>

<script>
export default {
    name: 'MatricsItemsTwo',
    props: {
        number: { type: String, default: '600+' },
        text: { type: String, default: 'Projects Completed' }
    }
};
</script>

<style scoped>

</style>
