export default {
    text1: { type: String, default: 'New Feature' },
    text2: { type: String, default: 'Checkout the team Dashboard' },
    text3: { type: String, default: 'High-performing remote teams. The future of work.' },
    text4: { type: String, default: 'Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.' },
    text5: { type: String, default: 'Demo' },
    text6: { type: String, default: 'Sign up' },
    btn1Icon: { type: String, default: '' },
    btn2Icon: { type: String, default: '' },
    videoUrl: { type: String, default: 'https://www.youtube.com/embed/Qmi-Xwq-MEc' },
    btn1Design: { type: String, default: 'basic-g' },
    btn2Design: { type: String, default: 'basic-a' },
    bgColor: { type: String, default: 'primary' },
    bgColorThickness: { type: String, default: '100' },
    bgGradient: { type: String, default: '2' },
    text1Color: { type: String, default: 'primary' },
    text1FontSize: { type: String, default: '' },
    text1FontWeight: { type: String, default: '' },
    text1Classes: { type: String, default: '' },
    text2Color: { type: String, default: 'primary' },
    text2FontSize: { type: String, default: '' },
    text2FontWeight: { type: String, default: '' },
    text2Classes: { type: String, default: '' },
    text3Color: { type: String, default: 'primary' },
    text3FontSize: { type: String, default: '5' },
    text3FontWeight: { type: String, default: '700' },
    text3Classes: { type: String, default: '' },
    text4Color: { type: String, default: 'primary' },
    text4FontSize: { type: String, default: '2' },
    text4FontWeight: { type: String, default: '' },
    text4Classes: { type: String, default: '' },
    text5Color: { type: String, default: '' },
    text5FontSize: { type: String, default: '' },
    text5FontWeight: { type: String, default: '' },
    text5Classes: { type: String, default: '' },
    text6Color: { type: String, default: '' },
    text6FontSize: { type: String, default: '' },
    text6FontWeight: { type: String, default: '' },
    text6Classes: { type: String, default: '' },
    // events
    text1Link: { type: String, default: '' },
    text2Link: { type: String, default: '' },
    text3Link: { type: String, default: '' },
    text4Link: { type: String, default: '' },
    text5Link: { type: String, default: '' },
    text6Link: { type: String, default: '' },
    text1Clickable: { type: Boolean, default: false },
    text2Clickable: { type: Boolean, default: false },
    text3Clickable: { type: Boolean, default: false },
    text4Clickable: { type: Boolean, default: false },
    text5Clickable: { type: Boolean, default: false },
    text6Clickable: { type: Boolean, default: false }
};
