<template>
    <a class="z-index-2 mu-p text-decoration-none" @click="$router.push(linkUrl)">
        <div class=""
             :class="[containerDefaultClasses,containerClasses,
             {'bg bg-cover': imgShape === 'rect','bs-5': cardDesign, 'py-3': size !=='sm'}]"
             :style="setRectBgImage">
            <img :class="[imgClasses,imageClasses]" v-if="imgShape !=='rect'" class="img-fluid" :src="img" alt="">
            <div class="px-2"
                 :class="[{'text-center': align === 'center' || cardDesign,'mt-3': imgAlign === 'top' && size !=='sm', 'ml-3': imgAlign === 'left' && size !== 'sm', 'glass-bg-panel': imgShape === 'rect'},contentContainerClasses]">
                <div>
                    <text-el tag="p" :fs="titleFontSize" :font-weight="titleFontWeight" class="mb-1"
                             :class="[titleDefaultClasses, titleClasses]">{{ title }}
                    </text-el>
                    <text-el tag="p" :fs="subTitleFontSize" class="m-0"
                             :class="[subTitleClasses, subTitleDefaultClasses]">{{ subTitle }}
                    </text-el>
                </div>
                <div :class="[{'mt-2': size !=='sm'}]" v-if="size !== 'sm'">
                    <slot name="content">
                        <text-el :fs="contentFontSize" :class="[contentDefaultClasses, contentClasses]">{{ content }}
                        </text-el>
                    </slot>
                </div>
                <div v-if="size !== 'sm'">
                    <slot name="footer">
                        <div v-if="showSocialLinks && size === 'lg'">
                            <a href="" target="_blank"><i :class="[socialIconDefaultClasses]"
                                                          class="fa fa-linkedin"></i></a>
                            <a href="" target="_blank" class="ml-3"><i :class="[socialIconDefaultClasses]"
                                                                       class="fa fa-dribbble"></i></a>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </a>
</template>
<script>
import TextEl from '../../components/typography/TextElement';

export default {
    name: 'TeamAvatarItem',
    components: { TextEl },
    props: {
        title: { type: String, default: 'Nicolas Horn' },
        subTitle: { type: String, default: 'Founder & CEO' },
        content: { type: String, default: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus, et.' },
        img: { type: String, default: '' },
        imgSize: { type: String, default: '1' },
        imgShape: { type: String, default: 'round' },
        imgBorder: { type: String, default: '0' },
        titleFontSize: { type: [String, Number], default: '2' },
        titleFontWeight: { type: [String, Number], default: 'bold' },
        contentFontSize: { type: [String, Number], default: '' },
        subTitleFontSize: { type: [String, Number], default: '1' },
        nameColor: { type: String, default: '' },
        subTitleColor: { type: String, default: '' },
        socialIconColor: { type: String, default: '' },
        contentColor: { type: String, default: '' },
        align: { type: String, default: 'left' },
        imgAlign: { type: String, default: 'top' },
        size: { type: String, default: 'lg' },
        cardDesign: { type: Boolean, default: false },
        showSocialLinks: { type: Boolean, default: true },
        linkUrl: { type: String, default: '/' },
        containerClasses: { type: [String, Array], default: '' },
        imageClasses: { type: [String, Array], default: '' },
        contentContainerClasses: { type: [String, Array], default: '' },
        titleClasses: { type: [String, Array], default: '' },
        contentClasses: { type: [String, Array], default: '' },
        subTitleClasses: { type: [String, Array], default: '' }
    },
    computed: {
        imgClasses () {
            const classNames = [];
            if (this.imgSize) {
                // classNames.push('avatar-img-' + this.imgShape + '-size-' + this.imgSize);
                if (this.imgShape !== 'rect') {
                    classNames.push('avatar-img-size-' + this.imgSize);
                }
            }
            if (this.imgShape === 'round' || this.imgShape === 'rect') {
                classNames.push('avatar-img-' + this.imgShape);
                if (this.imgShape === 'rect') {
                    classNames.push('avatar-img-' + this.imgShape + '-size-' + this.imgSize);
                }
            }
            if (this.imgBorder) {
                classNames.push('round-' + this.imgBorder);
            }
            return classNames;
        },
        subTitleDefaultClasses () {
            const classNames = [];
            if (this.subTitleColor) {
                classNames.push('text-' + this.subTitleColor);
            }
            return classNames;
        },
        titleDefaultClasses () {
            const classNames = [];
            if (this.nameColor) {
                classNames.push('text-' + this.nameColor);
            }
            return classNames;
        },
        contentDefaultClasses () {
            const classNames = [];
            if (this.contentColor) {
                classNames.push('text-' + this.contentColor);
            }
            return classNames;
        },
        socialIconDefaultClasses () {
            const classNames = [];
            if (this.socialIconColor) {
                classNames.push('text-' + this.socialIconColor);
            }
            return classNames;
        },
        containerDefaultClasses () {
            const classNames = [];
            if (this.align === 'center' || this.cardDesign) {
                classNames.push('fl-a-c');
            } else if (this.align === 'left' && !this.cardDesign) {
                classNames.push('fl-a-s');
            }
            if (this.size === 'sm') {
                // since only image, title and sub title present
                classNames.push('fl-a-c');
            }
            if (this.imgAlign === 'left' || this.size === 'sm') {
                classNames.push('fl-x');
            } else {
                classNames.push('fl-y');
            }
            if (this.imgShape === 'rect') {
                classNames.push('avatar-img-rect-size-' + this.imgSize);
                classNames.push('fl-j-e px-3');
            }
            return classNames;
        },
        setRectBgImage () {
            if (this.imgShape === 'rect') {
                return this.$bgImage(this.img);
            } else {
                return '';
            }
        }
    }
};
</script>
<style scoped lang="scss">
</style>
