<template>
    <div>
        <container>
            <card p="4">
                <row>
                    <col-12>
                        <h3>Icon Image Component</h3>
                        <p class="lead">Icon Image Variations component</p>
                        <h5>Usage</h5>
                        <p>Import Component on plugins/lego.js</p>
                        <p>import IconImage from 'lego-framework/src/components/IconImage';</p>
                        <p>Vue.component('icon-img', IconImage);</p>
                        <p>&#60;icon-img&#62;&#60;/icon-img&#62;</p>
                    </col-12>
                    <col-12>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Size Variants</lego-heading>
                        <component-wrapper title="Size 1-11" :code="codeStore.sizes.code">
                            <flex j="sa">
                                <icon-img :image="officeIcon" :size="1"></icon-img>
                                <icon-img :image="officeIcon" :size="2"></icon-img>
                                <icon-img :image="officeIcon" :size="3"></icon-img>
                                <icon-img :image="officeIcon" :size="4"></icon-img>
                                <icon-img :image="officeIcon" :size="5"></icon-img>
                                <icon-img :image="officeIcon" :size="6"></icon-img>
                                <icon-img :image="officeIcon" :size="7"></icon-img>
                                <icon-img :image="officeIcon" :size="8"></icon-img>
                                <icon-img :image="officeIcon" :size="9"></icon-img>
                                <icon-img :image="officeIcon" :size="10"></icon-img>
                                <icon-img :image="officeIcon" :size="11"></icon-img>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Custom Height</lego-heading>
                        <component-wrapper class="rotate-" title="Heights (0.6rem & 3.5rem)" :code="codeStore.customHeight.code">
                            <flex>
                                <icon-img :image="iconCheck" height="0.6"></icon-img>
                                <icon-img :image="iconCheck" height="3.5"></icon-img>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Hover Animations</lego-heading>
                        <component-wrapper title="Scale Up & Rotation Effects" :code="codeStore.hoverAnimations.code">
                            <flex j="sa">
                                <icon-img hover-effect="basic-1" :image="officeIcon" :size="5"></icon-img>
                                <icon-img hover-effect="basic-2" :image="officeIcon" :size="5"></icon-img>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Hover Animations Custom Values</lego-heading>
                        <component-wrapper title="" :code="codeStore.hoverAnimationsCustom.code">
                            <flex j="sb">
                                <div>
                                    <h6 class="mb-5">Scale Up (2.5)</h6>
                                    <icon-img hover-effect="basic-1" :image="officeIcon" :size="5" :hover-scale-value="2.5"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5">Rotation (45deg)</h6>
                                    <icon-img hover-effect="basic-2" :image="officeIcon" :size="5" hover-rotate-value="45deg"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5">Opacity:0.3</h6>
                                    <icon-img :image="officeIcon" :size="5" hover-opacity="0.3"></icon-img>
                                </div>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Animation Transition Time Changes</lego-heading>
                        <component-wrapper title="" :code="codeStore.animationTransitionTime.code">
                            <flex j="sb">
                                <div>
                                    <h6 class="mb-5">Time(0.3s)</h6>
                                    <icon-img hover-effect="basic-2" :image="officeIcon" :size="5" transition-duration="0.3s"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5">Time(0.5s)</h6>
                                    <icon-img hover-effect="basic-2" :image="officeIcon" :size="5" transition-duration="0.5s"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5">Time(0.7s)</h6>
                                    <icon-img hover-effect="basic-2" :image="officeIcon" :size="5" transition-duration="0.7s"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5">Time(2s)</h6>
                                    <icon-img hover-effect="basic-2" :image="officeIcon" :size="5" transition-duration="2s"></icon-img>
                                </div>
                            </flex>
                            <flex j="sb" mt="4">
                                <div>
                                    <h6 class="mb-5 text-hide">Time(0.3s)</h6>
                                    <icon-img hover-effect="basic-1" hover-scale-value="2.5" :image="officeIcon" :size="5" transition-duration="0.3s"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5 text-hide">Time(0.5s)</h6>
                                    <icon-img hover-effect="basic-1" hover-scale-value="2.5" :image="officeIcon" :size="5" transition-duration="0.5s"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5 text-hide">Time(0.7s)</h6>
                                    <icon-img hover-effect="basic-1" hover-scale-value="2.5" :image="officeIcon" :size="5" transition-duration="0.7s"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5 text-hide">Time(2s)</h6>
                                    <icon-img hover-effect="basic-1" hover-scale-value="2.5" :image="officeIcon" :size="5" transition-duration="2s"></icon-img>
                                </div>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Cursor Pointer</lego-heading>
                        <component-wrapper title="" :code="codeStore.cursorPointer.code">
                            <flex j="sa">
                                <icon-img :image="officeIcon" :size="5" cursor-pointer></icon-img>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 lg="6" mt="4">
                        <lego-heading tag="h4" align="left" class="mb-3" color="primary">Rotate Icon</lego-heading>
                        <component-wrapper title="" :code="codeStore.rotateIcon.code">
                            <flex j="sa">
                                <div>
                                    <h6 class="mb-5">Normal</h6>
                                    <icon-img :image="officeIcon" :size="5"></icon-img>
                                </div>
                                <div>
                                    <h6 class="mb-5">Rotate(90deg)</h6>
                                    <icon-img :image="officeIcon" :size="5" rotate="90deg"></icon-img>
                                </div>
                            </flex>
                        </component-wrapper>
                    </col-12>
                    <col-12 mt="4">
                        <h4 class="mt-3">Props</h4>
                        <prop-table :items="propsInfo"/>
                    </col-12>
                    <col-12 class="mt-4">
                        <h3>SCSS Variables</h3>
                        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                    </col-12>
                </row>
            </card>
        </container>
    </div>
</template>

<script>
import Row from 'lego-framework/src/containers/Row';
import Col12 from 'lego-framework/src/containers/grid/col-12';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import IconImg from 'lego-framework/src/components/IconImage';
import Container from 'lego-framework/src/containers/layout/Container';
import officeIcon from 'lego-framework/src/assets/images/icons/office-icon.svg';
import iconCheckGreen from 'lego-framework/src/assets/images/icons/icon-check-green.svg';
import codeStore from '../core-components/code-storage/icons.json';
import PropTable from '../docs-components/Props';

export default {
    metaInfo: {
        path: 'components/icon-image/',
        title: 'Icon Image'
    },
    name: 'IconImageDocumentation',
    components: { Container, IconImg, ComponentWrapper, PropTable, Row, Col12 },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--xs-factor',
                    description: 'proportion factor for size - extra small',
                    defaultvalue: '0.65'
                },
                {
                    slno: '2',
                    name: '--sm-factor',
                    description: 'proportion factor for size - small',
                    defaultvalue: '0.875'
                },
                {
                    slno: '3',
                    name: '--lg-factor',
                    description: 'proportion factor for size - large',
                    defaultvalue: '1.25'
                },
                {
                    slno: '4',
                    name: '--lg-factor',
                    description: 'proportion factor for size - extra large',
                    defaultvalue: '1.75'
                }
            ],
            officeIcon: officeIcon,
            codeStore: codeStore,
            iconCheck: iconCheckGreen,
            propsInfo: [
                ['image', 'String', 'icon image', [''], ''],
                ['size', 'String', 'Icon size', ['1 - 11'], '3'],
                ['height', 'String', 'Custom Height value set on rem', ['any value, eg: 2.8'], ''],
                ['rotate', 'String', 'Rotate icon with an angle value', ['any angle value, eg: 90deg'], ''],
                // ['hoverScaleUp', 'String', ' hover effect - scale up set ( scaling up image size on hover)', ['true', 'false'], 'false'],
                ['hoverEffect', 'String', ' hover effect set ( basic-1. scale up, basic-2. rotate)', ['basic-1', 'basic-2'], 'false'],
                // ['hoverScaleValue', 'String', 'hover effect - scale up - scale value is set', ['Any value'], '1.2(set on scss variable)'],
                // ['hoverRotate', 'String', 'hover effect - rotation set, (rotate image on hover)', ['false', 'true'], 'false'],
                ['hoverRotateValue', 'String', 'hover rotate angle set', ['any angle value'], '180deg'],
                ['transitionDuration', 'String', 'transition effect duration', ['any css time duration value'], '0.5s'],
                ['hoverOpacity', 'String', 'opacity value changes on hover set', ['any css opacity value'], '0.8'],
                ['additionalClasses', 'String', 'additional classes to be added is set here', [''], '0.8']
            ]
        };
    }
};
</script>

<style scoped>

</style>
