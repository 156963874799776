<template>
    <component :is="tag" :class="[{'w-100p': w100,'h-100p':h100},
    classNames, additionalClasses, marginClasses, paddingClasses]">
        <slot></slot>
    </component>
</template>
<script>
import marginsAndPaddingsMixin from '../../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'flex',
    props: {
        tag: { type: String, default: 'div' },
        dir: { type: String, default: '' },
        mdDir: { type: String, default: '' },
        lgDir: { type: String, default: '' },
        align: { type: String, default: '' },
        mdAlign: { type: String, default: '' },
        lgAlign: { type: String, default: '' },
        justify: { type: String, default: '' },
        lgJustify: { type: String, default: '' },
        mdJustify: { type: String, default: '' },
        w100: { type: Boolean, default: false },
        h100: { type: Boolean, default: false },
        additionalClasses: { type: [String, Array], default: '' }
    },
    mixins: [marginsAndPaddingsMixin],
    data () {
        return {
            cssProperties: {
                direction: {
                    x: 'fl-x',
                    y: 'fl-y'
                },
                justifyContent: {
                    center: 'fl-j-c',
                    start: 'fl-j-s',
                    end: 'fl-j-e',
                    c: 'fl-j-c',
                    s: 'fl-j-s',
                    e: 'fl-j-e',
                    sb: 'fl-j-sb',
                    se: 'fl-j-se',
                    sa: 'fl-j-sa'
                },
                alignItems: {
                    center: 'fl-a-c',
                    start: 'fl-a-s',
                    end: 'fl-a-e',
                    c: 'fl-a-c',
                    s: 'fl-a-s',
                    e: 'fl-a-e',
                    st: 'fl-a-d',
                    bl: 'fl-a-b'
                }
            }
        };
    },
    computed: {
        classNames () {
            const names = [];
            if (!this.dir) {
                names.push('fl-x');
            }
            if (this.dir) {
                names.push('' + this.cssProperties.direction[this.dir]);
            }
            if (this.mdDir) {
                names.push('md-' + this.cssProperties.direction[this.mdDir]);
            }
            if (this.lgDir) {
                names.push('lg-' + this.cssProperties.direction[this.lgDir]);
            }

            // Set Properties stored on the cssProperties Object
            if (this.justify) {
                names.push(this.cssProperties.justifyContent[this.justify]);
            }
            if (this.lgJustify) {
                names.push('md-' + this.cssProperties.justifyContent[this.lgJustify]);
            }
            if (this.lgJustify) {
                names.push('lg-' + this.cssProperties.justifyContent[this.lgJustify]);
            }
            if (this.align) {
                names.push(this.cssProperties.alignItems[this.align]);
            }
            if (this.lgAlign) {
                names.push('md-' + this.cssProperties.alignItems[this.lgAlign]);
            }
            if (this.lgAlign) {
                names.push('lg-' + this.cssProperties.alignItems[this.lgAlign]);
            }
            return names;
        }
    }
};
</script>
