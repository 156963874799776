<template>
    <Flex dir="x" flex-wrap>
        <a :href="item.link" target="_blank" class="text-decoration-none" :class="['px-' + spacing]"
           v-for="(item,i) in items" :key="i">
            <icon-image
                :size="iconSizeComputed"
                :hover-effect="hoverEffect"
                :cursor-pointer="true"
                :image="item.icon"/>
        </a>
    </Flex>
</template>

<script>
import Flex from '../../containers/layout/Flex';
import IconImage from '../../components/IconImage';

export default {
    name: 'SocialIcons',
    components: { Flex, IconImage },
    props: {
        items: { type: Array, required: true },
        spacing: { type: String, default: '1' },
        size: { type: String, default: 'sm' },
        hoverEffect: { type: String, default: 'basic-1' }
    },
    computed: {
        iconSizeComputed () {
            if (this.size === 'xs') {
                return '3';
            } else if (this.size === 'sm') {
                return '5';
            } else if (this.size === 'md') {
                return '7';
            } else if (this.size === 'lg') {
                return '9';
            } else if (this.size === 'xl') {
                return '11';
            } else {
                return '';
            }
        }
    }
};
</script>

<style scoped>

</style>
