<template>
    <page-template>
        <div class="container-fluid bg-2">
            <h2>Position Helpers</h2>
            <ClassTable :items="positionHelpers"/>
            <div class="bg-4 p-4">
                <h4>Usage note</h4>
                <h5>Positioning Classes</h5>
                <h5> .pos-a (Absolute Positioning)</h5>
                <p>Sets the position of the element as absolute. An absolutely positioned element is removed from
                    the normal document flow and is positioned relative to its nearest positioned ancestor (an ancestor
                    with
                    a
                    position value other than static). If no such ancestor exists, it positions itself relative to the
                    initial
                    containing block.
                </p>
                <p> Use Case: Use this for elements that need to be precisely placed in relation to their parent
                    container
                    or
                    for layering elements over others.</p>
                <h4 class="mt-4">.pos-r (Relative Positioning)</h4>
                <p>Sets the position of the element as relative. The element retains its space in the document
                    flow, but you can adjust its position using the top, right, bottom, and left properties. This means
                    it
                    can
                    be moved without affecting the layout of surrounding elements.</p>
                <p> Use Case: Ideal for making small adjustments to an element’s position while keeping its original
                    space,
                    such
                    as nudging an element slightly.</p>
                <h4 class="mt-4">.pos-st (Sticky Top Positioning)</h4>
                <p>Positions an element at the top of the viewport from edge to edge, but it only becomes fixed
                    when you scroll past it. This means the element will initially scroll with the page until it reaches
                    the
                    top, at which point it will "stick" to the top of the viewport.</p>
                <p> Use Case: Great for headers or navigation bars that should remain visible once the user scrolls past
                    a
                    certain point, enhancing usability.</p>
                <h4 class="mt-4">.pos-sb (Sticky Bottom Positioning)</h4>
                <p>Positions an element at the bottom of the viewport from edge to edge, similar to .pos-st. The
                    element only becomes fixed at the bottom after scrolling past it.</p>
                <p>Use Case: Useful for footers that should appear after the user scrolls down a certain distance,
                    ensuring
                    important information is always accessible.</p>
                <h4 class="mt-4">.pos-ft (Fixed Top Positioning)</h4>
                <p>Positions an element at the top of the viewport from edge to edge. A fixed element is removed
                    from the document flow and will stay at the top of the viewport, regardless of scrolling.</p>
                <p> Use Case: Commonly used for persistent headers that need to remain visible at the top of the screen
                    while
                    the user scrolls through the content.</p>
                <h4 class="mt-4"> .pos-fb (Fixed Bottom Positioning)</h4>
                <p>Positions an element at the bottom of the viewport from edge to edge. Like .pos-ft, a fixed
                    bottom element remains at the bottom of the viewport, regardless of scrolling.</p>
                <p>Use Case: Often used for footers or call-to-action buttons that should always be visible, allowing
                    users
                    easy access to important actions.</p>

            </div>
            <div class="mt-4 bg-4">
                <h4>Example</h4>
                <code-container show-code-no-toggle :code-str="codeStore.Position.code"/>

                <div class="pos-r">to give the class position relative</div>
                <div class="pos-abs"> element is positioned absolutely</div>
                <div class="sticky-top">Stick to the top on viewports sized</div>
                <div class="sticky-bottom">Stick to the bottom on viewports sized</div>
                <div class="fixed-top">This element remains fixed at the top of the viewport, no matter how much you
                    scroll.
                </div>
                <div class="fixed-bottom">This element remains fixed at the botttom of the viewport, no matter how much
                    you
                    scroll.
                </div>
            </div>
        </div>
    </page-template>

</template>

<script>
import helpersMixin from '../helpersMixin';
import ClassTable from 'lego-framework/src/views/docs/docs-components/ClassTable';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/position.json';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'helpers/position/',
        title: 'Position'
    },
    name: 'colors.scss',
    components: { ClassTable, CodeContainer },
    data () {
        return {
            codeStore: codeStore
        };
    }

};
</script>
