<template>
    <div class="py-2">
        <div class="">
            <slot name="headSection"></slot>
            <div class="fl-x-cc">
                <img v-if="!hideImage"
                     :src="headerImage"
                     alt="Header Image"
                     :style="{ textAlign: imageAlignment }"
                /></div>
        </div>
        <!--        </div>-->
        <h4 class=" text-fs-3"  :class="titleBgColor" :style="{ textAlign: titleAlignment  }">{{ Title }}</h4>
        <p class="text-fs-1" :class="contentBgColor" :style="{ textAlign: contentAlignment }">{{ Content }}</p>

    </div>
</template>

<script>
export default {
    name: 'VariationCustomization',
    props: {
        Title: String,
        Content: String,
        contentColor: {
            type: String,
            default: 'black'
        },
        titleColor: {
            type: String,
            default: 'black'
        },
        borderColor: {
            type: String,
            default: 'black'
        },
        titleAlignment: {
            type: String,
            default: 'left'
        },
        contentAlignment: {
            type: String,
            default: 'left'
        },
        imageAlignment: {
            type: String,
            default: 'center'
        },
        hideBorder: {
            type: Boolean,
            default: false
        },
        hideImage: Boolean,
        headerImage: String,
        titleBgColor: String,
        contentBgColor: String
    }
};
</script>

<style scoped>

</style>
