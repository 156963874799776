<template>
    <page-template class=" bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-fs-4">Progress Steps</h1>
                    <p class="lead">Progress Steps Items Design variants</p>
                    <h4 class="mt-4">Usage</h4>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import Progress from
                        'lego-framework/src/dashboard-components\progress-steps\design-1\ProgressStepsDesign1.vue';</p>
                    <p>import Progress from
                        'lego-framework/src/dashboard-components\progress-steps\design-4\ProgressStepsDesign4.vue';</p>
                    <p>import Progress from
                        'lego-framework/src/dashboard-components\progress-steps\design-2\ProgressStepsDesign2.vue';</p>
                    <p>import Progress from
                        'lego-framework/src/dashboard-components\progress-steps\design-3\ProgressStepsDesign3.vue';</p>
                </div>
                <div class="col-12">
                    <h2 class="my-3 text-fs-3">Design 1</h2>
                    <progress-steps1-usage/>
                </div>
                <div class="col-12">
                    <h4 class="my-3 text-fs-2 mt-5">Progress Step Item</h4>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">pending</h5>
                    <progress-step-details-item1 :show-line="false" status="pending"/>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">ongoing</h5>
                    <progress-step-details-item1 :show-line="false" status="ongoing"/>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">done</h5>
                    <progress-step-details-item1 :show-line="false" status="done"/>
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStorage.design1.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfoDesign1"/>
                </div>
                <div class="col-12 mt-4">
                    <h2 class="my-3 text-fs-3">Design 2</h2>
                    <progress-steps2-usage/>
                </div>
                <div class="col-12">
                    <h4 class="my-3 text-fs-2 mt-5">Progress Step Item</h4>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">pending</h5>
                    <progress-step-details-item2 status="pending"/>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">ongoing</h5>
                    <progress-step-details-item2 color="primary" status="ongoing"/>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">done</h5>
                    <progress-step-details-item2 color="primary" border-color="primary" status="done"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStorage.design2.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfoDesign2"/>
                </div>
                <div class="col-12 mt-4">
                    <h2 class="my-3 text-fs-3">Design 3</h2>
                    <progress-steps3-usage/>
                </div>
                <div class="col-12">
                    <h4 class="my-3 text-fs-2 mt-5">Progress Step Item</h4>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">pending</h5>
                    <progress-steps-details-item3 :show-line="false"/>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">done</h5>
                    <progress-steps-details-item3 :show-line="false" status="done"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStorage.design3.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfoDesign3"/>
                </div>
                <div class="col-12 mt-4">
                    <h2 class="my-3 text-fs-3">Design 4</h2>
                    <progress-steps4-usage/>
                </div>
                <div class="col-12">
                    <h4 class="my-3 text-fs-2 mt-5">Progress Step Item</h4>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">pending</h5>
                    <progress-steps-details-item4 :show-line="false"/>
                </div>
                <div class="col-lg-4">
                    <h5 class="my-3 text-fs-1">done</h5>
                    <progress-steps-details-item4 :show-line="false" status="done"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStorage.design2.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfoDesign4"/>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <h4 class="my-3 text-fs-2 mt-1">Progress Step Item 5</h4>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 border-color="primary" color="primary"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 border-color="primary"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 class="mt-3"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 class="mt-3" border-color="primary" color="primary"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 class="mt-3" border-color="primary"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 class="mt-3"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 class="mt-3" border-color="primary" color="primary"/>
                </div>
                <div class="col-lg-4">
                    <progress-steps-details-item5 class="mt-3" border-color="primary"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfoDesign5"/>
                </div>
                <div class="mt-3 col-12">
                    <h3>SCSS Variables</h3>

                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import ProgressStepsDetailsItem5 from 'lego-framework/src/dashboard-components/progress-steps/ProgressStepsItem5';
import ProgressStepDetailsItem2
    from 'lego-framework/src/dashboard-components/progress-steps/design-2/ProgressStepItem2';
import ProgressStepDetailsItem1
    from 'lego-framework/src/dashboard-components/progress-steps/design-1/ProgressStepItem1';
import ProgressStepsDetailsItem4
    from 'lego-framework/src/dashboard-components/progress-steps/design-4/ProgressStepItem4';
import ProgressStepsDetailsItem3
    from 'lego-framework/src/dashboard-components/progress-steps/design-3/ProgressStepItem3';

import icon1 from '../../assets/web/icons/progress-steps/icon-user.svg';
import icon2 from '../../assets/web/icons/progress-steps/flag-05.svg';
import icon3 from '../../assets/web/icons/progress-steps/users-plus.svg';
import icon4 from '../../assets/web/icons/progress-steps/stars-02.svg';

import ProgressSteps2Usage from './usage/ProgressSteps2Usage';
import ProgressSteps1Usage from './usage/ProgressSteps1Usage';
import ProgressSteps3Usage from './usage/ProgressSteps3Usage';
import ProgressSteps4Usage from './usage/ProgressSteps4Usage';
import codeStorage from './code-store/progress-steps.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'ProgressStepsDemo',
    components: {
        ProgressSteps4Usage,
        ProgressSteps3Usage,
        ProgressSteps1Usage,
        ProgressSteps2Usage,
        ProgressStepDetailsItem1,
        ProgressStepsDetailsItem4,
        ProgressStepsDetailsItem3,
        ProgressStepDetailsItem2,
        ProgressStepsDetailsItem5,
        PropTable
    },
    data () {
        return {
            codeStorage: codeStorage,
            items: [
                {
                    icon: icon1,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon2,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon3,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon4,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                }
                // {
                //     icon: icon5,
                //     text1: 'Your Details',
                //     text2: 'Name and Email',
                //     status: 'pending'
                // }
            ],
            propsInfoDesign1: [
                ['items', 'Array', 'array of items', '', ''],
                ['lineMl', 'String', 'separating line margin left', '', '-5.5rem'],
                ['lineMr', 'String', 'separating line margin right', '', '-5.5rem'],
                ['lineWidth', 'Width of the separating line', '', '12r'],
                ['sectionWidth', 'Width of a section', '', '12r']
            ],
            propsInfoDesign2: [
                ['items', 'Array', 'array of items', '', '']
            ],
            propsInfoDesign3: [
                ['items', 'Array', 'array of items', '', ''],
                ['lineMl', 'String', 'separating line margin left', '', '-4.8rem'],
                ['lineMr', 'String', 'separating line margin right', '', '-4.8rem'],
                ['lineWidth', 'Width of the separating line', '', '12r'],
                ['sectionWidth', 'Width of a section', '', '12r']
            ],
            propsInfoDesign4: [
                ['items', 'String', 'array of items', '', '']
            ],
            propsInfoDesign5: [
                ['text1', 'String', 'text item 1', '', ''],
                ['text2', 'String', 'text item 2', '', ''],
                ['text1FontWeight', 'String', 'text 1 font weight', '', '500'],
                ['color', 'String', 'text 1 font color', '', '2'],
                ['borderColor', 'String', 'border color', '', '']
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--progress-step-item-1-line-width',
                    description: 'line width of progress step design item 1',
                    defaultvalue: '12rem'
                },
                {
                    slno: '2',
                    name: '--progress-step-item-2-line-height',
                    description: 'line height of progress step design item 2',
                    defaultvalue: '2rem'
                },
                {
                    slno: '3',
                    name: '--progress-step-item-3-line-width',
                    description: 'line width of progress step design item 3',
                    defaultvalue: '12rem'
                },
                {
                    slno: '4',
                    name: '--progress-step-item-4-line-height',
                    description: 'line height of progress step design item 3',
                    defaultvalue: '2rem'
                }
            ]
        };
    },
    methods: {
        statusChange (idx) {
            this.items[idx - 1].status = 'done';
        }
    }
};
</script>

<style scoped>

</style>
