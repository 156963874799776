<template>
    <div>
        <div>
            <div class="fl-x">
                <div class="">
                    <div v-if="!hideImage " class="fl-y fl-a-c  w-10r">

                        <img :src="headerImage" class="" alt="Header Image"/>
                        <h4 :class="header1BgColor" class="text-center text-center text-fs-2">{{ heading1 }}</h4>
                        <p :class="title1BgColor" class="text-center text-fs-1">{{ title1 }}</p>
                    </div>
                </div>
                <slot name="veticalline1"></slot>
                <!--                <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n6" width="15r"/>-->
                <div v-if="!hideImage" class="fl-y fl-a-c  w-15r ml-n7">

                    <img :src="headerImage1" alt="Header Image"/>
                    <h4 :class="header2BgColor" class="text-center text-fs-2">{{ heading2 }}</h4>
                    <p :class="title3BgColor" class="">{{ title2 }}</p>
                </div>
                <slot name="veticalline2"></slot>
                <!--                <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n8" width="15r"/>-->
                <div v-if="!hideImage" class="fl-y fl-a-c  w-15r ml-n7" >

                    <img :src="headerImage2" alt="Header Image"/>
                    <h4 :class="header3BgColor" class="text-center text-fs-2">{{ heading3 }}</h4>
                    <p :class="title4BgColor" class="text-center ">{{ title3 }}</p>
                </div>
                <slot name="veticalline3"></slot>
                <!--                <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n8" width="15r"/>-->
                <div v-if="!hideImage" class="fl-y fl-a-c  w-15r ml-n7">

                    <img :src="headerImage3" alt="Header Image"/>
                    <h4 :class="header3BgColor" class="text-center text-fs-2">{{ heading4 }}</h4>
                    <p :class="title4BgColor" class="text-center">{{ title4 }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Details-horizontal',
    props: {
        headerImage: String,
        headerImage1: String,
        headerImage2: String,
        headerImage3: String,
        heading1: String,
        heading2: String,
        heading3: String,
        heading4: String,
        title1: String,
        title2: String,
        title3: String,
        title4: String,
        hideImage: Boolean,
        title1BgColor: String,
        title2BgColor: String,
        title3BgColor: String,
        title4BgColor: String,
        header1BgColor: String,
        header2BgColor: String,
        header3BgColor: String,
        header4BgColor: String
    }
};
</script>

<style scoped>

</style>
