<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Side Navbar Plain</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--nav-bar-plain-dropdown-bg',
                    description: 'Dropdown background color',
                    defaultvalue: 'Transparent'
                },
                {
                    slno: '2',
                    name: '--nav-bar-plain-dropdown-hover-bg',
                    description: 'Dropdown Hover background color',
                    defaultvalue: 'Transparent'
                },
                {
                    slno: '3',
                    name: '--nav-bar-plain-item-padding-y',
                    description: 'Navbar Link item - vertical padding',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '4',
                    name: '--nav-bar-plain-item-padding-x',
                    description: 'Navbar Link item - Horizontal padding',
                    defaultvalue: '1rem'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
