<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Tag Item</h2>
                    <p class="lead">Basic Tag item component</p>
                    <h4 class="mt-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Default</h5>
                    <tag-item text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">design: basic-b</h5>
                    <tag-item text="premium" design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.designBasicB.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">color: secondary</h5>
                    <tag-item text="premium" color="secondary"/>
                    <code-container show-code-no-toggle :code-str="codeStore.colorSecondary.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">color: info</h5>
                    <tag-item text="premium" color="info"/>
                    <code-container show-code-no-toggle :code-str="codeStore.colorInfo.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">borderRadius: 1</h5>
                    <tag-item text="premium" color="info" border-radius="1"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderRadius.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">font customization</h5>
                    <tag-item text="premium" font-weight="normal" font-size="1"
                              color="info"/>
                    <code-container show-code-no-toggle :code-str="codeStore.fontCustomization.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">additional classes</h5>
                    <tag-item text="premium"
                              container-classes="font-weight-light text-fs-2 px-3"
                              color="info"/>
                    <code-container show-code-no-toggle :code-str="codeStore.additionalClasses.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <col-12 class="mt-4">
                    <h3>SCSS Variables</h3>
                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </col-12>
            </div>
        </div>
    </page-template>
</template>

<script>
import TagItem from 'lego-framework/src/web-components/tag-items/TagItem';
import codeStore from '../code-storage/tag-item.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'TagItemVariantsDocumentation',
    metaInfo: {
        path: 'ui-elements/tag-item/',
        title: 'Tag Item'
    },
    components: { TagItem, CodeContainer, PropTable },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--tag-item-border-color',
                    description: 'Tag item border color value',
                    defaultvalue: 'var(--color-primary-200)'
                },
                {
                    slno: '2',
                    name: '--tag-item-bg-color',
                    description: 'Tag item background color',
                    defaultvalue: 'var(--color-primary-100)'
                },
                {
                    slno: '3',
                    name: '--tag-item-border-radius',
                    description: 'Tag item border radius',
                    defaultvalue: 'var(--round-3)'
                }
            ],
            codeStore: codeStore,
            propsInfo: [
                ['color', 'String', 'tag color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['borderRadius', 'String', 'border radius value', ['1-10'], '3'],
                ['design', 'String', 'design variant', ['basic-a', 'basic-b'], 'basic-a'],
                ['fontSize', 'String', 'font size', ['-1 - 7'], ''],
                ['fontWeight', 'String', 'font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder', '100 - 900'], ''],
                ['containerClasses', 'String, Array', 'additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
