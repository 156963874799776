<template>
    <li v-if="type === 'separator'" class="fl-gr-1">
    </li>
    <li v-else :class="liClassNames">
        <btn :text="text" v-bind="btnAttrs" v-if="type=== 'button'"/>
        <a :href="sanitizedLink" @click.prevent="linkClicked" v-else>
            <img :src="image" v-if="image" :alt="text" :class="imageClassNames" class="mr-1"/>
            <span>
                <i :class="icon" v-if="icon" class="mr-1"></i>
                <span v-html="text"></span>
                <!--                {{ text }}-->
            </span>

            <i class="fa fa-angle-down ml-1 submenu-indicator-icon" v-if="items && items.length"></i>
        </a>
        <ul :class="childMenuClasses" v-if="items">
            <lego-nav-item @link-event="bubble" @itemClicked="$emit('itemClicked')" v-bind="child" :shadow="shadow" :dropdownRound="dropdownRound" v-for="(child, index) in items"
                           :key="index"/>
        </ul>
    </li>
</template>

<script>
import utils from './utils';

export default {
    name: 'lego-nav-item',
    props: {
        dropdownRound: { type: [String, Number], default: '' },
        dropdownPlacement: { type: String, default: 'left' },
        type: { type: String, default: '' },
        btnAttrs: {
            type: Object,
            default () {
                return {};
            }
        },
        icon: { type: String, default: '' },
        text: { type: String, default: '' },
        event: { type: String, default: '' },
        link: { type: String, default: '' },
        image: { type: String, default: '' },
        imageRound: { type: String, default: '' },
        imageClass: { type: String, default: '' },
        items: { type: [Array, null], default: null },
        shadow: { type: String, default: '' },
        childMenuAdditionalClasses: { type: [String, Array], default: '' },
        liAdditionalClasses: { type: [String, Array], default: '' }
    },
    data () {
        return {
            activeChildren: 0,
            active: false,
            checked: false
        };
    },
    watch: {
        $route (to, from) {
            this.active = to.path === this.link;
        },
        activeChildren (to, from) {
            if (to > from) {
                this.checked = true;
            }
        },
        active (to, from) {
            if (from === to) {
                return;
            }
            const $parent = this.$parent;

            // Not a nav-item
            if ($parent.activeChildren === undefined) {
                return;
            }

            if (to === true) {
                $parent.activeChildren += 1;
            } else {
                $parent.activeChildren -= 1;
            }
        }
    },
    computed: {
        imageClassNames () {
            const names = [];
            const imageRound = this.imageRound;
            if (imageRound) {
                names.push('round-' + imageRound);
            }

            const imageClass = this.imageClass;
            if (imageClass) {
                names.push(imageClass);
            }
            return names;
        },
        liClassNames () {
            const names = [this.liAdditionalClasses];
            if (this.active || this.activeChildren > 0) {
                names.push('active');
            }

            if (this.dropdownPlacement === 'right') {
                names.push('right-dropdown');
            }

            if (this.type === 'button') {
                names.push('no-hover-effect');
            }

            return names;
        },
        sanitizedLink () {
            const link = this.link;
            if (link && link.length > 0 && link !== '#') {
                return utils.url(link);
            }
            return link;
        },
        childMenuClasses () {
            const names = ['nav-sub-menu', this.childMenuAdditionalClasses];
            if (this.shadow) {
                names.push(`bs-${this.shadow}`);
            }

            const isActive = this.active || this.activeChildren > 0;
            if (this.checked) {
                names.push('show');
            }

            if (isActive) {
                names.push('active');
            }

            if (this.dropdownRound) {
                names.push('round-wc-' + this.dropdownRound);
            }

            return names;
        }
    },

    mounted () {
        this.active = this.$route.path === this.link;
    },

    methods: {
        bubble (name) {
            this.$emit('link-event', name);
        },
        linkClicked () {
            this.checked = !this.checked;
            const link = this.link;
            const event = this.event;

            if (event) {
                this.bubble(event);
            } else if (link && link !== '#') {
                this.$router.push(link);
            }

            if (link && link !== '#') {
                this.$emit('itemClicked');
            }
        }
    }
};
</script>

<style scoped>

</style>
