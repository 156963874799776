<template>
    <div class="b-1 round-2 p-3">
        <div class="fl-te-c">
            <div class="">
                <icon-img :image="iconImg" size="8"/>
            </div>
            <div class="p-2 b-round">
                <icon-img :image="dotsIcon" size="4"/>
            </div>
        </div>
        <div class="mt-3 font-weight-500 text-2">{{text1}}</div>
        <div class="fl-te-c mt-1">
            <div class="font-weight-600 text-fs-4">{{text2}}</div>
            <custom-h-el class="fl-x fl-a-c b-secondary b-1 p-1 text-primary round-3" bg-color="secondary" bg-color-thickness="100">
                <icon-img :image="arrowIcon" size="1" rotate="-90deg"/>
                <div class="text-primary text-fs-0 font-weight-500">{{text3}}</div>
            </custom-h-el>
        </div>
    </div>
</template>

<script>
import graphIcon from './assets/icons8-graph-64.png';
import dotsIcon from './assets/three-dots.png';
import arrowIcon from './assets/icons8-arrow-right-16-green.png';
import CustomHEl from '../../containers/CustomHeightContainer';
import IconImg from '../../components/IconImage';

export default {
    name: 'MetricsItem4',
    components: { CustomHEl, IconImg },
    props: {
        text1: { type: String, default: 'Views 24 hours' },
        iconImg: { type: String, default: graphIcon },
        text2: { type: String, default: '2,000' },
        text3: { type: String, default: '100%' }
    },
    data () {
        return {
            dotsIcon: dotsIcon,
            arrowIcon: arrowIcon
        };
    }
};
</script>

<style scoped>

</style>
