<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Blog Item Card</h2>
                    <p class="lead">Blog Item Card with title, content, author name , date published, time to read, and
                        slots for adding custom contents</p>
                </div>
                <div class="col-12">
                    <h4 class="my-3">Design 1</h4>
                    <h3 class="my-3 text-fs-1">Usage</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">align : Vertical</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>

                    <code-container show-code-no-toggle :code-str="codeStore.design1.vertical.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">align : Vertical ( Card Design )</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"
                                card-design/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.verticalCard.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">align : Vertical ( Card Design with padding )</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                :img="img"
                                card-design/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.verticalCardPadding.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">align : Vertical ( img border radius )</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                img-border-radius="2"
                                :img="img"
                                card-design/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.verticalImgBorderRadius.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">align : Vertical ( img and card border radius )</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                img-border-radius="2"
                                border-radius="2"
                                :img="img"
                                card-design/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.imgAndCardBorderRadius.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">slot: bottom</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                img-border-radius="2"
                                border-radius="2"
                                :img="img"
                                card-design>
                                <template slot="bottom">
                                    <btn text="Learn More"/>
                                </template>
                            </blog-item-design1>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.slotBottom.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">slot: top-left</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                img-border-radius="2"
                                border-radius="2"
                                :img="img"
                                card-design>
                                <template slot="top-left">
                                    <span class="text-primary">Design</span>
                                </template>
                            </blog-item-design1>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.slotTopLeft.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">slot: top-right</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                img-border-radius="2"
                                border-radius="2"
                                :img="img"
                                card-design>
                                <template slot="top-right">
                                    <span class="p-1 round-1 bg-secondary text-fs-0">new</span>
                                </template>
                            </blog-item-design1>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.slotTopRight.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">font-customization</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                p="3"
                                img-border-radius="2"
                                border-radius="2"
                                :img="img"
                                card-design
                                tag-color="secondary"
                                text3-color="primary"
                                text4-color="2">
                            </blog-item-design1>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.fontCustomization.code"/>
                </div>
                <div class="col-lg-8">
                    <h5 class="my-3">align : horizontal</h5>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                align="horizontal"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.horizontal.code"/>
                </div>
                <div class="col-lg-8">
                    <h5 class="my-3">align : horizontal ( Card Design)</h5>
                    <blog-item-design1
                        text1="Design"
                        text2="8 Mins Read"
                        text3="Managing wild horse impacts in Kosciuszko National Park"
                        text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                        text5="Phoenix Baker"
                        text6="19 Jan 2024"
                        align="horizontal"
                        :avatar-img="avatarImg"
                        reading-time="8 Mins Read"
                        :img="img"
                        card-design/>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.horizontalCard.code"/>
                </div>
                <div class="col-lg-8">
                    <h5 class="my-3">align : horizontal ( Card Design with padding)</h5>

                    <blog-item-design1
                        text1="Design"
                        text2="8 Mins Read"
                        text3="Managing wild horse impacts in Kosciuszko National Park"
                        text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                        text5="Phoenix Baker"
                        text6="19 Jan 2024"
                        align="horizontal"
                        :avatar-img="avatarImg"
                        reading-time="8 Mins Read"
                        :img="img"
                        card-design
                        p="3"/>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.horizontalCardPadding.code"/>
                </div>
                <div class="col-12">
                    <h4 class="my-3">Design 2</h4>
                    <h3 class="my-3 text-fs-1">Usage</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.design2.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">align : Vertical</h5>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design2
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design2.vertical.code"/>
                </div>
                <div class="col-lg-8">
                    <h5 class="my-3">align : horizontal</h5>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design2
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                align="horizontal"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design2.horizontal.code"/>
                </div>
                <div class="col-12">
                    <h4 class="my-3">Design 3</h4>
                    <h3 class="my-3 text-fs-1">Usage</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.design3.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">align : Vertical</h5>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design3
                                text1="Phoenix Baker"
                                text2="Design"
                                text3="8 Mins Read"
                                text4="Managing wild horse impacts in Kosciuszko National Park"
                                text5="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                        National Park face many threats, including pressures from
                        introduced animals such as wild horses."
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design3.vertical.code"/>
                </div>
                <div class="col-lg-8">
                    <h5 class="my-3">align : horizontal</h5>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design3
                                text1="Phoenix Baker"
                                text2="Design"
                                text3="8 Mins Read"
                                text4="Managing wild horse impacts in Kosciuszko National Park"
                                text5="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                        National Park face many threats, including pressures from
                        introduced animals such as wild horses."
                                align="horizontal"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design3.horizontal.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h2 class="my-3">Hover Animations</h2>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">hover Image scaleUp</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                hover-image-scale-up
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle
                                    :code-str="codeStore.design1.hoverEffects.hoverImgScaleUp.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">hover shadow</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                hover-shadow="5"
                                hover-padding="2"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.hoverEffects.hoverShadow.code"/>
                </div>
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">hoverTransform: translateUp</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                hover-transform="translateUp"
                                hover-shadow="5"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle
                                    :code-str="codeStore.design1.hoverEffects.hoverTransformTranslateUp.code"/>
                </div>
                <!--                <div class="col-lg-6">-->
                <!--                    <h3 class="my-3 text-fs-1">hoverTransform: scaleUp</h3>-->
                <!--                    <blog-item-design-1
                text1=Design-
                text2=8 Mins Read-
                text3=Managing wild horse impacts in Kosciuszko National Park>
                text4=The delicate alpine and sub-alpine ecosystems of Kosciuszko National Park face many threats, including pressures from introduced animals such as wild horses.

                text5=Phoenix Baker<
                text6=19 Jan 2024!--
                                  hover-transform="rotateRight"-->
                <!--                        hover-shadow="5"-->
                <!--                        :avatar-img="avatarImg"-->
                <!--                        reading-time="8 Mins Read"-->
                <!--                        :img="img"/>-->
                <!--                </div>-->
                <div class="col-lg-6">
                    <h3 class="my-3 text-fs-1">hoverTransform: transformRotate-5</h3>
                    <div class="row">
                        <div class="col-8">
                            <blog-item-design1
                                text1="Design"
                                text2="8 Mins Read"
                                text3="Managing wild horse impacts in Kosciuszko National Park"
                                text4="The delicate alpine and sub-alpine ecosystems of Kosciuszko
                         National Park face many threats, including pressures from introduced animals
                         such as wild horses."
                                text5="Phoenix Baker"
                                text6="19 Jan 2024"
                                hover-transform="transformRotate-5"
                                hover-shadow="5"
                                :avatar-img="avatarImg"
                                reading-time="8 Mins Read"
                                :img="img"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle
                                    :code-str="codeStore.design1.hoverEffects.hoverTransformRotate.code"/>
                </div>

                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo1"/>
                </div>
                <div class-="col-lg-12  mt-4">
                    <h3>SCSS Variables</h3>

                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>

            </div>
        </div>
    </page-template>
</template>

<script>
import BlogItemDesign1 from 'lego-framework/src/web-components/blog-cards/design-1/BlogItemDesign1';
import img1 from 'lego-framework/src/views/demo/web-components/assets/kiwihug-zfBltqXeKL4-unsplash.jpg';
import avatar from 'lego-framework/src/assets/img/avatar/aiony-haust-3TLl_97HNJo-unsplash1.jpg';
import BlogItemDesign2 from 'lego-framework/src/web-components/blog-cards/design-2/BlogItemDesign2';
import BlogItemDesign3 from 'lego-framework/src/web-components/blog-cards/design-3/BlogItemDesign3';
import codeStore from './code-storage/blogs-splitted.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import propValuesVariants from 'lego-framework/src/views/docs/propValuesVariants';

export default {
    name: 'BlogItemVariantsSplittedDocs',
    metaInfo: {
        path: 'web-components/blog-item-card/',
        title: 'Blog Item'
    },
    components: { BlogItemDesign3, BlogItemDesign2, BlogItemDesign1, PropTable },
    data () {
        return {
            img: img1,
            avatarImg: avatar,
            codeStore: codeStore,
            propsInfo1: [
                ['img', 'String', 'Top image', '', ''],
                ['imgBorderRadius', 'String', 'Top image border radius', propValuesVariants.borderRadius, '0'],
                ['imgHeight', 'String', 'Top image border radius', ['height in rem (eg:17r)'], '15r'],
                ['avatarImg', 'String', 'Avatar Image of the author', ['image url'], ''],
                ['shadow', 'String', 'Shadow value', propValuesVariants.shadows, '0'],
                ['tagColor', 'String', 'tag color', propValuesVariants.themeColors, 'primary'],
                ['align', 'String', 'Alignment', ['vertical', 'horizonal'], 'vertical'],
                ['text1', 'String', 'Text1 (numbered according to order which it is shown (left-right, top-bottom)', '', ''],
                ['text2', 'String', 'Text1 (numbered according to order which it is shown (left-right, top-bottom)', '', ''],
                ['text3', 'String', 'Text1 (numbered according to order which it is shown (left-right, top-bottom)', '', ''],
                ['text4', 'String', 'Text1 (numbered according to order which it is shown (left-right, top-bottom)', '', ''],
                ['text5', 'String', 'Text1 (numbered according to order which it is shown (left-right, top-bottom)', '', ''],
                ['text6', 'String', 'Text1 (numbered according to order which it is shown (left-right, top-bottom)', '', ''],
                ['contentPx', 'String', 'content horizontal padding', propValuesVariants.spacer, '0'],
                ['contentMinHeight', 'String', 'Top image border radius', ['height in rem (eg:11r)'], '9.5r'],
                ['padding and margin', 'String', 'p,py,px,pb,pt,pl,pr,m,my,mx,mb,mt,ml,mr,..etc, applied to the root element', propValuesVariants.spacer, ''],
                ['borderRadius', 'String', 'border radius', propValuesVariants.borderRadius, '0'],
                ['showRedirectIcon', 'String', 'show or hide redirection link at the bottom', propValuesVariants.boolean, '0']
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--blog-item-card-tag-border-color',
                    description: 'Tag border color in blog item card',
                    defaultvalue: 'var(--color-primary-200)'
                }
            ],
            propValuesVariants: propValuesVariants
        };
    }
};
</script>

<style scoped>

</style>
