<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Metrics Type 2</h2>
                    <p class="lead">Matrices component for showing useful data to user on dashboard or other pages</p>
                    <h4 class="my-4">Usage</h4>
                    <p class="lead">import MetricsItem4 from 'lego-framework/src/dashboard-components/metrics/MetricsItem4'; </p>
                    <p class="lead">import MetricsItem5 from 'lego-framework/src/dashboard-components/metrics/MetricsItem5';</p>
                    <p class="lead">import MetricsSparkline1 from 'lego-framework/src/dashboard-components/metrics/MetricsSparkline1';</p>
                    <p class="lead">import MetricsSparkline2 from 'lego-framework/src/dashboard-components/metrics/MetricsSparkline2';</p>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Metrics Item - 4</h5>
                    <metrics-item4
                        text1="Views 24 hours"
                        text2="2,000"
                        text3="100%"/>
                    <code-container :code-str="codeStore.item4.default.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Metrics Item - 5</h5>
                    <metrics-item5
                        text1="Views 24 hours"
                        text2="2,000"
                        text3="100%"/>
                    <code-container :code-str="codeStore.item5.default.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Metrics Sparkline 1</h5>
                    <metrics-sparkline1
                        text1="Views 24 hours"
                        text2="2,000"
                        text3="100%"
                        text4="vs last month"
                        text5="View Report"/>
                    <code-container :code-str="codeStore.sparkline1.default.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Metrics Sparkline 2</h5>
                    <metrics-sparkline2
                        text1="Views 24 hours"
                        text2="2,000"
                        text3="100%"
                        text4="View Report"/>
                    <code-container :code-str="codeStore.sparkline2.default.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-3">Slots</h4>
                    <h5 class="my-3">graph</h5>
                    <p class="lead">for adding graph</p>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-3">Chart Configuration</h4>
                    <p class="lead">Add Dependencies to package.json file</p>
                    <code-container :code-str="codeStore.chartConfig.dependencies.code" show-code-no-toggle></code-container>
                    <h6 class="py-3">Line Chart</h6>
                    <code-container :code-str="codeStore.chartConfig.lineChart1.code" show-code-no-toggle></code-container>
                    <code-container class="mt-3" :code-str="codeStore.chartConfig.lineChart2.code" show-code-no-toggle></code-container>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import MetricsItem4 from '../../dashboard-components/metrics/MetricsItem4';
import MetricsItem5 from '../../dashboard-components/metrics/MetricsItem5';
import MetricsSparkline1 from '../../dashboard-components/metrics/MetricsSparkline1';
import MetricsSparkline2 from '../../dashboard-components/metrics/MetricsSparkline2';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../web-components/code-storage/metrics.json';
import PropTable from '../docs-components/Props';

export default {
    name: 'MetricsVariantsDemo',
    metaInfo: {
        path: 'web-components/metrics-type-2/',
        title: 'Metrics Type 2'
    },
    components: { MetricsItem4, MetricsItem5, MetricsSparkline1, MetricsSparkline2, CodeContainer, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'text prop numbered based on its relative position, counted left-right, top-bottom order', '', ''],
                ['text2', 'String', 'text prop numbered based on its relative position, counted left-right, top-bottom order', '', ''],
                ['text3', 'String', 'text prop numbered based on its relative position, counted left-right, top-bottom order', '', ''],
                ['text4', 'String', 'text prop numbered based on its relative position, counted left-right, top-bottom order', '', ''],
                ['text5', 'String', 'text prop numbered based on its relative position, counted left-right, top-bottom order', '', ''],
                ['icon', 'String', 'top icon class', ['any fa class'], ''],
                ['icon-img', 'String', 'Top Icon Image', ['any icon image'], '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
