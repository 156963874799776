<template>
    <div class="fl-x fl-a-e progress-step-item-2" :style="[setAnimationVariables]">
        <div>
            <div class="pos-r" v-if="showLine">
                <custom-space-el class="" width="1.5px" bg-color="1" :mt="lineMt" :mb="lineMb" ml="0.5rem" :height="lineHeight"/>
                <custom-space-el class="pos-a progress-step-item-2-vertical-line-animation top-0" :mt="lineMt" :mb="lineMb" width="1.5px" :bg-color="setBorderColor"
                                 ml="0.5rem" :height="lineHeight" v-if="status==='ongoing' || status ==='done'"/>
            </div>
            <icon-step-ongoing v-if="status ==='ongoing'"/>
            <icon-step-pending v-if="status ==='pending'"/>
            <icon-step-done v-if="status ==='done'"/>
        </div>
        <div class="ml-1 mb-n3">
            <text-el tag="div" :text="text1" :fs="text1FontSize" :color="color" :font-weight="text1FontWeight" mb="1"/>
            <text-el m="0" tag="div" :text="text2" :fs="text2FontSize" :color="color"/>
        </div>
    </div>
</template>

<script>
import TextEl from 'lego-framework/src/components/typography/TextElement';
import CustomSpaceEl from 'lego-framework/src/containers/CustomSpacingElement';
import IconStepOngoing from '../assets/IconStepOngoing';
import IconStepPending from '../assets/IconStepPending';
import IconStepDone from '../assets/IconStepDone';

export default {
    name: 'ProgressStepDetailsItem2',
    components: { IconStepDone, IconStepPending, TextEl, CustomSpaceEl, IconStepOngoing },
    props: {
        text1: { type: String, default: 'Your Details' },
        text2: { type: String, default: 'Please provide your name and email' },
        status: { type: String, default: 'pending' },
        text1FontWeight: { type: String, default: '500' },
        text1FontSize: { type: String, default: '1' },
        text2FontSize: { type: String, default: '0' },
        color: { type: String, default: '1' },
        borderColor: { type: String, default: '1' },
        lineHeight: { type: String, default: '2.5rem' },
        lineMt: { type: String, default: '-0.125rem' },
        lineMb: { type: String, default: '-0.08rem' },
        showLine: { type: Boolean, default: true }
    },
    computed: {
        setBorderColor () {
            if (this.status === 'done') {
                return 'primary';
            }
            return '1';
        },
        setAnimationVariables () {
            return {
                '--progress-step-item-2-line-height': this.lineHeight
            };
        }
    }
};
</script>

<style scoped lang="scss">
    .bg-fill-animation-primary {
        animation: bgColor 0.5s linear;
        animation-fill-mode: forwards;
        /*padding: var(--spacer-1);*/
        height: 100%;
        background-color: var(--color-primary);
    }

    @keyframes bgColor {
        from {
            height: 0;
        }
        to {
            height: 100%;
        }
    }
</style>
