export default {
    props: {
        p: { type: String, default: '' },
        pt: { type: String, default: '' },
        pb: { type: String, default: '' },
        py: { type: String, default: '' },
        px: { type: String, default: '' },
        pl: { type: String, default: '' },
        pr: { type: String, default: '' },
        pMd: { type: String, default: '' },
        ptMd: { type: String, default: '' },
        pbMd: { type: String, default: '' },
        pyMd: { type: String, default: '' },
        pxMd: { type: String, default: '' },
        plMd: { type: String, default: '' },
        prMd: { type: String, default: '' },
        pLg: { type: String, default: '' },
        ptLg: { type: String, default: '' },
        pbLg: { type: String, default: '' },
        pyLg: { type: String, default: '' },
        pxLg: { type: String, default: '' },
        plLg: { type: String, default: '' },
        prLg: { type: String, default: '' }
    },
    computed: {
        paddingClasses () {
            const classNames = [];
            const margins = ['p',
                'pt',
                'pb',
                'py',
                'px',
                'pl',
                'pr',
                'p-lg',
                'pt-lg',
                'pb-lg',
                'py-lg',
                'px-lg',
                'pl-lg',
                'pr-lg',
                'p-md',
                'pt-md',
                'pb-md',
                'py-md',
                'px-md',
                'pl-md',
                'pr-md'];
            const that = this;
            margins.forEach(item => {
                let paddingItemPropName = item;
                if (item.slice(-3) === '-md') {
                    // set key name according to  props name : eg(mt-md to mdMd)
                    paddingItemPropName = item.slice(0, -3) + 'Md';
                    // console.log('itemProp', itemProp);
                }
                if (item.slice(-3) === '-lg') {
                    // set key name according to  props name : eg(mt-lg to lgMd)
                    paddingItemPropName = item.slice(0, -3) + 'Lg';
                }
                classNames.push(this.setClassName(paddingItemPropName, that[paddingItemPropName]));
            });
            return classNames;
        }
    },
    methods: {
        setClassName (paddingTypePropName, value) {
            let paddingTypeStr = paddingTypePropName;
            if (paddingTypePropName.slice(-2) === 'Md') {
                // set key name according to  props name : eg(mt-md to mdMd)
                paddingTypeStr = paddingTypeStr.slice(0, -2) + '-' + 'md';
            }
            if (paddingTypePropName.slice(-2) === 'Lg') {
                // set key name according to  props name : eg(mt-md to mdMd)
                paddingTypeStr = paddingTypeStr.slice(0, -2) + '-' + 'lg';
            }
            if (this[paddingTypePropName]) {
                return paddingTypeStr + '-' + value;
            }
        }
    }
};
