<template>
    <page-template>
        <div class="bg-2 p-5">
            <div>
                <h3>Buttons</h3>
            </div>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>
    </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-10r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--btn-padding-y',
                    description: 'Button vertical padding, calculated differently based on button size using the following formula:<br>' +
                        '1. calc(var(--btn-base-padding-y) * var(--#{$name}-factor)<br>' +
                        'Depended on Variables:<br>' +
                        '1. --btn-base-padding-y<br>' +
                        '2. element-padding-y (in scss/variables/core.js)<br>' +
                        'Files: src\\scss\\components\\btns\\common.scss, src\\scss\\components\\btns\\_variations.scss, src\\scss\\variables\\core.js',
                    defaultvalue: '0.325em'
                },
                {
                    slno: '2',
                    name: '--btn-padding-x',
                    description: 'Button horizontal padding, calculated differently based on button size using the following formula:<br>' +
                        '1. calc(var(--btn-base-padding-x) * var(--#{$name}-factor)<br>' +
                        'Depended on Variables:<br>' +
                        '1. --btn-base-padding-x<br>' +
                        '2. element-padding-x (in scss/variables/core.js)',
                    defaultvalue: '0.75em'
                },
                {
                    slno: '3',
                    name: '--btn-font-size',
                    description: 'Font size inside button. Calculation formula:<br>' +
                        'calc(var(--btn-base-font-size) * var(--#{$name}-factor)<br>' +
                        'Depended Variables:<br>' +
                        '1. --btn-base-font-size<br>' +
                        '2. btn-base-font-size, --element-font-size, --font-size-base',
                    defaultvalue: '1rem'
                },
                {
                    slno: '4',
                    name: '--btn-height',
                    description: 'Height of button. Calculation formula:<br>' +
                        '1. calc(var(--btn-base-height) * var(--#{$name}-factor)<br>' +
                        'Files: src\\scss\\components\\btns\\common.scss, src\\scss\\components\\btns\\_variations.scss, src\\scss\\variables\\core.js<br>' +
                        'Calculation Formula:<br>' +
                        'calc(var(--element-line-height) + ((var(--element-padding-y) + var(--element-border-width)) * 2))<br>' +
                        '2.05rem',
                    defaultvalue: 'Calculation Formula: ' +
                        'calc(var(--element-line-height) + ((var(--element-padding-y) + var(--element-border-width)) * 2))<br>' +
                        '2.05rem'
                }
            ]
        };
    }
};
</script>

<style scoped>
</style>
