<template>
    <div class="fl-x fl-a-e progress-step-item-4" :style="[setAnimationVariables]">
        <div class="fl-y fl-a-c">
            <!--line item-->
            <div class="pos-r" v-if="showLine">
                <custom-space-el width="1.5px" :height="lineHeight" bg-color="1"/>
                <custom-space-el width="1.5px" :height="lineHeight" bg-color="1" v-show="status ==='done'"
                                 class="pos-a progress-step-item-4-vertical-line-animation top-0"/>
            </div>
            <!--line item-->
            <custom-h-el width="2.5rem" height="2.5rem" class="b-1 round-2 fl-x-cc" v-if="status==='pending'">
                <icon-img :image="iconImg"/>
            </custom-h-el>
            <custom-h-el width="2.5rem" height="2.5rem" class="b-1 round-2 fl-x-cc" v-else-if="status==='done'">
                <icon-img :image="iconImg"/>
            </custom-h-el>
        </div>
        <div class="ml-2">
            <text-el tag="div" :text="text1" :fs="text1FontSize" :color="setFontColor" :font-weight="text1FontWeight" mt="n1" my="0"/>
            <text-el m="0" tag="div" :text="text2" :fs="text2FontSize" :color="setFontColor"/>
        </div>
    </div>
</template>
<script>
import CustomHEl from 'lego-framework/src/containers/CustomHeightContainer';
import CustomSpaceEl from 'lego-framework/src/containers/CustomSpacingElement';
import iconUser from '../assets/icon-user.svg';
import TextEl from 'lego-framework/src/components/typography/TextElement';
import IconImg from 'lego-framework/src/components/IconImage';

export default {
    name: 'ProgressStepsDetailsItem4',
    components: { CustomHEl, CustomSpaceEl, TextEl, IconImg },
    props: {
        text1: { type: String, default: 'Your Details' },
        text2: { type: String, default: 'Please provide your name and email' },
        status: { type: String, default: 'pending' },
        iconImg: { type: String, default: iconUser },
        lineHeight: { type: String, default: '1rem' },
        text1FontWeight: { type: String, default: '500' },
        text1FontSize: { type: String, default: '1' },
        text2FontSize: { type: String, default: '0' },
        color: { type: String, default: 'muted' },
        borderColor: { type: String, default: '1' },
        showLine: { type: Boolean, default: true }
    },
    computed: {
        setFontColor () {
            if (this.status === 'inactive') {
                return 'muted';
            }
            return '1';
        },
        setAnimationVariables () {
            return {
                '--progress-step-item-4-line-height': this.lineHeight
            };
        }
    }
};
</script>

<style scoped>

</style>
