<template>
    <svg :width="width" :height="width" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1139_75839)">
            <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#F9FAFB"/>
            <path d="M1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20Z" stroke="#EAECF0" stroke-width="2"/>
            <circle cx="20" cy="20" r="6" fill="#D0D5DD"/>
        </g>
        <defs>
            <clipPath id="clip0_1139_75839">
                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'IconStepPending',
    props: {
        width: { type: String, default: '18' },
        height: { type: String, default: '18' }
    }
};
</script>

<style scoped>

</style>
