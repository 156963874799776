<template>
    <div :class="{'bg-light-text border-light':bg==='light','bg-dark-text b-1':bg==='dark'}" class="p-2 mt-3 br-1">
        <div class="fl-x fl-a-c fl-j-e mb-2 cursor-pointer" v-if="!showCodeNoToggle" @click="toggleCodeVisibility">
            <div class="fl-x fl-a-c b-1 px-3 toggle-btn">
                <p class="font-weight-bold m-0">Code</p>
                <i v-if="!showCode" class="fa fa-chevron-down cursor-pointer"></i>
                <i v-if="showCode" class="fa fa-chevron-up cursor-pointer"></i>
            </div>

        </div>
        <transition name="fade" mode="ease-out-in">
            <code-snippet v-if="showCodeCondition">
                <template #code>
                    <code>
                        <pre class="text-left fs--1">{{ codeStr }}</pre>
                    </code>
                </template>
            </code-snippet>
        </transition>
        <!--        //USAGE-->
        <div class="fl-x fl-a-c fl-j-e mb-2 cursor-pointer" v-if="usage !=='' && showCode"
             @click="toggleUsageVisibility">
            <div class="fl-x fl-a-c b-1 px-3 toggle-btn">
                <p class="font-weight-bold m-0">Usage</p>
                <i v-if="!showUsage" class="fa fa-chevron-down cursor-pointer"></i>
                <i v-if="showUsage" class="fa fa-chevron-up cursor-pointer"></i>
            </div>

        </div>
        <transition v-if="usage !=='' && showCode" name="fade" mode="ease-out-in">
            <code-snippet v-if="showUsage">
                <template #code>
                    <code>
                        <pre class="text-left fs--1">{{ usage }}</pre>
                    </code>
                </template>
            </code-snippet>
        </transition>
    </div>
</template>

<script>
import CodeSnippet from './CodeSnippet';

export default {
    name: 'CodeContainer',
    components: { CodeSnippet },
    props: {
        codeStr: {
            type: String,
            required: true,
            default: "printf('abcd');"
        },
        usage: {
            type: String,
            required: false,
            default: ''
        },
        bg: {
            type: String,
            default: 'light'
        },
        showCodeNoToggle: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            showCode: false,
            showUsage: false
        };
    },
    methods: {
        toggleCodeVisibility () {
            this.showUsage = false;
            this.showCode = !this.showCode;
        },
        toggleUsageVisibility () {
            this.showUsage = !this.showUsage;
        },
        hideCodeOnClickOutside () {
            if (this.showCode) {
                this.showCode = false;
            }
        }
    },
    computed: {
        showCodeCondition () {
            return this.showCode || this.showCodeNoToggle;
        }
    }
};
</script>

<style scoped lang="scss">
    .bg-dark-text {
        color: var(--color-white)
    }

    .bg-light-text {
        color: var(--color-black);
    }

    .toggle-btn {
        transition: all ease-in-out 500ms;
        border-radius: var(--round-1);

        &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
        }
    }

    .fs--1 {
        font-size: 0.9rem;
    }
</style>
