<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <div>
                        <h3>Blockquote</h3>
                        <p class="lead">Blockquote component</p>
                        <h5>Usage</h5>
                        <code-container show-code-no-toggle code-str="import SocialIcons from 'lego-framework/src/web-components/blockquote/BlockQuote.vue'"></code-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <h6 class="my-3">Default</h6>
                    <block-quote/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>
<script>
import BlockQuote from '../web-components/blockquote/BlockQuote';
import codeStore from './code-storage/block-quote.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'BlockQuotesDocs',
    components: { CodeContainer, BlockQuote, PropTable },
    metaInfo: {
        path: 'web-components/blockquote/',
        title: 'Blockquote'
    },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'Quote content', '', ''],
                ['text2', 'String', 'Author name', '', ''],
                ['color', 'String', 'Author name', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], 'primary'],
                ['borderSize', 'String', 'border size', ['1', '2', '3', '4', '5'], '3'],
                ['text1FontSize', 'String', 'quote content font size', ['1', '2', '3', '4', '5', '6', '7'], '3'],
                ['text1Color', 'String', 'quote content font size', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text1FontWeight', 'String', 'Quote content font weight', ['100', '200', '300', '400', '500', '600', '700'], '600'],
                ['text1Classes', 'String, Array', 'quote content additional classes', '', ''],
                ['text2FontSize', 'String', 'quote content font size', ['1', '2', '3', '4', '5', '6', '7'], ''],
                ['text2Color', 'String', 'quote content font size', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text2FontWeight', 'String', 'Quote content font weight', ['100', '200', '300', '400', '500', '600', '700'], ''],
                ['text2Classes', 'String, Array', 'author additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
