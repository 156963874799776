import { render, staticRenderFns } from "./ListingCardItemsDocs.vue?vue&type=template&id=191e4863&scoped=true&"
import script from "./ListingCardItemsDocs.vue?vue&type=script&lang=js&"
export * from "./ListingCardItemsDocs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191e4863",
  null
  
)

export default component.exports