<template>
    <component :is="tag" :style="[heightValue,minHeightValue, maxHeightValue, widthValue]"
               :class="[{'custom-height-contain--height': heightClassSetCondition,
               'custom-height-contain--min-h': minHeight !=='',
               'custom-height-contain--max-h': maxHeight !=='',
               'custom-height-contain--width': width !==''}, bgColorClasses]">
        <slot></slot>
    </component>
</template>

<script>
import BgColorThicknessMixin from '../mixins/BgColorThicknessMixin';

export default {
    name: 'CustomHeightContainer',
    props: {
        tag: { type: String, default: 'div' },
        height: { type: String, default: '' },
        heightMd: { type: String, default: '' },
        heightLg: { type: String, default: '' },
        minHeight: { type: String, default: '' },
        minHeightMd: { type: String, default: '' },
        minHeightLg: { type: String, default: '' },
        maxHeight: { type: String, default: '' },
        maxHeightMd: { type: String, default: '' },
        maxHeightLg: { type: String, default: '' },
        width: { type: String, default: '' }
    },
    computed: {
        heightValue () {
            let heightVal = this.height;
            // if heightMd is not set height is set as heightMdVal for responsive issue fixes
            let heightMdVal = this.heightMd === '' ? this.height : this.heightMd;
            // if heightLg is not set heightMd is set as heightLgVal for responsive issue fixes,
            // medium screen height is set to large screens also
            let heightLgVal = this.heightLg === '' ? heightMdVal : this.heightLg;
            // For setting r short form for rem unit
            if (this.height.slice('-1') === 'r') {
                heightVal = this.height + 'em';
            }
            if (heightMdVal.slice('-1') === 'r') {
                heightMdVal = heightMdVal + 'em';
            }
            if (heightLgVal.slice('-1') === 'r') {
                heightLgVal = heightLgVal + 'em';
            }
            return {
                '--custom-height-container-height': heightVal,
                '--custom-height-container-height-md': heightMdVal,
                '--custom-height-container-height-lg': heightLgVal
            };
        },
        minHeightValue () {
            let minHeightVal = this.minHeight;
            let minHeightMdVal = this.minHeightMd;
            let minHeightLgVal = this.minHeightLg;
            if (this.minHeight.slice('-1') === 'r') {
                minHeightVal = this.minHeight + 'em';
            }
            if (this.minHeightMd.slice('-1') === 'r') {
                minHeightMdVal = this.minHeightMd + 'em';
            }
            if (this.minHeightLg.slice('-1') === 'r') {
                minHeightLgVal = this.minHeightLg + 'em';
            }
            return {
                '--custom-height-container-min-height': minHeightVal,
                '--custom-height-container-min-height-md': minHeightMdVal,
                '--custom-height-container-min-height-lg': minHeightLgVal
            };
        },
        maxHeightValue () {
            let maxHeightVal = this.maxHeight;
            if (this.maxHeight.slice('-1') === 'r') {
                maxHeightVal = this.maxHeight + 'em';
            }
            return {
                '--custom-height-container-max-height': maxHeightVal
            };
        },
        widthValue () {
            let widthVal = this.width;
            if (this.width.slice('-1') === 'r') {
                widthVal = this.width + 'em';
            }
            return {
                '--custom-height-container-width': widthVal
            };
        },
        heightClassSetCondition () {
            return this.height !== '' || this.heightMd !== '' || this.heightLg !== '';
        }
    },
    mixins: [BgColorThicknessMixin]
};
</script>

<style scoped>

</style>
