<template>
    <div :class="{'fl-x':align === 'horizontal','fl-y':align==='vertical'}">
        <series-item :text="series1" :color="series1Color" :color-thickness="series1ColorThickness"/>
        <series-item :class="{'ml-4': align === 'horizontal','mt-2': align ==='vertical'}" :text="series2" :color="series2Color" :color-thickness="series2ColorThickness"/>
        <series-item :class="{'ml-4': align === 'horizontal','mt-2': align ==='vertical'}" :text="series3" :color="series3Color" :color-thickness="series3ColorThickness"/>
    </div>
</template>

<script>
import SeriesItem from './SeriesItem';

export default {
    name: 'SeriesItems',
    components: { SeriesItem },
    props: {
        series1: { type: String, default: 'series 1' },
        series2: { type: String, default: 'series 2' },
        series3: { type: String, default: 'series 3' },
        align: { type: String, default: 'horizontal' },
        color: { type: String, default: 'secondary' },
        series1Color: { type: String, default: 'secondary' },
        series2Color: { type: String, default: 'secondary' },
        series3Color: { type: String, default: 'secondary' },
        series1ColorThickness: { type: String, default: '300' },
        series2ColorThickness: { type: String, default: '400' },
        series3ColorThickness: { type: String, default: '' }
    }
};
</script>

<style scoped>

</style>
