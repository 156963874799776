<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Modals</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--modal-backdrop-color',
                    description: 'Background Color of modal backdrop',
                    defaultvalue: 'rgba(0, 0, 0, .4)'
                },
                {
                    slno: '2',
                    name: '--modal-border-radius',
                    description: 'Modal border radius',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '3',
                    name: '--modal-padding',
                    description: 'Modal Padding',
                    defaultvalue: '1rem'
                },
                {
                    slno: '4',
                    name: 'modal-width',
                    description: 'Modal width',
                    defaultvalue: '60rem'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
