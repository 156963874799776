<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    :hideImage="true"
                    Content="Your Content"
                    titleColor="#595A5C"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey">
                    <template #headSection>
                        <custom-h-el height="2px" bg-color="grey" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
            <div class="col-lg-3">

                <VariationCustomization
                    Title="Your details"
                    :hideImage="true"
                    Content="Your Content"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                    <template #headSection>
                        <custom-h-el height="2px" bg-color="primary" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
            <div class="col-lg-3">

                <VariationCustomization
                    Title="Your details"
                    Content="Your Content"
                    :hideImage="true"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                    <template #headSection>
                        <custom-h-el height="2px" bg-color="primary" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    :hideImage="true"
                    Content="Your Content"
                    titleColor="#595A5C"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey">
                    <template #headSection>
                        <custom-h-el height="3px" bg-color="grey" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
            <div class="col-lg-3">

                <VariationCustomization
                    Title="Your details"
                    :hideImage="true"
                    Content="Your Content"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                    <template #headSection>
                        <custom-h-el height="3px" bg-color="primary" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
            <div class="col-lg-3">

                <VariationCustomization
                    Title="Your details"
                    Content="Your Content"
                    :hideImage="true"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                    <template #headSection>
                        <custom-h-el height="3px" bg-color="primary" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    :hideImage="true"
                    Content="Your Content"
                    titleColor="#595A5C"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey">
                    <template #headSection>
                        <custom-h-el height="2px" bg-color="grey" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
            <div class="col-lg-3">

                <VariationCustomization
                    Title="Your details"
                    :hideImage="true"
                    Content="Your Content"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                    <template #headSection>
                        <custom-h-el height="2px" bg-color="primary" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
            <div class="col-lg-3">

                <VariationCustomization
                    Title="Your details"
                    Content="Your Content"
                    :hideImage="true"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                    <template #headSection>
                        <custom-h-el height="2px" bg-color="primary" width="14r"/>
                    </template>
                </VariationCustomization>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-lg-3">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Your content"
                    :hideBorder="true"
                    :headerImage="image2"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey" class="icon-size-10"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>
            <div class="col-lg-3">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Your Content"
                    :hideBorder="true"
                    :headerImage="image3"
                    titleBgColor="text-primary"
                    contentBgColor="text-secondary"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>
            <div class="col-lg-3">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Your Content"
                    :hideBorder="true"
                    :headerImage="image3"
                    titleBgColor="text-primary"
                    contentBgColor="text-secondary"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Your Content"
                    :hideBorder="true"
                    :headerImage="image2"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>
            <div class="col-lg-3">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Your Content"
                    :hideBorder="true"
                    :headerImage="image3"
                    titleBgColor="text-primary"
                    contentBgColor="text-secondary"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>
            <div class="col-lg-3">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Your Content"
                    :hideBorder="true"
                    :headerImage="image3"
                    titleBgColor="text-primary"
                    contentBgColor="text-secondary"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    Content="Please provide you name and email"
                    :hideImage="false"
                    :headerImage="image2"
                    titleAlignment="center"
                    contentAlignment="center"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey">
                </VariationCustomization>
            </div>
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    Content="Please provide you name and email"
                    :hideImage="false"
                    :headerImage="image3"
                    titleAlignment="center"
                    contentAlignment="center"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                </VariationCustomization>
            </div>
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    Content="Please provide you name and email"
                    :hideImage="false"
                    :headerImage="image3"
                    titleAlignment="center"
                    contentAlignment="center"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                </VariationCustomization>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    Content="Please provide you name and email"
                    :hideImage="false"
                    :headerImage="image2"
                    titleAlignment="center"
                    contentAlignment="center"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey">
                </VariationCustomization>
            </div>
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    Content="Please provide you name and email"
                    :hideImage="false"
                    :headerImage="image3"
                    titleAlignment="center"
                    contentAlignment="center"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                </VariationCustomization>
            </div>
            <div class="col-lg-3">
                <VariationCustomization
                    Title="Your details"
                    Content="Please provide you name and email"
                    :hideImage="false"
                    :headerImage="image3"
                    titleAlignment="center"
                    contentAlignment="center"
                    titleBgColor="text-primary"
                    contentBgColor="text-primary">
                </VariationCustomization>
            </div>
        </div>
        <div class="row mt-6">
            <div class="col-lg-4">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Please provide your name and email"
                    :headerImage="image2"
                    titleBgColor="text-grey"
                    contentBgColor="text-grey"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>

        </div>
        <div class="row mt-3">
            <div class="col-lg-4">
                <progress-step-text-alignment
                    Title="Your details"
                    Content="Please provide your name and email"
                    :headerImage="image3"
                    titleBgColor="text-primary"
                    contentBgColor="text-secondary"
                    borderColor="#9B26B6">
                </progress-step-text-alignment>
            </div>

        </div>
        <div class="row mt-6">
            <details-horizontal
                :header-image="image"
                :header-image1="image3"
                :header-image2="image3"
                :header-image3="image2"
                heading1="Your details"
                heading2="Company details"
                heading3="Invite your team"
                heading4="Add your social"
                title1="name and email"
                title2="title2"
                title3="title3"
                title4="title4"
                header1-bg-color="text-primary"
                header2-bg-color="text-primary"
                header3-bg-color="text-grey"
                header4-bg-color="text-grey"
                title2bgcolor="text-primary"
                title3-bg-color="text-primary">
                <template #veticalline1>
                    <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n6" width="15r"/>
                </template>
                <template #veticalline2>
                    <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n7" width="15r"/>
                </template>
                <template #veticalline3>
                    <custom-h-el height="1px" bg-color="grey" class="mt-4 ml-n7" width="15r"/>
                </template>
            </details-horizontal>
        </div><div class="row mt-6">
            <details-horizontal
                :header-image="image"
                :header-image1="image3"
                :header-image2="image3"
                :header-image3="image2"
                heading1="Your details"
                heading2="Company details"
                heading3="Invite your team"
                heading4="Add your social"
                title1="name and email"
                title2="title2"
                title3="title3"
                title4="title4"
                header1-bg-color="text-primary"
                header2-bg-color="text-primary"
                header3-bg-color="text-grey"
                header4-bg-color="text-grey"
                title2bgcolor="text-primary"
                title3-bg-color="text-primary">
                <template #veticalline1>
                    <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n6" width="15r"/>
                </template>
                <template #veticalline2>
                    <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n9" width="20r"/>
                </template>
                <template #veticalline3>
                    <custom-h-el height="1px" bg-color="grey" class="mt-4 ml-n9" width="20r"/>
                </template>
            </details-horizontal>
        </div>
<!--        <div class="row mt-6">-->
<!--            <details-overlap-->
<!--                :header-image="image"-->
<!--                :header-image1="image3"-->
<!--                :header-image2="image3"-->
<!--                :header-image3="image2"-->
<!--                heading1="Your details"-->
<!--                heading2="Company details"-->
<!--                heading3="Invite your team"-->
<!--                heading4="Add your social"-->
<!--                title1="name and email"-->
<!--                title2="title2"-->
<!--                title3="title3"-->
<!--                title4="title4"-->
<!--                header1-bg-color="text-primary"-->
<!--                header2-bg-color="text-primary"-->
<!--                header3-bg-color="text-primary"-->
<!--                header4-bg-color="text-grey"-->
<!--                title2bgcolor="text-primary"-->
<!--                title3-bg-color="text-primary">-->
<!--                <template #vertical-line1>-->
<!--                    <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n7" width="15r"/>-->
<!--                </template>-->
<!--                <template #vertical-line2>-->
<!--                    <custom-h-el height="1px" bg-color="primary" class="mt-4 ml-n10" width="15r"/>-->
<!--                </template>-->
<!--                <template #vertical-line4>-->
<!--                    <custom-h-el height="1px" bg-color="grey" class="mt-4 ml-n10" width="15r"/>-->
<!--                </template>-->
<!--            </details-overlap>-->
<!--        </div>-->
    </div>
</template>

<script>
import VariationCustomization from '../../components/Progress-steps/Variation-customization';
import image2 from '../../assets/web/icons/icon-grey-circle.png';
import image from '../../assets/web/icons/icons8-violet-tick.png';
import image3 from '../../assets/web/icons/ic-vlt.png';
import image4 from '../../assets/web/icons/icons8-user-64.png';
import ProgressStepTextAlignment from '../../components/Progress-steps/Progress-steps-text-alignment';
import DetailsHorizontal from '../../components/Progress-steps/Details-horizontal';
// import DetailsOverlap from '@components/Progress-steps/Details-overlap';

export default {
    name: 'ProgressSteps',
    components: { DetailsHorizontal, ProgressStepTextAlignment, VariationCustomization },
    data () {
        return {
            image: image,
            image2: image2,
            image3: image3,
            image4: image4,
            imageAlignment: 'center'

        };
    }

};
</script>

<style scoped>

</style>
