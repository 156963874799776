<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Crud Items</h3>
                    <p>Simple Add Item component</p>
                    <!--                <h4 class="mt-4 mb-3">Usage</h4>-->
                </div>
                <div class="col-12">
                    <h5 class="mt-2">Base Item</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.baseItemUsage.code"/>
                </div>
                <div class="col-lg-3 mt-3">
                    <add-item-base container-classes="bs-5"></add-item-base>
                </div>
                <div class="col-12 pb-5">
                    <code-container show-code-no-toggle :code-str="codeStore.baseItem.code"/>
                </div>
            </div>
            <div class="row  mt-3">
                <div class="col-12">
                    <h5 class="mt-2">Default item (customized)</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                    <p class="mt-2">You can customize the component like this below</p>
                    <code-container show-code-no-toggle :code-str="codeStore.customized.code"/>
                </div>
                <div class="col-lg-4 mt-3">
                    <add-item container-classes="bs-5"></add-item>
                </div>
            </div>
            <div class="col-lg-6 pb-5">
                <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
            </div>
            <div class="col-12 mt-4">
                <h4 class="mt-3">Props</h4>
                <prop-table :items="propsInfo"/>
            </div>
        </div>
    </page-template>
</template>

<script>
import AddItem from 'lego-framework/src/dashboard-components/crud-items/add-item/add-item-default';
import AddItemBase from 'lego-framework/src/dashboard-components/crud-items/add-item/AddItem';
import codeStore from '../code-storage/crud-item.json';
// import CodeContainer from '../../components/doc-items/CodeContainer';
import propValuesVariants from 'lego-framework/src/views/docs/propValuesVariants';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
export default {
    name: 'CrudItemsPage',
    components: { AddItem, AddItemBase, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['text', 'String', 'Text shown under the icon', '', 'Add Item'],
                ['iconImg', 'String', 'Icon image', '', ''],
                ['containerClasses', 'String, Array', 'Additional classes to be added to the root element', '', ''],
                ['shadow', 'String', 'Box shadow', propValuesVariants.shadows, ''],
                ['bgColor', 'String', 'Bg color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'warning', '1', '2', '3', '4'], ''],
                ['borderRadius', 'String', 'Border Radius', propValuesVariants.borderRadius, ''],
                ['h100p', 'Boolean', 'set height 100%', ['true', 'false'], 'true'],
                ['cursor', 'Boolean', 'cursor pointer', ['true', 'false'], 'true'],
                ['margin and padding props', 'Stromg', 'py,px,my,mx,p,pt,pb...etc', propValuesVariants.spacer, '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
