<template>
    <div class="pb-5 p-4 bg-2">
        <h2 class="mt-5">Grid Column</h2>
        <p class="lead">Grid Column component</p>
        <h5>Usage</h5>
        <p>Import Component on plugins/lego.js</p>
        <p>import Col12 from 'lego-framework/src/containers/grid/col-12';</p>
        <p>Vue.component('col-12', Col12);</p>
        <p>&#60;col-12&#62;
            &#60;/col-12&#62;
        </p>
        <h4>Props</h4>
        <prop-table :items="props"/>
        <row class="mt-4">
            <col-12>
                <lego-heading tag="h4" align="left" class="mb-4">Usage Examples</lego-heading>
            </col-12>
            <col-12>
                <div class="bg-2 b-1 round-1 p-3">
                    <row>
                        <col-12 lg="4" md="6">
                            <div class="p-1 b-1">col-lg-4</div>
                        </col-12>
                        <col-12 lg="4" md="6">
                            <div class="p-1 b-1">col-lg-4</div>
                        </col-12>
                        <col-12 lg="4" md="6">
                            <div class="p-1 b-1">col-lg-4</div>
                        </col-12>
                    </row>
                </div>
                <code-container class="bg-2 round-1" :code-str="sampleCode"></code-container>
            </col-12>
            <col-12>
                <h3>SCSS Variables</h3>
                <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
            </col-12>
        </row>
    </div>
</template>

<script>
// import DemoMixin from '../../views/DemoMixin';
import PropTable from '../../views/docs/docs-components/Props';
import LegoHeading from '../../components/Heading';
import CodeContainer from '../../components/doc-items/CodeContainer';
import Col12 from '../../containers/grid/col-12';
import Row from '../../containers/Row';

export default {
    // extends: DemoMixin,
    metaInfo: {
        path: 'containers/col-12/',
        title: 'Grid Column'
    },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--container-max-width-sm',
                    description: 'container max width sm is set',
                    defaultvalue: '540px'
                },
                {
                    slno: '2',
                    name: '--container-max-width-md',
                    description: 'container max width md is set',
                    defaultvalue: '720px'
                },
                {
                    slno: '3',
                    name: '--container-max-width-lg',
                    description: 'container max width lg is set',
                    defaultvalue: '960px'
                },
                {
                    slno: '4',
                    name: '--container-max-width-xl',
                    description: 'container max width xl is set',
                    defaultvalue: '1140px'
                },
                {
                    slno: '5',
                    name: '--grid-gutter-width',
                    description: 'grid gutter width value',
                    defaultvalue: '30px'
                },
                {
                    slno: '6',
                    name: 'grid breakponts - sm ',
                    description: 'grid breakpoint small screens',
                    defaultvalue: '576px'
                },
                {
                    slno: '7',
                    name: 'grid breakponts - md',
                    description: 'grid breakpoint medium screens',
                    defaultvalue: '768px'
                },
                {
                    slno: '8',
                    name: 'grid breakponts - lg',
                    description: 'grid breakpoint large screens',
                    defaultvalue: '992px'
                },
                {
                    slno: '9',
                    name: 'grid breakponts - xl',
                    description: 'grid breakpoint extra large screens',
                    defaultvalue: '1200px'
                },
                {
                    slno: '10',
                    name: 'grid-columns',
                    description: 'grid columns numbers',
                    defaultvalue: '12'
                },
                {
                    slno: '11',
                    name: 'grid-row-columns',
                    description: 'grid-row-columns',
                    defaultvalue: '6'
                }
            ],
            props: [
                ['tag', 'String', 'html tag name', ['any html tag, eg:div,section,main, footer,aside,...'], 'div'],
                ['xs', 'String', 'column grid width on xs screens', ['1-12 - according to no of total columns in grid'], ''],
                ['sm', 'String', 'column grid width on sm screens', ['1-12 - according to no of total columns in grid'], ''],
                ['md', 'String', 'column grid width on md screens', ['1-12 - according to no of total columns in grid'], ''],
                ['lg', 'String', 'column grid width on lg screens', ['1-12 - according to no of total columns in grid'], ''],
                ['xl', 'String', 'column grid width on xl screens', ['1-12 - according to no of total columns in grid'], '']

            ],
            sampleCode: '<row>\n' +
                    '  <col-12 lg="4" md="6"><div class="p-1 b-1">col-lg-4 col-md-6</div></col-12>\n' +
                    '  <col-12 lg="4" md="6"><div class="p-1 b-1">col-lg-4 col-md-6</div></col-12>\n' +
                    '  <col-12 lg="4" md="6"><div class="p-1 b-1">col-lg-4 col-md-6</div></col-12>\n' +
                    '</row>'
        };
    },
    components: { PropTable, LegoHeading, CodeContainer, Col12, Row }
};
</script>
