<template>
    <page-template class="p-5">
        <h2>Button</h2>
        <p class="lead">A normal button control with text and icon</p>
        <div class="mb-5">
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import Button from 'lego-framework/src/components/Button';</p>
            <p>Vue.component('btn', Button);</p>
            <p>&#60;btn&#62;&#60;/btn&#62;</p>
        </div>
        <h3 class="my-3">Color Variations</h3>
        <component-wrapper :code="codeStore.colorVariations.code">
            <div class="btn-group fl-wrap">
                <Button :text="color" design="basic" :color="color" v-for="color in theme['button-colors']" :key="color"/>
            </div>
        </component-wrapper>

        <h4 class="mt-4">With Icons</h4>
        <component-wrapper :code="codeStore.withIcons.code">
            <div class="btn-group fl-x-b fl-wrap">
                <Button :text="color" icon="fa fa-user" design="basic" :color="color"
                        v-for="color in theme['button-colors']" :key="color"/>
            </div>
        </component-wrapper>

        <h4 class="mt-4">Icon Alignment Right</h4>
        <component-wrapper :code="codeStore.iconAlignRight.code">

            <div class="btn-group fl-x-b fl-wrap">
                <Button :text="color" icon-alignment="right" icon="fa fa-user" design="basic" :color="color"
                        v-for="color in theme['button-colors']" :key="color"/>
            </div>
        </component-wrapper>

        <div>
            <h3 class="mt-4">Size Variations</h3>
            <component-wrapper :code="codeStore.sizeVariations.code">
                <div class="btn-group fl-x-b fl-wrap">
                    <Button :text="size" design="basic" color="primary" :size="size" v-for="size in sizes" :key="size"/>
                </div>
            </component-wrapper>
            <h4 class="mt-4">With Icons</h4>
            <component-wrapper :code="codeStore.sizeVariationsWithIcons.code">
                <div class="btn-group fl-x-b fl-wrap">
                    <Button :text="size" icon="fa fa-send" design="basic" color="primary" :size="size" v-for="size in sizes"
                            :key="size"/>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h3 class="mt-4">Design Variations</h3>
            <component-wrapper :code="codeStore.designVariations.code">
                <div class="fl-x-t fl-j-s fl-wrap">
                    <Button :text="design" :design="design" class="m-2" color="primary" v-for="design in designs"
                            :key="design"/>
                </div>
            </component-wrapper>

            <h4 class="mt-4">Disabled</h4>
            <component-wrapper :code="codeStore.disabled.code">
                <div class="fl-x-t fl-j-s fl-wrap">
                    <Button :text="design" class="m-2" disabled :design="design" color="primary" v-for="design in designs"
                            :key="design"/>
                </div>
            </component-wrapper>

            <h4 class="mt-4">With Icons</h4>
            <component-wrapper :code="codeStore.designVariationsWithIcons.code">
                <div class="fl-x-t fl-j-s fl-wrap">
                    <Button icon="fa fa-send" class="m-2" :text="design" :design="design" color="primary"
                            v-for="design in designs" :key="design"/>
                </div>
            </component-wrapper>

            <h4 class="mt-4">With Icons on the right</h4>
            <component-wrapper :code="codeStore.designVariationsWithIconOnRight.code">
                <div class="fl-x-t fl-j-s fl-wrap">
                    <Button icon="fa fa-send" class="m-2" icon-alignment="right" :text="design" :design="design"
                            color="primary" v-for="design in designs" :key="design"/>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h3 class="mt-4">Block Variations</h3>
            <component-wrapper :code="codeStore.blockVariations.code">
                <div class="row">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <Button block :text="size" design="basic" color="primary" :size="size"/>
                    </div>
                </div>
            </component-wrapper>

            <h3 class="mt-4">Block Variations With Icon</h3>
            <component-wrapper :code="codeStore.blockVariationsWithIcons.code">
                <div class="row">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <Button block :text="size" icon="fa fa-user" design="basic" color="primary" :size="size"/>
                    </div>
                </div>
            </component-wrapper>

            <h3 class="mt-4">Block Variations With Icon - Pill</h3>
            <component-wrapper :code="codeStore.blockVariationsWithIconsPill.code">
                <div class="row">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <Button block :text="size" icon="fa fa-user" border-radius="pill" design="basic" color="primary"
                                :size="size"/>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h3 class="my-3">Usage</h3>
            <code>
                &#60;btn&#62;&#60;/btn&#62;
            </code>
        </div>

        <div>
            <h2 class="my-3">Props</h2>
            <prop-table :items="props"/>
        </div>

        <div>
            <h2>Slots</h2>
            <SlotTable :items="slots"/>
        </div>

        <div>
            <h2>Events</h2>
            <p class="lead">All the standard events are supported in button</p>
        </div>

        <div>
            <h2>Variations</h2>
            <variation-table :items="variations"/>
        </div>
        <div class="mt-4">
            <h3>SCSS Variables</h3>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>
        <div class="mt-4">
            <h3>Color Variables - SCSS Variables</h3>
            <simple-table :fields="fields1" :data="data1" class="b-1"></simple-table>
        </div>
    </page-template>
</template>

<script>
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import helpersMixin from '../helpersMixin';
import theme from 'lego-framework/src/assets/theme.js';
import Button from 'lego-framework/src/components/Button';
import SlotTable from 'lego-framework/src/views/docs/docs-components/Slots';
import VariationTable from 'lego-framework/src/views/docs/docs-components/Variations';
import codeStore from './code-storage/Buttons.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'components/buttons/',
        title: 'Buttons'
    },
    name: 'ButtonDocumentation',
    components: { ComponentWrapper, VariationTable, SlotTable, Button, PropTable },
    data () {
        const designs = [
            'basic',
            'basic-a',
            'basic-b',
            'basic-c',
            'basic-d',
            'basic-e',

            'animated-a',
            'animated-b',
            'animated-c',
            'animated-d',
            'animated-e'
        ];
        return {
            fields1: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data1: [
                {
                    slno: '1',
                    name: '--btn-main-color',
                    description: 'main color of the button',
                    defaultvalue: 'primary format :' +
                        '<br>1. var(--color-#{$name})'
                },
                {
                    slno: '2',
                    name: '--btn-text-color',
                    description: 'button text color ',
                    defaultvalue: 'contrast color of the main color ' +
                        '<br>1. var(--color-#{$name}-contrast'
                },
                {
                    slno: '3',
                    name: '--btn-main-color-darken',
                    description: 'button color darken. for active state of the button',
                    defaultvalue: 'var(--color-primary-active)           Format : ' +
                        '<br>1. var(--color-#{$name}-active)'
                },
                {
                    slno: '4',
                    name: '--btn-main-color-lighten',
                    description: 'button color lighten. for setting hover color of the button',
                    defaultvalue: 'var(--color-primary-hover)           Format : ' +
                        '<br>1. var(--color-#{$name}-hover)'
                }
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-10r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--btn-padding-y',
                    description: 'Button vertical padding, calculated differently based on button size using the following formula:<br>' +
                        '1. calc(var(--btn-base-padding-y) * var(--#{$name}-factor)<br>' +
                        'Depended on Variables:<br>' +
                        '1. --btn-base-padding-y<br>' +
                        '2. element-padding-y (in scss/variables/core.js)<br>' +
                        'Files: src\\scss\\components\\btns\\common.scss, src\\scss\\components\\btns\\_variations.scss, src\\scss\\variables\\core.js',
                    defaultvalue: '0.325em'
                },
                {
                    slno: '2',
                    name: '--btn-padding-x',
                    description: 'Button horizontal padding, calculated differently based on button size using the following formula:<br>' +
                        '1. calc(var(--btn-base-padding-x) * var(--#{$name}-factor)<br>' +
                        'Depended on Variables:<br>' +
                        '1. --btn-base-padding-x<br>' +
                        '2. element-padding-x (in scss/variables/core.js)',
                    defaultvalue: '0.75em'
                },
                {
                    slno: '3',
                    name: '--btn-font-size',
                    description: 'Font size inside button. Calculation formula:<br>' +
                        'calc(var(--btn-base-font-size) * var(--#{$name}-factor)<br>' +
                        'Depended Variables:<br>' +
                        '1. --btn-base-font-size<br>' +
                        '2. btn-base-font-size, --element-font-size, --font-size-base',
                    defaultvalue: '1rem'
                },
                {
                    slno: '4',
                    name: '--btn-height',
                    description: 'Height of button. Calculation formula:<br>' +
                        '1. calc(var(--btn-base-height) * var(--#{$name}-factor)<br>' +
                        'Files: src\\scss\\components\\btns\\common.scss, src\\scss\\components\\btns\\_variations.scss, src\\scss\\variables\\core.js<br>' +
                        'Calculation Formula:<br>' +
                        'calc(var(--element-line-height) + ((var(--element-padding-y) + var(--element-border-width)) * 2))<br>' +
                        '2.05rem',
                    defaultvalue: 'Calculation Formula: ' +
                        'calc(var(--element-line-height) + ((var(--element-padding-y) + var(--element-border-width)) * 2))<br>' +
                        '2.05rem'
                }
            ],
            designs: designs,
            slots: [
                ['default', 'The default slot contains all the text and icon. If this slot is used then icon and text props wont work']
            ],
            variations: [
                [
                    'Delete Button',
                    '<delete-button>',
                    'Indented to use inside tables.',
                    [
                        ['text', 'Delete'],
                        ['color', 'Danger'],
                        ['icon', 'fa fa-trash'],
                        ['design', 'basic-b'],
                        ['size', 'xs']
                    ]
                ],
                [
                    'Edit Button',
                    '<edit-button>',
                    'Indented to use inside tables.',
                    [
                        ['text', 'Edit'],
                        ['icon', 'fa fa-pencil'],
                        ['design', 'basic'],
                        ['size', 'xs']
                    ]
                ],
                [
                    'Save Button',
                    '<save-button>',
                    'Indented to use in forms.',
                    [
                        ['text', 'Save'],
                        ['icon', 'fa fa-save'],
                        ['design', 'basic'],
                        ['type', 'submit']
                    ]
                ]
            ],
            props: [
                ['text', 'String', 'The text that has to be show in the button', ''],
                ['type', 'String', 'The type of the button.', ''],
                ['design', 'String', 'The design of the button.', designs],
                ['size', 'String', 'The type of the button.', ['xs', 'sm', 'md (default)', 'lg']],
                ['disabled', 'Boolean', 'Is the button disabled or not'],
                ['block', 'Boolean', 'block button or not'],
                ['color', 'String', 'The color of the buttons. This can be configures in theme.json', theme['button-colors']],
                ['icon', 'String', 'The icon class. It can be from font awesome or some other font based library.'],
                ['iconAlignment', 'String', 'Placement of the icon', ['left (default)', 'right']],
                ['borderRadius', 'String', 'Border radius from the list of border radius. Default is 1.', ''],
                ['shadow', 'String', 'The shadow number for the button. Default is 1', ''],
                ['hoverShadow', 'String', 'The shadow on hover number for the button. Default is 2', '']
            ],
            codeStore: codeStore,
            sizes: ['lg', 'md', 'sm', 'xs']

        };
    }
};
</script>

<style scoped>

</style>
