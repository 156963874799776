<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <div>
                        <h3>Social Icons</h3>
                        <p class="lead">Social icons component</p>
                        <h5>Usage</h5>
                        <code-container show-code-no-toggle code-str="import SocialIcons from 'lego-framework/src/web-components/SocialIcons.vue'"></code-container>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <h6 class="my-3">Default</h6>
                    <social-icons :items="socialItems"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basic.code"></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Size: lg</h6>
                    <social-icons :items="socialItems" size="lg"/>
                    <code-container show-code-no-toggle :code-str="codeStore.sizeLg.code"></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">hoverEffect: basic-2</h6>
                    <social-icons :items="socialItems" size="lg" hover-effect="basic-2"/>
                    <code-container show-code-no-toggle :code-str="codeStore.hoverEffectBasic2.code"></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">hoverEffect: basic-3</h6>
                    <social-icons :items="socialItems" size="lg" hover-effect="basic-3"/>
                    <code-container show-code-no-toggle :code-str="codeStore.hoverEffectBasic3.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>
<script>
import SocialIcons from '../web-components/SocialIcons';
import fbIcon from '../../assets/images/icons/social-icons/fb-dark.png';
import instaIcon from '../../assets/images/icons/social-icons/insta-dark.png';
import linkedInIcon from '../../assets/images/icons/social-icons/linkedin-dark.png';
import codeStore from './code-storage/social-icons.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'SocialIconsDocs',
    components: { CodeContainer, SocialIcons, PropTable },
    metaInfo: {
        path: 'web-components/social-icons/',
        title: 'Social Icons'
    },
    data () {
        return {
            socialItems: [
                { icon: fbIcon, link: 'https://www.facebook.com/' },
                { icon: instaIcon, link: 'https://www.instagram.com/' },
                { icon: linkedInIcon, link: 'https://www.instagram.com/' }
            ],
            codeStore: codeStore,
            propsInfo: [
                ['items', 'String', 'Items array', ['socialItems : [\n' +
                    '                { icon : fbIcon, link : \'https://www.facebook.com/\' },\n' +
                    '                { icon : instaIcon, link : \'https://www.instagram.com/\' },\n' +
                    '                { icon : linkedInIcon, link : \'https://www.instagram.com/\' }\n' +
                    '            ]'], ''],
                ['spacing', 'String', 'space between icons', ['1', '2', '3', '4', '5'], '1'],
                ['size', 'String', 'Icon size', ['xs', 'sm', 'md', 'lg', 'xl'], 'sm'],
                ['hoverEffect', 'String', 'Icon hover effect', ['xs', 'sm', 'md', 'lg', 'xl'], 'sm']
            ]
        };
    }
};
</script>

<style scoped>

</style>
