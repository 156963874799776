<template>
    <div class="pb-5 p-4 bg-2">
        <h2 class="mt-5">Card</h2>
        <p class="lead">Card with border , border radius, shadow and other attribues</p>
        <h5>Usage</h5>
        <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
        <h5 class="my-3">Default Use</h5>
        <card>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio dolorem,
                porro. Aspernatur culpa dolore doloremque illum neque numquam repellat? Animi
                aspernatur cum deleniti enim, impedit nisi obcaecati quod temporibus voluptates.
                Ad animi assumenda beatae blanditiis, consectetur cum ducimus eveniet ipsa iste
                laudantium modi non pariatur perferendis sit tempora, ullam, veniam.
            </p>
        </card>
        <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
        <h5 class="my-3">With Margin, Padding Bg Color and Bg color thickness</h5>
        <card py="6" mt="4" bg-color="secondary" bg-color-thickness="100">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio dolorem,
                porro. Aspernatur culpa dolore doloremque illum neque numquam repellat? Animi
                aspernatur cum deleniti enim, impedit nisi obcaecati quod temporibus voluptates.
                Ad animi assumenda beatae blanditiis, consectetur cum ducimus eveniet ipsa iste
                laudantium modi non pariatur perferendis sit tempora, ullam, veniam.
            </p>
        </card>
        <code-container show-code-no-toggle :code-str="codeStore.withSpacingAndBg.code"/>
        <h4>Props</h4>
        <prop-table :items="props"/>
        <col-12>
        <h3>SCSS Variables</h3>
         <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </col-12>
    </div>
</template>

<script>
// import DemoMixin from '../../views/DemoMixin';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';
import card from 'lego-framework/src/containers/layout/Card';
import codeStore from '../../views/containers/codes/card.json';

export default {
    // extends: DemoMixin,
    metaInfo: {
        path: 'container/card/',
        title: 'Card'
    },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-45r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--card-padding',
                    description: 'Card padding. Depending on variable.<br>1. --spacer-3',
                    defaultvalue: '1rem'
                },
                {
                    slno: '2',
                    name: '--container-border-radius',
                    description: 'Card border radius. Depending on variables.<br>1. --container-border-radius<br>2. --round-2',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '3',
                    name: '--container-separator',
                    description: 'Container separator (top margin on container). Depending on variable.<br>1. --spacer-5',
                    defaultvalue: '3rem'
                }
            ],
            codeStore: codeStore,
            props: [
                ['shadow', 'String', 'shadow variant', ['1- 29'], ''],
                ['borderRadius', 'String', 'border radius', ['0 - 10'], ''],
                ['bgColor', 'String', 'bg color of the card', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['bgColorThickness', 'String', 'bg color thickness value', ['100', '200', '300', '400', '600', '700', '800', '900'], ''],
                ['border', 'String', 'border variant', '1 - 10', ''],
                ['borderColor', 'String', 'bg Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['width', 'String', 'in rem short - 10r for 10rem', ['5r', '10r', '15r', '20r', '25r'], ''],
                ['of', 'String', 'set Overflow classes', ['a', 'h', 's', 'v'], ''],
                ['m', 'String', 'Margin value', ['margin spacer values usually between 1 - 9'], ''],
                ['mt', 'String', 'Margin top value', ['margin top spacer values usually between 1 - 9'], ''],
                ['ml', 'String', 'Margin left value', ['margin left spacer values usually between 1 - 9'], ''],
                ['p', 'String', 'Padding value', ['padding spacer values usually between 1 - 9'], ''],
                ['pt', 'String', 'Padding top value', ['padding top spacer values usually between 1 - 9'], ''],
                ['pl', 'String', 'Padding left value', ['padding left spacer values usually between 1 - 9'], ''],
                ['All spacing Items', 'String', 'mr, pr, py,my , mx ...', ['spacer values usually between 1 - 9'], ''],
                ['containerClasses', 'String, Array', 'container additional classes', '', '']
            ]
        };
    },
    components: { PropTable, card, CodeContainer }
};
</script>
