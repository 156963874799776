<template>
    <page-template>
     <div class="bg-2 p-5">
    <div>
        <h3>Custom Spacing Elements</h3>
    </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'BorderRadius',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--custom-spacing-container-display',
                    description: 'display property is set',
                    defaultvalue: '""'
                },
                {
                    slno: '2',
                    name: '--custom-spacing-container-width',
                    description: 'width property is set',
                    defaultvalue: '""'
                },
                {
                    slno: '3',
                    name: '--custom-spacing-container-height',
                    description: 'height property is set',
                    defaultvalue: '""'
                },
                {
                    slno: '4',
                    name: '--custom-spacing-container-m',
                    description: 'margin property set',
                    defaultvalue: '""'
                },
                {
                    slno: '5',
                    name: '--custom-spacing-container-mt',
                    description: 'margin-top property set',
                    defaultvalue: '""'
                },
                {
                    slno: '6',
                    name: '--custom-spacing-container-mr ',
                    description: 'margin-right property set',
                    defaultvalue: '""'
                },
                {
                    slno: '7',
                    name: '--custom-spacing-container-mb',
                    description: 'margin-bottom property set',
                    defaultvalue: '""'
                },
                {
                    slno: '8',
                    name: '--custom-spacing-container-ml',
                    description: 'margin-left property set',
                    defaultvalue: '""'
                },
                {
                    slno: '9',
                    name: '--custom-spacing-container-p',
                    description: 'padding property set',
                    defaultvalue: '""'
                },
                {
                    slno: '10',
                    name: '--custom-spacing-container-pt',
                    description: 'padding-top property set',
                    defaultvalue: '""'
                },
                {
                    slno: '11',
                    name: '--custom-spacing-container-pr',
                    description: 'padding-right property set',
                    defaultvalue: '""'
                },
                {
                    slno: '12',
                    name: '--custom-spacing-container-pb',
                    description: 'padding-bottom property set',
                    defaultvalue: '""'
                },
                {
                    slno: '13',
                    name: '--custom-spacing-container-pl',
                    description: 'padding-left property set',
                    defaultvalue: '""'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
