<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Size Variations</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--form-control-padding-y',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-base-padding-y)  * size factor) = 0.325em  * size factor'
                },
                {
                    slno: '2',
                    name: '--form-control-padding-x',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-base-padding-x)   * var(--#{$name}-factor)) = 0.75em *  size factor'
                },
                {
                    slno: '3',
                    name: '--form-control-line-height',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-base-line-height)  var(--#{$name}-factor)) = 1.6rem  * size factor'
                },
                {
                    slno: '4',
                    name: '--form-control-height-',
                    description: '',
                    defaultvalue: ' calc(var(--form-control-base-height) * var(--#{$name}-factor)) = calc(var(--element-line-height) + ((var(--element-padding-y) + var(--element-border-width)) * 2)) =  ( an issue in it since the --element-border-width is set in px, and others in rem )'
                },
                {
                    slno: '5',
                    name: '--form-control-font-size',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-base-font-size)  var(--#{$name}-factor)) = 1rem  * size factor'
                },
                {
                    slno: '6',
                    name: '--form-control-label-font-size\n',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-base-label-font-size) * var(--#{$name}-factor)) = 1rem * size factor'
                },
                {
                    slno: '7',
                    name: 'Size Factors',
                    description: '',
                    defaultvalue: '\'1. xs-factor\' : 0.65,\n' +
                        '\'2. sm-factor\' : 0.875,\n' +
                        '\'3. lg-factor\' : 1.25,\n' +
                        '\'4. xl-factor\' : 1.75,\n'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
