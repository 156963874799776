<template>
    <page-template>
    <div class="bg-2 p-5">
        <div>
            <h3>Size Variations</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Sizevariations',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--xs-factor',
                    description: 'proportion factor for size - extra small',
                    defaultvalue: '0.65'
                },
                {
                    slno: '2',
                    name: '--sm-factor',
                    description: 'proportion factor for size - small',
                    defaultvalue: '0.875'
                },
                {
                    slno: '3',
                    name: '--lg-factor',
                    description: 'proportion factor for size - large',
                    defaultvalue: '1.25'
                },
                {
                    slno: '4',
                    name: '--lg-factor',
                    description: 'proportion factor for size - extra large',
                    defaultvalue: '1.75'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
