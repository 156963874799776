<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4111 12.8068C10.4111 13.3457 10.6252 13.8626 11.0063 14.2437C11.3875 14.6249 11.9044 14.839 12.4433 14.839C12.9823 14.839 13.4992 14.6249 13.8803 14.2437C14.2614 13.8626 14.4755 13.3457 14.4755 12.8068C14.4755 12.2678 14.2614 11.7509 13.8803 11.3698C13.4992 10.9887 12.9823 10.7746 12.4433 10.7746C11.9044 10.7746 11.3875 10.9887 11.0063 11.3698C10.6252 11.7509 10.4111 12.2678 10.4111 12.8068ZM10.4111 6.71018C10.4111 7.24915 10.6252 7.76604 11.0063 8.14716C11.3875 8.52827 11.9044 8.74237 12.4433 8.74237C12.9823 8.74237 13.4992 8.52827 13.8803 8.14716C14.2614 7.76604 14.4755 7.24915 14.4755 6.71018C14.4755 6.1712 14.2614 5.65431 13.8803 5.2732C13.4992 4.89208 12.9823 4.67798 12.4433 4.67798C11.9044 4.67798 11.3875 4.89208 11.0063 5.2732C10.6252 5.65431 10.4111 6.1712 10.4111 6.71018ZM10.4111 18.9034C10.4111 19.4423 10.6252 19.9592 11.0063 20.3403C11.3875 20.7214 11.9044 20.9356 12.4433 20.9356C12.9823 20.9356 13.4992 20.7214 13.8803 20.3403C14.2614 19.9592 14.4755 19.4423 14.4755 18.9034C14.4755 18.3644 14.2614 17.8475 13.8803 17.4664C13.4992 17.0853 12.9823 16.8712 12.4433 16.8712C11.9044 16.8712 11.3875 17.0853 11.0063 17.4664C10.6252 17.8475 10.4111 18.3644 10.4111 18.9034Z"
            :fill="fillColor"/>
    </svg>
</template>

<script>
export default {
    name: 'OptionsVerticalSvg',
    props: {
        color: { type: String, default: 'primary' }
    },
    computed: {
        fillColor () {
            if (this.color) {
                return 'var(--color-' + this.color + ')';
            }
            return '#2A6772';
        }
    }
};
</script>

<style scoped>

</style>
