<template>
    <page-template>
        <div class="container-fluid py-3 bg-2">
            <div>
                <h2>Form Modifiers Classes</h2>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value) in classNames" :key="value.title">
                        <td>
                            {{ value.title }}
                        </td>
                        <td>
                            {{ value.desc }} <br>
                            <code-container show-code-no-toggle :code-str="codeStore.formNoShadow.code"/>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="bg-4 p-4">
                <h3>Input and Textarea Utilities</h3>
                <p>These utility classes are designed to enhance form inputs and textareas, providing better control
                    over
                    their appearance and behavior during user interactions.</p>

                <h4>Input Utilities</h4>
                <p>The following class can be used to modify input components:</p>

                <ul class="pl-3">
                    <li><strong>.input-focus-no-shadow</strong>: This class removes the shadow effect that typically
                        appears
                        when an input field is focused. It creates a cleaner look for your form inputs, allowing for a
                        more
                        streamlined design.
                    </li>
                </ul>

                <h4 class="mt-4">Textarea Utilities</h4>
                <p>To control the resizing behavior of textareas, you can use:</p>

                <ul class="pl-3">
                    <li><strong>.textarea-resize-none</strong>: This class disables the resizing of the textarea,
                        preventing
                        users from changing its dimensions. This can help maintain a consistent layout in forms.
                    </li>
                </ul>

                <h4 class="mt-4">Example Usage</h4>
                <p>Here’s how to apply these classes in your HTML components:</p>

                <h5>Input Example</h5>
                <pre><code>&lt;validated-input input-classes="input-focus-no-shadow b-1 b-secondary"/&gt;</code></pre>
                <p>This example creates a validated input component with the shadow removed on focus and applies
                    additional
                    border classes.</p>

                <h5>Textarea Example</h5>
                <pre><code>&lt;validated-input input-classes="input-focus-no-shadow b-1 b-secondary textarea-resize-none"/&gt;</code></pre>
                <p>This example shows a validated input component that combines the input-focus-no-shadow class with the
                    textarea-resize-none class to create a textarea that cannot be resized and has no focus shadow.</p>
            </div>
            <div class="col-lg-12 mt-4">

                <h3>Forms: SCSS Variables</h3>

                <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
            </div>
            <div class="col-lg-12 mt-4">

                <h3>Form Control Floating: SCSS Variables</h3>

                <simple-table :fields="fields1" :data="data1" class="b-1"></simple-table>
            </div>

        </div>
    </page-template>
</template>

<script>
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';
import codeStore from 'lego-framework/src/views/docs/helpers/codes/formModifers.json';

export default {
    name: 'FormModifierHelpers',
    components: { CodeContainer },
    metaInfo: {
        path: 'helpers/form-modifiers/',
        title: 'Form Modifiers'
    },
    data () {
        return {
            classNames: [
                { title: '.input-focus-no-shadow', desc: 'remove shadow on focus on form input components' },
                {
                    title: '.textarea-resize-none',
                    desc: 'remove resize from textarea added as prop value for input-classes'
                }
            ],
            codeStore: codeStore,
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--form-separator',
                    description: '',
                    defaultvalue: '1rem'
                },
                {
                    slno: '2',
                    name: '--form-control-label-font-size',
                    description: '',
                    defaultvalue: '1rem * size factor = 0.875rem ( md)'
                },
                {
                    slno: '3',
                    name: '--form-control-padding-y',
                    description: '',
                    defaultvalue: 'var(--element-padding-y) = 0.325em'
                },
                {
                    slno: '4',
                    name: '--form-control-line-height',
                    description: '',
                    defaultvalue: 'var(--element-line-height) = 1.6rem'
                },
                {
                    slno: '5',
                    name: '--form-control-border-width',
                    description: '',
                    defaultvalue: 'var(--element-border-width) = var(--border-width) = 1px'
                },
                {
                    slno: '6',
                    name: '--form-control-border-color',
                    description: '',
                    defaultvalue: 'var(--element-border-color), var(--border-color)  = #E4E9F2'
                },
                {
                    slno: '7',
                    name: '--form-control-font-size',
                    description: '',
                    defaultvalue: 'var(--element-font-size) = var(--font-size-base) = 1rem'
                },
                {
                    slno: '8',
                    name: '--form-control-color',
                    description: '',
                    defaultvalue: 'Default Value : var(--body-text-color)  = var(--text-1) = #161616'
                },
                {
                    slno: '9',
                    name: '--form-control-padding-x',
                    description: '',
                    defaultvalue: 'var(--element-padding-x) = 0.75em'
                },
                {
                    slno: '10',
                    name: '--form-control-disabled-bg',
                    description: '',
                    defaultvalue: 'var(--color-gray-100) = #f8f9fa'
                },
                {
                    slno: '11',
                    name: '--form-control-border-color-focus',
                    description: '',
                    defaultvalue: 'var(--element-border-color-focus) = var(--color-primary)'
                },
                {
                    slno: '12',
                    name: '--form-control-bg-color',
                    description: '',
                    defaultvalue: 'transparent'
                }
            ],
            fields1: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data1: [
                {
                    slno: '1',
                    name: '--translate-x',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-padding-x)) = var(--element-padding-x) = 0.75em  ( NB: there is an issue with the units of the adding parameters)'
                },
                {
                    slno: '1',
                    name: '--translate-y',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-line-height) + var(--form-control-padding-y)) = calc(var(--element-line-height) + var(--element-padding-y)   = 1.6rem + 0.325em'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
