<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>font Weight</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'FontWeight',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--font-weight-lighter',
                    description: 'font weight lighter',
                    defaultvalue: 'lighter'
                },
                {
                    slno: '2',
                    name: '--font-weight-light',
                    description: 'font weight light',
                    defaultvalue: '300'
                },
                {
                    slno: '3',
                    name: '--font-weight-normal',
                    description: 'font weight normal',
                    defaultvalue: '400'
                },
                {
                    slno: '4',
                    name: '--font-weight-bold',
                    description: 'font weight bold',
                    defaultvalue: '700'
                },
                {
                    slno: '5',
                    name: '--font-weight-bolder',
                    description: 'font weight bolder',
                    defaultvalue: 'bolder'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
