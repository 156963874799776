<template>
    <validation-provider tag="div" :rules="rules" :name="name || label" v-bind="$attrs" v-slot="{errors}" class="mb-form">
        <label class="form-check mb-form">
            <input type="checkbox" v-on="listeners" v-model="model" :disabled="disabled"/>
            <span :class="[labelClasses]">{{ label }}</span>
        </label>
        <p class="text-danger" v-html="errors[0]" v-if="errors[0]"></p>
    </validation-provider>
</template>

<script>
import BaseInput from './BaseInput';

export default {
    name: 'form-input',
    components: {},
    extends: BaseInput,
    props: {
        type: { type: String, default: 'text' }
    },
    methods: {
        updateValue () {
            this.$nextTick(() => {
                this.$emit('input', !this.model);
            });
        }
    }

};
</script>
