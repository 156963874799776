<template>
    <page-template class="bg-2">
        <div class="">
            <h2 class="">Pricing Cards</h2>
            <div class="row">
                <div class="col-12">
                    <h3 class="my-3">Card 1</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.card1.import"/>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <h5>design: basic-a</h5>
                            <pricing-card1
                                :icon-img="img2"
                                text1="Basic Plan"
                                text2="$10/mth"
                                text3="Our most popular plan"
                                :items="features.slice(0,5)"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.card1.basica.code"/>
                </div>

                <div class="col-6">
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <h5>design : basic-b</h5>
                            <pricing-card1
                                design="basic-b"
                                text1="Basic Plan"
                                text2="$10/mth"
                                text3="Our most popular plan"
                                :items="features.slice(0,5)"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.card1.basicb.code"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h3 class="my-4">Card 2</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.card2.import"/>
                </div>
                <div class="col-lg-4">
                    <pricing-card2
                        :icon-img="img2"
                        text1="$10/mth"
                        text2="Basic Plan"
                        text3="Our most popular plan"
                        :items="features.slice(0,5)"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.card2.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-12">
                    <h3 class="my-4">Card 3</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.card3.import"/>
                </div>
                <div class="col-lg-6 mt-3">
                    <div class="">
                        <pricing-card3
                            text1="Your Title"
                            titleColor="#9B26B6"
                            text2="Popular"
                            text3="Your content goes here."
                            text4="10"
                            width="40r"
                            :items="features"/>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.card3.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="my-4">Card 4</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.card4.import"/>
                </div>
                <div class="col-6 mt-3">
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <div class="">
                                <h5>design: basic-a</h5>
                                <pricing-card4
                                    tag1="Popular"
                                    titleColor="#9B26B6"
                                    text1="10"
                                    text2="Our most popular plan."
                                    :items="features"/>
                            </div>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.card4.basica.code"/>
                </div>
                <div class="col-6 mt-3">
                    <div class="row">
                        <div class="col-12 col-lg-8">
                            <div class="">
                                <h5>design: basic-b</h5>
                                <pricing-card4
                                    design="basic-b"
                                    tag1="Basic plan"
                                    tag2="most popular"
                                    titleColor="#9B26B6"
                                    text1="10"
                                    text2="Our most popular plan."
                                    text4="10"
                                    width="40r"
                                    :items="features"/>
                            </div>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.card4.basicb.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 mb-3">
                    <h3 class="my-4">Card 5</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.card5.import"/>
                </div>
                <div class="col-lg-4">
                    <pricing-card5
                        text1="Most popular plan"
                        text2="$10/mth"
                        text3="Basic Plan"
                        text4="Ideal for small teams and startups"
                        :items="features"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.card5.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 mb-3">
                    <h3 class="my-4">Card 6</h3>
                    <code-container show-code-no-toggle :code-str="codeStore.card6.import"/>
                </div>
                <div class="col-lg-4 ">
                    <pricing-card6
                        design="basic-b"
                        text1="Basic plan"
                        text2="popular"
                        titleColor="#9B26B6"
                        text3="10"
                        text4="Our most popular plan."
                        text5="10"
                        :items="features"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.card6.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4">
                    <h3 class="my-4">Card 2 - Customized</h3>
                    <pricing-card2
                        :icon-img="img2"
                        bg-color="primary"
                        bg-color-thickness="100"
                        container-classes="text-primary"
                        text1-color="primary"
                        text2-color="primary"
                        text3-color="primary"
                        text1="$10/mth"
                        text2="Basic Plan"
                        text3="Our most popular plan"
                        :items="features"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.card2Customized.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <div class="col-12">
                    <h3 class="my-4">Slots</h3>
                    <h5 class="my-3">top</h5>
                    <p>icons and tags added here ( buttons added in it)</p>
                    <h5 class="my-3">default</h5>
                    <p>features added in it</p>
                    <h5 class="my-3">bottom</h5>
                    <p>buttons added in it</p>
                    <h5 class="my-3">bottom</h5>
                    <p>buttons added in it</p>
                    <h5 class="my-3">text1</h5>
                    <p>replacement for text1</p>
                    <h5 class="my-3">text2</h5>
                    <p>replacement for text2</p>
                    <h5 class="my-3">text3</h5>
                    <p>replacement for text3</p>
                    <h5 class="my-3">text4 ( Only on cards 3, 5 and 6)</h5>
                    <p>replacement for text4 ( Only on cards 3, 5 and 6)</p>
                    <h5 class="my-3">text5 ( Only on card 6)</h5>
                    <p>replacement for text5 ( Only on card 6)</p>
                    <h5 class="my-3">text6 ( Only on card 6)</h5>
                    <p>replacement for text6 ( Only on card 6)</p>
                    <h5 class="my-3">text7 ( Only on card 6)</h5>
                    <p>replacement for text7 ( Only on card 6)</p>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import image from '../../../assets/web/icons/tick.png';
import image2 from '../../../assets/web/icons/icons8-violet-tick.png';
import PricingCard1 from 'lego-framework/src/web-components/pricing-cards/PricingCard1';
import PricingCard2 from 'lego-framework/src/web-components/pricing-cards/PricingCard2';
import PricingCard3 from 'lego-framework/src/web-components/pricing-cards/PricingCard3';
import PricingCard4 from 'lego-framework/src/web-components/pricing-cards/PricingCard4';
import PricingCard5 from 'lego-framework/src/web-components/pricing-cards/PricingCard5';
import PricingCard6 from 'lego-framework/src/web-components/pricing-cards/PricingCard6';
import codeStore from '../../../data/codes/web-components/pricing-cards.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'PriceCardVariantsDemoPage',
    components: { PricingCard6, PricingCard5, PricingCard4, PricingCard3, PricingCard2, PricingCard1, PropTable },
    data () {
        return {
            tickImg: image,
            img2: image2,
            features: [
                { icon: image, text: 'Basic reporting and analytics' },
                { icon: image, text: 'Upto 10 individual users' },
                { icon: image, text: 'Upto 10 individual users' },
                { icon: image, text: 'Upto 10 individual users' },
                { icon: image, text: 'Access to all basic features' },
                { icon: image, text: 'Basic reporting and analytics' },
                { icon: image, text: 'Upto 10 individual users' },
                { icon: image, text: 'Upto 10 individual users' }
            ],
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String,Number', 'text index is numbered in order left to right, top to bottom', '', ''],
                ['text2', 'String,Number', 'text index is numbered in order left to right, top to bottom', '', ''],
                ['text3', 'String,Number', 'text index is numbered in order left to right, top to bottom', '', ''],
                ['text4', 'String,Number', 'text index is numbered in order left to right only on card 3 , card 5 & card 6, top to bottom', '', ''],
                ['text5', 'String,Number', 'text index is numbered in order left to right only on card 6, top to bottom', '', ''],
                ['text6', 'String,Number', 'text index is numbered in order left to right only on card 6, top to bottom', '', ''],
                ['text7', 'String,Number', 'text index is numbered in order left to right only on card 6, top to bottom', '', ''],
                ['tag1', 'String,Number', 'tag text 1 only on card 4', '', ''],
                ['tag2', 'String,Number', 'tag text 2 only on card 4', '', ''],
                ['buttonText', 'String,Number', 'text on button', '', ''],
                ['bgColor', 'String', 'bg color of the card', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['borderRadius', 'String', 'Color Variant', ['1 - 10'], '2'],
                ['design', 'String', 'design Variant', ['basic-a', 'basic-b'], 'basic-a'],
                ['iconImg', 'String', 'top icon image', '', ''],
                ['items', 'Array', 'feature items array', '', ''],
                ['text1Color', 'String', 'text 1 font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text1FontSize', 'String', 'text 1 font size', ['3-12'], ''],
                ['text1Classes', 'String', 'text 1 additional classes mainly used for font classes', ['3-12'], ''],
                ['text2Color', 'String', 'text 2 font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text2FontSize', 'String', 'text 2 font size', ['3-12'], ''],
                ['text2Classes', 'String', 'text 2 additional classes mainly used for font classes', ['3-12'], ''],
                ['text3Color', 'String', 'text 3 font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text3FontSize', 'String', 'text 3 font size', ['3-12'], ''],
                ['text3Classes', 'String', 'text 3 additional classes mainly used for font classes', ['3-12'], ''],
                ['text4Color', 'String', 'text 4 font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text4FontSize', 'String', 'text 4 font size', ['3-12'], ''],
                ['text4Classes', 'String', 'text 4 additional classes mainly used for font classes', ['3-12'], ''],
                ['text5Color', 'String', 'text 5 font color (only on card 6)', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text5FontSize', 'String', 'text 5 font size (only on card 6)', ['3-12'], ''],
                ['text5Classes', 'String', 'text 5 additional classes mainly used for font classes (only on card 6)', ['3-12'], ''],
                ['text6Color', 'String', 'text 6 font color (only on card 6)', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text6FontSize', 'String', 'text 6 font size (only on card 6)', ['3-12'], ''],
                ['text6Classes', 'String', 'text 6 additional classes mainly used for font classes (only on card 6)', ['3-12'], ''],
                ['text7Color', 'String', 'text 7 font color (only on card 6)', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text7FontSize', 'String', 'text 7 font size (only on card 6)', ['3-12'], ''],
                ['text7Classes', 'String', 'text 7 additional classes mainly used for font classes (only on card 6)', ['3-12'], '']
            ]

        };
    }
};
</script>

<style scoped>

</style>
