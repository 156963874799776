<template>
    <component :is="tag" v-bind="attrs" class="lego-btn" :class="[className, additionalClasses]" v-on="listeners">
        <slot>
            <i :class="iconClass" v-if="icon && iconAlignment === 'left'"/>
            <icon-image class="space-right" :size="iconImgSize" :image="iconImg" v-if="iconImg && iconAlignment === 'left'"></icon-image>
            <span v-html="svgIcon" v-if="svgIcon && iconAlignment === 'left'"></span>
            <span v-if="displayText.length > 0">{{ displayText }}</span>
            <icon-image class="space-left" :size="iconImgSize" :image="iconImg" v-if="iconImg && iconAlignment === 'right'"></icon-image>
            <span v-html="svgIcon" v-if="svgIcon && iconAlignment === 'right'"></span>
            <i :class="iconClass" v-if="icon && iconAlignment === 'right'"/>
        </slot>
    </component>
</template>

<script>
import IconImage from './IconImage';

export default {
    name: 'lego-button',
    components: { IconImage },
    props: {
        loading: { type: Boolean, default: false },
        loadingText: { type: String, default: '' },
        title: { type: String, required: false, default: '' },
        text: { type: String, required: false, default: '' },
        type: { type: String, default: 'submit' },
        design: { type: String, required: false, default: 'basic' },
        size: { type: String, required: false, default: '' },
        disabled: { type: Boolean, default: false },
        block: { type: Boolean, default: false },
        pill: { type: Boolean, default: false },
        color: { type: String, required: false, default: 'primary' },
        icon: { type: String, required: false, default: '' },
        iconAlignment: { type: String, default: 'left' },
        borderRadius: { type: String, default: '1' },
        shadow: { type: String, default: '1' },
        hoverShadow: { type: String, default: '1' },
        iconImg: { type: String, default: '' },
        iconImgSize: { type: String, default: '1' },
        svgIcon: { type: String, default: '' },
        additionalClasses: { type: [String, Array], default: '' }
    },
    methods: {
        click (e) {
            if (!this.loading) {
                this.$emit('click', e);
            }
        }
    },
    computed: {
        listeners () {
            return {
                ...this.$listeners,
                click: this.click
            };
        },

        displayText () {
            if (this.loading) {
                return this.loadingText;
            }
            return this.text || this.title;
        },

        attrs () {
            const attrs = {};
            if (this.href) {
                attrs.href = this.href;
            }

            if (this.type) {
                attrs.type = this.type;
            }

            if (this.disabled || this.loading) {
                attrs.disabled = 'disabled';
            }

            return attrs;
        },
        tag () {
            if (this.href) {
                return 'a';
            }

            return 'button';
        },

        iconClass () {
            const names = [this.icon];
            if (this.title && this.title !== '') {
                if (this.iconAlignment === 'left') {
                    names.push('space-right');
                } else {
                    names.push('space-left');
                }
            }

            return names;
        },

        className () {
            const names = [];
            const style = `btn-${this.design}`;
            names.push(style);

            if (this.color) {
                names.push(`btn-color-${this.color}`);
            }

            if (this.borderRadius) {
                names.push('round-' + this.borderRadius);
            }

            if (this.shadow) {
                names.push('bs-' + this.shadow);
            }

            if (this.hoverShadow) {
                names.push('bs-' + this.hoverShadow + '-h');
            }

            if (this.size) {
                names.push(`btn-${this.size}`);
            }

            if (this.disabled || this.loading) {
                names.push('disabled');
            }

            if (this.block) {
                names.push('btn-block');
            }
            return names;
        }
    }
};
</script>
