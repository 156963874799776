<template>
    <div class="b-1 round-2 of-h fl-x" id="progressItemContainer" style="height: 0.65rem;">
        <div class="progress-item bg-primary" ref="progressItem" id="progressItemComponent"></div>
<!--        <span>{{percentageCalculation}}</span>-->
    </div>
</template>

<script>
export default {
    name: 'ProgressBarHtml',
    computed: {
        percentageCalculation () {
            // const progressItemEl = this.$refs.progressItem;
            const progressItemParentEl = document.getElementById('progressItemContainer');
            const progressItemEl = document.getElementById('progressItemComponent');
            // console.log('progress-item', (progressItemEl.clientWidth / progressItemParentEl.clientWidth) * 100, progressItemParentEl.clientWidth);
            if (progressItemParentEl && progressItemEl) {
                return (progressItemEl.clientWidth / progressItemParentEl.clientWidth) * 100;
            }
            return '5';
        }
    }
};
</script>

<style scoped lang="scss">
    .progress-item {
        animation: dash1 5s linear;
        animation-fill-mode: forwards;
        padding: var(--spacer-1);
        height: 100%;
    }

    @keyframes dash1 {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }
</style>
