<template>
    <page-template>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="my-3">News Letter CTA</h2>
                    <p class="lead">News Letter CTA with input field for getting contact</p>
                </div>
                <div class="col-12 mt-5">
                    <h4>Default ( with placeholder text)</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.import.default"/>
                    <news-letter-cta/>
                    <code-container :code-str="codeStore.defaultText.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>Default</h4>
                    <news-letter-cta
                        text1="Sign up for our newsletter"
                        text2="Enter your email"
                        text3="Subscribe"
                        text4="Be the first to know about releases and industry news and insights."
                        text5="We care about your data in our privacy policy"/>
                    <code-container :code-str="codeStore.default.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>bgColor: 2</h4>
                    <news-letter-cta
                        text1="Sign up for our newsletter"
                        text2="Enter your email"
                        text3="Subscribe"
                        text4="Be the first to know about releases and industry news and insights."
                        text5="We care about your data in our privacy policy"
                        bg-color="2"/>
                    <code-container :code-str="codeStore.bgColor2.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>buttonDesign: basic-b</h4>
                    <news-letter-cta
                        text1="Sign up for our newsletter"
                        text2="Enter your email"
                        text3="Subscribe"
                        text4="Be the first to know about releases and industry news and insights."
                        text5="We care about your data in our privacy policy"
                        button-design="basic-b"/>
                    <code-container :code-str="codeStore.buttonDesign2.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>News Letter Centered</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.import.centered"/>
                    <news-letter-cta-centered
                        text1="Sign up for our newsletter"
                        text2="Be the first to know about releases and industry news and insights."
                        text5="We care about your data in our privacy policy"
                        button-design="basic-b"/>
                    <code-container :code-str="codeStore.centered.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>News Letter Colored</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.import.colored"/>
                    <news-letter-cta-colored
                        text1="Sign up for our newsletter"
                        text2="Enter your email"
                        text4="Be the first to know about releases and industry news and insights."
                        text5="We care about your data in our privacy policy"
                        bg-color="primary"/>
                    <code-container :code-str="codeStore.colored.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>News Letter Colored , centered</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.import.centeredColored"/>
                    <news-letter-cta-centered-colored
                        text1="Sign up for our newsletter"
                        text2="Be the first to know about releases and industry news and insights."
                        text3="Enter your email"
                        text4="Subscribe"
                        text5="We care about your data in our privacy policy"
                        bg-color="primary"/>
                    <code-container :code-str="codeStore.centerdColored.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4>Submit Event (Emit: 'onsubmit')</h4>
                    <news-letter-cta-centered-colored @onsubmit="getNewsletterData"/>
                    <code-container :code-str="codeStore.onsubmitEvent.code" show-code-no-toggle/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import NewsLetterCtaCentered from 'lego-framework/src/web-components/news-letter-cta/NewsLetterCtaCentered';
import NewsLetterCta from 'lego-framework/src/web-components/news-letter-cta/NewsLetterCta';
import NewsLetterCtaColored from 'lego-framework/src/web-components/news-letter-cta/NewsLetterCtaColored';
import NewsLetterCtaCenteredColored from 'lego-framework/src/web-components/news-letter-cta/NewsLetterCtaCenteredColored';
// import CodeContainer from '../../../components/doc-items/CodeContainer';
import codeStore from './code-storage/news-letter-cta.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'NewsletterVariantsPage',
    components: {
        NewsLetterCtaCenteredColored,
        NewsLetterCtaColored,
        NewsLetterCta,
        NewsLetterCtaCentered,
        PropTable
    },
    metaInfo: {
        path: 'web-components/newsletter-cta/',
        title: 'Newsletter CTA'
    },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'text numbered according to its 2 dimensional order (left to right and top to bottom)', '', ''],
                ['text2', 'String', 'text numbered according to its 2 dimensional order (left to right and top to bottom)', '', ''],
                ['text3', 'String', 'text numbered according to its 2 dimensional order (left to right and top to bottom)', '', ''],
                ['text4', 'String', 'text numbered according to its 2 dimensional order (left to right and top to bottom)', '', ''],
                ['text5', 'String', 'text numbered according to its 2 dimensional order (left to right and top to bottom)', '', ''],
                ['bgColor', 'String', 'background color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary( in colored versions of component 2 in others)'],
                ['buttonDesign', 'String', 'button design variant', ['basic', 'basic-a', 'basic-b', 'basic-c', 'basic-d', 'basic-e', 'animated-a', 'animated-b', 'animated-c', 'animated-d', 'animated-e'], 'basic-b'],
                ['buttonColor', 'String', 'Button color variant ( only in colored versions of component)', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'secondary'],
                ['py', 'String', 'root element vertical padding', ['0', '9'], '6'],
                ['px', 'String', 'root element horizontal padding', ['0', '9'], '5'],
                ['text1Color', 'String', 'text1 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '5', 'etc'], ''],
                ['text1FontSize', 'String', 'text1 font size', ['-1 to 7'], '3'],
                ['text1Classes', 'String, Array', 'text1 additional classes', ''],
                ['text2Color', 'String', 'text2 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '5', 'etc'], ''],
                ['text2FontSize', 'String', 'text2 font size', ['-1 to 7'], '3'],
                ['text2Classes', 'String, Array', 'text2 additional classes', ''],
                ['text3Color', 'String', 'text3 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '5', 'etc'], ''],
                ['text3FontSize', 'String', 'text3 font size', ['-1 to 7'], '3'],
                ['text3Classes', 'String, Array', 'text3 additional classes', ''],
                ['text4Color', 'String', 'text4 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '5', 'etc'], ''],
                ['text4FontSize', 'String', 'text4 font size', ['-1 to 7'], '3'],
                ['text4Classes', 'String, Array', 'text4 additional classes', ''],
                ['text5Color', 'String', 'text5 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '5', 'etc'], ''],
                ['text5FontSize', 'String', 'text5 font size', ['-1 to 7'], '3'],
                ['text5Classes', 'String, Array', 'text5 additional classes', '']
            ]
        };
    },
    methods: {
        getNewsletterData (email) {
            console.log('email:', email);
        }
    }
};
</script>

<style scoped>

</style>
