<template>
    <div class="pb-5 bg-2 pl-5 pt-5">
        <div class="container">
            <h2>Modal</h2>
            <p class="lead">A simple modal component</p>
            <div class="mb-3">
                <h5>Usage</h5>
                <p>Import Component on plugins/lego.js</p>
                <p>import Modal from 'lego-framework/src/containers/Modal';</p>
                <p>Vue.component('modal', Modal);</p>
                <code-container :show-code-no-toggle="true"
                                :code-str="codeStore.modals.simpleUse.code"></code-container>
            </div>

            <h3>Props</h3>
            <prop-table :items="props"/>

            <h3>Color Demo</h3>
            <div class="btn-group">
                <Button text="Default Modal" @click="$refs.defaultModel.show()"/>
                <Button text="Colored Header Modal" @click="$refs.coloredHeaderModal.show()"/>
                <Button text="Full Colored Modal" @click="$refs.fullColoredModal.show()"/>
                <Button color="danger" text="Full Colored Modal" @click="$refs.fullColoredErrorModal.show()"/>
                <Button color="success" text="Full Colored Modal Width 30rem"
                        @click="$refs.fullColoredSuccessModal.show()"/>
                <Button color="info" text="Full Colored Modal Width 70vw" @click="$refs.fullColoredInfoModal.show()"/>
            </div>

            <modal title="Default Modal" ref="defaultModel">
                <div class="card">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, aspernatur aut consequatur cumque
                    debitis ex quidem vitae? Ab adipisci eum non odio odit porro
                    quia
                    quod sequi sit, suscipit velit.
                </div>
            </modal>

            <modal title="Colored Header Modal" ref="coloredHeaderModal" header-color="primary">
                <div class="card">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, aspernatur aut consequatur cumque
                    debitis ex quidem vitae? Ab adipisci eum non odio odit porro
                    quia
                    quod sequi sit, suscipit velit.
                </div>
            </modal>

            <modal title="Colored Header Modal" ref="fullColoredModal" color="primary">
                <div class="card">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, aspernatur aut consequatur cumque
                    debitis ex quidem vitae? Ab adipisci eum non odio odit porro
                    quia
                    quod sequi sit, suscipit velit.
                </div>
            </modal>

            <modal title="Colored Header Modal" ref="fullColoredErrorModal" color="danger">
                <div class="card">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, aspernatur aut consequatur cumque
                    debitis ex quidem vitae? Ab adipisci eum non odio odit porro
                    quia
                    quod sequi sit, suscipit velit.
                </div>
            </modal>

            <modal title="Full Success Colored, Width: 30rem" width="30r" ref="fullColoredSuccessModal" color="success">
                <div class="card">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, aspernatur aut consequatur cumque
                    debitis ex quidem vitae? Ab adipisci eum non odio odit porro
                    quia
                    quod sequi sit, suscipit velit.
                </div>
            </modal>

            <modal title="Full Info Colored, Width: 70vw" width="70v" ref="fullColoredInfoModal" color="info">
                <div class="card">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet, aspernatur aut consequatur cumque
                    debitis ex quidem vitae? Ab adipisci eum non odio odit porro
                    quia
                    quod sequi sit, suscipit velit.
                </div>
            </modal>
            <div class="row mt-4">
                <div class="col-12">
                    <h5 class="mb-4 text-primary">Other Modals Examples</h5>
                    <div class="col-12">
                        <component-wrapper title="Close On Backdrop" :code="codeStore.modals.closeOnBackdrop.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.SimpleModal.show()"></btn>
                            <modal title="Simple Modal ( Close on Backdrop)" ref="SimpleModal">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="No Close On Backdrop" :code="codeStore.modals.noCloseBackdrop.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.SimpleModalNoClose.show()"></btn>
                            <modal title="Simple Modal ( No Close on Backdrop)" ref="SimpleModalNoClose"
                                   :no-close-on-backdrop="true"
                                   width="30r" header-color="primary">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal ( Width 60r) & Header Color Primary"
                                           :code="codeStore.modals.modalWidth60re.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.SimpleModalWidth60r.show()"></btn>
                            <modal title="Simple Modal ( Width - 60r) & Header Color Primary" ref="SimpleModalWidth60r"
                                   :no-close-on-backdrop="true"
                                   width="60r" header-color="primary">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal Custom Buttons" :code="codeStore.modals.modalCustomButtons.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.modalCustomButtons.show()"></btn>
                            <modal title="Modal Custom Buttons" ref="modalCustomButtons"
                                   width="60r" header-color="primary" show-additional-buttons>
                                <template #buttons>
                                    <div class="btn-group">
                                        <btn icon="fa fa-edit"></btn>
                                        <btn icon="fa fa-trash"></btn>
                                        <btn icon="fa fa-close" @click="$refs.modalCustomButtons.close()"></btn>
                                    </div>
                                </template>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal With Dialog" :code="codeStore.modals.withDialog.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.modalWithDialog.show()"></btn>
                            <modal title="Modal With Dialog" ref="modalWithDialog" dialog
                                   width="60r" header-color="primary">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal With Custom Dialog"
                                           :code="codeStore.modals.withCustomDialog.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.modalWithCustomDialog.show()"></btn>
                            <modal title="Modal With Custom Dialog" ref="modalWithCustomDialog" dialog
                                   width="60r" header-color="primary">
                                <template #footer>
                                    <div class="btn-group">
                                        <btn text="Update"></btn>
                                        <btn text="Cancel"></btn>
                                    </div>
                                </template>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal Body Color" :code="codeStore.modals.modalBodyColor.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.modalBodyColor.show()"></btn>
                            <modal title="Modal Body Color" ref="modalBodyColor" body-color="secondary"
                                   width="60r" header-color="primary">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal Color Danger" :code="codeStore.modals.modalDanger.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.modalColorDanger.show()"></btn>
                            <modal title="Modal Color Danger" ref="modalColorDanger" color="danger"
                                   width="60r">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal Aligned View port center"
                                           :code="codeStore.modals.alignViewportCenter.code"
                                           class="text-primary text-center">
                            <btn text="Open Modal" @click="$refs.modalViewportCenter.show()"></btn>
                            <modal :align-viewport-center="true" title="Modal Aligned View port center"
                                   ref="modalViewportCenter"
                                   width="60r">
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae, blanditiis
                                    consequatur dolore ea fuga harum inventore minus nostrum nulla officiis quam quidem
                                    quis quos similique tempore veniam, voluptate, voluptates. Accusantium adipisci
                                    cumque delectus dolore error esse iure minus nemo quis reiciendis. Debitis eaque,
                                    eos excepturi expedita optio quae repudiandae tenetur.
                                </p>
                            </modal>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <h3 class="my-3">Modal Updated</h3>
                        <div class="col-12 mt-4">
                            <component-wrapper title="Modal Header Customized"
                                               :code="codeStore.modals.modalUpdated.headerChanges.code"
                                               class="text-primary">
                                <div class="text-center">
                                    <btn text="Text Below Icon Modal" @click="$refs.textBelowIconModal.show()"/>
                                </div>
                                <modal-header-updated
                                    ref="textBelowIconModal"
                                    align-viewport-center
                                    width="25r"
                                    header-classes="pb-0">
                                    <template #closeButton>
                                        <icon-img :image="crossIcon" size="4"/>
                                    </template>
                                    <template #title>
                                        <icon-img :image="tickIcon" size="7"></icon-img>
                                    </template>
                                    <h5 class="text-fs-1">Blog post published</h5>
                                    <p class="text-2">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis distinctio eos
                                        eveniet fugit labore laborum libero nemo nulla porro tenetur.
                                    </p>
                                    <div class="lg-fl-x w-100p fl-y">
                                        <btn class="w-100p order-2 order-lg-1 w-lg-50p mt-lg-0 mt-3" design="basic-b"
                                             text="Cancel"/>
                                        <btn class="w-100p order-1 order-lg-2 w-lg-50p ml-lg-2" text="Confirm"/>
                                    </div>
                                </modal-header-updated>
                            </component-wrapper>
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal with icon and text"
                                           :code="codeStore.modals.modalUpdated.textAndIconAlign.code"
                                           class="text-primary">
                            <div class="text-center">
                                <btn text="Icon Left : Text on right Modal"
                                     @click="$refs.iconLeftIconModal.show()"></btn>
                            </div>
                            <modal-header-updated
                                ref="iconLeftIconModal"
                                align-viewport-center
                                width="35r"
                                header-align-items="top"
                                header-classes="pt-4 pb-0">
                                <template #closeButton>
                                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                                </template>
                                <template #title>
                                    <div class="fl-x">
                                        <icon-img :image="tickIcon" size="7"></icon-img>
                                        <div class="ml-3">
                                            <h5 class="text-fs-1">Blog post published</h5>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis
                                                distinctio eos eveniet fugit labore laborum libero nemo nulla
                                                porro
                                                tenetur.
                                            </p>
                                        </div>
                                    </div>
                                </template>
                                <div class="lg-fl-te-c lg-fl-x w-100p fl-y">
                                    <validated-checkbox label="Don't show again" class="mb-0"/>
                                    <div class="lg-fl-x fl-y">
                                        <btn class="w-100p order-2 order-lg-1 w-lg-50p mt-lg-0 mt-3" design="basic-b"
                                             text="Cancel"/>
                                        <btn class="w-100p order-1 order-lg-2 w-lg-50p ml-lg-2" text="Confirm"/>
                                    </div>
                                </div>
                            </modal-header-updated>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal with Top Image and text"
                                           :code="codeStore.modals.modalUpdated.topImageAndText.code"
                                           class="text-primary">
                            <div class="text-center">
                                <btn text="Text Below Image Modal" @click="$refs.textBelowImageModal.show()"/>
                            </div>
                            <modal-header-updated
                                title="&nbsp;"
                                ref="textBelowImageModal"
                                align-viewport-center
                                width="25r"
                                header-align-items="top"
                                header-classes="pb-0">
                                <template #closeButton>
                                    <icon-img :image="crossIcon" size="4"/>
                                </template>
                                <custom-h-el height="10r" class="bg bg-cover round-1 bg-pos-c"
                                             :style="{ backgroundImage: 'url(' + topImg + ')' }">
                                </custom-h-el>
                                <div class="mt-3">
                                    <h5 class="text-fs-1">Blog post published</h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis distinctio eos
                                        eveniet fugit labore laborum libero nemo nulla porro
                                        tenetur.
                                    </p>
                                </div>
                                <div class="lg-fl-x w-100p fl-y">
                                    <btn :icon-img="iconCopy" icon-img-size="2"
                                         class="w-100p order-2 order-lg-1 w-lg-50p mt-lg-0 mt-3" design="basic-f"
                                         text="Copy Link"/>
                                    <btn class="w-100p order-1 order-lg-2 w-lg-50p ml-lg-2" text="Finish"/>
                                </div>
                            </modal-header-updated>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal with input box"
                                           :code="codeStore.modals.modalUpdated.withInputBox.code"
                                           class="text-primary text-center">
                            <div class="text-center">
                                <btn text="With Input Box Modal" @click="$refs.withInputBoxModal.show()"/>
                            </div>
                            <modal-header-updated
                                title="&nbsp;"
                                ref="withInputBoxModal"
                                align-viewport-center
                                width="25r"
                                header-align-items="top"
                                header-classes="pb-0">
                                <template #title>
                                    <div class="fl-x fl-j-c w-100p">
                                        <icon-img :image="mailIcon" size="7"/>
                                    </div>
                                </template>
                                <template #closeButton>
                                    <icon-img :image="crossIcon" size="4"/>
                                </template>
                                <div class="text-center">
                                    <h5 class="text-fs-1">Please check your email</h5>
                                    <p>
                                        We have sent a code to <span class="font-weight-500">olive@mail.com.</span>
                                    </p>
                                    <div class="fl-x fl-j-c">
                                        <validated-input
                                            input-classes="py-3 text-fs-3"
                                            input-element-classes="font-weight-900 text-center"/>
                                        <validated-input
                                            input-classes="py-3 text-fs-3"
                                            input-element-classes="font-weight-900 text-center" class="ml-2"/>
                                        <validated-input
                                            input-classes="py-3 text-fs-3"
                                            input-element-classes="font-weight-900 text-center" class="ml-2"/>
                                        <validated-input
                                            input-classes="py-3 text-fs-3"
                                            input-element-classes="font-weight-900 text-center" class="ml-2"/>
                                    </div>
                                    <p class="text-left">Didn't get a code? <a href="">Click to resend.</a></p>
                                </div>
                                <div class="lg-fl-x w-100p fl-y mt-4">
                                    <btn class="w-100p order-2 order-lg-1 w-lg-50p mt-lg-0 mt-3"
                                         design="basic-f"
                                         text="Cancel"/>
                                    <btn class="w-100p order-1 order-lg-2 w-lg-50p ml-lg-2" text="Verify"/>
                                </div>
                            </modal-header-updated>
                        </component-wrapper>
                    </div>
                    <div class="col-12 mt-4">
                        <component-wrapper title="Modal with Toggle Buttons"
                                           :code="codeStore.modals.modalUpdated.withToggleButton.code"
                                           class="text-primary">
                            <div class="text-center">
                                <btn text="With Toggle Modal" @click="$refs.withToggleModal.show()"/>
                            </div>
                            <modal-header-updated
                                title="&nbsp;"
                                ref="withToggleModal"
                                align-viewport-center
                                width="25r"
                                header-classes="pb-0">
                                <template #title>
                                    <icon-img :image="tickIcon" size="7"></icon-img>
                                </template>
                                <template #closeButton>
                                    <icon-img :image="crossIcon" size="4"/>
                                </template>
                                <h5 class="text-fs-1">Blog post published</h5>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    Debitis distinctio eos eveniet fugit labore laborum libero
                                    nemo nulla porro tenetur.
                                </p>
                                <div class="fl-x fl-a-s ml-n2">
                                    <toggle-button class="mt-n1"/>
                                    <div class="text-fs-0">
                                        <div class="font-weight-500">Share on Twitter</div>
                                        <div>@yourcompany</div>
                                    </div>
                                </div>
                                <div class="fl-x fl-a-s mt-2 ml-n2">
                                    <toggle-button class="mt-n1"/>
                                    <div class="text-fs-0">
                                        <div class="font-weight-500">Share on Medium</div>
                                        <div>yourcompany.medium.com</div>
                                    </div>
                                </div>
                                <div class="fl-x fl-a-s mt-2 ml-n2">
                                    <toggle-button :enabled="false" class="mt-n1"/>
                                    <div class="text-fs-0">
                                        <div class="font-weight-500">Share on Facebook</div>
                                        <div>@yourcompany</div>
                                    </div>
                                </div>
                                <div class="lg-fl-x w-100p fl-y mt-4">
                                    <btn
                                        icon-img-size="2"
                                        class="w-100p order-2 order-lg-1 w-lg-50p mt-lg-0 mt-3"
                                        design="basic-f" text="Cancel"/>
                                    <btn class="w-100p order-1 order-lg-2 w-lg-50p ml-lg-2" text="Confirm"/>
                                </div>
                            </modal-header-updated>
                        </component-wrapper>
                    </div>
                </div>
            </div>
            <h3 class="mt-4">SCSS Variables</h3>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>

        </div>

    </div>
</template>

<script>
// import DemoMixin from '../../views/DemoMixin';
import PropTable from '../../views/docs/docs-components/Props';
import Modal from '../../containers/Modal';
import Button from '../../components/Button';
import codeStore from './codes/modals.json';
import ComponentWrapper from '../../components/doc-items/ComponentWrapper';
import CodeContainer from '../../components/doc-items/CodeContainer';
import ModalHeaderUpdated from '../../containers/ModalHeaderUpdated';
import IconImg from '../../components/IconImage';
import crossIcon from '../../assets/images/icons/modal/icons8-cross-24.png';
import tickIcon from '../../assets/images/icons/modal/tick.png';
import iconCopy from '../../assets/images/icons/icons8-copy-24.png';
import mailIcon from '../../assets/images/icons/icons8-mail-50.png';
import topImg from '../../assets/img/banner/banners/photo-1619181018186-b57e024d7162.jpg';
import ValidatedCheckbox from '../../components/forms/FormCheckbox';
import CustomHEl from '../../containers/CustomHeightContainer';
import ToggleButton from '../../dashboard-components/toggle-button/ToggleButton';

export default {
    // extends: DemoMixin,
    metaInfo: {
        path: 'containers/modal/',
        title: 'Modal'
    },
    data () {
        return {
            props: [
                ['noCloseOnBackdrop', 'Boolean', 'Prevent closing the modal on clicking the backdrop'],
                ['noCloseOnEsc', 'Boolean', 'Prevent closing the modal when esc key is pressed'],
                ['width', 'String', 'Width of the modal. It can be any width from width helpers. Please refer width docs for complete list.', ['w-20r', 'w-30r', 'w-20p', 'etc']],

                ['title', 'String', 'The title of the modal'],
                ['enterAnimation', 'String', 'The animation which is to used when showing the modal.'],
                ['exitAnimation', 'String', 'The animation which is to used when hiding the modal.'],

                ['color', 'String', 'The total color of the modal. Uses the bg-* classes.', ['Refer color helpers for the complete list.']],
                ['headerColor', 'String', 'The header section color of  the modal. Uses the bg-* classes.', ['Refer color helpers for the complete list.']],
                ['footerColor', 'String', 'The footer section color of  the modal. Uses the bg-* classes.', ['Refer color helpers for the complete list.']],
                ['bodyColor', 'String', 'The body section color of  the modal. Uses the bg-* classes.', ['Refer color helpers for the complete list.']]
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--modal-backdrop-color',
                    description: 'Background Color of modal backdrop',
                    defaultvalue: 'rgba(0, 0, 0, .4)'
                },
                {
                    slno: '2',
                    name: '--modal-border-radius',
                    description: 'Modal border radius',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '3',
                    name: '--modal-padding',
                    description: 'Modal Padding',
                    defaultvalue: '1rem'
                },
                {
                    slno: '4',
                    name: 'modal-width',
                    description: 'Modal width',
                    defaultvalue: '60rem'
                }
            ],
            codeStore: codeStore,
            crossIcon: crossIcon,
            tickIcon: tickIcon,
            topImg: topImg,
            iconCopy: iconCopy,
            mailIcon: mailIcon
        };
    },
    components: {
        ModalHeaderUpdated,
        CodeContainer,
        Button,
        PropTable,
        Modal,
        ComponentWrapper,
        IconImg,
        ValidatedCheckbox,
        CustomHEl,
        ToggleButton
    }
};
</script>
