<template>
    <page-template class="p-5">
<!--        <component-wrapper :code="gridSystemCode" class="text-primary">-->
            <div class="row">
                <div class="col-12 m-0 p-0">
                    <h4 class="fs-1 mb-4">Grid System</h4>
                </div>
                <div class="col-1 bg-primary">col-1</div>
            </div>
            <div class="row mt-3">
                <div class="col-2 bg-primary">col-2</div>

            </div>
            <div class="row mt-3">
                <div class="col-3 bg-primary">col-3</div>

            </div>
            <div class="row mt-3">
                <div class="col-4 bg-primary">col-4</div>

            </div>
            <div class="row mt-3">
                <div class="col-5 bg-primary">col-5</div>
            </div>
            <div class="row mt-3">
                <div class="col-6 bg-primary">col-6</div>
            </div>
            <div class="row mt-3">
                <div class="col-7 bg-primary">col-7</div>
            </div>
            <div class="row mt-3">
                <div class="col-8 bg-primary">col-8</div>
            </div>
            <div class="row mt-3">
                <div class="col-9 bg-primary">col-9</div>
            </div>
            <div class="row mt-3">
                <div class="col-10 bg-primary">col-10</div>
            </div>
            <div class="row mt-3">
                <div class="col-11 bg-primary">col-11</div>
            </div>
            <div class="row mt-3">
                <div class="col-12 bg-primary">col-12</div>
            </div>
        <code-container :code-str="gridSystemCode" show-code-no-toggle/>
<!--        </component-wrapper>-->
        <grid-system-responsive-two-column></grid-system-responsive-two-column>
        <grid-system-responsive-three-column></grid-system-responsive-three-column>
        <grid-system-responsive-four-column></grid-system-responsive-four-column>
        <component-wrapper :code="codeStore.singleColumn.code">
            <div class="row mt-4">
                <div class="col-12 m-0 p-0">
                    <h4 class="fs-1 mb-4">Responsive Grid System ( Single Column Column )</h4>
                </div>
                <div class="col-12">
                    <div class="p-3 b-1-primary bg-primary">col-12</div>
                </div>
                <div class="col-12">
                    <div class="p-3 b-1-primary bg-primary">col-12
                    </div>
                </div>
                <div class="col-12">
                    <div class="p-3 b-1-primary bg-primary">col-12</div>
                </div>
            </div>
        </component-wrapper>
    </page-template>
</template>

<script>
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';
import GridSystemResponsiveFourColumn from './grid-responsive/GridSystemResponsiveFourColumn';
import GridSystemResponsiveThreeColumn from './grid-responsive/GridSystemResponsiveThreeColumn';
import GridSystemResponsiveTwoColumn from './grid-responsive/GridSystemResponsiveTwoColumn';
import codeStore from './code-store/grid-system.json';
import helpersMixin from '../helpersMixin';

export default {
    name: 'GridSystem',
    extends: helpersMixin,
    metaInfo: {
        path: 'design-system/grid/',
        title: 'Grid System'
    },
    components: {
        ComponentWrapper,
        GridSystemResponsiveTwoColumn,
        GridSystemResponsiveThreeColumn,
        GridSystemResponsiveFourColumn,
        CodeContainer
    },
    data () {
        return {
            gridSystemCode: codeStore.columnSizes.code,
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
