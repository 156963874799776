<template>
    <div class="tag-item-plain" :class="[marginClasses, containerDefaultClasses]">
        <div class="tag-item-plain-bullet" :class="['bg-' + bulletColor]"></div>
        <text-el class="ml-1 m-0" :font-weight="textFontWeight" :color="textColor" :fs="textFontSize"
                 :additional-classes="textClasses">{{ text }}
        </text-el>
    </div>
</template>
<script>
import TextEl from '../../../components/typography/TextElement';
import marginsAndPaddingsMixin from '../../../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'TagItemPlainWithBullet',
    components: { TextEl },
    props: {
        text: { type: String, required: true },
        textColor: { type: String, default: '' },
        textFontSize: { type: String, default: '1' },
        textFontWeight: { type: String, default: '500' },
        textClasses: { type: String, default: '' },
        border: { type: String, default: '1' },
        borderColor: { type: String, default: '' },
        borderRadius: { type: String, default: '2' },
        shadow: { type: String, default: '' },
        bgColor: { type: String, default: '' },
        bulletColor: { type: String, default: 'primary' },
        containerClasses: { type: [String, Array], default: '' }
    },
    computed: {
        containerDefaultClasses () {
            const classNames = [];
            if (this.border) {
                classNames.push('b-' + this.border);
            }
            if (this.borderRadius) {
                classNames.push('round-' + this.borderRadius);
            }
            if (this.borderColor) {
                classNames.push('b-' + this.borderColor);
            }
            if (this.shadow) {
                classNames.push('bs-' + this.shadow);
            }
            if (this.bgColor) {
                classNames.push('bg-' + this.bgColor);
            }
            return classNames;
        }
    },
    mixins: [marginsAndPaddingsMixin]
};
</script>

<style scoped>

</style>
