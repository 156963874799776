<template>
    <page-template class=" bg-2 ">
        <h1>Pagination</h1>
        <h4 class="mt-4">Usage</h4>
        <p>Import Component on plugins/lego.js</p>
        <p>import Pagination from 'lego-framework/src/dashboard-components/paginations/design-1/PaginationDesign1';</p>
        <p>import Pagination from 'lego-framework/src/dashboard-components/paginations/design-2/PaginationDesign2';</p>
        <p>import Pagination from 'lego-framework/src/dashboard-components/paginations/design-3/PaginationDesign3';</p>
        <p>import Pagination from 'lego-framework/src/dashboard-components/paginations/design-4/PaginationDesign4';</p>
        <code-container show-code-no-toggle :code-str="codeStore.design1.code"/>
        <div>
            <ul class="pagination justify-content-center pagination-sm">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>
        <code-container show-code-no-toggle :code-str="codeStore.design2.code"/>
        <div class="mt-4">
            <ul class="pagination justify-content-center pagination-sm pagination-separated">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>
        <code-container show-code-no-toggle :code-str="codeStore.design3.code"/>
        <div class="mt-4">
            <ul class="pagination justify-content-center pagination-sm pagination-circle">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>
        <code-container show-code-no-toggle :code-str="codeStore.design4.code"/>
        <div class="mt-4">
            <ul class="pagination justify-content-center pagination-sm pagination-plain">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>
        <code-container show-code-no-toggle :code-str="codeStore.design5.code"/>
        <div class="mt-4">
            <ul class="pagination justify-content-center pagination-sm pagination-plain pagination-circle">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>
        <code-container show-code-no-toggle :code-str="codeStore.design6.code"/>
        <div class="mt-4">
            <ul class="pagination justify-content-center pagination-sm pagination-plain pagination-circle">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>
        <code-container show-code-no-toggle :code-str="codeStore.design7.code"/>
        <div class="mt-4">
            <ul class="pagination justify-content-center pagination-sm pagination-plain pagination-separated">
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-left"></i></span></a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">1</a></li>
                <li class="active page-item"><a href="javascript:;" class="page-link">2</a></li>
                <li class="page-item"><a href="javascript:;" class="page-link">3</a></li>
                <li class="disabled page-item"><a href="javascript:;" class="page-link"><span><i
                    class="fa fa-chevron-right"></i></span></a></li>
            </ul>
        </div>

        <div class="mt-4">
            <h5 class="my-3">Pagination Type 1</h5>
            <h6 class="my-3">design: basic-a</h6>
            <pagination-design1 design="basic-a"/>
            <code-container show-code-no-toggle :code-str="codeStore.design8.code"/>
        </div>
        <div class="mt-4">
            <h6 class="my-3">design: basic-b</h6>
            <pagination-design1 design="basic-b"/>
            <code-container show-code-no-toggle :code-str="codeStore.design9.code"/>
        </div>
        <div class="mt-4">
            <h5 class="my-3">Pagination Type 2</h5>
            <h6 class="my-3">design: basic-a</h6>
            <pagination-design2 design="basic-a"/>
            <code-container show-code-no-toggle :code-str="codeStore.design10.code"/>
        </div>
        <div class="mt-4">
            <h6 class="my-3">design: basic-b</h6>
            <pagination-design2 design="basic-b"/>
            <code-container show-code-no-toggle :code-str="codeStore.design11.code"/>
        </div>
        <div class="mt-4">
            <h5 class="my-3">Pagination Type 3</h5>
            <pagination-design3/>
            <code-container show-code-no-toggle :code-str="codeStore.design12.code"/>
        </div>
        <div class="mt-4">
            <h5 class="my-3">Pagination Type 4</h5>
            <pagination-design4/>
            <code-container show-code-no-toggle :code-str="codeStore.design13.code"/>
        </div>
        <div class="mt-4">
            <h3>Pagination</h3>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>
    </page-template>
</template>
{
"data": {
"success": "true",
"error": "false"
}
}
<script>

import PaginationDesign1 from 'lego-framework/src/dashboard-components/paginations/design-1/PaginationDesign1';
import PaginationDesign2 from 'lego-framework/src/dashboard-components/paginations/design-2/PaginationDesign2';
import PaginationDesign3 from 'lego-framework/src/dashboard-components/paginations/design-3/PaginationDesign3';
import PaginationDesign4 from 'lego-framework/src/dashboard-components/paginations/design-4/PaginationDesign4';
import codeStore from '../code-storage/pagination.json';
import CodeContainer from '../../components/doc-items/CodeContainer';

export default {
    components: { CodeContainer, PaginationDesign4, PaginationDesign3, PaginationDesign2, PaginationDesign1 },
    data () {
        return {
            codeStore: codeStore,
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: 'pagination-border-width',
                    description: 'Pagination border width',
                    defaultvalue: '1px'
                },
                {
                    slno: '2',
                    name: '--pagination-color',
                    description: 'Pagination color',
                    defaultvalue: 'var(--link-color) = var(--color-primary)'
                },
                {
                    slno: '3',
                    name: '--pagination-bg',
                    description: 'Pagination Bg',
                    defaultvalue: 'var(--body-bg-color) = var(--bg-1) = #EEEEEE'
                },
                {
                    slno: '4',
                    name: '--pagination-border-color',
                    description: 'Pagination Border color',
                    defaultvalue: 'var(--color-gray-300) = #dee2e6'
                },
                {
                    slno: '5',
                    name: '--pagination-hover-color',
                    description: 'Pagination Hover color',
                    defaultvalue: 'var(--link-hover-color) = var(--color-primary-600)'
                },
                {
                    slno: '6',
                    name: '--pagination-focus-outline',
                    description: '',
                    defaultvalue: '0'
                },
                {
                    slno: '7',
                    name: '--pagination-focus-box-shadow',
                    description: '',
                    defaultvalue: ''
                },
                {
                    slno: '8',
                    name: '--pagination-active-color',
                    description: 'active state color',
                    defaultvalue: 'var(--color-white) = #FFFFFF'
                },
                {
                    slno: '9',
                    name: '--pagination-active-bg',
                    description: '',
                    defaultvalue: 'var(--color-primary)'
                },
                {
                    slno: '10',
                    name: '--pagination-active-border-color',
                    description: '',
                    defaultvalue: 'var(--color-primary)'
                },
                {
                    slno: '11',
                    name: '--pagination-disabled-color',
                    description: '',
                    defaultvalue: 'var(--color-gray-600) = #6c757d'
                },
                {
                    slno: '12',
                    name: '--pagination-disabled-bg',
                    description: '',
                    defaultvalue: 'var(--color-white) - #FFFFFF'
                },
                {
                    slno: '13',
                    name: '--pagination-disabled-border-color',
                    description: '',
                    defaultvalue: 'var(--color-gray-300) = #dee2e6'
                }
            ]
        };
    },
    metaInfo: {
        title: 'Paginations Demo Page',
        path: 'components/paginations/'
    },
    name: 'Pagination'
};
</script>

<style lang="scss">

</style>
