<template>
    <page-template>
        <div class="container-fluid bg-2 py-3">

            <div class="mb-5">
                <h2>Color Helpers</h2>
                <p class="lead">
                    Helper classes to add colors to text. All the theme colors wil be added.
                </p>
                <class-table :items="colorHelpers"/>
                <div class="bg-3 p-3">

                    <h5> CSS Color Classes Guide</h5>
                    <h5 class="pt-4">.bg-*: Use this class to set both the background color and the text color of an
                        element. The format is
                        bg-color-name.</h5>

                    <p>Example: .bg-primary sets the background to the primary color and adjusts the text color for
                        better
                        contrast.</p>
                    <h5 class="pt-4"> .bgc-*: This class sets only the background color of an element. The format is
                        bgc-color-name.</h5>

                    <h5 class="pt-4"> .text-*: Use this class to change the text color of an element. The format is
                        text-color-name.</h5>

                    <p> Example: .text-dark changes the text color to dark without affecting the background.
                    </p></div>

            </div>
            <div>
                <h3>Example</h3>
                <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                <div class="bg-primary p-3">Primary with contrasting color</div>
                <p class="text-primary p-3">Primary with contrasting color</p>
                <!--            <div class="bgc-black">Secondary with contrasting color</div>-->
            </div>

            <div class="my-5">
                <h3>Default Background Classes <small>.bg-*</small></h3>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th style="width: 10rem">Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(value, name) in theme['background-colors']" :key="value">
                        <td>.{{ name }}</td>
                        <td :class="name">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, cumque
                            quos? Repellendus sunt suscipit ut? Adipisci, alias aut
                            consequatur cumque dicta enim fugiat iure laboriosam quod repudiandae sapiente temporibus
                            veniam?
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="my-5">
                <h3>Default Text Classes <small>.text-*</small></h3>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th style="width: 10rem">Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(value, name) in theme['text-colors']" :key="value">
                        <td>.{{ name }}</td>
                        <td :class="name">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium, cumque
                            quos? Repellendus sunt suscipit ut? Adipisci, alias aut
                            consequatur cumque dicta enim fugiat iure laboriosam quod repudiandae sapiente temporibus
                            veniam?
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>

            <div class="my-5" v-for="[cls, description] in colorDemo" :key="cls">
                <h3>{{ description }} <small>.{{ cls }}*</small></h3>

                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th style="width: 10rem">Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="name in theme['key-colors']" :key="cls + name">
                        <td>.{{ cls }}{{ name }}</td>
                        <td :class="cls + name">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium,
                            cumque quos? Repellendus sunt suscipit ut? Adipisci, alias aut
                            consequatur cumque dicta enim fugiat iure laboriosam quod repudiandae sapiente temporibus
                            veniam?
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="bg-3 p-3">
                <h4>Background and Text Classes Guide</h4>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold">.bg-primary :-</span>This class sets a primary
                    color as the background. It is typically used for main
                    elements or calls to action, ensuring strong visibility and focus.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold">.bg-secondary :-</span>
                    This class applies a secondary color as the background. It’s useful for less prominent
                    elements that still need to stand out, often used to complement the primary color.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold"> .bg-third :-</span>
                    This class sets a third color as the background. It can be used for elements that
                    require differentiation from both primary and secondary colors, ideal for additional highlights or
                    sections.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold"> .bg-danger :-</span>
                    This class applies a color that signifies danger or warnings. It’s often used for
                    alerts or important notices, drawing immediate attention.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold">.bg-info :-</span>
                    This class sets an informational background color. It is typically used for messages or
                    elements that provide additional context or information.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold">.bg-danger:-</span>
                    Applies a background color that signifies danger or critical warnings. Use this for alerts or
                    notifications that require immediate attention.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold">.bg-warning:-</span>
                    Sets a background color that indicates caution or a warning. This class is suitable for
                    messages that require users to be alert but aren’t necessarily critical.</p>
                <p class="pt-4"><span class="text-fs-2 font-weight-bold">.bg-success</span>
                    Applies a background color that signifies success or positive feedback. This class is often
                    used for notifications or messages that indicate a successful action or outcome.</p>
            </div>
            <div>
                <code-container show-code-no-toggle :code-str="codeStore.default1.code"/>
                <div class="bg-warning">
                    <p class="pt-4 text-2">Sets a background color that indicates caution or a warning. This class is
                        suitable for
                        messages that require users to be alert but aren’t necessarily critical.</p>
                </div>
            </div>
            <div>
                <div>
                    <h3 class="mt-5">Warning: SCSS Variables</h3>

                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>
                <div class="mt-4">
                    <h3>Success: SCSS Variables</h3>

                    <simple-table :fields="fields1" :data="data1" class="b-1"></simple-table>
                </div>
                <div class="mt-4">
                    <h3>Danger and Error: SCSS Variables</h3>
                    <simple-table :fields="fields2" :data="data2" class="b-1"></simple-table>

                </div>
            </div>
            <col-12 class="mt-4">
                <h3>Background & Text</h3>
                <simple-table :fields="fields6" :data="data6" class="b-1"></simple-table>
            </col-12>

        </div>
    </page-template>
</template>

<script>
import ClassTable from 'lego-framework/src/views/docs/docs-components/ClassTable';
import helpersMixin from '../../views/helpersMixin';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/color.json';
// import CustomHEl from '../../containers/CustomHeightContainer';

export default {
    components: { ClassTable, CodeContainer },
    data () {
        return {
            fields6: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data6: [
                {
                    slno: '1',
                    name: '--color-primary-contrast Format: ' +
                        ' var(--color-#{$color}-contrast)',
                    description: 'Format: ' +
                        'Contrast color for primary,for example white is set as contrast color for a dark primary color ' +
                        'Setting on theme.json ' +
                        '<br>"primary": {' +
                        '    "color": "#0A821A",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '},</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '2',
                    name: '--color-secondary-contrast ' +
                        'Format: same as above',
                    description: 'Setting on theme.json: ' +
                        '<br>"secondary": {' +
                        '    "color": "#4AB058",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '},</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '3',
                    name: '--color-third-contrast ' +
                        'Format: same as above',
                    description: 'Setting on theme.json: ' +
                        '<br>"third": {' +
                        '    "color": "#aee80e",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '},</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '4',
                    name: 'similar variants can be added on theme.json in the same format if needed (eg:fourth,fifth..etc.)',
                    description: '',
                    defaultvalue: ''
                },
                {
                    slno: '5',
                    name: '--color-primary-hover',
                    description: 'Hover color for primary.  used for setting hover effects. for example on hovering a button its background color can be changed using this ' +
                        'Setting on theme.json : ' +
                        '<br>"primary": {' +
                        '    "color": "#0A821A",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '}</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '6',
                    name: '--color-primary-hover',
                    description: 'Active color for primary. used for setting active states. Setting on theme.json ' +
                        '<br>"primary": {' +
                        '    "color": "#0A821A",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '}</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                }
            ],
            codeStore: codeStore,
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--form-control-color',
                    description: '',
                    defaultvalue: 'var(--color-warning)'
                },
                {
                    slno: '2',
                    name: '--form-control-border-color',
                    description: '',
                    defaultvalue: 'var(--color-warning)'
                },
                {
                    slno: '3',
                    name: '--form-control-border-color-focus',
                    description: '',
                    defaultvalue: 'var(--color-warning)'
                }
            ],
            fields1: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data1: [
                {
                    slno: '1',
                    name: '--form-control-color',
                    description: '',
                    defaultvalue: 'var(--color-success)'
                },
                {
                    slno: '2',
                    name: '--form-control-border-color',
                    description: '',
                    defaultvalue: 'var(--color-success)'
                },
                {
                    slno: '3',
                    name: '--form-control-border-color-focus',
                    description: '',
                    defaultvalue: 'var(--color-success)'
                }
            ],
            fields2: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data2: [
                {
                    slno: '1',
                    name: '--form-control-color',
                    description: '',
                    defaultvalue: 'var(--color-danger)'
                },
                {
                    slno: '2',
                    name: '--form-control-border-color-focus',
                    description: '',
                    defaultvalue: 'var(--color-danger)'
                },
                {
                    slno: '3',
                    name: '--form-control-border-color',
                    description: '',
                    defaultvalue: 'var(--color-danger)'
                }
            ]
        };
    },
    extends: helpersMixin,
    metaInfo: {
        path: 'helpers/colors/',
        title: 'Colors'
    },
    name: 'colors.scss'
};
</script>
