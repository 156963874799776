<template>
    <div>
        <label :class="[paddingClasses, marginClasses]" class="fl-x-cc drop-zone mu-p b-1 w-100 text-2 round-2" ref="cFileUpload" @dragover.prevent @drop.prevent>
            <validated-file-input class="c-input-upload" label="File Upload" type="file"
                                  placeholder="End Date" :value="files" @input="getFileName"></validated-file-input>
            <!--            <div class="c-placeholder">{{ filename }}</div>-->
            <div @drop="dragFile" class="w-100 fl-y fl-a-c">
                <slot name="upload-icon">
                    <custom-h-el width="2.5rem" height="2.5rem" class="b-2 round-1 fl-x-cc">
                        <icon-img :image="uploadIcon" size="4"/>
                    </custom-h-el>
                </slot>
                <div class="mt-3 text-fs-1">
                    <span class="text-primary" :class="[clickToUploadClasses]">Click to Upload</span> or drag and drop
                </div>
                <div class="text-fs-1 mt-1" :class="[fileTypesClasses]">
                    <slot name="file-types">
                        <text-el :fs="fileTypesFontSize">{{fileTypes}}</text-el>
                    </slot>
                </div>
                <div v-if="files.length" class="mt-2">
                    <ul v-for="file in files" :key="file">
                        <li>{{ file.name }}</li>
                    </ul>
                </div>
            </div>
        </label>
        <!--        file items-->
        <file-item :file-name="files[0]? files[0].name:'sample-file'"
                   :file-size="getFileSize(files[0]? files[0].size:0)"
                   :file-extension="getFileExtention(files[0]?files[0].name:'')"
                   :status="status" class="mt-3"
                   v-if="status !=='' && design ==='basic-a' && showFileItem"/>
        <file-item-type2 class="my-3"
                         :status="status"
                         :file-name="files[0]? files[0].name:'sample-file'"
                         :file-size="getFileSize(files[0]? files[0].size:0)"
                         :file-extension="getFileExtention(files[0]?files[0].name:'')"
                         v-if="status !=='' && design ==='basic-b' && showFileItem"/>
    </div>
</template>

<script>
import uploadIcon from './assets/icons8-upload-to-cloud-24.png';
import CustomHEl from '../../containers/CustomHeightContainer';
import FileItem from './FileItem';
import FileItemType2 from './FileItemType2';
import ValidatedFileInput from '../../forms/FormFileInput';
import IconImg from '../../components/IconImage';
import TextEl from '../../components/typography/TextElement';
import marginsAndPaddingsMixin from '../../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'DragAndDropFile',
    components: { FileItemType2, CustomHEl, FileItem, ValidatedFileInput, IconImg, TextEl },
    props: {
        label: { type: String, default: '' },
        fileTypes: { type: String, default: 'SVG, PNG, JPG or GIF (max. 800x400px)' },
        design: { type: String, default: 'basic-a' },
        showFileItem: { type: Boolean, default: true },
        clickToUploadClasses: { type: [String, Array], default: '' },
        fileTypesClasses: { type: [String, Array], default: '' },
        fileTypesFontSize: { type: String, default: '1' },
        p: { type: String, default: '6' }
    },
    mixins: [marginsAndPaddingsMixin],
    data () {
        return {
            files: [],
            uploadIcon: uploadIcon,
            filename: null,
            status: ''
        };
    },
    methods: {
        uploadFile (e) {
            this.files = e.target.files;
        },
        dragFile (e) {
            this.status = 'ongoing';
            this.files = e.dataTransfer.files;
            this.status = 'done';
            this.$emit('getFiles', this.files);
        },
        getFileName (event) {
            this.status = 'ongoing';
            // alert(event);
            const valueEl = document.querySelector('.c-input-upload input').files;
            this.files = valueEl;
            this.status = 'done';
            this.$emit('getFiles', this.files);
            // const el = '\\';
            // const lastIdx = valueEl.lastIndexOf(el);
            // console.log('last idx', lastIdx);
            // console.log('filename', valueEl.slice(lastIdx + 1));
            // this.filename = valueEl.slice(lastIdx + 1);
        },
        getFileExtention (filename) {
            if (filename.slice(-5)[0] === '.') {
                // getting extentions wth 4 letters
                return filename.slice(-4, filename.length);
            }
            return filename.slice(-3, filename.length);
        },
        getFileSize (fileSize) {
            if (String(fileSize).length === 6) {
                const fileSizeInUnit = fileSize / 1024;
                return fileSizeInUnit.toFixed(2) + 'KB';
            } else if (String(fileSize).length === 7) {
                const fileSizeInUnit = fileSize / (1024 * 1024);
                return fileSizeInUnit.toFixed(2) + 'MB';
            }
            return 0;
        }
    }
};
</script>

<style scoped lang="scss">
</style>
