<template>
    <component @click="$emit('click')" :is="tag" class="lh-1"
               :class="[ typographyClassNames, additionalClasses, marginClasses, paddingClasses,{'mu-p': cursorPointer}]">
        <slot>{{ text }}</slot>
    </component>
</template>

<script>
import marginsAndPaddingsMixin from '../../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'TextElement',
    props:
        {
            text: {
                type: String,
                default:
                    'sample text'
            },
            tag: {
                type: String,
                default:
                    'p'
            },
            align: {
                type: String,
                default:
                    ''
            },
            mdAlign: {
                type: String,
                default:
                    ''
            },
            lgAlign: {
                type: String,
                default:
                    ''
            },
            fontWeight: {
                type: String,
                default:
                    ''
            },
            fs: {
                type: String,
                default:
                    ''
            },
            color: {
                type: String,
                default:
                    ''
            },
            // ref                : { type : String, default : '' },
            textWrap: {
                type: Boolean,
                default:
                    false
            },
            textNoWrap: {
                type: Boolean,
                default:
                    false
            },
            textTruncate: {
                type: Boolean,
                default:
                    false
            },
            textLowercase: {
                type: Boolean,
                default:
                    false
            },
            textUppercase: {
                type: Boolean,
                default:
                    false
            },
            textCapitalize: {
                type: Boolean,
                default:
                    false
            },
            textMuted: {
                type: Boolean,
                default:
                    false
            },
            textDecorationNone: {
                type: Boolean,
                default:
                    false
            },
            textBreak: {
                type: Boolean,
                default:
                    false
            },
            textReset: {
                type: Boolean,
                default:
                    false
            },
            textHide: {
                type: Boolean,
                default:
                    false
            },
            noMargin: {
                type: Boolean,
                default:
                    false
            },
            cursorPointer: {
                type: Boolean,
                default:
                    false
            },
            additionalClasses: {
                type: [String, Array],
                default:
                    ''
            }
        },
    data () {
        return {
            cssProperties: {
                textAlign: {
                    center: 'text-center',
                    left: 'text-left',
                    right: 'text-right',
                    justify: 'text-justify',
                    c: 'text-center',
                    l: 'text-left',
                    r: 'text-right',
                    j: 'text-justify'
                },
                fontWeight: {
                    light: 'font-weight-light',
                    lighter: 'font-weight-lighter',
                    normal: 'font-weight-normal',
                    bold: 'font-weight-bold',
                    bolder: 'font-weight-bolder'
                }
            }
        };
    },
    computed: {
        typographyClassNames () {
            const typographyClassNames = [];
            if (this.align) {
                typographyClassNames.push(this.cssProperties.textAlign[this.align]);
            }
            if (this.mdAlign) {
                const propertyValue = this.cssProperties.textAlign[this.mdAlign];
                typographyClassNames.push('text-md-' + propertyValue.slice(5, propertyValue.length));
            }
            if (this.lgAlign) {
                const propertyValue = this.cssProperties.textAlign[this.lgAlign];
                typographyClassNames.push('text-lg-' + propertyValue.slice(5, propertyValue.length));
            }
            if (this.fontWeight) {
                // typographyClassNames.push(this.cssProperties.fontWeight[this.fontWeight]);
                typographyClassNames.push('font-weight-' + this.fontWeight);
            }
            if (this.color) {
                typographyClassNames.push('text-' + this.color);
            }
            if (this.textMuted) {
                typographyClassNames.push('text-muted');
            }
            if (this.textHide) {
                typographyClassNames.push('text-hide');
            }
            if (this.textDecorationNone) {
                typographyClassNames.push('text-decoration-none');
            }
            if (this.textWrap) {
                typographyClassNames.push('text-wrap');
            }
            if (this.textNoWrap) {
                typographyClassNames.push('text-nowrap');
            }
            if (this.textTruncate) {
                typographyClassNames.push('text-truncate');
            }
            if (this.textLowercase) {
                typographyClassNames.push('text-lowercase');
            }
            if (this.textUppercase) {
                typographyClassNames.push('text-uppercase');
            }
            if (this.textCapitalize) {
                typographyClassNames.push('text-capitalize');
            }
            if (this.textBreak) {
                typographyClassNames.push('text-break');
            }
            if (this.textReset) {
                typographyClassNames.push('text-reset');
            }
            if (this.noMargin) {
                typographyClassNames.push('m-0');
            }
            if (this.fs && !this.fs.includes('x')) {
                typographyClassNames.push('text-fs-' + this.fs);
            } else if (['2x', '3x', '4x', '5x'].includes(this.fs)) {
                typographyClassNames.push('text-' + this.fs);
            }
            return typographyClassNames;
        }
    },
    mixins: [marginsAndPaddingsMixin]

}
;
</script>

<style scoped>

</style>
