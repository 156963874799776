<template>
    <div class="pos-r b-1 p-2 round-1 fl-x fl-te-t">

        <code ref="code" class="py-3">
            <slot name="code"></slot>
        </code>
        <textarea
            class="cv-code-snippet__clippy"
            style="position: absolute; left: -9999px; max-width: 0; opacity: 0; overflow: hidden;"
            aria-hidden="true"
            ref="clippy">
        </textarea>
        <div class="fl-x fl-j-e">
            <button @click="onCopyCode" class="px-1 py-1 mb-3 text-fs-0 mu-p round-1 bg-primary-h b-1 cursor-pointer mr-3"><span class="">Copy</span><i class="fa fa-copy ml-1"></i>
            </button>
            <div ref="copiedMsg" class="pos-a copied-msg ml-n4 text-fs-0"><i class="fa fa-check color-primary"></i></div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        onCopyCode () {
            // copy to clipboard
            this.$refs.clippy.value = this.$refs.code.innerText;
            this.$refs.clippy.select();
            document.execCommand('copy');
            // show notification
            this.$refs.copiedMsg.classList.add('show');
            setTimeout(() => {
                this.$refs.copiedMsg.classList.remove('show');
            }, 800);
        }
    }

};
</script>

<style scoped lang="scss">
    .copied-msg {
        right: 5.562rem;
        /*top: -0.06125rem;-->*/
        top: 0.7rem;
        z-index: 4;
        padding: 0.1rem 0.4rem;
        background: var(--color-primary);
        color: #fafafa;
        border-radius: 0.5rem;
        visibility: hidden;
        transition: all ease-in-out 500ms;
        //transform: translateX(-100%);

        &.show {
            //transform: translateX(25%);
            //left: 4.062rem;
            visibility: visible;
        }
    }
</style>
