<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Listing Card Items</h2>
                    <p class="lead">Listing card items in dashboards</p>
                    <h4 class="my-4">Usage</h4>
                    <p class="lead">import ListingCardItem1 from 'lego-framework/src/dashboard-components/crud-items/listing-card/design-1/ListingCardDesign1.vue'; </p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4">
                    <listing-card-item/>
                    <code-container show-code-no-toggle :code-str="codeStore.design1.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import ListingCardItem from '../../dashboard-components/crud-items/listing-card/design-1/ListingCardDesign1';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from './code-storage/listing-card-items.json';
import PropTable from '../docs-components/Props';

export default {
    name: 'ListingCardItemsDocs',
    metaInfo: {
        title: 'Listing Cards',
        path: 'dashboard-components/listing-cards-docs/'
    },
    components: { CodeContainer, ListingCardItem, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['color', 'String', 'Base color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['title', 'String', 'Title Text', '', 'Income Tax May 2024'],
                ['body', 'String', 'Body content text', '', ''],
                ['titleClasses', 'String', 'additional classes adding to title', '', ''],
                ['bodyMinHeight', 'Boolean', 'Minimum height of the body section', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
