<template>
    <div>
        <progress-steps-design2 :items="items"/>
        <div class="btn-group mt-5">
            <btn size="sm" text="1st step" @click="statusChange(1)"/>
            <btn size="sm" text="2nd step" @click="statusChange(2)"/>
            <btn size="sm" text="3rd step" @click="statusChange(3)"/>
            <btn size="sm" text="4th step" @click="statusChange(4)"/>
        </div>
    </div>
</template>

<script>
import ProgressStepsDesign2 from '../../progress-steps-updated/design-3/ProgressStepsDesign3';
import icon1 from '../../../assets/web/icons/progress-steps/icon-user.svg';
import icon2 from '../../../assets/web/icons/progress-steps/flag-05.svg';
import icon3 from '../../../assets/web/icons/progress-steps/users-plus.svg';
import icon4 from '../../../assets/web/icons/progress-steps/stars-02.svg';

export default {
    name: 'ProgressSteps2Usage',
    components: { ProgressStepsDesign2 },
    data () {
        return {
            items: [
                {
                    icon: icon1,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon2,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon3,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon4,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                }
            ]
        };
    },
    methods: {
        statusChange (idx) {
            this.items[idx - 1].status = 'done';
        }
    }
};
</script>

<style scoped>

</style>
