<template>
    <page-template>
        <div class="container-fluid py-4 bg-2">
            <h2>Flexbox Alignment Helpers</h2>
            <div class="bg-info card my-3">
                <b>Note</b> Please set height on the parent element to get the full result. If the height of the parent
                is
                set to auto, then
                vertical alignments wont have any effect.
            </div>

            <table class="table table-bordered">
                <thead>
                <tr>
                    <th style="width: 10rem">Class Name</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="[cls, code, description] in flexAtomicHelpers" :key="cls">
                    <td>.{{ cls }}</td>
                    <td>
                        <b>{{ code }}</b>
                        {{ description }}
                    </td>
                </tr>

                <tr v-for="[cls, description] in flexBasicHelpers" :key="cls">
                    <td>.{{ cls }}</td>
                    <td>{{ description }}</td>
                </tr>
                <tr v-for="[cls, description] in flexAlignments" :key="cls">
                    <td>.{{ cls }}</td>
                    <td>{{ description }}</td>
                </tr>
                <tr v-for="[cls, description] in flexEqualAlignments" :key="cls">
                    <td>.{{ cls }}</td>
                    <td>{{ description }}</td>
                </tr>
                </tbody>
            </table>
            <div class="bg-4 p-3">
                Flexbox Utility Classes Overview
                These utility classes enable you to create flexible layouts using CSS Flexbox. Each class helps you
                control
                the direction, alignment, and spacing of child elements within a flex container.
            </div>
            <div class="bg-4 mt-4 p-3">
                <h4>Flex Direction</h4>
                <p>.fl-x: Sets display to flex and aligns child elements in a horizontal row.</p>
                <p>.fl-y: Sets display to flex and aligns child elements in a vertical column.</p>
                <h4> Alignment Classes</h4>
                <p>.fl-a-s: Aligns items to the start (top or left).</p>
                <p> .fl-a-e: Aligns items to the end (bottom or right).</p>
                <p> .fl-a-c: Centers items both horizontally and vertically.</p>
                <p> .fl-a-d: Stretches child elements to fill the available space.</p>
                <h4 class="mt-4"> Justification Classes</h4>
                <p>.fl-j-s: Justifies content to the start (left).</p>
                <p>.fl-j-e: Justifies content to the end (right).</p>
                <p>.fl-j-c: Centers content within the container.</p>
                <p>.fl-j-sa: Distributes space around items.</p>
                <p>.fl-j-se: Distributes space evenly between items.</p>
                <p>.fl-j-sb: Spaces items between each other.</p>
                <h4> Top/Bottom Alignment (Horizontal Flex)</h4>
                <p>.fl-x-t: Aligns children to the top horizontally.</p>
                <p>.fl-x-b: Aligns children to the bottom horizontally.</p>
                <p>.fl-x-c: Aligns children to the center horizontally.</p>
                <h4 class="mt-4">Edge Alignment (Horizontal Flex)</h4>
                <p>.fl-te-t: Aligns children to the top ends horizontally.</p>
                <p>.fl-te-b: Aligns children to the bottom ends horizontally.</p>
                <p>.fl-te-c: Centers children at the ends horizontally.</p>
                <h4 class="mt-4">Left/Right Alignment (Vertical Flex)</h4>
                <p>.fl-y-l: Aligns children to the left vertically.</p>
                <p>.fl-y-r: Aligns children to the right vertically.</p>
                <p>.fl-y-c: Aligns children to the center vertically.</p>
                <h4 class="mt-4"> Corner Alignment (Horizontal Flex)</h4>
                <p>.fl-x-tl: Aligns children to the top left.</p>
                <p>.fl-x-tr: Aligns children to the top right.</p>
                <p>.fl-x-tc: Aligns children to the top center.</p>
                <p>.fl-x-bl: Aligns children to the bottom left.</p>
                <p>.fl-x-br: Aligns children to the bottom right.</p>
                <p>.fl-x-bc: Aligns children to the bottom center.</p>
                <p>.fl-x-cl: Aligns children to the center left.</p>
                <p>.fl-x-cr: Aligns children to the center right.</p>
                <p>.fl-x-cc: Aligns children to the center (bottom).</p>
                <h4 class="mt-4">Corner Alignment (Vertical Flex)</h4>
                <p>.fl-y-tl: Aligns children to the top left.</p>
                <p>.fl-y-tr: Aligns children to the top right.</p>
                <p>.fl-y-tc: Aligns children to the top center.</p>
                <p>.fl-y-bl: Aligns children to the bottom left.</p>
                <p>.fl-y-br: Aligns children to the bottom right.</p>
                <p>.fl-y-bc: Aligns children to the bottom center.</p>
                <p>.fl-y-cl: Aligns children to the center left.</p>
                <p>.fl-y-cr: Aligns children to the center right.</p>
                <p>.fl-y-cc: Aligns children to the center (bottom).</p>
                <h4 class="mt-4">Equal Column and Row Classes</h4>
                <p>.fl-eq: Creates equal width columns with full height.</p>
                <p>.fl-eq-c: Equal width columns, centered.</p>
                <p>.fl-eq-t: Equal width columns, aligned at the top.</p>
                <p>.fl-eq-b: Equal width columns, aligned at the bottom.</p>
                <p>.fl-eqh: Creates equal height rows with full width.</p>
                <p>.fl-eqh-l: Equal height rows, aligned to the left.</p>
                <p>.fl-eqh-c: Equal height rows, centered.</p>
                <p>.fl-eqh-r: Equal height rows, aligned to the right.</p>
                <div class="bg-2 p-3">
                    <h4 class="mt-4">Usage Tips</h4>
                    <p>These classes allow for a highly customizable layout, making it easy to adjust the arrangement of
                        elements
                        based on the design needs. Experiment with different combinations to achieve the desired
                        layout!</p></div>
            </div>

            <div class="my-4" v-for="[cls, description] in flexAlignments" :key="cls">
                <h3>{{ description }}</h3>
                <div class="bg-info full-height p-4" :class="cls">
                    <div class="p-4 bg-2">1</div>
                    <div class="p-5 bg-2">2</div>
                    <div class="p-6 bg-2">3</div>
                </div>
            </div>

            <div class="my-4" v-for="[cls, description] in flexEqualAlignments" :key="cls">
                <h3>{{ description }}</h3>
                <div class="bg-info full-height p-4" :class="cls">
                    <div class="p-4 bg-warning">1</div>
                    <div class="p-4 bg-success">2</div>
                    <div class="p-4 bg-danger">3</div>
                </div>
            </div>

            <div class="my-4" v-for="[cls, description] in flexBasicHelpers" :key="cls">
                <h3>{{ description }}</h3>
                <div class="bg-info full-height p-4" :class="cls">
                    <div class="p-4 bg-warning">1</div>
                    <div class="p-4 bg-success">2</div>
                </div>
            </div>

        </div>
    </page-template>

</template>

<script>
import helpersMixin from '../helpersMixin';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'helpers/flex-alignments/',
        title: 'Flex Alignments'
    },
    name: 'colors.scss',
    components: {}
};
</script>
