<template>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" appear ref="transition" duration="200">
        <div class='tab' v-if='isActive' :class="containerClasses">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: { type: String, default: 'Tab' },
        icon: { type: String, default: '' },
        containerClasses: { type: [String, Array], default: '' }

    },
    data () {
        return {
            isActive: false
        };
    }
};
</script>

<style lang="css">

</style>
