<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Forms</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--form-separator',
                    description: '',
                    defaultvalue: '1rem'
                },
                {
                    slno: '2',
                    name: '--form-control-label-font-size',
                    description: '',
                    defaultvalue: '1rem * size factor = 0.875rem ( md)'
                },
                {
                    slno: '3',
                    name: '--form-control-padding-y',
                    description: '',
                    defaultvalue: 'var(--element-padding-y) = 0.325em'
                },
                {
                    slno: '4',
                    name: '--form-control-line-height',
                    description: '',
                    defaultvalue: 'var(--element-line-height) = 1.6rem'
                },
                {
                    slno: '5',
                    name: '--form-control-border-width',
                    description: '',
                    defaultvalue: 'var(--element-border-width) = var(--border-width) = 1px'
                },
                {
                    slno: '6',
                    name: '--form-control-border-color',
                    description: '',
                    defaultvalue: 'var(--element-border-color), var(--border-color)  = #E4E9F2'
                },
                {
                    slno: '7',
                    name: '--form-control-font-size',
                    description: '',
                    defaultvalue: 'var(--element-font-size) = var(--font-size-base) = 1rem'
                },
                {
                    slno: '8',
                    name: '--form-control-color',
                    description: '',
                    defaultvalue: 'Default Value : var(--body-text-color)  = var(--text-1) = #161616'
                },
                {
                    slno: '9',
                    name: '--form-control-padding-x',
                    description: '',
                    defaultvalue: 'var(--element-padding-x) = 0.75em'
                },
                {
                    slno: '10',
                    name: '--form-control-disabled-bg',
                    description: '',
                    defaultvalue: 'var(--color-gray-100) = #f8f9fa'
                },
                {
                    slno: '11',
                    name: '--form-control-border-color-focus',
                    description: '',
                    defaultvalue: 'var(--element-border-color-focus) = var(--color-primary)'
                },
                {
                    slno: '12',
                    name: '--form-control-bg-color',
                    description: '',
                    defaultvalue: 'transparent'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
