<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="mb-3">Progress Circle</h2>
                    <p class="lead">Progress circle with value and label</p>
                    <h4>Usage</h4>
                    <p>import ProgressCircle from 'lego-framework/src/web-components/progress-circle/ProgressCircle';
                    </p>
                    <p class="mb-5">import ProgressHalfCircle from 'lego-framework/src/web-components/progress-circle/ProgressHalfCircle';</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="m-1 b-1 round-2">
                        <h5 class="m-3">design: basic-a</h5>
                        <progress-circle :value="50"/>
                        <code-container show-code-no-toggle :code-str="codeStore.circle.basicA"></code-container>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="m-1 b-1 round-2">
                        <h5 class="m-3">design: basic-b</h5>
                        <progress-circle value="50" design="basic-b"/>
                        <code-container show-code-no-toggle :code-str="codeStore.circle.basicB"></code-container>
                    </div>
                </div>
            </div>
            <div class="row mt-4 b-1 round-2">
                <div class="col-12">
                    <h4 class="my-3 mb-5">Size Variants <span class="font-weight-normal">( xs, sm, md, lg, xl )</span></h4>
                </div>
                <div class="col-lg-3">
                    <h5>size: xs</h5>
                    <progress-circle size="xs" :value="75"/>
                </div>
                <div class="col-lg-3">
                    <h5>size: sm</h5>
                    <progress-circle size="sm" :value="60"/>
                </div>
                <div class="col-lg-3">
                    <h5>size: md</h5>
                    <progress-circle size="md" :value="60"/>
                </div>
                <div class="col-lg-3">
                    <h5>size: lg</h5>
                    <progress-circle size="lg" :value="60"/>
                </div>
                <div class="col-lg-3">
                    <h5>size: xl</h5>
                    <progress-circle size="xl" :value="60"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.circle.sizeVariants"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="my-3">Half Circle</h3>
                </div>
                <div class="col-lg-4">
                    <h5>half circle</h5>
                    <progress-half-circle value="75"/>
                    <code-container class="mt-6" show-code-no-toggle :code-str="codeStore.halfCircle.default"></code-container>
                </div>
            </div>
            <div class="row mt-7">
                <div class="col-12">
                    <h3>Size Variants</h3>
                </div>
                <div class="col-lg-3">
                    <h5>size - xs</h5>
                    <progress-half-circle value="75" size="xs"/>
                </div>
                <div class="col-lg-3">
                    <h5>size - sm</h5>
                    <progress-half-circle value="75" size="sm"/>
                </div>
                <div class="col-lg-3">
                    <h5>size - md</h5>
                    <progress-half-circle value="75" size="md"/>
                </div>
                <div class="col-lg-3">
                    <h5>size - lg</h5>
                    <progress-half-circle value="75" size="lg"/>
                </div>
                <div class="col-lg-3">
                    <h5>size - xl</h5>
                    <progress-half-circle value="75" size="xl"/>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.halfCircle.sizeVariants"></code-container>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <h3>Fonts Customizations</h3>
                </div>
                <div class="col-lg-6">
                    <progress-circle
                        value="68"
                        label-classes="font-weight-bold"
                        value-classes="font-weight-normal"/>
                    <code-container show-code-no-toggle :code-str="codeStore.circle.fontCustomization"></code-container>
                </div>
                <div class="col-lg-6">
                    <progress-half-circle
                        value="68"
                        label-classes="font-weight-light"
                        value-classes="font-weight-normal"/>
                    <code-container class="mt-6" show-code-no-toggle :code-str="codeStore.halfCircle.fontCustomization"></code-container>
                </div>
                 <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import ProgressCircle from 'lego-framework/src/web-components/progress-circle/ProgressCircle';
import ProgressHalfCircle from 'lego-framework/src/web-components/progress-circle/ProgressHalfCircle';
import codeStore from './code-storage/progress-circle.json';
// import CodeContainer from '../../../components/doc-items/CodeContainer';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'ProgressCircleVariantsDocs',
    metaInfo: {
        path: 'web-components/ProgressCircle/',
        title: 'ProgressCircle'
    },
    components: {
        ProgressCircle,
        ProgressHalfCircle,
        PropTable
    },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['value', 'String,Number', 'progress value', '', ''],
                ['label', 'String', 'Label Text', '', ''],
                ['color', 'String', 'Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'secondary'],
                ['size', 'String', 'Size Variant', ['xs', 'sm', 'md', 'lg', 'xl'], 'md'],
                ['design', 'String', 'Design Variant( Only on ProgressCircle component)', ['basic-a', 'basic-b'], 'basic-a'],
                ['labelClasses', 'String,Array', 'Additional class to be added to label text', '', ''],
                ['valueClasses', 'String,Array', 'Additional class to be added to Value text', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
