<template>
    <div>
        <progress-steps-design1 :items="items"/>
        <div class="btn-group mt-5">
            <btn size="sm" text="1st step" @click="statusChange(1)"/>
            <btn size="sm" text="1st step done" @click="statusChangeDone(1)"/>
            <btn size="sm" text="2nd step" @click="statusChange(2)"/>
            <btn size="sm" text="2nd step done" @click="statusChangeDone(2)"/>

            <btn size="sm" text="3rd step" @click="statusChange(3)"/>
            <btn size="sm" text="3rd step done" @click="statusChangeDone(3)"/>

            <btn size="sm" text="4th step" @click="statusChange(4)"/>
            <btn size="sm" text="4th step done" @click="statusChangeDone(4)"/>
        </div>
    </div>
</template>

<script>
import ProgressStepsDesign1 from '../../progress-steps-updated/design-1/ProgressStepsDesign1';

export default {
    name: 'ProgressSteps1Usage',
    components: { ProgressStepsDesign1 },
    data () {
        return {
            items: [
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                }
            ]
        };
    },
    methods: {
        statusChange (idx) {
            this.items[idx - 1].status = 'ongoing';
        },
        statusChangeDone (idx) {
            this.items[idx - 1].status = 'done';
        }
    }
};
</script>

<style scoped>

</style>
