<template>
    <page-template>
     <div class="bg-2 p-5">
    <div>
        <h3>Border Radius</h3>
    </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'BorderRadius',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--border-radius',
                    description: 'border radius',
                    defaultvalue: '.375rem'
                },
                {
                    slno: '2',
                    name: '--border-radius-sm',
                    description: 'border radius small',
                    defaultvalue: '.25rem'
                },
                {
                    slno: '3',
                    name: '--border-radius-lg',
                    description: 'border radius large',
                    defaultvalue: '.4375rem'
                },
                {
                    slno: '4',
                    name: '--border-radius-xl',
                    description: 'border radius extra large',
                    defaultvalue: '5rem'
                },
                {
                    slno: '5',
                    name: '--border-radius-pill',
                    description: 'border radius pill',
                    defaultvalue: '50rem'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
