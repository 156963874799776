<template>
    <component :is="tag" :class="[classNames, marginClasses, paddingClasses]">
        <slot></slot>
    </component>
</template>
<script>
import marginsAndPaddingsMixin from '../../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'col-12',
    mixins: [marginsAndPaddingsMixin],
    data () {
        return {};
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        xs: {
            type: String,
            required: ''
        },
        sm: {
            type: String,
            required: ''
        },
        md: {
            type: String,
            default: ''
        },
        lg: {
            type: String,
            default: ''
        },
        xl: {
            type: String,
            default: ''
        },
        order: {
            type: String,
            required: ''
        },
        orderXs: {
            type: String,
            required: ''
        },
        orderSm: {
            type: String,
            required: ''
        },
        orderMd: {
            type: String,
            default: ''
        },
        orderLg: {
            type: String,
            default: ''
        },
        orderXl: {
            type: String,
            default: ''
        }
    },
    computed: {
        classNames () {
            const names = ['col-12'];
            if (this.xl) {
                names.push('col-xl-' + this.xl);
            }
            if (this.lg) {
                names.push('col-lg-' + this.lg);
            }
            if (this.md) {
                names.push('col-md-' + this.md);
            }
            if (this.sm) {
                names.push('col-sm-' + this.sm);
            }
            if (this.xs) {
                names.push('col-xs-' + this.xs);
            }
            // Order Classes
            if (this.order) {
                names.push('order-' + this.order);
            }
            if (this.orderXl) {
                names.push('order-xl-' + this.orderXl);
            }
            if (this.orderLg) {
                names.push('order-lg-' + this.orderLg);
            }
            if (this.orderMd) {
                names.push('order-md-' + this.orderMd);
            }
            if (this.orderSm) {
                names.push('order-sm-' + this.orderSm);
            }
            if (this.orderXs) {
                names.push('order-xs-' + this.orderXs);
            }

            return names;
        }
    }
};
</script>
