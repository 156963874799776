<template>
    <div class="b-1 round-2">
        <div class="p-3">
            <div class="fl-te-c">
                <div class="ml-1 text-fs-0 font-weight-600">{{text1}}</div>
                <div class="b-round">
                    <icon-img :image="dotsIcon" size="4"/>
                </div>
            </div>
            <div class="fl-te-c">
                <div class="font-weight-600 text-fs-4">{{text2}}</div>
                <div class="fl-x fl-a-c">
                    <icon-img :image="arrowCurvedIcon" size="4"/>
                    <div class="text-secondary ml-1 font-weight-500">{{text3}}</div>
                    <div class="ml-1">{{text4}}</div>
                </div>
            </div>
            <div class="mt-2">
                <slot name="graph">
                    <img :src="graphIcon" class="img-fluid">
                </slot>
            </div>
        </div>
        <div class="p-2 px-3 bt-1 fl-te-c">
            <icon-img :image="settingsIcon" size="8"/>
            <div class="text-primary text-fs-1 font-weight-500">{{text5}}</div>
        </div>
    </div>
</template>

<script>
import graphIcon from './assets/graph.png';
import dotsIcon from './assets/three-dots.png';
import arrowIcon from './assets/icons8-arrow-right-16-green.png';
import arrowCurvedIcon from './assets/arrow-1.png';
import settingsIcon from './assets/settings.png';
import IconImg from '../../components/IconImage';

export default {
    name: 'MetricsSparkline1',
    components: { IconImg },
    props: {
        text1: { type: String, default: 'Views 24 hours' },
        iconImg: { type: String, default: graphIcon },
        text2: { type: String, default: '2,000' },
        text3: { type: String, default: '100%' },
        text4: { type: String, default: 'vs last month' },
        text5: { type: String, default: 'View Report' }
    },
    data () {
        return {
            dotsIcon: dotsIcon,
            arrowIcon: arrowIcon,
            arrowCurvedIcon: arrowCurvedIcon,
            settingsIcon: settingsIcon,
            graphIcon: graphIcon
        };
    }
};
</script>

<style scoped>

</style>
