<template>
    <page-template>
    <div class="bg-2 p-5">
    <div>
        <h3>Spacer</h3>
    </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Spacers',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--spacer-0',
                    description: 'spacer 0',
                    defaultvalue: '0rem'
                },
                {
                    slno: '2',
                    name: '--spacer-1',
                    description: 'spacer 1',
                    defaultvalue: '0.25rem'
                },
                {
                    slno: '3',
                    name: '--spacer-2',
                    description: 'spacer 2',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '4',
                    name: '--spacer-3',
                    description: 'spacer 3',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--spacer-4',
                    description: 'spacer 4',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '6',
                    name: '--spacer-5',
                    description: 'spacer 5',
                    defaultvalue: '3rem'
                },
                {
                    slno: '7',
                    name: '--spacer-6',
                    description: 'spacer 6',
                    defaultvalue: '5rem'
                },
                {
                    slno: '8',
                    name: '--spacer-7',
                    description: 'spacer 7',
                    defaultvalue: '7rem'
                },
                {
                    slno: '9',
                    name: '--spacer-8',
                    description: 'spacer 8',
                    defaultvalue: '9rem'
                },
                {
                    slno: '10',
                    name: '--spacer-9',
                    description: 'spacer 9',
                    defaultvalue: '12rem'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
