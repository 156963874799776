export default {
    text1: { type: 'String', default: 'Grow your users.' },
    text2: { type: 'String', default: 'Smarter.' },
    text3: { type: 'String', default: 'Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users. Trusted by over 4,000 startups.' },
    text4: { type: 'String', default: 'Enter your email' },
    text5: { type: 'String', default: 'Get started' },
    text6: { type: 'String', default: 'We care about your data in our' },
    btn1Icon: { type: 'String', default: '' },
    btn2Icon: { type: 'String', default: '' },
    videoUrl: { type: 'String', default: 'https://www.youtube.com/embed/Qmi-Xwq-MEc' },
    btn1Design: { type: 'String', default: 'basic-g' },
    btn2Design: { type: 'String', default: 'basic' },
    bgColor: { type: 'String', default: 'primary' },
    bgColorThickness: { type: 'String', default: '' },
    bgGradient: { type: 'String', default: '2' },
    text1Color: { type: 'String', default: '' },
    text1FontSize: { type: 'String', default: '4x' },
    text1FontWeight: { type: 'String', default: '700' },
    text1Classes: { type: 'String', default: '' },
    text2Color: { type: 'String', default: 'primary-200' },
    text2FontSize: { type: 'String', default: '4x' },
    text2FontWeight: { type: 'String', default: '700' },
    text2Classes: { type: 'String', default: '' },
    text3Color: { type: 'String', default: 'primary-200' },
    text3FontSize: { type: 'String', default: '2' },
    text3FontWeight: { type: 'String', default: '' },
    text3Classes: { type: 'String', default: '' },
    text4Color: { type: 'String', default: '' },
    text4FontSize: { type: 'String', default: '2' },
    text4FontWeight: { type: 'String', default: '' },
    text4Classes: { type: 'String', default: '' },
    text5Color: { type: 'String', default: '' },
    text5FontSize: { type: 'String', default: '' },
    text5FontWeight: { type: 'String', default: '' },
    text5Classes: { type: 'String', default: '' },
    text6Color: { type: 'String', default: '' },
    text6FontSize: { type: 'String', default: '' },
    text6FontWeight: { type: 'String', default: '' },
    text6Classes: { type: 'String', default: '' },
    // events
    text1Link: { type: 'String', default: '' },
    text2Link: { type: 'String', default: '' },
    text3Link: { type: 'String', default: '' },
    text4Link: { type: 'String', default: '' },
    text5Link: { type: 'String', default: '/' },
    text6Link: { type: 'String', default: '' },
    text1Clickable: { type: 'Boolean', default: false },
    text2Clickable: { type: 'Boolean', default: false },
    text3Clickable: { type: 'Boolean', default: false },
    text4Clickable: { type: 'Boolean', default: false },
    text5Clickable: { type: 'Boolean', default: false },
    text6Clickable: { type: 'Boolean', default: false }
};
