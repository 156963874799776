<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>File Upload</h2>
                    <p class="lead">File Upload drag and drop component</p>
                    <h4 class="my-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="py-3">Default</h5>
                    <drag-and-drop-file/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="py-3">design: basic-b</h5>
                    <drag-and-drop-file design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicB.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="py-3">event emit: getFiles</h5>
                    <drag-and-drop-file @getFiles="getFiles"/>
                    <code-container show-code-no-toggle :code-str="codeStore.eventEmit.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <div class="h4 my-3">Events</div>
                <p>getFiles</p>
                <code-container show-code-no-toggle :code-str="codeStore.eventEmit.code"/>
            </div>
        </div>
    </page-template>
</template>

<script>
import DragAndDropFile from '../../forms/drag-and-drop-file-upload/DragAndDropFile';
import codeStore from './code-storage/drag-and-drop.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'FileUploadComponentDocs',
    metaInfo: {
        path: 'dashboard-components/drag-and-drop-file-input/',
        title: 'Drag and Drop File Input'
    },
    components: { DragAndDropFile, CodeContainer, PropTable },
    data () {
        return {
            files: null,
            codeStore: codeStore,
            propsInfo: [
                ['fileTypes', 'String', 'allowed file types information', '', ''],
                ['design', 'String', 'design variant', ['basic-a', 'basic-b'], ''],
                ['showFileItem', 'Boolean', 'show/Hide uploaded file information card', ['basic-a', 'basic-b'], '']
            ]
        };
    },
    methods: {
        getFiles (files) {
            this.files = files;
        }
    }
};
</script>

<style scoped>

</style>
