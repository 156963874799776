<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Fonts & Typography</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--font-family-sans-serif',
                    description: 'set default font families',
                    defaultvalue: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
                },
                {
                    slno: '2',
                    name: '--font-family-monospace',
                    description: 'set monospace and related font families',
                    defaultvalue: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace'
                },
                {
                    slno: '3',
                    name: '--font-family-base',
                    description: 'assigned value of variable  --font-family-sans-serif',
                    defaultvalue: 'var(--font-family-sans-serif)'
                },
                {
                    slno: '4',
                    name: '--font-size-base',
                    description: 'base font size',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--font-size-sm',
                    description: 'Derived using base font size',
                    defaultvalue: ' calc(var(--font-size-base) * var(--sm-factor))'
                },
                {
                    slno: '6',
                    name: '--font-size-lg',
                    description: 'Derived using base font size',
                    defaultvalue: 'calc(var(--font-size-base) * var(--lg-factor))'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
