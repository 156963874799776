<template>
    <span class="tag-item text-fs-0 bg-3 d-inline-block" :class="['text-' + color]" :style="setTagStyles">
        <span class="fl-x fl-a-c">
            <slot>
                <span class="tag-item-bullet" :class="['bg-' + color]"></span>
                <span>{{ text }}</span>
            </slot>
        </span>
    </span>
</template>

<script>
export default {
    name: 'TagItemWithBullet',
    props: {
        color: { type: String, default: 'primary' },
        text: { type: String, default: 'primary' },
        borderRadius: { type: String, default: '3' }
    },
    computed: {
        setTagStyles () {
            return {
                '--tag-item-border-color': 'var(--color-' + this.color + '-200)',
                '--tag-item-bg-color': 'var(--color-' + this.color + '-100)',
                '--tag-item-border-radius': 'var(--round-' + this.borderRadius + ')'
            };
        }
    }
};
</script>

<style scoped>

</style>
