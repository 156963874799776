<template>
    <page-template>
        <div class="bg-2">
            <div class="container-fluid py-4 bg-2">
                <div>
                    <h2>Z-Index Classes</h2>
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th>Class</th>
                            <th>Description</th>
                        </tr>
                        </thead>
                        <tr v-for="(value) in classNames" :key="value.title">
                            <td>
                                {{ value.title }}
                            </td>
                            <td>{{ value.desc }}</td>
                        </tr>
                    </table>
                    <div class="bg-4 p-4">
                        <h2>Z-Index Utilities</h2>
                        <p>
                            Z-index utilities allow you to control the stacking order of elements in your layout. By
                            assigning a z-index value, you can specify how elements overlap one another. The following
                            classes are available:
                        </p>

                        <h5>.z-index--2</h5>
                        <p>Sets the z-index to -2, placing the element behind others with a higher z-index.</p>

                        <h5>.z-index--1</h5>
                        <p>Sets the z-index to -1, placing the element behind others with a higher z-index.</p>

                        <h5>.z-index-0</h5>
                        <p>Sets the z-index to 0, placing the element at the default stacking level.</p>

                        <h5>.z-index-1</h5>
                        <p>Sets the z-index to 1, placing the element above those with a lower z-index.</p>

                        <h5>.z-index-10</h5>
                        <p>Sets the z-index to 10, allowing for greater control in stacking elements.</p>

                        <h5>.z-index-md</h5>
                        <p>Sets the z-index to 699, useful for medium-level stacking contexts.</p>

                        <h5>.z-index-lg</h5>
                        <p>Sets the z-index to 999, useful for high-level stacking contexts.</p>

                        <h5>.z-index-max</h5>
                        <p>Sets the z-index to 1001, ensuring the element is on top of all others.</p>
                    </div>
                    <div class="mt-4 p-4">
                        <h2>Example</h2>

                        <code-container show-code-no-toggle :code-str="codeStore.zindex.code"/>
                        <div class="pos-r" style="height: 200px; margin-bottom: 20px; border: 1px solid #ccc;">
                            <div class="pos-abs z-index--1"
                                 style="background-color: rgba(255, 0, 0, 0.5); top: 40px; left: 20px; width: 150px; height: 100px;">
                                Z-Index -1
                            </div>
                            <div class="pos-abs z-index-0"
                                 style="background-color: rgba(0, 255, 0, 0.5); top: 20px; left: 40px; width: 150px; height: 100px;">
                                Z-Index 0
                            </div>
                            <div class="pos-abs z-index-1"
                                 style="background-color: rgba(0, 0, 255, 0.5); top: 0; left: 60px; width: 150px; height: 100px;">
                                Z-Index 1
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/z-index.json';

export default {
    name: 'zindexHelpers',
    components: { CodeContainer },
    metaInfo: {
        path: 'helpers/z-index/',
        title: 'z-index'
    },
    data () {
        return {
            classNames: [
                { title: '.z-index--2', desc: 'z-index it set to value -2.' },
                { title: '.z-index--1', desc: 'z-index it set to value -1.' },
                { title: '.z-index-0', desc: 'z-index it set to value 0.' },
                { title: '.z-index-1', desc: 'z-index it set to value 1.' },
                { title: '.z-index-10', desc: 'z-index it set to value 10.' },
                { title: '.z-index-md', desc: 'z-index it set to value 699.' },
                { title: '.z-index-lg', desc: 'z-index it set to value 999.' },
                { title: '.z-index-max', desc: 'z-index it set to value 1001.' }
            ],
            codeStore: codeStore
        };
    }
};
</script>
<style>

</style>
