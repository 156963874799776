<template>
    <page-template>
        <div class="container-fluid bg-2 py-4">
            <div>
                <h2 class="my-3">Transforms</h2>
                <h5 class="mb-3">Horizontal</h5>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value, index) in range" :key="index">
                        <td>
                            <span>.translate-x-</span>{{ value }}
                        </td>
                        <td>transform: translate x direction -<span>{{ value }}</span>%;
                            transform: translateX(<span>{{ value }}</span>%);
                        </td>
                    </tr>
                </table>
                <div class="bg-4 p-4">
                    <h4>Transform Utilities</h4>
                    <p>
                        Transform utilities allow you to move elements along the X and Y axes using CSS transformations.
                        These utilities can be especially useful for animations, positioning elements precisely, or
                        creating
                        effects like sliding.
                    </p>

                    <h4>Horizontal Translations</h4>
                    <p>Classes for Translating Elements Horizontally:</p>
                    <ul class="pl-3">
                        <li class="mt-2"><strong>.translate-x--100</strong>: Moves the element 100% to the left.</li>
                        <li class="mt-2"><strong>.translate-x--50</strong>: Moves the element 50% to the left.</li>
                        <li class="mt-2"><strong>.translate-x-0</strong>: No horizontal movement.</li>
                        <li class="mt-2"><strong>.translate-x-50</strong>: Moves the element 50% to the right.</li>
                        <li class="mt-2"><strong>.translate-x-100</strong>: Moves the element 100% to the right.</li>
                    </ul>
                </div>

                <div>
                    <h4 class="mt-4">Example</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.transform.code"/>
                    <div class="translate-x--50">This element is shifted 50% to the left.</div>
                    <div class="translate-x-0">This element is in its original position.</div>
                    <div class="translate-x-50">This element is shifted 50% to the right.</div>

                </div>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value, index) in range" :key="index">
                        <td>
                            <span>.translate-y-</span>{{ value }}
                        </td>
                        <td>transform: translate y direction -<span>{{ value }}</span>%;
                            transform: translateY(<span>{{ value }}</span>%);
                        </td>
                    </tr>
                </table>
                <h5 class="mb-3 mt-4">Vertical</h5>
                <div class="bg-4 mt-4 p-4">
                    <h3>Vertical Translations</h3>
                    <p>Classes for Translating Elements Vertically:</p>
                    <ul class="pl-3">
                        <li class="mt-2"><strong>.translate-y--100</strong>: Moves the element 100% upward.</li>
                        <li class="mt-2"><strong>.translate-y--50</strong>: Moves the element 50% upward.</li>
                        <li class="mt-2"><strong>.translate-y-0</strong>: No vertical movement.</li>
                        <li class="mt-2"><strong>.translate-y-50</strong>: Moves the element 50% downward.</li>
                        <li class="mt-2"><strong>.translate-y-100</strong>: Moves the element 100% downward.</li>
                    </ul>
                </div>
                <h3 class="mt-4">Example</h3>
                <code-container show-code-no-toggle :code-str="codeStore.transform1.code"/>

                <div class="translate-y--50">This element is shifted 50% upward.</div>
                <div class="translate-y-0">This element is in its original position.</div>
                <div class="translate-y-50">This element is shifted 50% downward.</div>
                <h4 class="my-3">Transforms in rem</h4>
                <h5 class="mb-3">Horizontal</h5>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value, index) in range2" :key="index">
                        <td>
                            <span>.translate-x-</span>{{ value }}r
                        </td>
                        <td>transform: translate x direction -<span>{{ value }}</span>%;
                            transform: translateX(<span>{{ value }}</span>%);
                        </td>
                    </tr>
                </table>
                <h5 class="mb-3">Vertical</h5>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value, index) in range2" :key="index">
                        <td>
                            <span>.translate-y-</span>{{ value }}r
                        </td>
                        <td>transform: translate y direction -<span>{{ value }}</span>%;
                            transform: translateY(<span>{{ value }}</span>%);
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/transforms.json';

export default {
    name: 'TransformsHelpers',
    components: { CodeContainer },
    metaInfo: {
        path: 'helpers/transforms/',
        title: 'Transforms'
    },
    data () {
        return {
            codeStore: codeStore
        };
    },
    computed: {
        range () {
            const arr = [];
            for (let i = -100; i <= 100; i = i + 10) {
                arr.push(i);
            }
            return arr;
        },
        range2 () {
            const arr = [];
            for (let i = -60; i <= 60; i = i + 5) {
                arr.push(i);
            }
            return arr;
        }
    }
};
</script>

<style scoped>

</style>
