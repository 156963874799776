<template>
    <svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20Z"
              :fill="'var(--color-' + color + ')'"/>
        <path d="M1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20Z" :stroke="'var(--color-' + color + '-200)'" stroke-width="1"/>
        <circle cx="20" cy="20" r="6" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: 'IconStepOngoing',
    props: {
        color: { type: String, default: 'primary' },
        width: { type: String, default: '18' },
        height: { type: String, default: '18' }
    }
};
</script>

<style scoped>

</style>
