<template>
    <div class="pl-4" :class="[borderClass]">
        <text-el :fs="text1FontSize" :additional-classes="[text1Classes]" :font-weight="text1FontWeight"
                 :color="text1Color" font-style="italic"><i>{{ text1 }}</i></text-el>
        <text-el :fs="text2FontSize" :additional-classes="[text2Classes]" :font-weight="text2FontWeight"
                 :color="text2Color" class="mt-4">— {{ text2 }}
        </text-el>
    </div>
</template>

<script>
import TextEl from '../../../components/typography/TextElement';

export default {
    name: 'BlockQuote',
    components: { TextEl },
    props: {
        text1: {
            type: String,
            default: '“In a world older and more complete than ours they move finished and complete, gifted with extensions of the senses we have lost or never attained, living by voices we shall never hear.”'
        },
        text2: { type: String, default: 'Olivia Rhye, Product Designer' },
        color: { type: String, default: 'primary' },
        borderSize: { type: String, default: '3' },
        text1FontSize: { type: String, default: '3' },
        text1Color: { type: String, default: '' },
        text1FontWeight: { type: String, default: '600' },
        text1Classes: { type: [String, Array], default: '' },
        text2FontSize: { type: String, default: '' },
        text2Color: { type: String, default: '' },
        text2FontWeight: { type: String, default: '' },
        text2Classes: { type: [String, Array], default: '' }
    },
    computed: {
        borderClass () {
            const classNames = [];
            if (this.color) {
                classNames.push('b-' + this.color);
            }
            if (this.borderSize) {
                classNames.push('bl-' + this.borderSize);
            }
            return classNames;
        }
    }
};
</script>

<style scoped>

</style>
