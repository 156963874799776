<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="my-3">Activity Gauge Customizable</h2>
                    <p class="lead">Customizable circle color, circle color thickness and font styles </p>
                    <h4 class="my-3">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.activityGaugeCustomizable.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">design : basic-a</h5>
                    <activity-gauge-customizable
                        series1-color="primary"
                        series2-color="secondary"
                        series3-color="third"
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        :total-value="1000"/>
                    <code-container show-code-no-toggle :code-str="codeStore.customizable.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">design: basic-b</h5>
                    <activity-gauge-customizable
                        series1-color="primary"
                        series2-color="secondary"
                        series3-color="third"
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.customizableBasicB.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">with circle color thickness</h5>
                    <!--                    <p>with circle color thickness</p>-->
                    <activity-gauge-customizable
                        value-color="info"
                        series1-color="primary"
                        series1-color-thickness="400"
                        series2-color="secondary"
                        series2-color-thickness="400"
                        series3-color="third"
                        series3-color-thickness="400"
                        :value1="75"
                        :value2="85"
                        :value3="70"
                        design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.customizableThickness.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">circle radius and color - customizable</h5>
                    <activity-gauge-customizable
                        series1-color="info"
                        series2-color="danger"
                        series3-color="success"
                        label-font-size="12px"
                        label="Active Users"
                        storke-width="12px"
                        total-value-font-size="24px"
                        series1-radius="100"
                        series2-radius="74"
                        series3-radius="50"
                        total-value-position-x="65"
                        total-value-position-y="73"
                        :value1="75"
                        :value2="85"
                        :value3="80"/>
                    <code-container show-code-no-toggle :code-str="codeStore.customizableRadius.code"/>
                </div>
                <div class="col-lg-6">
                    <h2 class="my-4">circle - customizable</h2>
                    <activity-gauge-circle-customizable
                        series1-color="info"
                        series2-color="danger"
                        series3-color="success"
                        label-font-size="12px"
                        label="Active Users"
                        storke-width="12px"
                        total-value-font-size="24px"
                        lg-radius="100"
                        md-radius="74"
                        sm-radius="50"
                        :value1="75"
                        :value2="85"
                        :value3="80"/>
                    <code-container show-code-no-toggle :code-str="codeStore.circleCustomizable.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import ActivityGaugeCustomizable from '../../../dashboard-components/activity-gauges/ActivityGaugeCustomizable';
import ActivityGaugeCircleCustomizable from '../../../dashboard-components/activity-gauges/circles/ActivityGaugeCircleCustomizable';
import codeStore from '../code-storage/activity-gauge.json';
import CodeContainer from '../../../components/doc-items/CodeContainer';
import PropTable from '../../docs-components/Props';

export default {
    name: 'ActivityGaugeCustomizableDocsPage',
    metaInfo: {
        path: 'dashboard-components/activity-gauge-customizable/',
        title: 'Activity Customizable'
    },
    components: { ActivityGaugeCustomizable, ActivityGaugeCircleCustomizable, CodeContainer, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['value1', 'String', 'inner circle value', ['Numbers  0 - 100'], '75'],
                ['value2', 'String', 'second circle value', ['Numbers  0 - 100'], '60'],
                ['value3', 'String', 'outer circle value', ['Numbers  0 - 100'], '80'],
                ['label', 'String', 'Label on center', '', 'Users'],
                ['totalValue', 'String', 'total value shown on center', '', '1000'],
                ['series1Radius', 'String,Number', 'series 1 circle radius', '', '69'],
                ['series2Radius', 'String,Number', 'series 2 circle radius', '', '95'],
                ['series3Radius', 'String,Number', 'series 3 circle radius', '', '118'],
                ['totalValue', 'String', 'total value shown on center', '', '1000'],
                ['totalValueColor', 'String', 'total value color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'secondary'],
                ['series1Color', 'String', 'series 1 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['series2Color', 'String', 'series 2 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'secondary'],
                ['series3Color', 'String', 'series 3 color', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'third'],
                ['series1Color', 'String', 'series 1 color thickness', ['100', '200', '300', '400', '600', '700', '800', '900'], ''],
                ['series2Color', 'String', 'series 2 color thickness', ['100', '200', '300', '400', '600', '700', '800', '900'], ''],
                ['series3Color', 'String', 'series 3 color thickness', ['100', '200', '300', '400', '600', '700', '800', '900'], ''],
                ['labelPositionX', 'String,Number', 'Label position x', '', '92px'],
                ['labelPositionY', 'String,Number', 'Label position y', '', '245px'],
                ['totalValuePositionX', 'String,Number', 'Label position x', '', '58px'],
                ['totalValuePositionY', 'String,Number', 'Label position x', '', '78px'],
                ['svgSize', 'String,Number', 'size of svg', '', '330'],
                ['design', 'String', 'design based on alignment of the indicators ( not on ActivityGaugeCircle component)', ['basic-a', 'basic-b'], 'basic-a'],
                ['labelFontSize', 'String', 'font size of label on center', ['value in px unit'], '16px'],
                ['labelFontWeight', 'String', 'font weight of label on center', ['normal', 'bold'], 'bold'],
                ['totalValueFontSize', 'String', 'font size of total value on center', ['value in px unit'], '30px'],
                ['totalValueFontWeight', 'String', 'font weight of total value on center', ['normal', 'bold'], 'bold']
            ]
        };
    }
};
</script>

<style scoped>

</style>
