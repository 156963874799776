<template>
    <div class="col-lg-9 mt-4">
        <div class="fl-x">
            <div class="bg-1 col-lg-5 mt-6 px-4">
                <img src="../../assets/img/avatar/star.jpg" alt="" class="w-20p">
                <h4 class="font-weight-bold mt-2">{{headings}}</h4>
                <h6 class="font-weight-bold mt-5">{{name}}</h6>
                <p>Product Designer,Quotient</p>
            </div>
            <div class="col-lg-4">
                <img src="../../assets/img/avatar/aiony-haust-3TLl_97HNJo-unsplash1.jpg" alt="" class="w-100p">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialItemTwo',
    props: {
        headings: { type: String, default: 'Love the simplicity of the service and the prompt customer support.We can\'t imagine working without it.' },
        name: { type: String, default: '- Renee Wells' }
    }
};
</script>

<style scoped>

</style>
