<template>
    <page-template class="bg-2">
        <div class="">
            <h2 class="">Team Section Colored</h2>
            <p class="lead">Component with title content and tags</p>
            <h4 class="my-2">Import Component</h4>
            <code-container
                show-code-no-toggle
                :code-str="codeStore.colored.import"/>
            <div class="row">
                <div class="col-12 ">
                    <h5 class="mb-3">Basic Use</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.basicUse"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Default</h5>
                    <team-section-item-colored
                        text1="Design"
                        text2="View Job"
                        text3="Product Designer"
                        text4="Design"
                        text5="Were looking for a mid-level product designer to join our team."
                        :tag-items="tagItems1"/>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.default"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Card Design</h5>
                    <team-section-item-colored
                        text1="Design"
                        text2="View Job"
                        text3="Product Designer"
                        text4="Design"
                        text5="Were looking for a mid-level product designer to join our team."
                        :tag-items="tagItems1"
                        mt="3"
                        card-design/>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.cardDesign"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Bottom Tags using slot</h5>
                    <team-section-item-colored
                        text1="Design"
                        text2="View Job"
                        text3="Product Designer"
                        text4="Design"
                        text5="Were looking for a mid-level product designer to join our team."
                        :tag-items="tagItems1" mt="3" card-design>
                        <template #bottom>
                            <div class="fl-x fl-a-c">
                                <icon-text text="Tag 1" text-font-size="1" icon-size="2" :img="iconLocation"/>
                                <icon-text text="Tag 2" text-font-size="1" icon-size="2" :img="iconClock" ml="3"/>
                            </div>
                        </template>
                    </team-section-item-colored>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.bottomTagsSlot"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Fonts Customization</h5>
                    <team-section-item-colored
                        text1="Design"
                        text2="View Job"
                        text3="Product Designer"
                        text4="Design"
                        text5="Were looking for a mid-level product designer to join our team."
                        text1-color="info"
                        text2-color="info"
                        text4-color="info"
                        text5-font-weight="light"
                        :tag-items="tagItems1" mt="3"
                        card-design/>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.fontsCustomization"/>
                </div>
                <div class="col-12">
                    <h5 class="my-4">Slots <span class="font-weight-normal">(top,bottom)</span></h5>
                    <team-section-item-colored
                        text1="Design"
                        text2="View Job"
                        text3="Product Designer"
                        text4="Design"
                        text5="Were looking for a mid-level product designer to join our team."
                        class="mt-3" card-design>
                        <template slot="text1">
                            <div class="text-primary font-weight-500">Slot (text1)</div>
                        </template>
                        <template slot="text2">
                            <div class="b-1 round-1 p-1 px-2 bg-2 bs-5">Slot (text2)</div>
                        </template>
                        <template slot="text3">
                            <div class="text-primary text-fs-2 font-weight-500">Slot (text3)</div>
                        </template>
                        <template slot="text4">
                            <div class="text-danger text-fs-0 ml-1">Slot (text4)</div>
                        </template>
                        <template slot="text5">
                            <div class="text-2 ml-1 md-max-w-70p">Slot (text5) Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis explicabo fuga fugiat inventore
                                laboriosam odio
                                perspiciatis placeat reiciendis rem, voluptatibus.
                            </div>
                        </template>
                        <template #bottom>
                            <div class="text-danger text-fs-1 ml-1 mt-2">Slot (bottom)</div>
                            <div class="fl-x fl-a-c mt-1">
                                <icon-text :img="iconClock" icon-size="2" text-color="2" text="Full-time(slot:bottom)"/>
                                <icon-text :img="iconDollar" icon-size="4" text-color="2" text="$80k - $100k (slot:bottom)" ml="3"/>
                            </div>
                        </template>
                    </team-section-item-colored>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.allSlots"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import TeamSectionItemColored from '../web-components/team-section/TeamSectionItemColored';
import iconClock from '../demo/web-components/assets/icons/icons8-clock-24.png';
import iconDollar from '../demo/web-components/assets/icons/icons8-dollar-50.png';
import iconLocation from '../demo/web-components/assets/icons/icons8-location-24.png';
import iconAustraliaFlag from '../demo/web-components/assets/icons/icons8-australia-24.png';
import IconText from '../web-components/tag-items/IconText';
import codeStore from './code-storage/team-section-item.json';
import PropTable from '../docs-components/Props';
import CodeContainer from '../../components/doc-items/CodeContainer';

export default {
    name: 'TeamSectionColoredVariantsDocsPage',
    metaInfo: {
        path: 'web-components/team-section-colored/',
        title: 'Team Section Item Colored'
    },
    components: { CodeContainer, TeamSectionItemColored, IconText, PropTable },
    data () {
        return {
            iconClock: iconClock,
            iconDollar: iconDollar,
            iconLocation: iconLocation,
            iconAustraliaFlag: iconAustraliaFlag,
            codeStore: codeStore,
            tagItems: [
                {
                    icon: iconClock,
                    text: 'Full-time',
                    iconSize: 2
                },
                {
                    icon: iconDollar,
                    text: '$90k - $100k',
                    iconSize: 4
                }
            ],
            tagItems1: [
                {
                    icon: iconLocation,
                    text: 'Remote',
                    iconSize: 2
                },
                {
                    icon: iconClock,
                    text: 'Full-time',
                    iconSize: 2
                }
            ],
            propsInfo: [
                ['text1', 'String', 'tag text on top left of heading - text index numbered on left-right top-bottom order', '', ''],
                ['text2', 'String', 'link text on top left of heading - text index numbered on left-right top-bottom order', '', ''],
                ['text3', 'String', 'heading text, text index numbered on left-right top-bottom order', '', ''],
                ['text4', 'String', 'heading right tag text, text index numbered on left-right top-bottom order', '', ''],
                ['text5', 'String', 'description content, text index numbered on left-right top-bottom order', '', ''],
                ['rightIcon', 'String', 'link icon on right side', '', ''],
                ['shadow', 'String', 'Box Shadow Variant', ['1 - 29'], ''],
                ['border', 'String', 'border variant', ['1 - 10'], ''],
                ['borderRadius', 'String', 'border radius variant', ['1 - 10'], ''],
                ['borderColor', 'String', 'border color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'dark', 'light'], ''],
                ['tagItems', 'String', 'Tag Items Array, with tag objects contains icon and text', '', ''],
                ['cardDesign', 'Boolean', 'card design set', ['true', 'false'], 'false'],
                ['p,px,py,pl,pr...', 'String,Number', 'padding variants', ['1 - 9'], 'p=5'],
                ['m,mx,my,ml,mr...', 'String,Number', 'margin variants', ['1 - 9'], ''],
                ['text1Color', 'String', 'text 1 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text1FontSize', 'String', 'text 1 font size', ['-1 - 7'], '0'],
                ['text1FontWeight', 'String', 'text 1 font size', ['light', 'normal', 'bold', 'bolder', 'itealic', '100 - 900'], 'bold'],
                ['text1Classes', 'String', 'text1 color variant', ['-1 - 7'], '0'],
                ['text2Color', 'String', 'text2 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text2FontSize', 'String', 'text 2 font size', ['-1 - 7'], ''],
                ['text2FontWeight', 'String', 'text 2 font size', ['light', 'normal', 'bold', 'bolder', 'itealic', '100 - 900'], ''],
                ['text2Classes', 'String', 'text2 additional classes', '', ''],
                ['text3Color', 'String', 'text3 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text3FontSize', 'String', 'text 3 font size', '', ''],
                ['text3FontWeight', 'String', 'text 3 font size', ['light', 'normal', 'bold', 'bolder', 'itealic', '100 - 900'], ''],
                ['text3Classes', 'String', 'text3 additional classes', '', ''],
                ['text4Color', 'String', 'text4 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text4FontSize', 'String', 'text 4 font size', '', ''],
                ['text4FontWeight', 'String', 'text 4 font size', ['light', 'normal', 'bold', 'bolder', 'itealic', '100 - 900'], ''],
                ['text4Classes', 'String', 'text4 additional classes', '', ''],
                ['text5Color', 'String', 'text5 text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text5FontSize', 'String', 'text 5 font size', '', ''],
                ['text5FontWeight', 'String', 'text 5 font size', ['light', 'normal', 'bold', 'bolder', 'itealic', '100 - 900'], ''],
                ['text5Classes', 'String', 'text5 additional classes', '', ''],
                ['socialIconColor', 'String', 'social icon color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['align', 'Boolean', 'Align image and text items center horizontally ( only on TeamAvatarItemVertical)', ['left', 'center'], 'left'],
                ['cardDesign', 'Boolean', 'Wrap items inside a card', ['true', 'false'], 'false'],
                ['linkUrl', 'String', 'Forward link url', '', ''],
                ['containerClasses', 'String,Array', 'Items main container( contains image and text ) additional classes', '', ''],
                ['imgClasses', 'String,Array', 'Image additional classes', '', ''],
                ['contentContainerClasses', 'String,Array', 'Content Container additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
