<template>
    <img :src="image" class="icon-img" alt="" @click="$emit('click')"
         :style="[{height: heightValue}, setHoverRotateValue,
         setHoverScaleValue, setTransitionDurationValue, sethoverOpacity, setRotation]"
         :class="[{'mu-p': cursorPointer},
         {'icon-img-h-scale-up': hoverEffect==='basic-1'},{'icon-img-h-rotate': hoverEffect ==='basic-2'},
           {'icon-img-rotate': rotate !==''}, classNames, additionalClasses,]">
</template>
<script>
export default {
    name: 'IconImage',
    props: {
        image: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: '3'
        },
        height: {
            type: String,
            default: ''
        },
        rotate: {
            type: String,
            default: ''
        },
        hoverEffect: {
            type: String,
            default: ''
        },
        hoverRotate: {
            type: Boolean,
            default: false
        },
        hoverRotateValue: {
            type: String,
            default: ''
        },
        hoverScaleValue: {
            type: String,
            default: ''
        },
        transitionDuration: {
            type: String,
            default: ''
        },
        hoverOpacity: {
            type: String,
            default: ''
        },
        cursorPointer: {
            type: Boolean,
            default: false
        },
        additionalClasses: { type: [String, Array], default: '' }

    },
    computed: {
        classNames () {
            const names = [];
            if (this.size) {
                names.push('icon-size-' + this.size);
            }
            return names;
        },
        heightValue () {
            // if (this.height !== '' && this.size === '') {
            if (this.height !== '') {
                return this.height + 'rem';
            }
            return '';
        },
        // rotateStyle () {
        //     if (this.rotate !== '') {
        //         return { transform: 'rotate(' + this.rotate + ')' };
        //     }
        //     return '';
        // },
        setHoverRotateValue () {
            return {
                '--icon-img-hover-rotation': this.hoverRotateValue
            };
        },
        setHoverScaleValue () {
            return {
                '--icon-img-hover-scale-up': this.hoverScaleValue
            };
        },
        setTransitionDurationValue () {
            return {
                '--icon-img-transition-duration': this.transitionDuration
            };
        },
        sethoverOpacity () {
            return {
                '--icon-img-hover-opacity': this.hoverOpacity
            };
        },
        setRotation () {
            return {
                '--icon-img-rotate': this.rotate
            };
        }
    }
};
</script>

<style scoped>

</style>
