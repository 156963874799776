<template>
    <component :is="tag" class="row" :class="[marginClasses, paddingClasses]">
        <slot></slot>
    </component>
</template>

<script>
import marginsAndPaddingsMixin from '../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'Row',
    mixins: [marginsAndPaddingsMixin],
    props: {
        tag: {
            type: String,
            default: 'div'
        }
    }
};
</script>
