<template>
    <div class="b-1 px-3 py-3 round-1">
        <div class="fl-te-t">
            <div class="fl-x">
                <div>
                    <icon-img size="10" :image="fileIcon"/>
                    <custom-h-el height="1.1rem" width="1.9rem" class="bg-danger ml-n1 mt-n5 text-fs--1 p-1 text-uppercase">{{fileExtension}}</custom-h-el>
                </div>
                <div class="ml-2">
                    <div class="">{{fileName}}</div>
                    <div class="text-fs-1 mt-1" v-if="fileSize">{{fileSize}}</div>
                </div>
            </div>
            <div class="fl-x-cc">
                <transition-fade>
                    <loading-animation v-if="status==='ongoing'" key="loading"/>
                    <input type="checkbox" class="mb-0 accent-primary" :checked="true" v-else key="success-checkbox"/>
                </transition-fade>
            </div>
        </div>
    </div>
</template>

<script>
import fileIcon from './assets/icons8-file-50.png';
import IconImg from '../../components/IconImage';
import TransitionFade from '../../components/animate-transition-fade';
import CustomHEl from '../../containers/CustomHeightContainer';

export default {
    name: 'FileItemType2',
    components: { CustomHEl, TransitionFade, IconImg },
    props: {
        fileName: { type: String, default: 'Tech design requirements.pdf' },
        status: { type: String, default: 'ongoing' },
        fileSize: { type: String, default: '' },
        fileExtension: { type: String, default: 'pdf' }
    },
    data () {
        return {
            fileIcon: fileIcon
        };
    }
};
</script>

<style scoped>

</style>
