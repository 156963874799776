<template>
    <page-template>
        <div class="">
            <h2 class="">Sidebar</h2>
            <p class="lead">colored , plain and icons only variants</p>
            <div class="row mt-5">
                <div class="col-4">
                    <h3 class="my-4">Default</h3>
                    <side-navbar :items="menu"/>
                </div>
                <div class="col-8">
                    <h5 class="my-4">Code</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-4">
                    <h3 class="my-4">Colored</h3>
                    <side-nav-bar-colored
                        :items="menu"
                        menu-list-ul-classes="max-h-lg-60vh"
                        drop-down-animation="fade">
                        <template #top>
                            <div class="p-3">
                                <form-input input-classes="text-white input-placeholder-white"
                                            placeholder="Search"></form-input>
                            </div>
                        </template>
                        <template #bottom>
                            <avatar-card
                                :img="avatarImg"
                                img-align="left"
                                img-size="0"
                                content="" title-font-size="1" sub-title-font-size="0"
                                :show-social-links="false"></avatar-card>
                        </template>
                    </side-nav-bar-colored>
                </div>
                <div class="col-8">
                    <h5 class="my-4">Code</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.colored.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-4">
                    <h3 class="my-4">Plain</h3>
                    <side-navbar-plain
                        :items="menu"
                        menu-list-ul-classes="max-h-lg-60vh"
                        drop-down-animation="fade"/>
                </div>
                <div class="col-8">
                    <h5 class="my-4">Code</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.plain.code"/>
                </div>
                <div class="col-lg-12 mt-4">
                    <h3>Side Navbar Plain: SCSS Variables</h3>
                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-4">
                    <h3 class="my-4">Icons Only</h3>
                    <side-nav-bar-icons-only
                        :items="menuIconOnly"
                        colored hover-effect="basic-2"
                        class="w-5r"
                        menu-list-ul-classes="max-h-lg-60vh"
                        drop-down-animation="fade"/>
                </div>
                <div class="col-8">
                    <h5 class="my-4">Code</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.iconsOnly.code"/>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-4">Slots</h4>
                    <h5>top</h5>
                    <p>Items above menu list</p>
                    <h5>bottom</h5>
                    <p>Items below menu list</p>
                    <h5>logo</h5>
                    <p>logo item</p>
                </div>
                <div class="col-lg-12 mt-4">
                    <h3>Side Navbar: SCSS Variables</h3>
                    <simple-table :fields="fields1" :data="data1" class="b-1"></simple-table>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import getMenu from './sidebar/menu-test';
import getIconOnlyMenu from './sidebar/menu-icon-only';
import SideNavBarColored from '../../components/side-navbar-colored/SideNavBarColored';
import SideNavbarPlain from '../../components/side-navbar-plain/SideNavBarPlain';
import SideNavBarIconsOnly from '../../components/sidebar-icons-only/SideNavBarIconsOnly';
import SideNavbar from '../../components/SideNavBar';
import AvatarCard from '../web-components/TeamAvatarItem';
import avatarImg from '../../assets/img/avatar/aiony-haust-3TLl_97HNJo-unsplash1.jpg';
import codeStore from './code-storage/side-bar.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import FormInput from '../../forms/FormInput';
import PropTable from '../docs-components/Props';

export default {
    name: 'SidebarNavigationDocsPage',
    metaInfo: {
        path: 'web-components/SideNavBar/',
        title: 'Side Bar'
    },
    components: {
        SideNavBarColored,
        PropTable,
        SideNavbar,
        AvatarCard,
        SideNavbarPlain,
        SideNavBarIconsOnly,
        CodeContainer,
        FormInput
    },
    data () {
        return {
            menu: getMenu(),
            menuIconOnly: getIconOnlyMenu(),
            value: '',
            avatarImg: avatarImg,
            codeStore: codeStore,
            propsInfo: [
                ['logoText', 'String', 'Logo text instead of logo image ( not in SideNavBarIconsOnly)', '', 'Xeoscript Technologies'],
                ['items', 'Array', 'array of link objects , same as Navbar', '', ''],
                ['shadow', 'String', 'box shadow variant', '1-29', '5'],
                ['dropDownAnimation', 'String', 'Dropdown animation', ['zoom', 'fade', 'etc'], 'fade'],
                ['dropDownAnimationMobile', 'String', 'Dropdown animation', ['zoom', 'fade', 'etc'], 'fade'],
                ['menuListUlClasses', 'String,Array', 'menu list ul element additional classes', '', '']
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--nav-bar-plain-dropdown-bg',
                    description: 'Dropdown background color',
                    defaultvalue: 'Transparent'
                },
                {
                    slno: '2',
                    name: '--nav-bar-plain-dropdown-hover-bg',
                    description: 'Dropdown Hover background color',
                    defaultvalue: 'Transparent'
                },
                {
                    slno: '3',
                    name: '--nav-bar-plain-item-padding-y',
                    description: 'Navbar Link item - vertical padding',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '4',
                    name: '--nav-bar-plain-item-padding-x',
                    description: 'Navbar Link item - Horizontal padding',
                    defaultvalue: '1rem'
                }
            ],
            fields1: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-20r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data1: [
                {
                    slno: '1',
                    name: '--nav-bar-dropdown-bg',
                    description: 'Navbar Dropdown background color. Depending on variable:<br>' +
                        '1. --bg-2',
                    defaultvalue: '#FFFFFF'
                },
                {
                    slno: '2',
                    name: '--nav-bar-dropdown-text-color',
                    description: 'Navbar Dropdown text color. Depending on variable:<br>' +
                        '1. --text-1',
                    defaultvalue: '#161616'
                },
                {
                    slno: '3',
                    name: '--nav-bar-dropdown-hover-text-color',
                    description: 'Navbar Dropdown text color on hover. Depending on variable:<br>' +
                        '1. --color-primary',
                    defaultvalue: 'primary color'
                },
                {
                    slno: '4',
                    name: '--nav-bar-dropdown-hover-bg',
                    description: 'Navbar Dropdown background color on hover. Depending on variable:<br>' +
                        '1. --bg-2',
                    defaultvalue: '#FFFFFF'
                },
                {
                    slno: '5',
                    name: 'nav-bar-animation-duration',
                    description: 'Navbar Animation Duration',
                    defaultvalue: '300ms'
                },
                {
                    slno: '6',
                    name: '--nav-bar-hover-effect-color',
                    description: 'Navbar Hover effect color. Depending on variable:<br>' +
                        '1. --nav-bar-hover-text-color',
                    defaultvalue: 'primary color'
                },
                {
                    slno: '7',
                    name: '--nav-bar-logo-text-color',
                    description: 'Navbar logo text color. Depending on variable:<br>' +
                        '1. --color-primary',
                    defaultvalue: 'primary color'
                },
                {
                    slno: '8',
                    name: '--nav-bar-logo-font-size',
                    description: 'Navbar logo font size',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '9',
                    name: '--nav-bar-height',
                    description: 'Navbar Height',
                    defaultvalue: '3.8rem'
                },
                {
                    slno: '10',
                    name: '--nav-bar-item-padding-y',
                    description: 'Navbar Item vertical padding',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '11',
                    name: '--nav-bar-item-padding-x',
                    description: 'Navbar Item horizontal padding',
                    defaultvalue: 'var(--spacer-3) - 1rem'
                },
                {
                    slno: '12',
                    name: '--nav-bar-text-color',
                    description: 'Navbar Text Color',
                    defaultvalue: 'var(--text-1) - #161616'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
