<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Bg & Text</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--color-primary-contrast Format: ' +
                        ' var(--color-#{$color}-contrast)',
                    description: 'Format: ' +
                        'Contrast color for primary,for example white is set as contrast color for a dark primary color ' +
                        'Setting on theme.json ' +
                        '<br>"primary": {' +
                        '    "color": "#0A821A",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '},</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '2',
                    name: '--color-secondary-contrast ' +
                        'Format: same as above',
                    description: 'Setting on theme.json: ' +
                        '<br>"secondary": {' +
                        '    "color": "#4AB058",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '},</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '3',
                    name: '--color-third-contrast ' +
                        'Format: same as above',
                    description: 'Setting on theme.json: ' +
                        '<br>"third": {' +
                        '    "color": "#aee80e",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '},</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '4',
                    name: 'similar variants can be added on theme.json in the same format if needed (eg:fourth,fifth..etc.)',
                    description: '',
                    defaultvalue: ''
                },
                {
                    slno: '5',
                    name: '--color-primary-hover',
                    description: 'Hover color for primary.  used for setting hover effects. for example on hovering a button its background color can be changed using this ' +
                        'Setting on theme.json : ' +
                        '<br>"primary": {' +
                        '    "color": "#0A821A",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '}</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                },
                {
                    slno: '6',
                    name: '--color-primary-hover',
                    description: 'Active color for primary. used for setting active states. Setting on theme.json ' +
                        '<br>"primary": {' +
                        '    "color": "#0A821A",' +
                        '    "contrast": "white",' +
                        '    "focus": "600",' +
                        '    "active": "600",' +
                        '    "hover": "400"' +
                        '}</br>',
                    defaultvalue: 'Set on theme.json, specific for projects'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
