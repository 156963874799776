<template>
    <page-template>
        <div class="bg-2 p-5">
            <div>
                <h3>Side Navbar</h3>
            </div>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>
    </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-20r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--nav-bar-dropdown-bg',
                    description: 'Navbar Dropdown background color. Depending on variable:<br>' +
                        '1. --bg-2',
                    defaultvalue: '#FFFFFF'
                },
                {
                    slno: '2',
                    name: '--nav-bar-dropdown-text-color',
                    description: 'Navbar Dropdown text color. Depending on variable:<br>' +
                        '1. --text-1',
                    defaultvalue: '#161616'
                },
                {
                    slno: '3',
                    name: '--nav-bar-dropdown-hover-text-color',
                    description: 'Navbar Dropdown text color on hover. Depending on variable:<br>' +
                        '1. --color-primary',
                    defaultvalue: 'primary color'
                },
                {
                    slno: '4',
                    name: '--nav-bar-dropdown-hover-bg',
                    description: 'Navbar Dropdown background color on hover. Depending on variable:<br>' +
                        '1. --bg-2',
                    defaultvalue: '#FFFFFF'
                },
                {
                    slno: '5',
                    name: 'nav-bar-animation-duration',
                    description: 'Navbar Animation Duration',
                    defaultvalue: '300ms'
                },
                {
                    slno: '6',
                    name: '--nav-bar-hover-effect-color',
                    description: 'Navbar Hover effect color. Depending on variable:<br>' +
                        '1. --nav-bar-hover-text-color',
                    defaultvalue: 'primary color'
                },
                {
                    slno: '7',
                    name: '--nav-bar-logo-text-color',
                    description: 'Navbar logo text color. Depending on variable:<br>' +
                        '1. --color-primary',
                    defaultvalue: 'primary color'
                },
                {
                    slno: '8',
                    name: '--nav-bar-logo-font-size',
                    description: 'Navbar logo font size',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '9',
                    name: '--nav-bar-height',
                    description: 'Navbar Height',
                    defaultvalue: '3.8rem'
                },
                {
                    slno: '10',
                    name: '--nav-bar-item-padding-y',
                    description: 'Navbar Item vertical padding',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '11',
                    name: '--nav-bar-item-padding-x',
                    description: 'Navbar Item horizontal padding',
                    defaultvalue: 'var(--spacer-3) - 1rem'
                },
                {
                    slno: '12',
                    name: '--nav-bar-text-color',
                    description: 'Navbar Text Color',
                    defaultvalue: 'var(--text-1) - #161616'
                }
            ]
        };
    }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
