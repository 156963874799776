import AnimateTransition from './AnimateTransition';

export default {
    name: 'animate-transition-fade',
    extends: AnimateTransition,
    props: {
        enterAnimation: { type: String, default: 'fadeIn' },
        exitAnimation: { type: String, default: 'fadeOut' }
    }
};
