<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Avatar Image</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-35r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-50r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--avatar-img-border-radius-top-left',
                    description: 'avatar image border radius top left value ',
                    defaultvalue: '0'
                },
                {
                    slno: '2',
                    name: '--avatar-img-border-radius-top-right',
                    description: 'avatar image border radius top-right value ',
                    defaultvalue: '0'
                },
                {
                    slno: '3',
                    name: '--avatar-img-border-radius-bottom-right',
                    description: 'avatar image border radius bottom-right value ',
                    defaultvalue: '0'
                },
                {
                    slno: '4',
                    name: '--avatar-img-border-radius-bottom-left',
                    description: 'avatar image border radius bottom-left value ',
                    defaultvalue: '0'
                },
                {
                    slno: '5',
                    name: '--avatar-img-md-border-radius-top-left',
                    description: 'avatar image border radius top-left value for medium screens',
                    defaultvalue: '0'
                },
                {
                    slno: '6',
                    name: '--avatar-img-md-border-radius-top-right',
                    description: 'avatar image border radius top-right value for medium screens',
                    defaultvalue: '0'
                },
                {
                    slno: '7',
                    name: '--avatar-img-md-border-radius-bottom-right',
                    description: 'avatar image border radius bottom-right value for medium screens ',
                    defaultvalue: '0'
                },
                {
                    slno: '8',
                    name: '--avatar-img-md-border-radius-bottom-left',
                    description: 'avatar image border radius bottom-left value for medium screens',
                    defaultvalue: '0'
                },
                {
                    slno: '9',
                    name: '--avatar-img-lg-border-radius-top-left',
                    description: 'avatar image border radius top-left value for large screens',
                    defaultvalue: '0'
                },
                {
                    slno: '10',
                    name: '--avatar-img-lg-border-radius-top-right',
                    description: 'avatar image border radius top-right value for large screens',
                    defaultvalue: '0'
                },
                {
                    slno: '11',
                    name: '--avatar-img-lg-border-radius-bottom-right',
                    description: 'avatar image border radius bottom-right value for large screens',
                    defaultvalue: '0'
                },
                {
                    slno: '12',
                    name: '--avatar-img-lg-border-radius-bottom-left',
                    description: 'avatar image border radius bottom-left value for large screens',
                    defaultvalue: '0'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
