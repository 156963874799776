<template>
    <div :class="[containerDefaultClasses,{'card': cardDesign}]">
        <!--        icon Section-->
        <div :class="[{'': design ==='basic-b'}]">
            <custom-h-el
                :width="iconWidth"
                :height="iconHeight"
                class="mb-2 fl-x-cc b-2 round-2"
                :class="[iconDefaultClasses]"
                :style="{'border-color': iconBorderColorStylesUpdated}">
                <slot name="icon">
                    <i :class="[icon]" v-if="icon && iconImg ===''" class="m-0"></i>
                    <icon-img :image="iconImg"></icon-img>
                </slot>
            </custom-h-el>
        </div>
        <!--        Title and Content Section-->
        <div class="" :class="[{'': design === 'basic-b'}]">
            <text-el :font-weight="titleFontWeight" :color="titleColor" :fs="titleFontSize" class="mb-2"
                     :class="[titleClasses]">{{ title }}
            </text-el>
            <text-el
                :font-weight="contentFontWeight"
                :color="contentColor" :fs="contentFontSize"
                class="mb-3"
                :class="[contentClasses]">
                {{ content }}
            </text-el>
        </div>
    </div>
</template>

<script>
import CustomHEl from '../../../containers/CustomHeightContainer';
import IconImg from '../../../components/IconImage';
import TextEl from '../../../components/typography/TextElement';

export default {
    name: 'FaqItem',
    components: { CustomHEl, IconImg, TextEl },
    props: {
        title: { type: String, default: 'Is there a free trail available' },
        content: {
            type: String,
            default: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used\n' +
                '                in laying out print, graphic or web designs. The passage is attributed to an unknown\n' +
                '                typesetter in the 15th c'
        },
        align: { type: String, default: 'center' },
        icon: { type: String, default: 'fa fa-heart-o' },
        iconImg: { type: String, default: '' },
        iconWidth: { type: String, default: '2.5r' },
        iconHeight: { type: String, default: '2.5r' },
        iconColor: { type: String, default: '' },
        iconBorderColor: { type: String, default: '' },
        design: { type: String, default: 'basic-a' },
        shadow: { type: String, default: '' },
        cardDesign: { type: Boolean, default: false },
        titleFontSize: { type: String, default: '' },
        titleFontWeight: { type: String, default: 'bold' },
        titleColor: { type: String, default: '' },
        titleClasses: { type: String, default: '' },
        contentFontSize: { type: String, default: '' },
        contentFontWeight: { type: String, default: '' },
        contentColor: { type: String, default: '' },
        contentClasses: { type: String, default: '' }
    },
    computed: {
        containerDefaultClasses () {
            const classNames = [];
            if (this.design === 'basic-b') {
                classNames.push('d-grid ');
                classNames.push('text-left');
                classNames.push('fl-a-s');
            } else {
                classNames.push('fl-y');
            }
            if (this.align && this.design !== 'basic-b') {
                classNames.push('text-' + this.align);
            }
            if (this.align === 'center' && this.design !== 'basic-b') {
                classNames.push('fl-a-c');
            } else if (this.align === 'left') {
                classNames.push('fl-a-s');
            }
            // Card Design Classes
            if (this.cardDesign && this.shadow) {
                classNames.push('bs-' + this.shadow);
            } else if (this.cardDesign && !this.shadow) {
                classNames.push('bs-5');
            }
            return classNames;
        },
        iconDefaultClasses () {
            const classNames = [];
            const defaultBorderItems = ['light', 'dark'];
            if (this.iconBorderColor && defaultBorderItems.includes(this.iconBorderColor)) {
                classNames.push('b-' + this.iconBorderColor);
            }
            if (this.iconColor) {
                classNames.push('text-' + this.iconColor);
            }
            return classNames;
        },
        iconBorderColorStylesUpdated () {
            const defaultBorderItems = ['light', 'dark'];
            if (this.iconBorderColor && !defaultBorderItems.includes(this.borderColor)) {
                return 'var(--color-' + this.iconBorderColor + '-100) !important';
            } else if (this.iconBorderColor && defaultBorderItems.includes(this.iconBorderColor)) {
                return '';
            }
            return '';
        }
    }
};
</script>

<style scoped>
.d-grid {
    display: grid;
    grid-template-columns: 4rem auto;
}
</style>
