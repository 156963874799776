<template>
    <page-template class="p-5">
        <h2>Mask Input</h2>
        <p class="lead">Mask Input also supports all the properties of input</p>
        <div class="mb-5">
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import FormMaskInput from 'lego-framework/src/forms/FormMaskInput';</p>
            <p>Vue.component('validated-mask-input', FormMaskInput);</p>
            <p>&#60;validated-mask-input/&#62;</p>
        </div>
        <p>
            The masked input will prevent all the inputs other than then permitted input.
            The applied mask in all the inputs are ##-##-####. Which means 5 numbers only.
        </p>

        <div class="row">

            <div class="col">
                <h3>Mask</h3>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th style="width: 2rem">Placeholder</th>
                        <th>Format</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>#</td>
                        <td>Number (0-9)</td>
                    </tr>
                    <tr>
                        <td>A</td>
                        <td>Letter in any case (a-z,A-Z)</td>
                    </tr>
                    <tr>
                        <td>N</td>
                        <td>Number or letter (a-z,A-Z,0-9)</td>
                    </tr>
                    <tr>
                        <td>X</td>
                        <td>Any symbol</td>
                    </tr>
                    <tr>
                        <td>?</td>
                        <td>Optional (next character)</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div class="col">
                <h3>Common Masks</h3>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th style="width: 2rem">Placeholder</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>##########</td>
                        <td>10 digit number</td>
                    </tr>
                    <tr>
                        <td>##-##-####</td>
                        <td>DD-MM-YYYY or MM-DD-YYYY</td>
                    </tr>
                    <tr>
                        <td>######</td>
                        <td>6 digit pin code</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <h3 class="my-3">Common Masks</h3>
        <component-wrapper :code="codeStore.maskInput.commonMasks.code">
            <div class="row">
                <div class="col-md-4">
                    <p class="font-weight-bold">10 digit number</p>
                    <p>mask : ########## </p>
                    <FormInput mask="##########"/>
                </div>
                <div class="col-md-4">
                    <p class="font-weight-bold">DD-MM-YYYY or MM-DD-YYYY</p>
                    <p>mask: ##-##-####</p>
                    <FormInput mask="##-##-####"/>
                </div>
                <div class="col-md-4">
                    <p class="font-weight-bold">6 digit pin code</p>
                    <p>mask: ######</p>
                    <FormInput mask="######"/>
                </div>
            </div>
        </component-wrapper>
        <h3 class="my-3">Color Variations</h3>
        <p>Form only supports contextual colors now. More color options will be added soon</p>
        <div class="row">
            <div class="col-md-3">
                <b>Color: null</b>
                <FormInput mask="##-##-####"/>
            </div>
            <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                <b>Color: {{ color }}</b>
                <FormInput mask="#####" :color="color"/>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput mask="#####" icon="fa fa-user"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput mask="#####" icon="fa fa-user" :color="color"/>
                </div>
            </div>
        </div>

        <div>
            <h3 class="mt-4">Disabled State</h3>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput mask="#####" disabled label="Disabled"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput mask="#####" label="Disabled" disabled :color="color"/>
                </div>
            </div>
            <h4 class="mt-4">Disabled State With Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput mask="#####" disabled icon="fa fa-user" label="Disabled"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput mask="#####" icon="fa fa-user" label="Disabled" disabled :color="color"/>
                </div>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Label and Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput mask="#####" icon="fa fa-user" label="First Name"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput mask="#####" icon="fa fa-user" :color="color" label="First Name"/>
                </div>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Label, Icons and Error Messages</h4>
            <div class="row flx-b">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput mask="#####" icon="fa fa-user" label="First Name" error-message="First Name is required."/>
                </div>
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput mask="#####" icon="fa fa-user" label="First Name" success-message="First Name is required."/>
                </div>
            </div>
            <alert color="info" border="l" icon-colored mode="border" border-thickness="4" shadow="5">
                Note that giving a error message will automatically set the color to error. Likewise setting success message will set the color to success
            </alert>
        </div>

        <div>
            <h3 class="mt-4">Size Variations</h3>
            <div class="row fl-x-t">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput mask="#####" :label="size" :size="size"/>
                </div>
            </div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row fl-x-b">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput mask="#####" :label="size" icon="fa fa-user" :size="size"/>
                </div>
            </div>
        </div>

        <div>
            <h2 class="my-3">Props</h2>
            <prop-table :items="maskedInputProps"/>
        </div>

        <div>
            <h2>Slots</h2>
            <p class="lead">Currently no slots are implemented in form input</p>
        </div>

        <div>
            <h2>Events</h2>
            <p class="lead">All the standard events are supported in button</p>
        </div>

    </page-template>
</template>

<script>
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import FormInput from 'lego-framework/src/forms/FormMaskInput';
import Input from './Input';
import codeStore from './code-storage/form-input.json';

export default {
    extends: Input,
    metaInfo: {
        path: 'components/mask-input/',
        title: 'Mask Input'
    },
    name: 'MaskInputDocumentation',
    components: { FormInput, PropTable },
    data () {
        return {
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
