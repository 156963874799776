<template>
    <page-template>
        <container py="3">
            <row>
                <col-12 lg="6">
                    <h3 class="text-primary">Html Headings</h3>
                    <component-wrapper :code="codeStore.htmlHeadings.code">
                        <h1>Heading 1</h1>
                        <h2>Heading 2</h2>
                        <h3>Heading 3</h3>
                        <h4>Heading 4</h4>
                        <h5>Heading 5</h5>
                        <h6>Heading 6</h6>
                    </component-wrapper>

                </col-12>
                <col-12 lg="6">
                    <h3 class="text-primary">Font Weights & Styles</h3>
                    <component-wrapper :code="codeStore.fontWeightsAndStyles.code">
                        <p class="font-weight-lighter">font-weight-lighter</p>
                        <p class="font-weight-normal">font-weight-normal</p>
                        <p class="font-weight-bold">font-weight-bold</p>
                        <p class="font-weight-bolder">font-weight-bolder</p>
                        <p class="font-italic">font-italic</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h3 class="text-primary">Font Size Variants</h3>
                    <component-wrapper :code="codeStore.otherRegularFontSizes.code">
                        <p class="text-fs--1">text-fs--1</p>
                        <p class="text-fs-0">text-fs-0</p>
                        <p class="text-fs-1">text-fs-1</p>
                        <p class="text-fs-2">text-fs-2</p>
                        <p class="text-fs-3">text-fs-3</p>
                        <p class="text-fs-4">text-fs-4</p>
                        <p class="text-fs-5">text-fs-5</p>
                        <p class="text-fs-6">text-fs-6</p>
                        <p class="text-fs-7">text-fs-7</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h3 class="text-primary">Display font Sizes</h3>
                    <component-wrapper :code="codeStore.displayFontSizes.code">
                        <p class="display-1">display-1</p>
                        <p class="display-2">display-2</p>
                        <p class="display-3">display-3</p>
                        <p class="display-4">display-4</p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h3 class="text-primary">Other Large font Sizes</h3>
                    <component-wrapper :code="codeStore.otherLargeFontSizes.code">
                        <p class="text-2x mb-5">text-2x</p>
                        <p class="text-3x mb-5">text-3x</p>
                        <p class="text-4x mb-5">text-4x</p>
                        <p class="text-5x mb-5">text-5x</p>
                    </component-wrapper>
                </col-12>
                <div class="col-lg-12 mt-4">
                    <h3>Font Items: SCSS Variables</h3>
                    <simple-table :fields="fields3" :data="data3" class="b-1"></simple-table>
                </div>
                <col-12 lg="6" mt="4">
                    <h3 class="text-primary">Text Cases</h3>
                    <component-wrapper :code="codeStore.textCases.code">
                        <p class="text-lowercase">text-lowercase</p>
                        <p class="text-uppercase">text-uppercase</p>
                        <p class="text-capitalize">text-capitalize</p>
                    </component-wrapper>
                </col-12>
                <col-12 mt="4">
                    <h3 class="text-primary">Text Alignments</h3>
                    <component-wrapper :code="codeStore.textAlignments.code">
                        <p class="text-left">
                            text aligned on left side
                        </p>
                        <p class="text-center">
                            text aligned on center
                        </p>
                        <p class="text-right">
                            text aligned on right side
                        </p>
                        <p class="text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Aliquid at blanditiis commodi corporis dolor inventore
                            labore provident.faf afa fadsfasfasfasdfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                        </p>
                        <p class="text-center text-lg-left">
                            text aligned left on large screens, aligned center on other screens

                        </p>
                        <p class="text-left text-lg-center">
                            text aligned center on large screens, aligned left on other screens
                        </p>
                        <p class="text-center text-lg-right">
                            text aligned right on large screens, aligned center on other screens
                        </p>
                        <p class="text-center text-lg-justify">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Aliquid at blanditiis commodi corporis dolor inventore
                            labore provident.faf afa fadsfasfasfasdfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                            Dicta, eveniet facilis.fsdfasdfasfasdfasfasfasfaadsfa
                        </p>
                    </component-wrapper>
                </col-12>
                <col-12 lg="6" mt="4">
                    <h3 class="text-primary">Other Classes</h3>
                    <component-wrapper :code="codeStore.otherClasses.code">
                        <p class="text-monospace">text-monospace</p>
                        <p class="text-muted">text-muted</p>
                        <p class="text-black-50">text-black-50</p>
                    </component-wrapper>
                </col-12>
                <col-12 mt="4">
                    <h3>Text Element Component</h3>
                    <p class="lead">Text properties can be set using this component</p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import TextElement from 'lego-framework/src/components/typography/TextElement';</p>
                    <p>Vue.component('text-el', TextElement);</p>
                    <p>&#60;text-el&#62;&#60;/text-el&#62;</p>
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="props"/>
                </col-12>
                <div class="col-lg-12">
                    <h3>SCSS Variables</h3>
                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>
            </row>
        </container>
    </page-template>
</template>

<script>
import Col12 from 'lego-framework/src/containers/grid/col-12';
import Row from 'lego-framework/src/containers/Row';
import Container from 'lego-framework/src/containers/layout/Container';
import typography from '../core-components/code-storage/typography.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import PropTable from '../docs-components/Props';

export default {
    name: 'TextElementDocs',
    components: { PropTable, ComponentWrapper, Col12, Row, Container },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--h1-font-size',
                    description: 'h1 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*2.5)'
                },
                {
                    slno: '2',
                    name: '--h2-font-size',
                    description: 'h2 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*2)'
                },
                {
                    slno: '3',
                    name: '--h3-font-size',
                    description: 'h3 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.75)'
                },
                {
                    slno: '4',
                    name: '--h4-font-size',
                    description: 'h4 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.5)'
                },
                {
                    slno: '5',
                    name: '--h5-font-size',
                    description: 'h5 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.25)'
                },
                {
                    slno: '6',
                    name: '--h6-font-size',
                    description: 'h6 heading tag font size',
                    defaultvalue: 'calc(--font-size-base)'
                },
                {
                    slno: '7',
                    name: '--headings-margin-bottom',
                    description: 'Headings margin bottom',
                    defaultvalue: 'calc(var(--spacer-3)/2)'
                },
                {
                    slno: '8',
                    name: '--headings-font-family',
                    description: 'Headings font family',
                    defaultvalue: 'inherit'
                },
                {
                    slno: '9',
                    name: '--hedings-font-weight',
                    description: 'Headings font weight',
                    defaultvalue: '500'
                },
                {
                    slno: '10',
                    name: '--headings-line-height',
                    description: 'Headings line height',
                    defaultvalue: '1.2'
                },
                {
                    slno: '11',
                    name: '--headings-color',
                    description: 'Headings color',
                    defaultvalue: 'inherit'
                },
                {
                    slno: '12',
                    name: '--display1-size',
                    description: 'Display 1 heading size',
                    defaultvalue: '6rem'
                },
                {
                    slno: '13',
                    name: '--display2-size',
                    description: 'Display 2 heading size',
                    defaultvalue: '5.5rem'
                },
                {
                    slno: '14',
                    name: '--display3-size',
                    description: 'Display 3 heading size',
                    defaultvalue: '4.5rem'
                },
                {
                    slno: '15',
                    name: '--display4-size',
                    description: 'Display 4 heading size',
                    defaultvalue: '3.5rem'
                },
                {
                    slno: '16',
                    name: '--display1-weight',
                    description: 'Display 1 heading Font Weight',
                    defaultvalue: '300'
                },
                {
                    slno: '17',
                    name: '--display2-weight',
                    description: 'Display 2 heading font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '18',
                    name: '--display3-weight',
                    description: 'Display 3 heading font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '19',
                    name: '--display4-size',
                    description: 'Display 4 heading font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '20',
                    name: '--display-line-height',
                    description: 'Display heading line height',
                    defaultvalue: 'var(--headings-line-height)(value:1.2)'
                },
                {
                    slno: '21',
                    name: '--lead-font-size',
                    description: 'Lead text font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.25)'
                },
                {
                    slno: '22',
                    name: '--lead-font-weight',
                    description: 'Lead text font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '23',
                    name: '--small-font-size',
                    description: 'Lead text font size',
                    defaultvalue: '80%'
                },
                {
                    slno: '24',
                    name: '--text-muted',
                    description: 'Text muted color',
                    defaultvalue: 'var(--color-gray-600)'
                },
                {
                    slno: '25',
                    name: '--blockqoute-small-color',
                    description: 'Blockqoute small color',
                    defaultvalue: 'var(--color-gray-600)'
                },
                {
                    slno: '26',
                    name: '--blockqoute small font size',
                    description: 'blockqoute small font size',
                    defaultvalue: 'var(--small-font-size)'
                },
                {
                    slno: '27',
                    name: '--blockqoute-font-size',
                    description: 'Blockqoute font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.25)'
                },
                {
                    slno: '28',
                    name: '--hr-border-color',
                    description: 'hr border color',
                    defaultvalue: 'var(--color-black-10)'
                },
                {
                    slno: '29',
                    name: '--hr-border-width',
                    description: 'hr border width',
                    defaultvalue: 'var(--border-width)'
                },
                {
                    slno: '30',
                    name: '--mark-padding',
                    description: '',
                    defaultvalue: '.2em'
                },
                {
                    slno: '31',
                    name: '--dt-font-weight',
                    description: '',
                    defaultvalue: 'var(--font-weight-bold)'
                },
                {
                    slno: '32',
                    name: '--kbd-box-shadow',
                    description: '',
                    defaultvalue: 'inset 0 - 1rem 0var(--color-black-30)'
                },
                {
                    slno: '33',
                    name: '--nested-kbd-font-weight',
                    description: '',
                    defaultvalue: 'var(--font-weight-bold)'
                },
                {
                    slno: '34',
                    name: '--list-inline-padding',
                    description: '',
                    defaultvalue: '.5rem'
                },
                {
                    slno: '35',
                    name: '--mark-bg',
                    description: '',
                    defaultvalue: '#fcf8e3'
                },
                {
                    slno: '36',
                    name: '--hr-margin-y',
                    description: 'hr vertical margin',
                    defaultvalue: 'var(--spacer-3)'
                }
            ],
            codeStore: typography,
            props: [
                ['tag', 'String', 'html tag name', ['any html tag, eg:div,section,main, footer,aside,...'], 'div'],
                ['align', 'String', 'text alignment', ['l', 'r', 'c', 'justify'], ''],
                ['mdAlign', 'String', 'text alignment on medium screens', ['l', 'r', 'c', 'justify'], ''],
                ['lgAlign', 'String', 'text alignment on large screens', ['l', 'r', 'c', 'justify'], ''],
                ['fontWeight', 'String', 'font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder'], ''],
                ['fs', 'String', 'font size variangs', ['-1 to 7']],
                ['color', 'String', 'color variants', ['primary', 'secondary', '...', '1', '2', '...']],
                ['textWrap', 'Boolean', 'Set property { white-space: normal !important; }', ['true', 'false'], 'false'],
                ['textNoWrap', 'Boolean', 'Set property { white-space: nowrap !important; }', ['true', 'false'], 'false'],
                ['textTruncate', 'Boolean', 'set property { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }', ['true', 'false'], 'false'],
                ['textLowercase', 'Boolean', 'set property { text-transform: lowercase !important; }', ['true', 'false'], 'false'],
                ['textUppercase', 'Boolean', 'set property { text-transform: uppercase !important; }', ['true', 'false'], 'false'],
                ['textUppercase', 'Boolean', 'set property { text-transform: uppercase !important; }', ['true', 'false'], 'false'],
                ['textCapitalize', 'Boolean', 'set property { text-transform: capitalize !important; }', ['true', 'false'], 'false'],
                ['textMuted', 'Boolean', 'tex-muted (dim color) is set ', ['true', 'false']],
                ['textDecorationNone', 'Boolean', 'Set property { text-decoration: none !important; }', ['true', 'false'], 'false'],
                ['textBreak', 'Boolean', 'Set property {\n' +
                '    word-break: break-word !important; // Deprecated, but avoids issues with flex containers\n' +
                '    word-wrap:  break-word !important; // Used instead of `overflow-wrap` for IE & Edge Legacy\n' +
                '}', ['true', 'false'], 'false'],
                ['textReset', 'Boolean', 'Set property { color: inherit !important; }', ['true', 'false'], 'false'],
                ['textHide', 'Boolean', 'Set properties set to hide text, font:             0/0 a;\n' +
                '    color:            transparent;', ['true', 'false'], 'false'],
                ['additionalClasses', 'String', 'Additional classes to be added']
            ],
            fields3: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-20r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data3: [
                {
                    slno: '1',
                    name: '--text-fs--3',
                    description: 'font size is set using this. class related\n ' +
                        '1. text-fs--3',
                    defaultvalue: '0.46875rem'
                },
                {
                    slno: '2',
                    name: '--text-fs--2',
                    description: 'Similar to the first item',
                    defaultvalue: '0.5625rem'
                },
                {
                    slno: '3',
                    name: '--text-fs--1',
                    description: 'Similar to the first item',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '4',
                    name: '--text-fs-0',
                    description: 'Similar to the first item',
                    defaultvalue: '0.875rem'
                },
                {
                    slno: '5',
                    name: '--text-fs-1',
                    description: 'Similar to the first item',
                    defaultvalue: '1rem'
                },
                {
                    slno: '6',
                    name: '--text-fs-2',
                    description: 'Similar to the first item',
                    defaultvalue: '1.25rem'
                },
                {
                    slno: '7',
                    name: '--text-fs-3',
                    description: 'Similar to the first item',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '8',
                    name: '--text-fs-4',
                    description: 'Similar to the first item',
                    defaultvalue: '2.125rem'
                },
                {
                    slno: '9',
                    name: '--text-fs-5',
                    description: 'Similar to the first item',
                    defaultvalue: '3rem'
                },
                {
                    slno: '10',
                    name: '--text-fs-sm-5',
                    description: 'Similar to the first item',
                    defaultvalue: '2.4rem'
                },
                {
                    slno: '11',
                    name: '--text-fs-6',
                    description: 'Similar to the first item',
                    defaultvalue: '3.75rem'
                },
                {
                    slno: '12',
                    name: '--text-fs-7',
                    description: 'Similar to the first item',
                    defaultvalue: '6rem'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
