<template>
    <table class="table table-bordered">
        <thead>
        <tr>
            <th style="width: 6rem">Prop</th>
            <th>Type</th>
            <th>Description</th>
            <th>Allowed Values</th>
            <th>Default Value</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="[prop, type, description, values, default_value] in items" :key="prop">
            <td v-html="prop"></td>
            <td v-html="type"></td>
            <td>{{ description }}</td>
            <td>
                <ol class="ml-4">
                    <li v-for="(value, index) in values" :key="index" v-html="value"></li>
                </ol>
            </td>
            <td>{{default_value}}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'PropTable',
    props: {
        items: Array
    }
};
</script>
