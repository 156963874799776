<template>
    <div>
        <div class="container">
            <h3 class="my-3">Cards</h3>
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import CustomHeightContainer from 'lego-framework/src/containers/CustomHeightContainer';</p>
            <p>Vue.component('custom-h-el', CustomHeightContainer);</p>
            <p>&#60;custom-h-el&#62;&#60;custom-h-el /&#62;</p>
            <div class="row mt-4">
                <div class="col-lg-4 col-12">
                    <a class="mu-p text-decoration-none" target="_blank">
                        <div class="bg-2 bs-4 b-1 bg-3-h">
                            <a href="" class="mb-0" target="_blank">
                                <custom-h-el height="10r" class="bg bg-cover bg-pos-c"
                                             :style="{ backgroundImage: 'url(' + 'https://plus.unsplash.com/premium_photo-1671987556132-313d34dfa559?q=80&w=1986&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' + ')' }">
                                </custom-h-el>
                            </a>
                            <div class="px-3">
                                <div class="fl-te-c pt-3 pb-2">
                                    <div class="fl-x fl-a-c">
                                        <i class="fa fa-map-marker"></i>
                                        <span class="ml-2 text-fs-0 ml-2 text-2">Application closed</span>
                                    </div>
                                    <div class="fl-x fl-a-c mu-p">
                                        <i class="fa fa-map-marker"></i>
                                        <span class="text-fs-0 ml-2 text-2">Place</span>
                                    </div>
                                </div>
                                <custom-h-el min-height="12r" class="mt-2">
                                    <h4 class="text-fs-2">Test Course 1</h4>
                                    <ul class="list-unstyled mt-3">
                                        <li class="fl-x mb-2">
                                             <span class="fl-x fl-a-c fl-j-s d-inline-block min-w-1re">
                                               <i class="fa fa-file"></i>
                                             </span>
                                            <span class="ml-2 text-2 text-fs-0">APPLICATION CLOSES : 2023-11-02</span>
                                        </li>
                                        <li class="fl-x mb-2 mt-1">
                                             <span class="ml-1 fl-x fl-a-c fl-j-s d-inline-block min-w-1re">
                                                <i class="fa fa-clock-o"></i>
                                             </span>
                                            <span class="ml-2 text-2 text-fs-0">DURATION : 3 Months</span>
                                        </li>
                                        <li class="fl-x mb-2 fl-a-c mt-1">
                                            <span class="ml-1 fl-x fl-a-c fl-j-s d-inline-block min-w-1re">&#8377;</span>
                                            <span class="ml-2 text-2 text-fs-0">COURSE FEE : Rs. 15000 /-</span>
                                        </li>
                                    </ul>
                                </custom-h-el>
                            </div>
                            <div class="text-center py-2 bt-1 b-light mt-2">
                                <div class="text-decoration-none mu-p">
                                    <span class="">KNOW MORE</span>
                                    <span class="ml-2"><i class="fa fa-plus"></i></span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="col-12 mt-4">
                    <code-container :code-str="codeStore.itemListing.code" :show-code-no-toggle="true"></code-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import codeStore from '../core-components/code-storage/cards.json';
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';

export default {
    name: 'TheCards',
    metaInfo: {
        path: 'components/cards/',
        title: 'Card'
    },
    components: { CodeContainer },
    data () {
        return {
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
