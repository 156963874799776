<template>
    <page-template>
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="my-3">Base Footer</h2>
                    <p>Footer component contains logo , link items and credits section</p>
                    <h5>Import component</h5>
                    <code-container code-str="import BaseFooter from 'lego-framework/src/web-components/base-footer/BaseFooter';" show-code-no-toggle/>
                    <p>use inside src\layouts\MainLayout.vue</p>
                </div>
                <div class="col-12 mt-4">
                    <h3 class="my-3">Background Color</h3>
                    <p>Prop: bgColor</p>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Background Color : 2 <span class="font-weight-normal">(white)</span> - (Default)</h5>
                    <base-footer :logo-img="logo" :items="footerLinks"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.default.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>Link Sections Data Format</h5>
                    <p class="mb-0">links.js</p>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.linksSectionsDataFormat.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Background Color : Primary</h5>
                    <base-footer :logo-img="logo" :items="footerLinks" bg-color="primary"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.bgPrimary.code"></code-container>

                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Background Color : 4</h5>
                    <base-footer :logo-img="logo" :items="footerLinks" bg-color="4"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.bgColor4.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Background Image set</h5>
                    <base-footer :logo-img="logo" :items="footerLinks" :bg-img="bgImg"></base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.bgImgSet.code"></code-container>

                </div>
                <div class="col-12 mt-3">
                    <h3 class="mb-3 mt-5">Designs</h3>
                    <p>Prop: design</p>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Design: basic-a</h5>
                    <base-footer :logo-img="logo" :items="footerLinks" design="basic-a"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.designBasicA.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Design: basic-b ( Includes logo and content)</h5>
                    <base-footer
                        :logo-img="logo"
                        :items="footerLinks"
                        design="basic-b"
                        content="Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                         Ab distinctio earum ex minima necessitatibus obcaecati provident,"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.designBasicBwithLogoOnTop.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Logo Sizes - (xs,sm,md,lg)</h5>
                    <p>Prop: logoSize</p>
                </div>
                <div class="col-12 mt-3">
                    <h5 class="my-3">Logo Size : xs</h5>
                    <base-footer :logo-img="logo" design="basic-b" logo-size="xs" :items="footerLinks"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.topLogoSizeXs.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Logo Size: sm</h5>
                    <base-footer :logo-img="logo" design="basic-b" logo-size="sm" :items="footerLinks"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.topLogoSizeSm.code"></code-container>

                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Logo Size: md</h5>
                    <base-footer :logo-img="logo" design="basic-b" logo-size="md" :items="footerLinks"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.topLogoSizeMd.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Logo Size: lg</h5>
                    <base-footer :logo-img="logo" design="basic-b" logo-size="lg" :items="footerLinks"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.topLogoSizeLg.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Slot for Content Below the Logo</h5>
                    <base-footer :logo-img="logo" design="basic-b" :items="footerLinks">
                        <template #content>
                            <address class="c-address mt-3 m-0 fs-lg--1">
                                <p class="mb-3">Headquarters Office Address</p>
                                <p class="mb-3">Mattupatty P.O, Munnar, Idukki</p>
                                Kerala - PIN :685 616
                            </address>
                        </template>
                    </base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.contentBelowLogoSlot.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Title and link Fonts Customization</h5>
                    <base-footer
                        :logo-img="logo"
                        :items="footerLinks"
                        title-color="primary"
                        title-font-size="1"
                        link-color="2"
                        link-font-size="0"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.titleAndLinkFontsCustomization.code"></code-container>

                </div>
                <div class="col-12 mt-3">
                    <h3 class="my-3 mt-5">Slots</h3>
                </div>
                <div class="col-12 mt-2">
                    <h5 class="my-3">Design: basic-b ( with slot : content) - without logo</h5>
                    <base-footer
                        :items="footerLinks"
                        design="basic-b">
                        <template #content>
                            <address class="c-address mt-3 m-0 fs-lg--1">
                                <p class="mb-3">Headquarters Office Address</p>
                                <p class="mb-3">Mattupatty P.O, Munnar, Idukki</p>
                                Kerala - PIN :685 616
                            </address>
                        </template>
                    </base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.slotContentWithoutLogo.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">List Section with Custom Slots</h5>
                    <base-footer :logo-img="logo" :items="FooterLinksWithSlot" design="basic-a">
                        <template slot="contact">
                            <p>Phone <br>
                                +91 9675 897 365 <br>
                                +91 9675 897 365 <br>
                                Email : info@kldb.com <br>
                                Fax : info@kldb.com</p>
                        </template>
                    </base-footer>
                    <p class="mt-4">Set Slot attribute in links data object</p>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.customSlotSetting.code"></code-container>
                    <p>use the slot which set on the link object</p>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.customSlotUsage.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-3">Slot for adding items on top of the links ( slot: 'top-content')</h5>
                    <base-footer :logo-img="logo" :items="footerLinks" logo-size="md" design="basic-b">
                        <template #top-content>
                            <flex j="c" a="c" py="4">
                                <div class="text-center">
                                    <p class="text-fs-2 font-weight-bold">Lets get started on Something great</p>
                                    <p>Join over 4,000+ startups already growing with United.</p>
                                    <div class="mt-5">
                                        <btn text="Chat to Us" design="basic-b" class="mr-2"></btn>
                                        <btn text="Get Started"></btn>
                                    </div>
                                </div>
                            </flex>
                        </template>
                    </base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.topContentSlot.code"></code-container>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Slot for Adding Custom Logo with slot ( slot: 'logo')</h5>
                    <base-footer :logo-img="logo" design="basic-b" :items="footerLinks">
                        <template #logo>
                            <img src="../../assets/img/logo-black.svg" class="img-fluid" style="height:9rem !important;" alt="">
                        </template>
                    </base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.logoSlot.code"></code-container>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Slot for setting content below the logo ( slot: 'content')</h5>
                    <base-footer :logo-img="logo" design="basic-b" :items="footerLinks">
                        <template #content>
                            <address class="c-address mt-3 m-0 fs-lg--1">
                                <p class="mb-3">Headquarters Office Address</p>
                                <p class="mb-3">Mattupatty P.O, Munnar, Idukki</p>
                                Kerala - PIN :685 616
                            </address>
                        </template>
                    </base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.contentBelowLogoSlot.code"></code-container>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Slots for setting Bottom content ( slots: 'bottom-left', 'bottom-right')</h5>
                    <base-footer :logo-img="logo" design="basic-b" :items="footerLinks">
                        <template #bottom-left>
                            {{ new Date().getFullYear() }} Company All Rights Reserved.
                        </template>
                        <template #bottom-right>
                            <div class="text-fs-1 text-primary">
                                <router-link to="/" class="text-decoration-none">Privacy Policy</router-link>
                                <router-link to="/" class="text-decoration-none ml-2">Terms and Condition</router-link>
                            </div>
                        </template>
                    </base-footer>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.slotForBottomContents.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import FooterLinks from './footer-data/links';
import FooterLinksWithSlot from './footer-data/links-with-slot';
import bgImg from '../../assets/img/banner/photo-1619425234456-55a97b272611.jpg';
import logo from '../../assets/img/logo-black.svg';
import codeStore from './code-storage/base-footer.json';
import BaseFooter from 'lego-framework/src/web-components/base-footer/BaseFooter';
// import CodeContainer from '../../../components/doc-items/CodeContainer';
import flex from 'lego-framework/src/containers/layout/Flex';
import PropTable from '../docs-components/Props';

export default {
    name: 'BaseFooterDesignVariationsDocs',
    metaInfo: {
        path: 'web-components/base-footer/',
        title: 'BaseFooter'
    },
    components: { BaseFooter, flex, PropTable },
    data () {
        return {
            footerLinks: FooterLinks(),
            FooterLinksWithSlot: FooterLinksWithSlot(),
            bgImg: bgImg,
            logo: logo,
            codeStore: codeStore,
            propsInfo: [
                ['items', 'Object', 'links data object', '', ''],
                ['content', 'String', 'Content below footer', '', ''],
                ['logoImg', 'String', 'Logo Image Url', '', ''],
                ['bgColor', 'String', 'Background Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], '2'],
                ['bgImg', 'String', 'Background Image', '', ''],
                ['titleFontSize', 'String', 'Links Section title font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['linkFontSize', 'String', 'Link item font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['titleColor', 'String', 'Links Section title font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['linkColor', 'String', 'Link item font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['design', 'String', 'Design Variations', ['basic-a', 'basic-b'], 'basic-a'],
                ['logoSize', 'String', 'Logo Size', ['xs', 'sm', 'md', 'lg'], 'md'],
                ['credit', 'String', 'Credit text after year text', '', ''],
                ['creditFontSize', 'String', 'Bottom credit text font size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['creditColor', 'String', 'Links Section title font color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['titleClasses', 'String', 'Additional classes for links section title', '', ''],
                ['linkClasses', 'String', 'Additional classes for links section title', '', ''],
                ['containerClasses', 'String', 'Additional classes for root parent element', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
