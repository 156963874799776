<template>
    <div>
        <progress-steps-design4 :items="items"/>
        <div class="btn-group mt-5">
            <btn size="sm" text="1st step ongoing" @click="statusChangeToOngoing(1)"/>
            <btn size="sm" text="1st step done" @click="statusChange(1)"/>
            <btn size="sm" text="2nd step ongoing" @click="statusChangeToOngoing(2)"/>
            <btn size="sm" text="2nd step done" @click="statusChange(2)"/>
            <btn size="sm" text="3rd step ongoing" @click="statusChangeToOngoing(3)"/>
            <btn size="sm" text="3rd step done" @click="statusChange(3)"/>
            <btn size="sm" text="4th step ongoing" @click="statusChangeToOngoing(4)"/>
            <btn size="sm" text="4th step done" @click="statusChange(4)"/>
        </div>
    </div>
</template>

<script>
import ProgressStepsDesign4 from '../../progress-steps-updated/design-2/ProgressStepsDesign2';

export default {
    name: 'ProgressSteps4Usage',
    components: { ProgressStepsDesign4 },
    data () {
        return {
            items: [
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                }
            ]
        };
    },
    methods: {
        statusChange (idx) {
            this.items[idx - 1].status = 'done';
        },
        statusChangeToOngoing (idx) {
            this.items[idx - 1].status = 'ongoing';
        }
    }
};
</script>

<style scoped>

</style>
