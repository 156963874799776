<template>
    <div class="py-5 bg-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-fs-4 my-3">Custom Height & Width Container</h1>
                    <p class="lead">element with custom paddings,margins, display,bg color, height and width properties</p>
                    <h4 class="my-3">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Height: 10r, Width: 15r</h5>
                    <custom-spacing-element class="b-1 bg-primary" height="10r" width="15r">
                        Height: 10r, Width: 15r
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.heightAndWidth.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Padding(p): 5rem</h5>
                    <custom-spacing-element class="b-1 bg-primary" p="5rem">
                        padding: 5rem
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.padding.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Padding - top,right,bottom, left</h5>
                    <custom-spacing-element class="b-1 bg-primary" pt="1rem" pr="2rem" pb="3rem" pl="2.5rem">
                        Padding - top,right,bottom, left
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.paddingAll.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">display: flex , default(block)</h5>
                    <custom-spacing-element class="b-1" p="3" display="flex">
                        <div class="b-1 bg-primary p-2">display</div>
                        <div class="b-1 bg-primary p-2 ml-1">block</div>
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.display.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Margin (m=4rem)</h5>
                    <custom-spacing-element class="b-1 bg-primary" m="4rem">
                        with margin
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.margin.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Margin top,right,bottom, left</h5>
                    <custom-spacing-element width="5r" class="b-1 bg-primary" mt="4rem"
                                            mr="2rem" mb="3rem" ml="4rem">
                        with margin
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.marginAll.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Bg Color and Bg Color Thickness</h5>
                    <custom-spacing-element m="3rem" bg-color="secondary" bg-color-thickness="100">
                        Bg Color and Bg Color Thickness
                    </custom-spacing-element>
                    <code-container show-code-no-toggle :code-str="codeStore.bgColorAndThickness.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomSpacingElement from '../../containers/CustomSpacingElement';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/custom-spacing-el.json';
import PropTable from '../docs-components/Props';

export default {
    name: 'CustomSpacingContainerDocs',
    metaInfo: {
        path: 'ui-elements/custom-spacing/',
        title: 'Custom Spacing Container'
    },
    components: { CodeContainer, CustomSpacingElement, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['tag', 'String', 'html tag', ['any html tag'], 'div'],
                ['text', 'String', 'text value for child', '', ''],
                ['display', 'String', 'css display property', '', ''],
                ['p', 'String', 'Padding css value', '', ''],
                ['pt', 'String', 'Padding top value', '', ''],
                ['pr', 'String', 'Padding right value', '', ''],
                ['pb', 'String', 'Padding bottom value', '', ''],
                ['pl', 'String', 'Padding left value', '', ''],
                ['m', 'String', 'Margin css value', '', ''],
                ['mt', 'String', 'Margin top value', '', ''],
                ['mr', 'String', 'Margin right value', '', ''],
                ['mb', 'String', 'Margin bottom value', '', ''],
                ['ml', 'String', 'Margin left value', '', ''],
                ['height', 'String', 'height value in rem (re short eg:10re), vh or other units', '', ''],
                ['width', 'String', 'width value in rem (re short eg:10re), vh or other units', '', ''],
                ['containerClasses', 'String', 'container additional classes', '', ''],
                ['bgColor', 'String', 'bg color variants', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['bgColorThickness', 'String', 'bg color thickness value', ['100', '200', '300', '400', '600', '700', '800', '900'], '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
