<template>
    <base-form-control v-bind="formControlAttributes" @clear-clicked="clear">
        <slot name="prepend"></slot>
        <input v-on="listeners" v-bind="controlAttributes" :disabled="disabled" v-model="model" :type="type" :class="[inputElementClasses]" class="control"/>
        <slot name="append"></slot>
    </base-form-control>
</template>

<script>
import BaseFormControl from './BaseFormControl';
import BaseInput from './BaseInput';

export default {
    name: 'form-input',
    components: { BaseFormControl },
    extends: BaseInput,
    props: {
        type: { type: String, default: 'text' },
        inputElementClasses: { type: [String, Array], default: 'text' }
    }

};
</script>
