<template>
    <div class="bt-1" :class="[containerDefaultClasses, marginClasses, paddingClasses]">
        <slot name="top"></slot>
        <!--Top Heading and Location Tag-->
        <div class="lg-fl-te-c">
            <!--Top Left and Right Items-->
            <div class="fl-a-c lg-fl-x">
                <slot name="text1">
                    <text-el
                        no-margin
                        class="d-inline-block mr-1"
                        :color="text1Color"
                        :fs="text1FontSize"
                        :font-weight="text1FontWeight"
                        :additional-classes="[text1Classes]">
                        {{ text1 }}
                    </text-el>
                </slot>
                <!--tag item for medium and large screens-->
                <div class="d-none d-lg-flex">
                    <slot name="text2">
                        <tag-item-plain
                            :text="text2"
                            :text-color="text2Color"
                            :text-font-size="text2FontSize"
                            :text-font-weight="text2FontWeight"
                            :text-classes="[text2Classes]"/>
                    </slot>
                </div>
            </div>
            <!--Top Right items slot (top-right)-->
            <slot name="top-right">
                <div class="fl-x mt-1 mt-lg-0">
                    <slot name="text3">
                        <icon-text
                            :img="rightIcon"
                            icon-size="3"
                            border="2"
                            border-radius="2"
                            px="1"
                            :text="text3"
                            :text-color="text3Color"
                            :text-font-size="text3FontSize"
                            :text-font-weight="text3FontWeight"
                            :text-classes="[text3Classes]"/>
                    </slot>
                    <!--tag item for small screens-->
                    <div class="d-md-none ml-1">
                        <slot name="text2">
                            <tag-item-plain
                                :text="text2"
                                :text-color="text2Color"
                                :text-font-size="text2FontSize"
                                :text-font-weight="text2FontWeight"
                                :text-classes="[text2Classes]"/>
                        </slot>
                    </div>
                </div>
            </slot>
        </div>
        <!--text2 -content paragraph-->
        <slot name="text4">
            <text-el
                class="pt-2"
                :color="text4Color"
                :fs="text4FontSize"
                :font-weight="text4FontWeight"
                :additional-classes="[text4Classes]">
                {{ text4 }}
            </text-el>
        </slot>
        <!--bottom tags slot(bottom)-->
        <slot name="bottom">
            <div class="fl-x fl-a-c">
                <template v-if="tagItems">
                    <!--if tag arrays data prop is set-->
                    <icon-text
                        v-for="(tag,i) in
                        tagItems"
                        :key="tag.text"
                        :img="tag.icon"
                        :icon-size="tag.iconSize"
                        text-color="2"
                        :text="tag.text"
                        :ml="i>0?3:0"/>
                </template>
            </div>
        </slot>
    </div>
</template>

<script>
import TagItemPlain from '../tag-items/TagItemPlainWithBullet';
import IconText from '../tag-items/IconText';
import TextEl from '../../../components/typography/TextElement';
import marginsAndPaddingsMixin from '../../../components/mixins/margin-and-padding/marginsAndPaddingsMixin';
import iconAustraliaFlag from './assets/icons8-australia-24.png';

export default {
    name: 'TeamSectionPlainItemPlain',
    components: { TagItemPlain, IconText, TextEl },
    props: {
        linkUrl: { type: String, default: '/' },
        text1: { type: String, default: 'Product Designer' },
        text2: { type: String, default: 'Design' },
        text3: { type: String, default: 'Melbourne, Australia' },
        text4: { type: String, default: 'Were looking for a mid-level product designer to join our team.' },
        rightIcon: { type: String, default: iconAustraliaFlag },
        shadow: { type: String, default: '' },
        border: { type: String, default: '' },
        borderRadius: { type: String, default: '' },
        borderColor: { type: String, default: '' },
        tagItems: { type: Array, default: null },
        cardDesign: { type: Boolean, default: false },
        p: { type: String, default: '5' },

        text1Color: { type: String, default: '' },
        text1FontSize: { type: String, default: '' },
        text1FontWeight: { type: String, default: 'bold' },
        text1Classes: { type: String, default: '' },

        text2Color: { type: String, default: '' },
        text2FontSize: { type: String, default: '' },
        text2FontWeight: { type: String, default: '' },
        text2Classes: { type: String, default: '' },

        text3Color: { type: String, default: '' },
        text3FontSize: { type: String, default: '' },
        text3FontWeight: { type: String, default: '' },
        text3Classes: { type: String, default: '' },

        text4Color: { type: String, default: '' },
        text4FontSize: { type: String, default: '' },
        text4FontWeight: { type: String, default: '' },
        text4Classes: { type: String, default: '' }
    },
    computed: {
        containerDefaultClasses () {
            const classNames = [];
            if (this.border) {
                classNames.push('b-' + this.border);
            }
            if (this.borderColor) {
                classNames.push('b-' + this.borderColor);
            }
            if (this.borderRadius) {
                classNames.push('round-' + this.borderRadius);
            }
            if (this.shadow) {
                classNames.push('bs-' + this.shadow);
            }
            if (this.cardDesign) {
                classNames.push('bs-5 b-1 round-2');
            }
            return classNames;
        }
    },
    mixins: [marginsAndPaddingsMixin]
};
</script>

<style scoped>

</style>
