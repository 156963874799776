<template>
    <component :is="tag" class="container" :class="[marginClasses, paddingClasses, additionalClasses]">
        <slot></slot>
    </component>
</template>
<script>
import marginsAndPaddingsMixin from '../../mixins/margin-and-padding/marginsAndPaddingsMixin';

export default {
    name: 'container',
    mixins: [marginsAndPaddingsMixin],
    data () {
        return {};
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        additionalClasses: { type: [String, Array], default: '' }
    }
};
</script>
