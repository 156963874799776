<template>
<!--    <div class="round-1 b-1 bs-1 p-3 bg-3" style="background-color: rgba(240,240,240,0.45);">-->
    <div class="round-1 b-1 bs-1 py-3 bg-3" style="background-color: rgba(238,238,238,0.25);">
        <h5 class="text-primary text-center mb-3">{{ title }}</h5>
        <div class="py-3 px-3">
            <slot></slot>
        </div>
        <div>
            <code-container :show-code-no-toggle="showCodeNoToggle" :code-str="code" :usage="usage" :bg="bg"></code-container>
        </div>
    </div>
</template>

<script>
import CodeContainer from './CodeContainer';

export default {
    name: 'ComponentWrapper',
    components: { CodeContainer },
    props: {
        code: {
            type: String,
            required: true,
            default: "printf('abcd');"
        },
        usage: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        bg: {
            type: String,
            default: 'light'
        },
        showCodeNoToggle: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style scoped>
</style>
