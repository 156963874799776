<template>
    <svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1139_75914)">
            <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="#F9F5FF"/>
            <path d="M1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20Z" :fill="'var(--color-' + color + ')'"/>
            <path d="M1 20C1 9.50659 9.50659 1 20 1C30.4934 1 39 9.50659 39 20C39 30.4934 30.4934 39 20 39C9.50659 39 1 30.4934 1 20Z" :stroke="'var(--color-' + color + ')'" stroke-width="2"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M28.4939 12.3168L16.5606 23.8334L13.3939 20.4501C12.8106 19.9001 11.8939 19.8668 11.2273 20.3334C10.5773 20.8168 10.3939 21.6668 10.7939 22.3501L14.5439 28.4501C14.9106 29.0168 15.5439 29.3668 16.2606 29.3668C16.9439 29.3668 17.5939 29.0168 17.9606 28.4501C18.5606 27.6668 30.0106 14.0168 30.0106 14.0168C31.5106 12.4834 29.6939 11.1334 28.4939 12.3001V12.3168Z"
                  fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1139_75914">
                <path d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<script>
export default {
    name: 'IconStepDone',
    props: {
        color: { type: String, default: 'primary' },
        width: { type: String, default: '20' },
        height: { type: String, default: '20' }
    }
};
</script>

<style scoped>

</style>
