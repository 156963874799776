import officeIcon from '../../../assets/images/icons/office-icon.svg';

const getMenu = function () {
    const menu = [
        {
            text: 'test',
            link: '#',
            items: [
                {
                    text: 'test 1',
                    link: '/web-components/SideNavBar/'
                }
            ]
        },
        {
            image: officeIcon,
            text: 'Home',
            link: '/sidebar-variants/'
        },
        {
            text: 'Essentials',
            image: officeIcon,
            link: '#',
            items: [
                {
                    text: 'Forms',
                    link: '/forms/'
                },
                {
                    text: 'Buttons',
                    link: '/buttons/'
                },
                {
                    text: 'Modals',
                    link: '/modals/'
                },
                {
                    text: 'Notifications',
                    link: '/notifications/'
                },
                {
                    text: 'Tabs',
                    link: '/tabs/'
                },
                {
                    text: 'Sections',
                    link: '/sections/'
                },
                {
                    text: 'Sidebars',
                    link: '/sidebars/'
                },
                {
                    text: 'Navbars',
                    link: '/navbars/'
                },
                {
                    text: 'Website Sections',
                    link: '/website-sections/'
                },
                {
                    text: 'Flex Items',
                    link: '/flex-items/'
                }
            ],
            dropdownPlacement: 'left'
        },
        {
            icon: '',
            text: 'Components',
            link: '/components/',
            items: [
                {
                    text: 'Buttons',
                    link: '/lego-buttons/'
                },
                {
                    text: 'Icons',
                    link: '/icons/'
                },
                {
                    text: 'Button Gruops',
                    link: '/btn-groups/'
                },
                {
                    text: 'Loading Animations',
                    link: '/loading-animations/'
                },
                {
                    text: 'Data Not Found',
                    link: '/data-not-found-components/'
                },
                {
                    text: 'Dashboard Stats',
                    link: '/dashboard-stats/'
                },
                {
                    text: 'Cards',
                    link: '/the-cards/'
                }
            ]
        },
        {
            icon: '',
            text: 'Scss',
            link: '#',
            items: [
                {
                    text: 'Spacing',
                    link: '/spacing/'
                },
                {
                    text: 'Background and Colors',
                    link: '/bg-and-colors/'
                }
            ]
        },
        {
            icon: '',
            text: 'Layout',
            link: '#',
            items: [
                {
                    text: 'Flex',
                    link: '/flex/'
                }
            ]
        },
        {
            icon: '',
            text: 'Typography',
            link: '/typography/',
            class: 'text-uppercase',
            items: [
                {
                    text: 'Headings',
                    link: '/headings/'
                }
            ]
        },
        {
            icon: '',
            text: 'Tables',
            class: '',
            items: [
                {
                    text: 'Simple Table',
                    link: '/simple-table/'
                },
                {
                    text: 'Vue Table',
                    link: '/vue-table-docs/'
                },
                {
                    text: 'Vue Table Card',
                    link: '/vue-table-card-docs/'
                }
            ]
        },
        {
            icon: '',
            text: 'Directives',
            class: '',
            items: [
                {
                    text: 'Click Outside',
                    link: '/directive-click-outside/'
                }
            ]
        },
        {
            icon: '',
            text: 'Web Components',
            class: '',
            items: [
                {
                    text: 'Team Avatar',
                    link: '/team-avatar-variants/'
                },
                {
                    text: 'Features Section',
                    link: '/features-section-item.json-variants/'
                },
                {
                    text: 'Testimonials',
                    link: '/Testimonials-Variants/'
                },
                {
                    text: 'Metrics',
                    link: '/Matrics-Variants-Page/'
                },
                {
                    text: 'Blog Item Card',
                    link: '/blog-item-card/'
                },
                {
                    text: 'Faq Item',
                    link: '/faq-items/'
                },
                {
                    text: 'Pricing Card',
                    link: '/price-cards/'
                },
                {
                    text: 'Newsletter',
                    link: '/Newsletter-Variants-Page/'
                },
                {
                    text: 'Base Footer',
                    link: '/base-footer/'
                },
                {
                    text: 'Newsletter',
                    link: '/Newsletter-Variants-Page/'
                },
                {
                    text: 'Faq Accordion',
                    link: '/faq-accordion/'
                },
                {
                    text: 'Contact us',
                    link: '/contact-us/'
                },
                {
                    text: 'Progress Circle',
                    link: '/progress-circle/'
                },
                {
                    text: 'Sidebars',
                    link: '/sidebar-variants/'
                }
            ]
        }

    ]
    ;

    // const adminMenu = [
    //     {
    //         text  : 'Admin Master Data',
    //         link  : '#',
    //         items : [
    //             {
    //                 text : 'Master Data',
    //                 link : '/app/master-data/'
    //             },
    //             {
    //                 text : 'Master Data',
    //                 link : '/app/master-data/'
    //             }
    //         ]
    //     }
    // ];

    // Do something similar for any number of roles
    return [
        ...menu
    ];
};
export default getMenu;
