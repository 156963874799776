<template>
    <div class="fl-x progress-step-item-1" :style="setLineWidth">
        <div v-if="showLine" class="pos-r">
            <line-item bg-color="1" :width="lineWidth" mt="0.65rem" :ml="lineMl" :mr="lineMr"/>
            <line-item bg-color="1" :width="lineWidth" mt="0.65rem" :ml="lineMl" :mr="lineMr"
                       class="pos-a top-0 progress-step-item-1-horizontal-line-animation" v-show="status==='ongoing' || status ==='done'"/>
        </div>
        <custom-h-el class="text-center" :width="sectionWidth">
            <icon-step-pending v-if="status ==='pending'" key="pending"/>
            <icon-step-ongoing v-if="status ==='ongoing'" key="ongoing"/>
            <icon-step-done v-if="status ==='done'" key="done"/>
            <text-el tag="div" :text="text1" :fs="text1FontSize" :color="setTextColor" :font-weight="text1FontWeight" my="1"/>
            <text-el m="0" tag="div" :text="text2" :fs="text2FontSize" :color="setTextColor"/>
        </custom-h-el>
    </div>
</template>

<script>
import TextEl from 'lego-framework/src/components/typography/TextElement';
import IconStepOngoing from '../assets/IconStepOngoing';
import IconStepPending from '../assets/IconStepPending';
import IconStepDone from '../assets/IconStepDone';
import LineItem from '../line-item';
import CustomHEl from 'lego-framework/src/containers/CustomHeightContainer';

export default {
    name: 'ProgressStepItem1',
    components: { LineItem, IconStepDone, IconStepPending, TextEl, IconStepOngoing, CustomHEl },
    props: {
        text1: { type: String, default: 'Your Details' },
        text2: { type: String, default: 'Please provide your name and email' },
        status: { type: String, default: 'pending' },
        text1FontWeight: { type: String, default: '500' },
        text1FontSize: { type: String, default: '1' },
        text2FontSize: { type: String, default: '0' },
        color: { type: String, default: 'primary' },
        borderColor: { type: String, default: '1' },
        lineWidth: { type: String, default: '' },
        lineMl: { type: String, default: '-5.5rem' },
        lineMr: { type: String, default: '-5.5rem' },
        showLine: { type: Boolean, default: true },
        sectionWidth: { type: String, default: '' }
    },
    computed: {
        setTextColor () {
            if (this.status === 'ongoing') {
                return this.color;
            }
            return '1';
        },
        setLineWidth () {
            let lineWidthVal = this.lineWidth;
            if (this.lineWidth.slice('-1') === 'r') {
                lineWidthVal = this.lineWidth + 'em';
            }
            return {
                '--progress-step-item-1-line-width': lineWidthVal
            };
        }
    }
};
</script>

<style scoped>
    /*.bg-fill-animation-dark {*/
    /*    animation: bgColor 0.5s linear;*/
    /*    animation-fill-mode: forwards;*/
    /*    !*padding: var(--spacer-1);*!*/
    /*    !*height: 100%;*!*/
    /*    background-color: var(--color-primary);*/
    /*}*/

    /*@keyframes bgColor {*/
    /*    from {*/
    /*        width: 0;*/
    /*    }*/
    /*    to {*/
    /*        width: 12rem;*/
    /*    }*/
    /*}*/
</style>
