<template>
    <page-template class="py-3 bg-2">
        <div class="">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Simple Table</h3>
                    <p class="lead">Simple table - customizable</p>
                    <h5>Usage</h5>
                    <p>Import Component on plugins/lego.js</p>
                    <p>import SimpleTable from 'lego-framework/src/tables/SimpleTable';</p>
                    <p>Vue.component('simple-table', SimpleTable);</p>
                    <p>&#60;simple-table :fields="fields" :data="data"&#62;&#60;simple-table/&#62;</p>
                </div>
                <div class="col-lg-12 mt-4">
                    <component-wrapper :code="codeStore.simpleTable.code">
                        <simple-table :fields="fields" :data="data">
                            <template #remarks="{rowData}">
                                {{data.indexOf(rowData)}}
                            </template>
                        </simple-table>
                    </component-wrapper>
                </div>
                 <div class="col-lg-12 mt-4">
                     <lego-heading tag="h4">Customized Components</lego-heading>
                 </div>
                 <div class="col-lg-12 mt-4">
                     <lego-heading tag="h6">Table Border Collapse Separate</lego-heading>
                 </div>
                <div class="col-lg-12">
                    <component-wrapper :code="codeStore.simpleTableBorderSeparate.code">
                        <simple-table-border-separate table-heading="Table Title" :fields="fields1" :data="data"></simple-table-border-separate>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                     <lego-heading tag="h6">Table with Header Contains Title and Buttons</lego-heading>
                 </div>
                <div class="col-lg-12">
                    <component-wrapper :code="codeStore.simpleTableHeaderAndButtons.code">
                        <simple-table-border-separate table-heading="Table Title" :fields="fields1" :data="data">
                            <template #heading>
                                <h5 class="text-5 font-poppins-semibold fs-lg-2">Table Title</h5>
                            </template>
                            <template #buttons>
                                <btn>Add</btn>
                                <btn>Update</btn>
                            </template>
                        </simple-table-border-separate>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                     <lego-heading tag="h6">Table with Custom Slots for Columns</lego-heading>
                 </div>
                <div class="col-lg-12">
                    <component-wrapper :code="codeStore.simpleTableCustomSlots.code">
                        <simple-table-border-separate :fields="fields3" :data="data3">
                            <template #heading>
                                <h5 class="text-fs-2">Sessions Data</h5>
                            </template>
                            <template #sessions>
                                <div class="py-2 px-3">
                                    <div class="py-1 px-3 b-1 b-primary round-1 text-primary text-center">First</div>
                                </div>
                                <div class="py-2 px-3">
                                    <div class="py-1 px-3 b-1 b-primary round-1 text-primary text-center">Second</div>
                                </div>
                            </template>
                            <template #a="{rowData}">
                                <div class="py-2">
                                    <div class="py-1 ">{{rowData.sessions.first.a}}</div>
                                </div>
                                <div class="py-2">
                                    <div class="py-1 ">{{rowData.sessions.second.a}}</div>
                                </div>
                            </template>
                            <template #b="{rowData}">
                                <div class="py-2 ">
                                    <div class="py-1 ">{{rowData.sessions.first.b}}</div>
                                </div>
                                <div class="py-2">
                                    <div class="py-1">{{rowData.sessions.second.b}}</div>
                                </div>
                            </template>
                            <template #c="{rowData}">
                                <div class="py-2 ">
                                    <div class="py-1">{{rowData.sessions.first.c}}</div>
                                </div>
                                <div class="py-2">
                                    <div class="py-1">{{rowData.sessions.second.c}}</div>
                                </div>
                            </template>
                        </simple-table-border-separate>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                     <lego-heading tag="h6">Table - Header Colored</lego-heading>
                 </div>
                <div class="col-lg-12">
                    <component-wrapper :code="codeStore.simpleTableHeaderColored.code">
                        <simple-table-header-colored :fields="fields2" :data="data2">
                            <template #heading>
                                <h5 class="text-5 font-poppins-semibold fs-lg-2">Table Title</h5>
                            </template>
                            <template #buttons>
                                <btn design="basic-a" color="secondary">View All</btn>
                            </template>
                            <template #name="{rowData}">
                                <div class="py-1 text-primary font-poppins-bold">{{rowData.dept}}</div>
                            </template>
                            <template #data="{rowData}">
                                <div class="">
                                    <div class="py-1">
                                        <span>Subject 1 : {{rowData.subject1.title}}</span> <span>{{rowData.subject1.score}}</span>
                                    </div>
                                    <div class="py-1">
                                        <span>Subject 2 : {{rowData.subject2.title}}</span> <span>{{rowData.subject2.score}}</span>
                                    </div>
                                </div>
                            </template>
                        </simple-table-header-colored>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <div class="col-lg-12">
                    <h3>SCSS Variables</h3>
                    <simple-table :fields="fields6" :data="data6" class="b-1"></simple-table>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import SimpleTableBorderSeparate from 'lego-framework/src/tables/simple-table-extended/simple-table-border-separate';
import SimpleTableHeaderColored from 'lego-framework/src/tables/simple-table-extended/simple-table-header-colored';
import SimpleTable from 'lego-framework/src/tables/SimpleTable';
import codeStore from './code-store/simple-tables.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
export default {
    name: 'SimpleTableDocs',
    components: { SimpleTableBorderSeparate, SimpleTableHeaderColored, ComponentWrapper, SimpleTable, PropTable },
    data () {
        return {
            fields6: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-40r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data6: [
                {
                    slno: '1',
                    name: '--table-header-color',
                    description: 'table header color in table class',
                    defaultvalue: '#000'
                },
                {
                    slno: '2',
                    name: '--table-data-color',
                    description: 'table data color in table class',
                    defaultvalue: '#000'
                },
                {
                    slno: '3',
                    name: '--table-margin-bottom',
                    description: 'table margin bottom spacing value,depended variables ' +
                        '--container-separator' +
                        '--spacer-5 ' +
                        '<br>refer : src/scss/variables/core.js</br>',
                    defaultvalue: '3rem'
                },
                {
                    slno: '4',
                    name: '--table-cell-padding',
                    description: 'table cell item base padding',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--table-border-width',
                    description: 'Table border width',
                    defaultvalue: '1px'
                },
                {
                    slno: '6',
                    name: '--table-border-color',
                    description: 'table border color. Depended on other variable --border-color. Set on theme.json ' +
                        '<br>"border-color-levels": {' +
                        '    "border-color": "#E4E9F2",' +
                        '    "border-color-light": "#EDF1F7",' +
                        '    "border-color-dark": "#8F9BB3"' +
                        '}</br>',
                    defaultvalue: '#E4E9F2'
                },
                {
                    slno: '7',
                    name: '--table-cell-padding-sm',
                    description: 'table cell padding for small tables. class related is <br>table-sm</br>',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '8',
                    name: '--table-accent-bg',
                    description: 'background color for stripped table . <br>Related class : .table-striped depended variable : --bg-1  set on theme.json</br> ' +
                        '<br>"alternative-background-colors": {' +
                        '    "bg-1": "#111111",' +
                        '    "bg-2": "#212121",' +
                        '    "bg-3": "#0d0d0d",' +
                        '    "bg-4": "#191919"' +
                        '}</br>',
                    defaultvalue: '#111111'
                },
                {
                    slno: '9',
                    name: 'table-hover-color',
                    description: 'color for table hover . <br>Related class : .table-hover depended variable : --text-2  set on theme.json ' +
                        '<br>"text-colors": {' +
                        '    "text-1": "#161616",' +
                        '    "text-2": "#525252",' +
                        '    "text-3": "#666666",' +
                        '    "text-4": "#A8A8A8"' +
                        '}</br>',
                    defaultvalue: '#525252'
                },
                {
                    slno: '10',
                    name: '--table-hover-bg',
                    description: 'background for table hover .<br> Related class : .table-hover . depended variable : --bg-3 set on theme.json ' +
                        '<br>"alternative-background-colors": {' +
                        '    "bg-1": "#111111",' +
                        '    "bg-2": "#212121",' +
                        '    "bg-3": "#0d0d0d",' +
                        '    "bg-4": "#191919"' +
                        '}</br>',
                    defaultvalue: '#0d0d0d'
                }
            ],
            codeStore: codeStore,
            fields: [
                {
                    name: 'date',
                    title: 'Date',
                    titleClass: '',
                    dataClass: 'w-10r'
                },
                {
                    name: 'item',
                    title: 'Item Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'batch_no',
                    title: 'Batch No.',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: '__slot:remarks',
                    title: 'Remarks',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    date: '05-02-2021',
                    item: 'Item 1',
                    batch_no: 'S5',
                    quantity: '975.56 Kg',
                    remarks: 'Lorem ipsum dolor sit amet'
                },
                {
                    date: '06-03-2021',
                    item: 'Item 2',
                    batch_no: 'S1',
                    quantity: '898.36 Kg',
                    remarks: 'Sed ut perspiciatis unde omniserror sit voluptatem accusanti'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 3',
                    batch_no: 'S2',
                    quantity: '958.12 Kg',
                    remarks: 'Nil'
                },
                {
                    date: '05-04-2021',
                    item: 'Item 4',
                    batch_no: 'S4',
                    quantity: '958.12 Kg',
                    remarks: 'dolor sit amet consectetr'
                }
            ],
            fields1: [
                {
                    name: 'date',
                    title: 'Date',
                    titleClass: ' ',
                    dataClass: 'w-10r bg-1'
                },
                {
                    name: 'item',
                    title: 'Item Name',
                    titleClass: '',
                    dataClass: 'w-15r bg-1'
                },
                {
                    name: 'batch_no',
                    title: 'Batch No.',
                    titleClass: ' ',
                    dataClass: 'w-10r bg-1'
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    titleClass: '',
                    dataClass: 'w-10r bg-1'
                },
                {
                    name: 'remarks',
                    title: 'Remarks',
                    titleClass: '',
                    dataClass: 'w-20r bg-1'
                }
            ],
            fields2: [
                {
                    name: '__slot:name',
                    title: '',
                    titleClass: '',
                    dataClass: 'w-5r bt-1 b-dark'
                },
                {
                    name: '__slot:data',
                    title: '',
                    titleClass: '',
                    dataClass: 'w-20r bt-1 b-dark'
                }
            ],
            data2: [
                {
                    dept: 'A',
                    subject1: {
                        title: 'Chemistry',
                        score: '85 Points'
                    },
                    subject2: {
                        title: 'Maths',
                        score: '80 Points'
                    }
                },
                {
                    dept: 'B',
                    subject1: {
                        title: 'Chemistry',
                        score: '80 Points'
                    },
                    subject2: {
                        title: 'Maths',
                        score: '90 Points'
                    }
                },
                {
                    dept: 'C',
                    subject1: {
                        title: 'Chemistry',
                        score: '75 Points'
                    },
                    subject2: {
                        title: 'Maths',
                        score: '85 Points'
                    }
                }
            ],
            fields3: [
                {
                    name: 'date',
                    title: 'Date',
                    titleClass: 'b-unset',
                    dataClass: 'bg-3 w-15r'
                },
                {
                    name: 'batch',
                    title: 'Batch/Day',
                    titleClass: 'b-unset',
                    dataClass: 'bg-3 w-10r'
                }, {
                    name: '__slot:sessions',
                    title: 'Sessions',
                    titleClass: 'b-unset',
                    dataClass: 'bg-4 w-10r'
                },
                {
                    name: '__slot:a',
                    title: 'A',
                    titleClass: 'b-unset',
                    dataClass: 'bg-4 w-10r'
                }, {
                    name: '__slot:b',
                    title: 'B',
                    titleClass: 'b-unset',
                    dataClass: 'bg-4 w-10r'
                },
                {
                    name: '__slot:c',
                    title: 'C',
                    titleClass: 'b-unset',
                    dataClass: 'bg-4 w-10r'
                },
                {
                    name: 'no_of_sessions',
                    title: 'No. of Sessions',
                    titleClass: ' b-unset',
                    dataClass: 'bg-3 w-15r'
                },
                {
                    name: 'remarks',
                    title: 'Remarks',
                    titleClass: ' b-unset',
                    dataClass: 'bg-3 w-15r'
                }
            ],
            data3: [
                {
                    date: '05-02-2021',
                    batch: 'B12',
                    sessions: {
                        first: {
                            a: '3Hrs',
                            b: '5Hrs',
                            c: '2Hrs'
                        },
                        second: {
                            a: '3Hrs',
                            b: '4Hrs',
                            c: '3Hrs'
                        }
                    },
                    no_of_sessions: '6',
                    remarks: 'Session completed Successfully'
                },
                {
                    date: '05-02-2021',
                    batch: 'B15',
                    sessions: {
                        first: {
                            a: '3Hrs',
                            b: '5Hrs',
                            c: '2Hrs'
                        },
                        second: {
                            a: '3Hrs',
                            b: '4Hrs',
                            c: '3Hrs'
                        }
                    },
                    no_of_sessions: '8',
                    remarks: 'Session delayed due to technical reasons'
                }
            ],
            propsInfo: [
                ['fields', 'Array', 'Table fields (array of field data objects)', '', ''],
                ['data', 'Array', 'Array of data objects', '', ''],
                ['keyField', 'String', 'Key field name', '', ''],
                ['animation', 'String', 'Animation variant', ['fade', 'bounce'], 'fade'],
                ['showBorder', 'Boolean', 'Show/hide border', ['true', 'false'], 'false'],
                ['hideIfNoData', 'Boolean', 'Hide Table in no data', ['true', 'false'], 'false'],
                ['containerClasses', 'String, Array', 'root element additional classes', '', ''],
                ['tableAdditionalClasses', 'String, Array', 'additional classes to be added to table', '', ''],
                ['tableHeading', 'String, Array', 'Table heading text string', '', ''],
                ['tableHeadingContainerClasses', 'String, Array', 'Table heading container class', '', '']
            ]

        };
    }
};
</script>

<style scoped>

</style>
