<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Icon Text</h2>
                    <p class="lead">Icon Text element</p>
                    <h4 class="mt-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Default</h5>
                    <icon-text :img="iconImg"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">With Padding and border</h5>
                    <icon-text :img="iconImg" border="1"
                               shadow="5" p="2"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.withPaddingAndBorder.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">border Radius</h5>
                    <icon-text :img="iconImg" border="1"
                               shadow="5" p="2" border-radius="2"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderRadius.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">border color</h5>
                    <icon-text :img="iconImg" border="1"
                               border-color="info" shadow="5" p="2"
                               border-radius="2"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderColor.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Bg Color and Bg Color Thickness</h5>
                    <icon-text bg-color="primary" bg-color-thickness="200"
                               :img="iconImg" border="1" shadow="5" p="2"
                               border-radius="2"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.bgColorAndThickness.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Icon Size: 7</h5>
                    <icon-text icon-size="7" :img="iconImg" border="1"
                               shadow="5" p="2" border-radius="2"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.iconSize.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">reverse-alignment: true</h5>
                    <icon-text reverse-alignment icon-size="7" :img="iconImg"
                               border="1" shadow="5" p="2" border-radius="2"
                               text="Text is added here alongside the icon"/>
                    <code-container show-code-no-toggle :code-str="codeStore.reverse.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">icon only</h5>
                    <icon-text reverse-alignment icon-size="7" :img="iconImg"
                               border="1" shadow="5" p="2" border-radius="2"/>
                    <code-container show-code-no-toggle :code-str="codeStore.iconOnly.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import IconText from '../../views/tag-items/IconText';
import iconImg from '../../assets/images/icons/user.png';
import codeStore from '../code-storage/icon-text.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'IconTextVariantionsDocumentation',
    metaInfo: {
        path: 'ui-elements/icon-text/',
        title: 'Icon Text'
    },
    components: { IconText, CodeContainer, PropTable },
    data () {
        return {
            iconImg: iconImg,
            codeStore: codeStore,
            propsInfo: [
                ['img', 'String', 'Icon Image', '', ''],
                ['text', 'String', 'Text alongside icon', '', ''],
                ['textColor', 'String', 'text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['textFontSize', 'String', 'text font size', ['-1 - 7'], ''],
                ['textFontWeight', 'String', 'font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder', '100 - 900'], ''],
                ['textClasses', 'String, Array', ' text additional classes', '', ''],
                ['iconSize', 'String, Array', ' icon sizes', '1 - 11', '5'],
                ['border', 'String', 'border variant', '1 - 10', ''],
                ['borderRadius', 'String', 'border radius', '0 - 10', ''],
                ['borderColor', 'String', 'bg Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['shadow', 'String', 'shadow variant', ['1- 29'], ''],
                ['bgColor', 'String', 'bg Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['bgColorThickness', 'String', 'shadow variant', ['100', '200', '300', '400', '600', '700', '800', '900'], ''],
                ['reverseAlignment', 'Boolean', 'reverse text and icon alignment', ['true', 'false'], 'false'],
                ['containerClasses', 'String,Array', 'Container additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
