<template>
    <page-template>
        <div class="container-fluid py-4 bg-2 of-s">
            <h2>Width Helpers</h2>
            <div class="row ">
                <div class="col-4" v-for="[unit, name] in [['r', 'rem'], ['p', '%'], ['v', 'vw']]" :key="unit">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Class</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tr v-for="i in theme.widths" :key="unit + i">
                            <td>.w-{{ i }}{{ unit }}</td>
                            <td>Width {{ i }}{{ name }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="bg-4 mt-4 p-4">

                <h2>Benefits of Using Width Helper Classes</h2>

                <p>Width helper classes provide a convenient way to manage element widths in your web design. Here are
                    some
                    key reasons to use them:</p>

                <h5>1. Consistency</h5>
                <p>Using predefined classes ensures a consistent width across your project. This uniformity helps
                    maintain a
                    cohesive look and feel, making your design more visually appealing.</p>

                <h5>2. Simplified Maintenance</h5>
                <p>With width helper classes, you can easily apply or modify widths without having to write custom CSS.
                    This
                    simplification leads to faster development and easier maintenance of your styles.</p>

                <h5>3. Responsive Design</h5>
                <p>These classes can help you create responsive designs. By using classes that adjust widths based on
                    viewport size, you ensure that your layout adapts gracefully to different screen sizes.</p>

                <h5>4. Readability</h5>
                <p>Using descriptive class names like <code>.w-5r</code> or <code>.w-20r</code> makes your HTML more
                    readable. Other developers (or you in the future) can quickly understand the purpose of each
                    element's
                    width.</p>

                <h5>5. Quick Prototyping</h5>
                <p>When building prototypes or MVPs, width helper classes allow for rapid design iterations. You can
                    quickly
                    test various layouts without extensive CSS adjustments.</p>

            </div>

            <h3 class="">Width in Different Responsive Breakpoints</h3>
            <h4 class="my-3">Classes for width in breakpoint(lg)</h4>
            <p class="font-weight-normal">(NB:similar classes can also be used on sm,md and xl screens)</p>
            <div class="row ">
                <div class="col" v-for="[unit, name] in [['r', 'rem'], ['p', '%'], ['v', 'vw']]" :key="unit">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Class</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tr v-for="i in theme.widths" :key="unit + i">
                            <td>.lg-w-{{ i }}{{ unit }}</td>
                            <td>Width {{ i }}{{ name }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <h3 class="my-3">Min Width</h3>
            <div class="row ">
                <div class="col" v-for="[unit, name] in [['r', 'rem'], ['p', '%']]" :key="unit">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Class</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tr v-for="i in theme.widths" :key="unit + i">
                            <td>.min-w-{{ i }}{{ unit }}</td>
                            <td>Min-Width {{ i }}{{ name }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <h4 class="my-3">Classes for min-width in breakpoint(lg)</h4>
            <p class="font-weight-normal">(NB:similar classes can also be used on sm,md and xl screens)</p>
            <div class="row ">
                <div class="col" v-for="[unit, name] in [['r', 'rem'], ['p', '%']]" :key="unit">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Class</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tr v-for="i in theme.widths" :key="unit + i">
                            <td>.lg-min-w-{{ i }}{{ unit }}</td>
                            <td>Min-Width {{ i }}{{ name }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <h3 class="my-3">Max Width</h3>
            <div class="row ">
                <div class="col" v-for="[unit, name] in [['r', 'rem'], ['p', '%']]" :key="unit">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Class</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tr v-for="i in theme.widths" :key="unit + i">
                            <td>.max-w-{{ i }}{{ unit }}</td>
                            <td>Max-Width {{ i }}{{ name }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <h4 class="my-3">Classes for max-width in breakpoint(lg)</h4>
            <p class="font-weight-normal">(NB:similar classes can also be used on sm,md and xl screens)</p>
            <div class="row ">
                <div class="col" v-for="[unit, name] in [['r', 'rem'], ['p', '%']]" :key="unit">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>Class</td>
                            <td>Description</td>
                        </tr>
                        </thead>
                        <tr v-for="i in theme.widths" :key="unit + i">
                            <td>.lg-max-w-{{ i }}{{ unit }}</td>
                            <td>Max-Width {{ i }}{{ name }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="bg-4 p-5">
                <h2>Usage Note for Max Width and Min Width Classes</h2>

                <p>Max-width and min-width classes are vital for achieving responsive designs. Here’s how to use them
                    effectively:</p>

                <h5 class="mt-4">Max Width Classes</h5>
                <p>Max-width classes control the maximum size of an element, preventing it from becoming overly wide on
                    larger screens. Use them to:</p>
                <ul>
                    <li class="mt-3">Enhance Readability: Set a max width to ensure text remains easily readable without
                        line length issues.
                    </li>
                    <li class="mt-3">Maintain Aesthetics: Control the layout to keep visual elements balanced and
                        aligned, especially in grid layouts.
                    </li>
                    <li class="mt-3">Improve Usability: Ensure buttons and interactive elements do not stretch too much,
                        which could hinder user interaction.
                    </li>
                </ul>

                <h5 class="mt-3">Example Class:</h5>
                <p>.lg-max-w-5r - This sets a maximum width of 5rem for an element on large screens.</p>

                <h5 class="mt-5">Min Width Classes</h5>
                <p>Min-width classes establish a minimum size for elements, ensuring they remain usable on smaller
                    screens.
                    Use them to:</p>
                <ul>
                    <li class="mt-3">Prevent Overlapping: Keep elements from becoming too small, which can cause overlap
                        or confusion in layouts.
                    </li>
                    <li class="mt-3">Enhance Accessibility: Ensure that buttons and text do not shrink beyond a usable
                        size, improving accessibility for all users.
                    </li>
                </ul>

                <h5>Example Classes:</h5>
                <p>.lg-min-w-5r - Sets a minimum width of 5rem for an element on large screens.</p>
                <p>.lg-min-w-10r - Sets a minimum width of 10rem for an element on large screens.</p>

                <h5>Responsive Breakpoints</h5>
                <p>These classes can also be applied to other breakpoints such as <strong>sm</strong>,
                    <strong>md</strong>,
                    and <strong>xl</strong>. This flexibility allows for a tailored approach to responsiveness based on
                    the
                    specific needs of your design.</p>

                <h5>Conclusion</h5>
                <p>Using max-width and min-width classes strategically helps create responsive, user-friendly
                    interfaces.
                    They ensure that your design adapts effectively to different screen sizes, improving overall
                    usability
                    and aesthetics.</p>
            </div>
            <div class="mt-4">
                <h3>Example</h3>
                <code-container show-code-no-toggle :code-str="codeStore.width.code"/>
                <div class="b-2 w-5r">
                    <p>This box has a width of 5rem.</p>
                </div>

                <div class="b-2 w-10r">
                    <p>This box has a width of 10rem.</p>
                </div>

                <div class="b-2 w-15r">
                    <p>This box has a width of 15rem.</p>
                </div>
                <div class="b-2 lg-max-w-5r">
                    <p>This box has a max width of 5rem.</p>
                </div>

                <div class="b-2 lg-max-w-10r">
                    <p>This box has a max width of 10rem.</p>
                </div>

                <div class="b-2 lg-max-w-15r">
                    <p>This box has a max width of 15rem.</p>
                </div>
                <div class="b-2 lg-min-w-5r">
                    <p>This box has a min width of 5rem.</p>
                </div>

                <div class="b-2 lg-min-w-10r">
                    <p>This box has a min width of 10rem.</p>
                </div>

                <div class="b-2 lg-min-w-15r">
                    <p>This box has a min width of 15rem.</p>
                </div>
            </div>

            <div v-for="[unit, name] in [['r', 'rem'], ['p', '%'], ['v', 'vw']]" :key="unit" class="mt-4">
                <h3>Width {{ name }}</h3>
                <div class="bgc-primary-100" style="overflow: auto; position: relative;">
                    <div class="bg-primary py-4" v-for="i in theme.widths" :class="'w-' + i + unit" :key="unit + i">
                        .w-{{
                            i
                        }}{{ unit }}
                    </div>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import helpersMixin from '../helpersMixin';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/width.json';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'helpers/sizes/',
        title: 'Widths'
    },
    name: 'colors.scss',
    components: { CodeContainer },
    data () {
        return {
            codeStore: codeStore
        };
    }

};
</script>
