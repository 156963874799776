import officeIcon from '../../../assets/images/icons/office-icon.svg';

const getMenu = function () {
    const menu = [
        {
            text: '',
            link: '#'
        },
        {
            image: officeIcon,
            text: '',
            link: '/web-components/SideNavBar/'
        },
        {
            text: '',
            image: officeIcon,
            link: '#',
            dropdownPlacement: 'left'
        },
        {
            icon: '',
            image: officeIcon,
            text: '',
            link: '/components/'
        },
        {
            image: officeIcon,
            text: '',
            link: '#'
        },
        {
            image: officeIcon,
            text: '',
            link: '#'
        },
        {
            image: officeIcon,
            text: '',
            link: '/typography/',
            class: 'text-uppercase'
        },
        {
            image: officeIcon,
            text: '',
            class: ''
        },
        {
            image: officeIcon,
            text: '',
            class: ''
        },
        {
            image: officeIcon,
            text: '',
            class: ''
        },
        {
            image: officeIcon,
            text: '',
            class: ''
        },
        {
            image: officeIcon,
            text: '',
            class: ''
        },
        {
            image: officeIcon,
            text: '',
            class: ''
        }

    ];

    // const adminMenu = [
    //     {
    //         text  : 'Admin Master Data',
    //         link  : '#',
    //         items : [
    //             {
    //                 text : 'Master Data',
    //                 link : '/app/master-data/'
    //             },
    //             {
    //                 text : 'Master Data',
    //                 link : '/app/master-data/'
    //             }
    //         ]
    //     }
    // ];

    // Do something similar for any number of roles
    // if (user && user.admin === true) {
    //     return [
    //         ...menu,
    //         ...adminMenu
    //     ];
    // }
    return [
        ...menu
    ];
};
export default getMenu;
