<template>
    <page-template>
        <div class="mb-5">
            <h2>Upgrading the existing lego project</h2>
            <ol class="ml-5">
                <li>yarn upgrade lego-framework</li>
                <li>Copy the theme.json from the lego project</li>
                <li>Create env file with theme.json path</li>
                <li>Update the css variables if required.</li>
                <li>Add var-scss-loader in package.json and install it</li>
                <li>Add lego vue plugin in package.json</li>
            </ol>
        </div>
        <div class="mb-5">
            <h2>Implementing Code formatting</h2>
            <ol class="ml-5">
                <li>Update the eslintrc</li>
                <li>Update the editorconfig</li>
                <li>run yarn format</li>
            </ol>
        </div>

        <div class="mb-5">
            <h2>Adding docs to existing project</h2>
            <ol class="ml-5">
                <li>Import the docs route from the lego project</li>
                <li>Add it to the current project routes</li>
            </ol>
        </div>
    </page-template>
</template>

<script>

export default {
    metaInfo: {
        path: 'core/upgrade-existing-projects/',
        title: 'Upgrade Existing Projects'
    },
    name: 'colors.scss',
    components: {}
};
</script>
