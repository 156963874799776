const theme = require('lego-framework/src/assets/theme');

const colorHelpers = [
    ['bg-*', 'bg-color-name, sets background color and text color.'],
    ['bgc-*', 'bgc-color-name, sets background color.'],

    ['text-*', 'bgt-color-name, sets color as text color.']
];
const colorDemo = [
    ['bg-', 'Background and Text'],
    ['bgc-', 'Background Color Only'],
    ['bge-', 'Background and Text with a hover effect'],

    ['text-', 'Text Color']
];

const helpersMixin = {
    data () {
        const shadowClasses = [];

        for (let i = 0; i < 64; i += 1) {
            shadowClasses.push(i + 1);
        }
        return {
            theme: theme,
            colorHelpers: colorHelpers,
            colorDemo: colorDemo,
            shadowClasses: shadowClasses,
            flexAtomicHelpers: [
                ['fl-x', 'display: flex; flex-direction: row;', 'Place child elements in the horizontal fashion'],
                ['fl-y', 'display: flex; flex-direction: column;', 'Place child elements in the vertical fashion'],

                ['fl-a-s', 'align-items: flex-start;', 'Horizontal: Align items in the top, Vertical: Align items in the left'],
                ['fl-a-e', 'align-items: flex-end;', 'Horizontal: Align items in the bottom, Vertical: Align items in the right'],
                ['fl-a-c', 'align-items: center;', 'Horizontal: Align items in the center, Vertical: Align items in the middle'],
                ['fl-a-d', 'align-items: stretch;', 'The child elements will expands to take up the remaining space.'],

                ['fl-j-s', 'justify-contents: flex-start;', ''],
                ['fl-j-e', 'justify-contents: flex-end;', ''],
                ['fl-j-c', 'justify-contents: center;', ''],

                ['fl-j-sa', 'justify-contents: space-around;', ''],
                ['fl-j-se', 'justify-contents: space-evenly;', ''],
                ['fl-j-sb', 'justify-contents: space-between;', '']
            ],

            flexAlignments: [
                ['fl-x-tl', 'Align children in the top left horizontally'],
                ['fl-x-tr', 'Align children in the top right horizontally'],
                ['fl-x-tc', 'Align children in the top center horizontally'],

                ['fl-x-bl', 'Align children in the bottom left horizontally'],
                ['fl-x-br', 'Align children in the bottom right horizontally'],
                ['fl-x-bc', 'Align children in the bottom center horizontally'],

                ['fl-x-cl', 'Align children in the center left horizontally'],
                ['fl-x-cr', 'Align children in the center right horizontally'],
                ['fl-x-cc', 'Align children in the bottom center horizontally'],

                ['fl-y-tl', 'Align children in the top left vertically'],
                ['fl-y-tr', 'Align children in the top right vertically'],
                ['fl-y-tc', 'Align children in the top center vertically'],

                ['fl-y-bl', 'Align children in the bottom left vertically'],
                ['fl-y-br', 'Align children in the bottom right vertically'],
                ['fl-y-bc', 'Align children in the bottom center vertically'],

                ['fl-y-cl', 'Align children in the center left vertically'],
                ['fl-y-cr', 'Align children in the center right vertically'],
                ['fl-y-cc', 'Align children in the bottom center vertically']
            ],

            flexEqualAlignments: [
                ['fl-eq', 'Equal width columns, full height'],
                ['fl-eq-c', 'Equal width columns, aligned in the center'],
                ['fl-eq-t', 'Equal width columns, aligned in the top'],
                ['fl-eq-b', 'Equal width columns, aligned in the bottom'],

                ['fl-eqh', 'Equal Height Rows, full width'],
                ['fl-eqh-l', 'Equal Height Rows, aligned in the left'],
                ['fl-eqh-c', 'Equal Height Rows, aligned in the center'],
                ['fl-eqh-r', 'Equal Height Rows, aligned in the right']
            ],

            flexBasicHelpers: [
                ['fl-x-t', 'Align children in the top, horizontally'],
                ['fl-x-b', 'Align children in the bottom, horizontally'],
                ['fl-x-c', 'Align children in the center, horizontally'],

                ['fl-te-t', 'Align children in the top, in the two ends horizontally'],
                ['fl-te-b', 'Align children in the bottom, in the two ends horizontally'],
                ['fl-te-c', 'Align children in the center, in the two ends horizontally'],

                ['fl-y-l', 'Align children in the left, vertically'],
                ['fl-y-r', 'Align children in the right, vertically'],
                ['fl-y-c', 'Align children in the center, vertically']
            ],
            positionHelpers: [
                ['pos-a', 'Set the position of the element as absolute.'],
                ['pos-r', 'Set the position of the element as relative'],

                ['pos-st', 'Position an element at the top of the viewport, from edge to edge, but only after you scroll past it. '],
                ['pos-sb', 'Position an element at the bottom of the viewport, from edge to edge, but only after you scroll past it. '],

                ['pos-ft', 'Position an element at the top of the viewport, from edge to edge'],
                ['pos-fb', 'Position an element at the bottom of the viewport, from edge to edge']
            ],
            sizes: ['lg', 'md', 'sm', 'xs']
        };
    }
};

export default helpersMixin;
