import { render, staticRenderFns } from "./CustomSpacingContainerDocs.vue?vue&type=template&id=fabfc398&scoped=true&"
import script from "./CustomSpacingContainerDocs.vue?vue&type=script&lang=js&"
export * from "./CustomSpacingContainerDocs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fabfc398",
  null
  
)

export default component.exports