<template>
    <div :class="{'fl-x': design ==='basic-b'}">
        <activity-gauge-circle-customizable
            v-bind="circleAttributes"
            :series1-color="series1ColorThickness !== '' ? series1Color + '-' + series1ColorThickness: series1Color"
            :series2-color="series2ColorThickness !== '' ? series2Color + '-' + series2ColorThickness: series2Color"
            :series3-color="series3ColorThickness !== '' ? series3Color + '-' + series3ColorThickness: series3Color"/>
<!--        series indicators-->
        <series-items
            class="mt-2"
            :align="design ==='basic-b'?'vertical':'horizontal'"
            :class="[{'mt-6':  design ==='basic-b'}]"
            :series1-color="series1Color"
            :series2-color="series2Color"
            :series3-color="series3Color"
            :series1-color-thickness="series1ColorThickness"
            :series2-color-thickness="series2ColorThickness"
            :series3-color-thickness="series3ColorThickness"/>
    </div>
</template>

<script>
import ActivityGaugeCircleCustomizable from './circles/ActivityGaugeCircleCustomizable';
import SeriesItems from './SeriesItems';

export default {
    name: 'ActivityGaugeCustomizable',
    components: { SeriesItems, ActivityGaugeCircleCustomizable },
    props: {
        value1: { type: Number, default: 75 },
        value2: { type: Number, default: 60 },
        value3: { type: Number, default: 80 },
        label: { type: String, default: 'Users' },
        totalValue: { type: Number, default: 1000 },
        totalValueColor: { type: String, default: 'secondary' },
        series1Color: { type: String, default: 'primary' },
        series2Color: { type: String, default: 'secondary' },
        series3Color: { type: String, default: 'third' },
        series1ColorThickness: { type: String, default: '' },
        series2ColorThickness: { type: String, default: '' },
        series3ColorThickness: { type: String, default: '' },
        series1Radius: { type: String, default: '69' },
        series2Radius: { type: String, default: '95' },
        series3Radius: { type: String, default: '118' },
        labelPositionX: { type: String, default: '92px' },
        labelPositionY: { type: String, default: '245px' },
        totalValuePositionX: { type: String, default: '58px' },
        totalValuePositionY: { type: String, default: '78px' },
        svgSize: { type: String, default: '330' },
        design: { type: String, default: 'basic-a' },
        labelFontSize: { type: String, default: '16px' },
        labelFontWeight: { type: String, default: 'bold' },
        totalValueFontSize: { type: String, default: '30px' },
        totalValueFontWeight: { type: String, default: 'bold' }
    },
    computed: {
        circleAttributes () {
            return {
                value1: this.value1,
                value2: this.value2,
                value3: this.value3,
                label: this.label,
                totalValue: this.totalValue,
                labelFontSize: this.labelFontSize,
                labelFontWeight: this.labelFontWeight,
                totalValueFontSize: this.totalValueFontSize,
                totalValueColor: this.totalValueColor,
                totalValueFontWeight: this.totalValueFontWeight,
                smRadius: this.series1Radius,
                mdRadius: this.series2Radius,
                lgRadius: this.series3Radius,
                labelPositionX: this.labelPositionX,
                labelPositionY: this.labelPositionY,
                totalValuePositionX: this.totalValuePositionX,
                totalValuePositionY: this.totalValuePositionY,
                svgSize: this.svgSize
            };
        }
    }
};
</script>

<style scoped>

</style>
