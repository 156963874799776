export default {
    props: {
        bgColor: { type: String, default: '' },
        bgColorThickness: { type: String, default: '' }
    },
    computed: {
        bgColorClasses () {
            const classNames = [];
            if (this.bgColor && !this.bgColorThickness) {
                classNames.push('bg-' + this.bgColor);
            } else if (this.bgColor && this.bgColorThickness) {
                classNames.push('bgc-' + this.bgColor + '-' + this.bgColorThickness);
            }
            return classNames;
        }
    }
};
