<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Custom Height Container</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-30r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-30r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--custom-height-container-height',
                    description: 'container height',
                    defaultvalue: '""'
                },
                {
                    slno: '2',
                    name: '--custom-height-container-height-md',
                    description: 'container height on medium size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '3',
                    name: '--custom-height-container-height-lg',
                    description: 'container height on large size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '4',
                    name: '--custom-height-container-min-height',
                    description: 'container min-height',
                    defaultvalue: '""'
                },
                {
                    slno: '5',
                    name: '--custom-height-container-min-height-md',
                    description: 'container min-height on medium size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '6',
                    name: '--custom-height-container-min-height-lg',
                    description: 'container min-height on large size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '7',
                    name: '--custom-height-container-max-height',
                    description: 'container max-height',
                    defaultvalue: 'unset'
                },
                {
                    slno: '8',
                    name: '--custom-height-container-max-height-md',
                    description: 'container max-height on medium size screens',
                    defaultvalue: 'unset'
                },
                {
                    slno: '9',
                    name: '--custom-height-container-max-height-lg',
                    description: 'container max-height on large size screens',
                    defaultvalue: 'unset'
                },
                {
                    slno: '10',
                    name: '--custom-height-container-width',
                    description: 'container width',
                    defaultvalue: 'unset'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
