<template>
    <page-template>
        <div class=" bg-2">
            <div>
                <h3 class="my-3">Login Pages</h3>
                <p class="lead">Login component with authentication logic added</p>
                <h5>Usage</h5>
                <p class="text-left">
                    Add lego-modules to dependencies in package.json
                </p>
                <code-container :code-str="codeStore.design1.usage" show-code-no-toggle></code-container>
            </div>
            <h5 class="my-3">Design 1</h5>
            <!--            <img src="../../../assets/images/login-page-design1.png" class="img-fluid" alt="">-->
            <h6>Import component</h6>
            <code-container show-code-no-toggle code-str="import LoginPageDesign1 from 'lego-modules/src/pages/login/design-1';"></code-container>
            <login-page-design1></login-page-design1>
            <code-container :code-str="codeStore.design1.code" class="mt-3" :show-code-no-toggle="true"></code-container>
            <col-12 mt="4">
                <h4 class="mt-3">Props</h4>
                <prop-table :items="propsInfo"/>
            </col-12>
            <h4 class="py-3">Full Page Login</h4>
            <h6>Import component</h6>
            <code-container show-code-no-toggle code-str="import FullPageLogin from 'lego-modules/src/pages/login/FullPageLogin';"></code-container>
            <full-page-login :img="img"></full-page-login>
            <col-12 mt="4">
                <h4 class="mt-3">Props</h4>
                <prop-table :items="propsInfoFullPageLogin"/>
            </col-12>
        </div>
    </page-template>
</template>

<script>
import codeStore from '../code-store/login-pages.json';
import CodeContainer from '../../../components/doc-items/CodeContainer';
import LoginPageDesign1 from 'lego-modules/src/pages/login/design-1';
import FullPageLogin from 'lego-modules/src/pages/login/FullPageLogin';
import img from '../../../assets/img/banner/corporate-work-3.jpg';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'LoginPage',
    metaInfo: {
        path: 'auth-docs/login-pages/',
        title: 'Login Page'
    },
    components: { CodeContainer, LoginPageDesign1, FullPageLogin, PropTable },
    data () {
        return {
            codeStore: codeStore,
            img: img,
            propsInfo: [
                ['infoMessage', 'String', 'info message', '', ''],
                ['headingText', 'String', 'Heading text', '', 'login'],
                ['btnLoadingText', 'String', 'button loading text', '', 'login'],
                ['url', 'Boolean', 'url', ['true', 'false'], 'true'],
                ['logoImg', 'Boolean', 'logo img', ['true', 'false'], 'true'],
                ['redirectUrl', 'String', 'redirect url', '', '/']
            ],
            propsInfoFullPageLogin: [
                ['contentWidth', 'String', 'content width (grid width value)', ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12'], '4'],
                ['imgWidth', 'String', 'content width (grid width value)', ['1', '2', '3', '5', '6', '7', '8', '9', '10', '11', '12'], ''],
                ['contentPadding', 'String', 'content padding', ['padding spacer values'], '3'],
                ['imageAlign', 'String', 'image horizontal alignment', ['left', 'right'], 'right'],
                ['usernameLabel', 'String', 'label for username field', '', 'Username'],
                ['passwordLabel', 'String', 'label for password field', '', 'Password'],
                ['buttonText', 'String', 'button text', '', 'Login'],
                ['buttonColor', 'String', 'button color', ['all theme colors'], 'primary'],
                ['buttonDesign', 'String', 'button design', ['basic-a', 'basic-b', 'upto basic-f'], 'basic'],
                ['buttonSize', 'String', 'button size', ['xs', 'sm', 'md', 'lg', 'xl'], 'md'],
                ['title', 'String', 'title on top', '', 'Login'],
                ['boxInfo', 'String', 'info insid the box', '', 'Forgot password?'],
                ['logoImg', 'String', 'logo image', '', ''],
                ['img', 'String', 'aside image', '', ''],
                ['imgAsBackground', 'String', 'set image as background', '', ''],
                ['boxClasses', 'String, Array', 'box additional classes', '', ''],
                ['showHeader', 'Boolean', 'show or hide header', ['true', 'false'], 'true'],
                ['showFooter', 'Boolean', 'show or hide footer', ['true', 'false'], 'true'],
                ['buttonClasses', 'String, Array', 'Button additional classes', '', ''],
                ['imgClasses', 'String, Array', 'image additional classes', '', ''],
                ['imgContainClasses', 'String, Array', 'image container additional classes', '', ''],
                ['containerClasses', 'String, Array', 'root container additional classes', '', ''],
                ['rowClasses', 'String, Array', 'grid row container additional classes', '', ''],
                ['containerPadding', 'String, Array', 'container padding value', ['padding spacer values'], ''],
                ['imgAnimationEffect', 'String', 'image animation effect', ['1', '2', 'upto 7'], '1']
            ]
        };
    }
};
</script>

<style scoped>

</style>
