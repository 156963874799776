import paddingMixin from './paddingsMixin';

export default {
    props: {
        m: { type: String, default: '' },
        mt: { type: String, default: '' },
        mb: { type: String, default: '' },
        my: { type: String, default: '' },
        mx: { type: String, default: '' },
        ml: { type: String, default: '' },
        mr: { type: String, default: '' },
        mMd: { type: String, default: '' },
        mtMd: { type: String, default: '' },
        mbMd: { type: String, default: '' },
        myMd: { type: String, default: '' },
        mxMd: { type: String, default: '' },
        mlMd: { type: String, default: '' },
        mrMd: { type: String, default: '' },
        mLg: { type: String, default: '' },
        mtLg: { type: String, default: '' },
        mbLg: { type: String, default: '' },
        myLg: { type: String, default: '' },
        mxLg: { type: String, default: '' },
        mlLg: { type: String, default: '' },
        mrLg: { type: String, default: '' }
    },
    mixins: [paddingMixin],
    computed: {
        marginClasses () {
            const classNames = [];
            const margins = ['m',
                'mt',
                'mb',
                'my',
                'mx',
                'ml',
                'mr',
                'm-lg',
                'mt-lg',
                'mb-lg',
                'my-lg',
                'mx-lg',
                'ml-lg',
                'mr-lg',
                'm-md',
                'mt-md',
                'mb-md',
                'my-md',
                'mx-md',
                'ml-md',
                'mr-md'];
            const that = this;
            margins.forEach(item => {
                let marginItemPropName = item;
                if (item.slice(-3) === '-md') {
                    // set key name according to  props name : eg(mt-md to mdMd)
                    marginItemPropName = item.slice(0, -3) + 'Md';
                    // console.log('itemProp', itemProp);
                }
                if (item.slice(-3) === '-lg') {
                    // set key name according to  props name : eg(mt-lg to lgMd)
                    marginItemPropName = item.slice(0, -3) + 'Lg';
                }
                classNames.push(this.setClassName(marginItemPropName, that[marginItemPropName]));
            });
            return classNames;
        }
    },
    methods: {
        setClassName (marginTypePropName, value) {
            let marginTypeStr = marginTypePropName;
            if (marginTypePropName.slice(-2) === 'Md') {
                // set key name according to  props name : eg(mt-md to mdMd)
                marginTypeStr = marginTypeStr.slice(0, -2) + '-' + 'md';
            }
            if (marginTypePropName.slice(-2) === 'Lg') {
                // set key name according to  props name : eg(mt-md to mdMd)
                marginTypeStr = marginTypeStr.slice(0, -2) + '-' + 'lg';
            }
            if (this[marginTypePropName]) {
                return marginTypeStr + '-' + value;
            }
        }
    }
};
