<template>
    <page-template>
    <div class="container-fluid py-4 bg-2">

        <div v-for="[a, property] in names" :key="property">
            <h2>{{ property }}</h2>
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th v-for="[b, variation] in variations" :key="property + variation">{{ variation }}</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tr v-for="(value, index) in theme.spacers" :key="value + variations">
                    <td v-for="[b, variation] in variations" :key="value + property + variation">
                        .{{ a }}{{ b }}-{{ index }}
                    </td>
                    <td>{{ value }}</td>
                </tr>
            </table>
        </div>
        <div>
            <div class="bg-4 p-4">
                <h2> Spacing Utilities</h2>

                <p class="mt-4"><strong>Margin:</strong> Controls the outer spacing around elements, useful for
                    positioning and spacing.
                </p>
                <h5>.m-0</h5>
                <p class="">Sets the margin to 0rem on all sides of an element.</p>

                <h5>.mt-0</h5>
                <p>Sets the top margin to 0rem, affecting only the top side of the element.</p>

                <h5>.mb-0</h5>
                <p>Sets the bottom margin to 0rem, affecting only the bottom side of the element.</p>

                <h5>.ml-0</h5>
                <p>Sets the left margin to 0rem, affecting only the left side of the element.</p>

                <h5>.mr-0</h5>
                <p>Sets the right margin to 0rem, affecting only the right side of the element.</p>

                <h5>.mx-0</h5>
                <p>Sets the left and right margins to 0rem, affecting both the left and right sides of the element.</p>

                <h5>.my-0</h5>
                <p>Sets the top and bottom margins to 0rem, affecting both the top and bottom sides of the element.</p>
            </div>
            <h2 class="mt-4">Margin Utility Classes Example</h2>
            <code-container show-code-no-toggle :code-str="codeStore.margin.code"/>

            <div class="p-3 b-2 m-2">.m-2: Margin on all sides (2rem)</div>

            <div class="p-3 b-2 mt-2">.mt-2: Margin top (2rem)</div>

            <div class="p-3 b-2 mb-2">.mb-2: Margin bottom (2rem)</div>

            <div class="p-3 b-2 ml-2">.ml-2: Margin left (2rem)</div>

            <div class="p-3 b-2 mr-2">.mr-2: Margin right (2rem)</div>

            <div class="p-3 b-2 mx-2">.mx-2: Margin left and right (2rem)</div>

            <div class="bg-4 p-4 mt-4">
                <h2>Spacing Utilities</h2>

                <p class="mt-4"><strong>Padding:</strong> Controls the inner spacing within elements, improving content
                    readability and design.</p>

                <h5>.p-0</h5>
                <p>Sets the padding to 0rem on all sides of an element.</p>

                <h5>.pt-0</h5>
                <p>Sets the top padding to 0rem, affecting only the top side of the element.</p>

                <h5>.pb-0</h5>
                <p>Sets the bottom padding to 0rem, affecting only the bottom side of the element.</p>

                <h5>.pl-0</h5>
                <p>Sets the left padding to 0rem, affecting only the left side of the element.</p>

                <h5>.pr-0</h5>
                <p>Sets the right padding to 0rem, affecting only the right side of the element.</p>

                <h5>.px-0</h5>
                <p>Sets the left and right paddings to 0rem, affecting both the left and right sides of the element.</p>

                <h5>.py-0</h5>
                <p>Sets the top and bottom paddings to 0rem, affecting both the top and bottom sides of the element.</p>
            </div>

            <h2 class="mt-4">Padding Utility Classes Example</h2>
            <code-container show-code-no-toggle :code-str="codeStore.padding.code"/>

            <div class="p-3 b-2">.p-5: Padding on all sides (3rem)</div>

            <div class="p-3 b-2 pt-5">.pt-5: Padding top (3rem)</div>

            <div class="p-3 b-2 pb-5">.pb-5: Padding bottom (3rem)</div>

            <div class="p-3 b-2 pl-5">.pl-5: Padding left (3rem)</div>

            <div class="p-3 b-2 pr-5">.pr-5: Padding right (3rem)</div>

            <div class="p-3 b-2 px-5">.px-5: Padding left and right (3rem)</div>

            <div class="p-3 b-2 py-5">.py-5: Padding top and bottom (3rem)</div>

            <h2>Negative Margins</h2>
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th v-for="[b, variation] in variations" :key="property + variation">{{ variation }}</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tr v-for="(value, index) in theme.spacers" :key="value + variations">
                    <td v-for="[b, variation] in variations" :key="value + property + variation">
                        .m{{ b }}-n{{ index }}
                    </td>
                    <td>{{ value }}</td>
                </tr>
            </table>
        </div>
        <div class="bg-4 p-4">
            <h2>Negative Margin Utilities</h2>
            <p class="mt-3">
                Negative margin utilities allow you to adjust the spacing of elements by pulling them closer together
                than the default spacing. This can be useful for fine-tuning layouts and creating specific visual
                effects. The following classes are available:
            </p>

            <h5 class="mt-3">.m-n0</h5>
            <p>Sets the margin to 0rem on all sides of an element, effectively eliminating any outer spacing.</p>

            <h5>.mt-n0</h5>
            <p>Sets the top margin to 0rem, pulling the element closer to the one above it.</p>

            <h5>.mb-n0</h5>
            <p>Sets the bottom margin to 0rem, pulling the element closer to the one below it.</p>

            <h5>.ml-n0</h5>
            <p>Sets the left margin to 0rem, pulling the element closer to the one on its left.</p>

            <h5>.mr-n0</h5>
            <p>Sets the right margin to 0rem, pulling the element closer to the one on its right.</p>

            <h5>.mx-n0</h5>
            <p>Sets the left and right margins to 0rem, eliminating horizontal spacing between adjacent elements.</p>

            <h5>.my-n0</h5>
            <p>Sets the top and bottom margins to 0rem, eliminating vertical spacing between adjacent elements.</p>
        </div>
        <div>
            <h3 class="mt-4">Example</h3>
            <code-container show-code-no-toggle :code-str="codeStore.negativemargin.code"/>

            <div class="p-3 b-2">.m-n4: Negative Margin on all sides (-1.5rem)</div>

            <div class="p-3 b-2 mt-n4">.mt-n4: Negative Margin top (-1.5rem)</div>

            <div class="p-3 b-2 mb-n4">.mb-n4: Negative Margin bottom (-1.5rem)</div>

            <div class="p-3 b-2 ml-n4">.ml-n4: Negative Margin left (-1.5rem)</div>

            <div class="p-3 b-2 mr-n4">.mr-n4: Negative Margin right (-1.5rem)</div>

            <div class="p-3 b-2 mx-n4">.mx-n4: Negative Margin left and right (-1.5rem)</div>

            <div class="p-3 b-2 my-n4">.my-n4: Negative Margin top and bottom (-1.5rem)</div>
        </div>

        <div class="py-5">
            <h3>Space Chart</h3>
            <div v-for="(value, index) in theme.spacers" class="my-2" :key="index">
                <div class="fl-x">

                    <span style="line-height: 2rem" class="bg-success px-2">{{ index }}</span>
                    <span :style="{width: value, display: 'inline-block'}" class="bg-primary"></span>
                </div>
            </div>
            <h3 class="mt-4">SCSS Variables</h3>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>

    </div>
        </page-template>
</template>

<script>
import helpersMixin from '../helpersMixin';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/spaces.json';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'helpers/spaces/',
        title: 'Spaces'
    },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--spacer-0',
                    description: 'spacer 0',
                    defaultvalue: '0rem'
                },
                {
                    slno: '2',
                    name: '--spacer-1',
                    description: 'spacer 1',
                    defaultvalue: '0.25rem'
                },
                {
                    slno: '3',
                    name: '--spacer-2',
                    description: 'spacer 2',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '4',
                    name: '--spacer-3',
                    description: 'spacer 3',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--spacer-4',
                    description: 'spacer 4',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '6',
                    name: '--spacer-5',
                    description: 'spacer 5',
                    defaultvalue: '3rem'
                },
                {
                    slno: '7',
                    name: '--spacer-6',
                    description: 'spacer 6',
                    defaultvalue: '5rem'
                },
                {
                    slno: '8',
                    name: '--spacer-7',
                    description: 'spacer 7',
                    defaultvalue: '7rem'
                },
                {
                    slno: '9',
                    name: '--spacer-8',
                    description: 'spacer 8',
                    defaultvalue: '9rem'
                },
                {
                    slno: '10',
                    name: '--spacer-9',
                    description: 'spacer 9',
                    defaultvalue: '12rem'
                }
            ],
            codeStore: codeStore,
            names: [['m', 'Margin'], ['p', 'Padding']],
            variations: [['', 'All Sides'], ['t', 'Top'], ['b', 'Bottom'], ['l', 'Left'], ['r', 'Right'], ['x', 'Left and Right'], ['y', 'Top and Bottom']]
        };
    },
    name: 'colors.scss',
    components: { CodeContainer }

};
</script>
