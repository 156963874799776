<template>
    <page-template class="">
        <div class="bg-2">
            <div class="row">
                <div class="col-12">
                    <h3 class="my-3">UI Layouts</h3>
                    <p>Base layouts for structuring the UI layout of the web app</p>
                    <h4>1. Main Layout</h4>
                    <p>It stored in location - <span class="text-primary">src\layouts\MainLayout.vue</span></p>
                    <p>In Main Layout basic layout structure of the web app is set, Top Navigation, Sidebar , Headers set there</p>
                    <h5>Basic code Structure of main layout</h5>
                    <code-container :code-str="codeStore.mainLayout.code" show-code-no-toggle/>
                    <h5 class="my-3">Main Layout with sidebar</h5>
                    <code-container :code-str="codeStore.mainLayoutSidebar.code" show-code-no-toggle/>
                    <h5 class="my-3">Blank Layout</h5>
                    <p>Blank layout is usually used for authentication pages</p>
                    <code-container :code-str="codeStore.blankLayout.code" show-code-no-toggle/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';
import codeStore from './code-store/ui-layouts.json';

export default {
    name: 'UiLayoutsDocs',
    components: { CodeContainer },
    data () {
        return {
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
