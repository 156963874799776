<template>
    <div>
        <div class="container">
            <component-wrapper class="text-primary" :code="code">
                <div class="row py-3">
                    <div class="col-12 m-0 p-0">
                        <h4 class="fs-1 mb-4">Responsive Grid System ( 3 Column )</h4>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="p-3 bg-primary">
                            col-12 col-lg-4 col-md-6
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="p-3 bg-primary">
                            col-12 col-lg-4 col-md-6
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <div class="p-3 bg-primary">
                            col-12 col-lg-4 col-md-6
                        </div>
                    </div>
                </div>
            </component-wrapper>
        </div>
    </div>
</template>

<script>
import codeStore from '../code-store/grid-system.json';

export default {
    name: 'GridSystemResponsiveThreeColumn',

    data () {
        return {
            code: codeStore.threeColumn.code
        };
    }
};
</script>

<style scoped>

</style>
