<template>
    <div class="pb-5 p-4 bg-2">
        <h2 class="mt-5">Container</h2>
        <p class="lead">Responsive Container with left and right margins set</p>
        <h5>Usage</h5>
        <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
        <h5 class="my-4">Default Use</h5>
        <container py="6" mt="4">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio dolorem,
                porro. Aspernatur culpa dolore doloremque illum neque numquam repellat? Animi
                aspernatur cum deleniti enim, impedit nisi obcaecati quod temporibus voluptates.
                Ad animi assumenda beatae blanditiis, consectetur cum ducimus eveniet ipsa iste
                laudantium modi non pariatur perferendis sit tempora, ullam, veniam.
            </p>
        </container>
        <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
        <h4>Props</h4>
        <prop-table :items="props"/>
    </div>
</template>

<script>
// import DemoMixin from '../../views/DemoMixin';
import PropTable from '../../views/docs/docs-components/Props';
import CodeContainer from '../../components/doc-items/CodeContainer';
import container from '../../containers/layout/Container';
import codeStore from './codes/container.json';
export default {
    // extends: DemoMixin,
    metaInfo: {
        path: 'containers/container/',
        title: 'Container'
    },
    data () {
        return {
            codeStore: codeStore,
            props: [
                ['tag', 'String', 'html tag name', ['any html tag, eg:div,section,main, footer,aside,...'], 'div'],
                ['m', 'String', 'Margin value', ['margin spacer values usually between 1 - 9'], ''],
                ['mt', 'String', 'Margin top value', ['margin top spacer values usually between 1 - 9'], ''],
                ['ml', 'String', 'Margin left value', ['margin left spacer values usually between 1 - 9'], ''],
                ['p', 'String', 'Padding value', ['padding spacer values usually between 1 - 9'], ''],
                ['pt', 'String', 'Padding top value', ['padding top spacer values usually between 1 - 9'], ''],
                ['pl', 'String', 'Padding left value', ['padding left spacer values usually between 1 - 9'], ''],
                ['All spacing Items', 'String', 'mr, pr, py,my , mx ...', ['spacer values usually between 1 - 9'], '']
            ]
        };
    },
    components: { PropTable, container, CodeContainer }
};
</script>
