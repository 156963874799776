<template>
    <page-template>
        <div class="bg-2 p-5">
            <div>
                <h3>Card</h3>
            </div>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>
    </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-45r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--card-padding',
                    description: 'Card padding. Depending on variable.<br>1. --spacer-3',
                    defaultvalue: '1rem'
                },
                {
                    slno: '2',
                    name: '--container-border-radius',
                    description: 'Card border radius. Depending on variables.<br>1. --container-border-radius<br>2. --round-2',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '3',
                    name: '--container-separator',
                    description: 'Container separator (top margin on container). Depending on variable.<br>1. --spacer-5',
                    defaultvalue: '3rem'
                }
            ]
        };
    }
};
</script>

<style scoped>
</style>
