import { render, staticRenderFns } from "./NavItemPlain.vue?vue&type=template&id=d2212dee&scoped=true&"
import script from "./NavItemPlain.vue?vue&type=script&lang=js&"
export * from "./NavItemPlain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2212dee",
  null
  
)

export default component.exports