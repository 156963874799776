<template>
    <page-template>
 <div class="bg-2 p-5">
        <div>
            <h3>Font Items</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'GrayColors',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-20r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--text-fs--3',
                    description: 'font size is set using this. class related\n ' +
                        '1. text-fs--3',
                    defaultvalue: '0.46875rem'
                },
                {
                    slno: '2',
                    name: '--text-fs--2',
                    description: 'Similar to the first item',
                    defaultvalue: '0.5625rem'
                },
                {
                    slno: '3',
                    name: '--text-fs--1',
                    description: 'Similar to the first item',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '4',
                    name: '--text-fs-0',
                    description: 'Similar to the first item',
                    defaultvalue: '0.875rem'
                },
                {
                    slno: '5',
                    name: '--text-fs-1',
                    description: 'Similar to the first item',
                    defaultvalue: '1rem'
                },
                {
                    slno: '6',
                    name: '--text-fs-2',
                    description: 'Similar to the first item',
                    defaultvalue: '1.25rem'
                },
                {
                    slno: '7',
                    name: '--text-fs-3',
                    description: 'Similar to the first item',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '8',
                    name: '--text-fs-4',
                    description: 'Similar to the first item',
                    defaultvalue: '2.125rem'
                },
                {
                    slno: '9',
                    name: '--text-fs-5',
                    description: 'Similar to the first item',
                    defaultvalue: '3rem'
                },
                {
                    slno: '10',
                    name: '--text-fs-sm-5',
                    description: 'Similar to the first item',
                    defaultvalue: '2.4rem'
                },
                {
                    slno: '11',
                    name: '--text-fs-6',
                    description: 'Similar to the first item',
                    defaultvalue: '3.75rem'
                },
                {
                    slno: '12',
                    name: '--text-fs-7',
                    description: 'Similar to the first item',
                    defaultvalue: '6rem'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
