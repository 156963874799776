<template>
    <page-template class="bg-2 ">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <div>
                        <h2>Features Section Item</h2>
                        <p class="lead">Features listing item component</p>
                        <h5>Usage</h5>
                        <p>Import Component on plugins/lego.js</p>
                        <p>import TeamAvatarItem from 'lego-framework/src/web-components/FeaturesSectionItem';</p>
                        <p>Vue.component('features-section-item', FeaturesSectionItem);</p>
                        <!--                        <p class="text-left">-->
                        <!--                            &#60;team-avatar-item :avatar-img="avatarImg"/&#62;-->
                        <!--                        </p>-->
                        <code-container :show-code-no-toggle="true" :code-str="codeStore.default.code"></code-container>
                    </div>
                </div>
                <div class="col-12 mt-5">
                    <h3 class="mb-5">Layout Alignments</h3>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Default</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.default.code"></code-container>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Icon Align Left</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :icon-align-left="true"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.iconAlignLeft.code"></code-container>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Align Center</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        align="center"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.alignCenter.code"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="mb-5">Redirection</h3>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Prop: linkUrl</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        link-url="/"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.redirection.code"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="mb-5">Color & Background</h3>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Background Color <span class="font-weight-normal">( Prop: bgColor)</span></h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        bg-color="1"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.background.code"></code-container>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Icon Background Color <span class="font-weight-normal">( Prop: iconBgColor)</span></h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        icon-bg-color="primary"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.iconBgColor.code"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4>Base Color</h4>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>baseColor: 'primary'</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="primary"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.colorPrimary.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>baseColor: 'secondary'</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="secondary"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.colorSecondary.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>baseColor: 'third'</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="third"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.colorThird.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>baseColor: 'success'</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="success"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.colorSuccess.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>baseColor: 'danger'</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="danger"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.colorDanger.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>baseColor: 'info'</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="info"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.colorInfo.code"></code-container>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="my-3 mb-5">Icon & Link</h3>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-3">Without Link <span class="font-weight-normal">( Prop: showLink = false)</span></h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :show-link="false"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.withoutLink.code"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 mt-4">
                    <h5 class="my-3">Without Icon Border <span class="font-weight-normal">( Prop: iconBordered = false)</span></h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :icon-bordered="false"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.withoutIconBorder.code"></code-container>

                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4>Icon Images</h4>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="my-3">Top Icon Image</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :icon-img="mailIcon"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.iconImage.code"></code-container>

                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="my-3">Link Icon Image</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :icon-img="mailIcon" :link-icon-img="arrowRight"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.linkIconImage.code"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4>Icon Classes</h4>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="my-3">Top Icon Class</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.iconClass.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="my-3">Link Icon Class</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        link-icon="fa fa-chevron-right"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.linkIconClass.code"></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 mt-4">
                    <h5>Icon round</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :icon-round="true"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.iconRound.code"></code-container>

                </div>
                <div class="col-lg-6 mt-4">
                    <h5>Custom Footer Using Slot - footer</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        icon-bg-color="1" bg-color="2" :icon-round="true" color="secondary">
                        <template #footer>
                            <div class="btn-group mt-2">
                                <btn text="Demo" design="basic-b"></btn>
                                <btn text="Learn More"></btn>
                            </div>
                        </template>
                    </features-section-item>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.footerSlot.code"></code-container>

                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="mb-5">Borders</h3>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>Border left</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="primary" :border-left="true"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.borderLeft.code"></code-container>

                </div>
                <div class="col-lg-6 mt-4">
                    <h5>Border right</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="primary" :border-right="true"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.borderRight.code"></code-container>

                </div>
                <div class="col-lg-6 mt-4 bg-2">
                    <h5>Border top</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="primary" b-color="light" align="center" :border-top="true"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.borderTop.code"></code-container>
                </div>
                <div class="col-lg-6 mt-4 bg-2">
                    <h5>Border bottom</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        color="primary" align="center" b-color="dark" :border-bottom="true"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.borderBottom.code"></code-container>

                </div>
                <div class="col-lg-6 mt-4 bg-2">
                    <h5 class="mb-4">Icon Overlapped <span class="font-weight-normal text-fs-1">( iconOverlapped: true)</span><br> <span
                        class="font-weight-normal text-fs-1"> NB: contentBgColor should be set </span></h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        :icon-overlapped="true" content-bg-color="3" align="center"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.iconOverlapped.code"></code-container>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <h3 class="mb-5">Border Radius</h3>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5>Border Radius : 2</h5>
                    <features-section-item
                        icon="fa fa-star"
                        heading="Share team inboxes"
                        content="Whether you have a team of 2 or 200,our shared team inboxes keep everyone on
                        the same page and in the loop"
                        bg-color="primary" border-radius="2"/>
                    <code-container :show-code-no-toggle="true" :code-str="codeStore.borderRadius.code"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import mailIcon from '../../../assets/web/icons/mail-icon.svg';
import arrowRight from '../../../assets/web/icons/icons8-arrow-right-16-green.png';
// import FeaturesSectionItem from 'lego-framework/src/web-components/FeaturesSectionItem';
import codeStore from '../../../data/codes/web-components/features-section-item.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'FeaturesSectionItemVariants',
    components: { PropTable },
    data () {
        return {
            mailIcon: mailIcon,
            arrowRight: arrowRight,
            codeStore: codeStore,
            propsInfo: [
                ['heading', 'String', 'Heading Text', '', ''],
                ['content', 'String', 'Content Text', '', ''],
                ['bgColor', 'String', 'Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['color', 'String', 'Base Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['icon', 'String', 'Icon favicon Class', ['Any favicon icon class'], 'fa fa-star'],
                ['iconColor', 'String', 'Icon Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['iconBgColor', 'String', 'Icon Background Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['iconRound', 'Boolean', 'Icon Shape Round', ['true', 'false'], 'false'],
                ['iconOverlapped', 'Boolean', 'Icon Overlapped on the content element', ['true', 'false'], 'false'],
                ['iconImg', 'String', 'Icon Image Url', '', ''],
                ['linkIcon', 'String', 'Link Icon favicon Class', ['Any favicon icon class'], 'fa fa-arrow-right'],
                ['linkIconImg', 'String', 'Link Icon Image Url', '', ''],
                ['linkText', 'String', 'Link Text', '', ''],
                ['linkColor', 'String', 'Link Color', '', ''],
                ['contentBgColor', 'String', 'Content Background Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['showLink', 'Boolean', 'Show or hide link', ['true', 'false'], 'true'],
                ['linkUrl', 'String', 'Forward link url', '', ''],
                ['iconAlignLeft', 'Boolean', 'Align main icon on left side and content on right', ['true', 'false'], 'false'],
                ['iconBordered', 'Boolean', 'Add or Remove icon border', ['true', 'false'], 'true'],
                ['align', 'String', 'icon and text horizontal alignment', ['left', 'center'], 'left'],
                ['borderLeft', 'Boolean', 'Border on left side', ['true', 'false'], 'false'],
                ['borderRight', 'Boolean', 'Border on right side', ['true', 'false'], 'false'],
                ['borderTop', 'Boolean', 'Border on top', ['true', 'false'], 'false'],
                ['borderBottom', 'Boolean', 'Border on bottom', ['true', 'false'], 'false'],
                ['borderRadius', 'String', 'Border Radius', ['0', '1', '2', '3', 'Other border Radius values'], '0'],
                ['borderColor', 'String', 'Border Color Variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], 'primary'],
                ['borderSize', 'String', 'Border Size Variant', ['0', '1', '2', '3', '4', '5', 'Other border Size values'], '5'],
                ['headingAdditionalClasses', 'String/Array', 'Additional classes to be added to heading', '', ''],
                ['contentAdditionalClasses', 'String/Array', 'Additional classes to be added to content', '', ''],
                ['containerAdditionalClasses', 'String/Array', 'Additional classes to be added to main container', '', '']

            ]
        };
    }
};
</script>

<style scoped>

</style>
