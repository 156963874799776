import { render, staticRenderFns } from "./DragAndDropFile.vue?vue&type=template&id=6cd20058&scoped=true&"
import script from "./DragAndDropFile.vue?vue&type=script&lang=js&"
export * from "./DragAndDropFile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd20058",
  null
  
)

export default component.exports