<template>
    <div class="bg-2">
        <div class="container">
            <h3 class="my-3">Notifications Modals</h3>

            <btn text="Avatar Left : Text on right Modal" @click="$refs.avatarLeftIconModal.show()"></btn>

            <modal
                ref="avatarLeftIconModal"
                align-viewport-center
                width="35r"
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div class="fl-x">
                        <div class="pos-r">
                            <icon-img :image="avatar" size="7"></icon-img>
                            <custom-h-el width="8px" height="8px" bg-color="primary" class="b-round pos-a mt-n2 ml-4"/>
                        </div>
                        <div class="ml-3">
                            <div class="fl-x fl-a-c">
                                <div class="text-fs-1 font-weight-500">Katherine Moss</div>
                                <div class="text-2 ml-3">2min ago</div>
                            </div>
                            <p class="mt-1 mb-2">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis distinctio eos eveniet
                                fugit labore laborum libero nemo nulla porro
                                tenetur.
                            </p>
                            <div class="fl-x">
                                <div class="font-weight-500 text-grey  mr-3">Dismiss</div>
                                <div class=" font-weight-500 text-primary">Change log</div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
            <btn text="Icon Left : Text on right Modal" class="ml-3" @click="$refs.iconLeftIconModal.show()"></btn>

            <modal
                ref="iconLeftIconModal"
                align-viewport-center
                width="35r"
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div class="fl-x">
                        <icon-img :image="circleIcon" size="7"></icon-img>
                        <div class="ml-3">

                            <h5 class="text-fs-1">We have just released a new update!</h5>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis distinctio eos eveniet
                                fugit labore laborum libero.</p>

                            <div class="fl-x pt-2">
                                <div class="font-weight-bold text-grey mr-3">Dismiss</div>
                                <div class=" font-weight-bold text-primary">Change log</div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
            <btn text="Image Left : Text on right Modal" class="ml-3" @click="$refs.imageLeftIconModal.show()"></btn>

            <modal
                ref="imageLeftIconModal"
                align-viewport-center
                width="35r"
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div class="fl-x">
                        <icon-img :image="socio" class="h-100p mt-2" size=""></icon-img>
                        <div class="ml-3">

                        <h5 class="text-fs-1">We have just released a new update!</h5>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis distinctio eos eveniet
                            fugit labore laborum libero.</p>

                            <div class="fl-x pt-2">
                                <div class="font-weight-bold text-grey mr-3">Dismiss</div>
                                <div class=" font-weight-bold text-primary">Change log</div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>

            <btn text="Image Left alert : Text on right Modal" class="ml-3" @click="$refs.imageLeftIconAlert.show()"></btn>

            <modal
                ref="imageLeftIconAlert"
                header-align-items="top"
                width="70p" class=""
                align-viewport-center
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div class="fl-x ">
                        <icon-img :image="circleIcon" size="7" class=""></icon-img>
                        <div class="ml-2">

                            <h5 class="text-fs-1">There is a probelm with that action</h5>

                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis distinctio eos eveniet
                                fugit labore laborum libero.</p>

                            <div class="fl-x pt-1">
                                <p class=" text-grey mr-3">Dismiss</p>
                                <p class="  text-primary">Change log</p>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>
<script>
import modal from 'lego-framework/src/containers/ModalHeaderUpdated';
import IconImg from 'lego-framework/src/components/IconImage';
import tickIcon from '../../assets/web/icons/tick.png';
import crossIcon from '../../assets/web/icons/icons8-cross-24.png';
import avatar from '../../assets/img/avatar/image-avatar.png';
import socio from '../../assets/web/social-media-icons/insta-dark.png';
import circleIcon from '../../assets/web/icons/icon-red.png';

export default {
    name: 'NotificationModal',
    components: { modal, IconImg },
    data () {
        return {
            tickIcon: tickIcon,
            crossIcon: crossIcon,
            avatar: avatar,
            socio: socio,
            circleIcon: circleIcon

        };
    }
};
</script>
