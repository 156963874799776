<template>
    <div class="p-5 bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <div>
                        <h2>Tabs</h2>
                        <p class="lead">Tabs switching component</p>
                        <h5>Usage</h5>
                        <p>Import Component on plugins/lego.js</p>
                        <p>import Tabs from 'lego-framework/src/containers/Tabs';</p>
                        <p>import Tab from 'lego-framework/src/containers/Tab';</p>
                        <p>Vue.component('tabs', Tabs);</p>
                        <p>Vue.component('Tab', Tab);</p>
                        <p class="text-left">
                            &#60;tabs&#62;<br>
                            &nbsp;&nbsp;&#60;tab&#62;&#60;/tab&#62; <br>
                            &nbsp;&nbsp;&#60;tab&#62;&#60;/tab&#62; <br>
                            &#60;/tabs&#62;
                        </p>
                    </div>
                </div>
                <div class="col-12">
                    <h4 class="my-3">Default Tabs</h4>
                    <component-wrapper :code="codeStore.horizontal.code">
                        <tabs>
                            <tab title="Tab 1">Hello From Tab 1</tab>
                            <tab title="Tab 2">Hello From Tab 2</tab>
                            <tab title="Tab 3">Hello From Tab 3</tab>
                            <tab title="Tab 4">Hello From Tab 4</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-3">Vertical Tabs</h4>
                    <component-wrapper :code="codeStore.vertical.code">
                        <tabs class="mt-3" vertical>
                            <tab title="Tab 1">Hello From Vertical Tab 1</tab>
                            <tab title="Tab 2">Hello From Vertical Tab 2</tab>
                            <tab title="Tab 3">Hello From Vertical Tab 3</tab>
                            <tab title="Tab 4">Hello From Vertical Tab 4</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h3>Horizontal Alignments</h3>
                    <h4 class="my-4">align: left (default)</h4>
                    <component-wrapper :code="codeStore.horizontal.code">
                        <tabs align="left">
                            <tab title="Tab 1">Hello From Tab 1</tab>
                            <tab title="Tab 2">Hello From Tab 2</tab>
                            <tab title="Tab 3">Hello From Tab 3</tab>
                            <tab title="Tab 4">Hello From Tab 4</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-4">align: center</h4>
                    <component-wrapper :code="codeStore.alignCenter.code">
                        <tabs class="mt-3" align="center">
                            <tab title="Tab 1">Hello From Tab 1</tab>
                            <tab title="Tab 2">Hello From Tab 2</tab>
                            <tab title="Tab 3">Hello From Tab 3</tab>
                            <tab title="Tab 4">Hello From Tab 4</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="my-4">align: right</h4>
                    <component-wrapper :code="codeStore.alignRight.code">
                        <tabs class="mt-3" align="right">
                            <tab title="Tab 1">Hello From Tab 1</tab>
                            <tab title="Tab 2">Hello From Tab 2</tab>
                            <tab title="Tab 3">Hello From Tab 3</tab>
                            <tab title="Tab 4">Hello From Tab 4</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-5">
                    <h4 class="text-primary">color: primary</h4>
                    <component-wrapper :code="codeStore.colorPrimary.code">
                        <tabs color="primary">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-5">
                    <h4 class="text-secondary">color: secondary</h4>
                    <component-wrapper :code="codeStore.colorSecondary.code">
                        <tabs color="secondary">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-4">
                    <h4 class="text-third">color: third</h4>
                    <component-wrapper :code="codeStore.colorThird.code">
                        <tabs color="third">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-4">
                    <h4 class="text-success">color: success</h4>
                    <component-wrapper :code="codeStore.colorSuccess.code">
                        <tabs color="success">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-4">
                    <h4 class="text-danger">color: danger</h4>
                    <component-wrapper :code="codeStore.colorDanger.code">
                        <tabs color="danger">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-4">
                    <h4 class="text-info">color: info</h4>
                    <component-wrapper :code="codeStore.colorInfo.code">
                        <tabs color="info">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-6 mt-4">
                    <h4 class="text-warning">color: warning</h4>
                    <component-wrapper :code="codeStore.colorWarning.code">
                        <tabs color="warning">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-12 mt-4">
                    <h4>Tab Title icons</h4>
                    <component-wrapper :code="codeStore.withTitleIcons.code">
                        <tabs>
                            <tab title="Tab 1" icon="fa fa-user">Hello From Tab 1</tab>
                            <tab title="Tab 2" icon="fa fa-gears">Hello From Tab 2</tab>
                            <tab title="Tab 3" icon="fa fa-send">Hello From Tab 3</tab>
                            <tab title="Tab 4" icon="fa fa-address-book">Hello From Tab 4</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>Mode line (default)</h4>
                    <component-wrapper :code="codeStore.modeLine.code">
                        <tabs mode="line" align="center">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>Mode pill</h4>
                    <component-wrapper :code="codeStore.modePill.code">
                        <tabs mode="pill" align="center">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>Mode round</h4>
                    <component-wrapper :code="codeStore.modeRound.code">
                        <tabs mode="round" align="center">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>Mode outline</h4>
                    <component-wrapper :code="codeStore.modeOutline.code">
                        <tabs mode="outline" align="center">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4 class="my-3">Mode : panel</h4>
                    <component-wrapper :code="codeStore.modePanel.code">
                        <tabs mode="panel" align="center">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>Mode : formal</h4>
                    <component-wrapper :code="codeStore.modeFormal.code">
                        <tabs mode="formal" align="center">
                            <tab title="tab 1">Hello From Tab 1</tab>
                            <tab title="tab 2">Hello From Tab 2</tab>
                            <tab title="tab 3">Hello From Tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>without container border</h4>
                    <component-wrapper :code="codeStore.withoutContainerBorder.code">
                        <tabs tabs-container-classes="b-unset" align="center">
                            <tab title="tab 1">Hello from tab 1</tab>
                            <tab title="tab 2">Hello from tab 2</tab>
                            <tab title="tab 3">Hello from tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>
                <div class="col-lg-12 mt-4">
                    <h4>Customized with modifier classes</h4>
                    <component-wrapper :code="codeStore.customized.code">
                        <tabs mode="formal" tabs-container-classes="b-unset" tab-title-classes="py-3"
                              header-classes="bs-9 bg-3" align="center">
                            <tab title="tab 1">Hello from tab 1</tab>
                            <tab title="tab 2">Hello from tab 2</tab>
                            <tab title="tab 3">Hello from tab 3</tab>
                        </tabs>
                    </component-wrapper>
                </div>

                <div class="col-lg-12 mt-4">
                    <h3 class="">SCSS Variables</h3>

                <simple-table :fields="fields" :data="data" class="b-1"></simple-table></div>
            </div>
        </div>
    </div>
</template>

<script>
// import DemoMixin from '../../../views/DemoMixin';
import codeStore from './codes/tabs.json';
import ComponentWrapper from '../../components/doc-items/ComponentWrapper';
import Tabs from '../../containers/Tabs';
import Tab from '../../containers/Tab';

export default {
    // extends: DemoMixin,
    metaInfo: {
        path: 'containers/tabs/',
        title: 'Tabs'
    },
    components: { Tab, Tabs, ComponentWrapper },
    data () {
        return {
            codeStore: codeStore,
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--tabs-selected-color',
                    description: 'Tab selected Color. Depending on variable.<br>1. --color-#{$name} (format) --color-primary',
                    defaultvalue: 'Primary Color'
                },
                {
                    slno: '2',
                    name: '--tabs-selected-text-color',
                    description: 'Tab selected Color. Depending on variable. Format.<br>1. --color-#{$name}-contrast',
                    defaultvalue: 'primary contrast color'
                },
                {
                    slno: '3',
                    name: '--tabs-border-color',
                    description: 'Tabs border color. Depending on variable.<br>1. --border-color',
                    defaultvalue: '#E4E9F2'
                },
                {
                    slno: '4',
                    name: '--border-radius-pill',
                    description: 'Border radius pill value set',
                    defaultvalue: '50rem'
                }
            ]
        };
    }
};
</script>
