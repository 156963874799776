<template>
    <page-template>
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Contact Us Items</h2>
                    <p class="lead">Contact us forms and other components</p>
                    <h4 class="my-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h5 class="my-4">contact us form card</h5>
                    <contact-us-form-card/>
                    <code-container show-code-no-toggle :code-str="codeStore.formCard.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-4">contact us with map</h5>
                    <contact-us-form-with-map
                        text1="Contact us"
                        text2="We'd Love to hear from you"/>
                    <code-container show-code-no-toggle :code-str="codeStore.formWithMap.code"/>
                </div>
                <div class="col-lg-3 mt-4">
                    <h5 class="my-4">contact us Card item</h5>
                    <contact-card-item
                        :icon-img="image"
                        bgColor="2"
                        text1="Call us"
                        text2="Mon-Fri from 8am to 5pm."
                        text3="+91 8173838763"/>
                </div>
                <div class="col-12">
                    <code-container show-code-no-toggle :code-str="codeStore.cardItem.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h5 class="my-4">contact us Cards section</h5>
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <h5 class="text-primary">Contact Us</h5>
                            <h2 class="font-weight-bold pt-2">We'd Love to hear from you</h2>
                            <p class="pt-2">Our friendly team is always there to chat</p>
                        </div>

                        <div class="col-lg-3">
                            <contact-card-item
                                :icon-img="image"
                                bgColor="2"
                                text1="Chat to sale"
                                text2="gvsvd"
                                text3="sadhs@hhws.com"/>
                        </div>
                        <div class="col-lg-3 mt-5 mt-lg-0">
                            <contact-card-item
                                :icon-img="image"
                                bgColor="2"
                                text1="Chat to support"
                                text2="gvsvd"
                                text3="sadhs@hhws.com"/>
                        </div>
                        <div class="col-lg-3 mt-5 mt-lg-0">
                            <contact-card-item
                                :icon-img="image"
                                bgColor="2"
                                text1="Visit us"
                                text2="gvsvd"
                                text3="sadhs@hhws.com"/>
                        </div>
                        <div class="col-lg-3 mt-5 mt-lg-0">
                            <contact-card-item
                                :icon-img="image"
                                bgColor="2"
                                text1="Call us"
                                text2="gvsvd"
                                text3="+91 8173838763"/>
                        </div>
                    </div>
                    <code-container show-code-no-toggle :code-str="codeStore.cardsSection.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>

        </div>
    </page-template>
</template>

<script>
import image from '../../assets/images/icons/phone-sq.png';
import image2 from '../../assets/images/icons/icons8-violet-tick.png';
import image3 from '../../assets/images/icons/icons8-location-50.png';
import ContactUsFormCard from 'lego-framework/src/web-components/contact-us/ContactUsFormCard';
import ContactUsFormWithMap from 'lego-framework/src/web-components/contact-us/ContactUsFormWithMap';
import ContactCardItem from 'lego-framework/src/web-components/contact-us/ContactCardItem';
import codeStore from './code-storage/contact-us-items.json';
// import CodeContainer from '/components/doc-items/CodeContainer';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'ContactUsDemoPage',
    metaInfo: {
        path: 'web-components/contact-us-items/',
        title: 'Contact Us Items'
    },
    components: { ContactCardItem, ContactUsFormWithMap, ContactUsFormCard, PropTable },
    data () {
        return {
            image: image,
            image2: image2,
            image3: image3,
            cardBgColor: '#F9FAFB',
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'text item in component indexed in order from top to bottom, left to right', '', ''],
                ['text2', 'String', 'text item in component indexed in order from top to bottom, left to right', '', ''],
                ['text3', 'String', 'text item in component indexed in order from top to bottom, left to right', '', ''],
                ['iconImg', 'String', 'Icon Image (on ContactCardItem) component', '', ''],
                ['text1Color ', 'String', 'Icon Image (on ContactCardItem) component', '', ''],
                ['text1Color ', 'String', 'text1 color', '', ''],
                ['text1FonSize ', 'String', 'text1 font size', '', ''],
                ['text1Classes ', 'String', 'text1 additional classes', '', ''],
                ['text2Color ', 'String', 'text2 color', '', ''],
                ['text2FonSize ', 'String', 'text2 font size', '', ''],
                ['text2Classes ', 'String', 'text2 additional classes', '', ''],
                ['text3Color ', 'String', 'text3 color', '', ''],
                ['text3FonSize ', 'String', 'text3 font size', '', ''],
                ['text3Classes ', 'String', 'text3 additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
