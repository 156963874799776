<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="">Testimonial Section Item</h2>
                    <p class="lead">Testimonial item component item component</p>
                    <h5>Usage</h5>
                    <p>import TestimonialDesign1 from 'lego-framework/src/web-components/TestimonialDesign1';</p>
                    <p>import TestimonialDesign2 from 'lego-framework/src/web-components/TestimonialDesign2';</p>
                    <p>import TestimonialDesign3 from 'lego-framework/src/web-components/TestimonialDesign3';</p>
                    <!--                    <p>Vue.component('features-section-item', FeaturesSectionItem);</p>-->
                </div>
                <div class="col-12">
                    <testimonial-design1
                        text1="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        text2="Caityln King"
                        text3="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12">
                    <h5>design: basic-b</h5>
                    <testimonial-design1
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"
                        design="basic-b"/>
                    <code-container :code-str="codeStore.design1.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>Left Align</h5>
                    <testimonial-design1
                        align="left"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1AlignLeft.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>Left Align, design: basic-b</h5>
                    <testimonial-design1
                        align="left"
                        design="basic-b"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1AlignLeft.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>bgColor: info</h5>
                    <testimonial-design1
                        bg-color="info"
                        content="Love the simplicity of the service and the prompt
                        customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1BgColor.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>borderRadius: 2</h5>
                    <testimonial-design1
                        border-radius="2"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1BorderRadius.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>avatarImgSize: 1</h5>
                    <testimonial-design1
                        avatar-img-size="1"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1AvatarImgSize1.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>avatarImgSize: 2</h5>
                    <testimonial-design1
                        avatar-img-size="2"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1AvatarImgSize2.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>ratingSize: md</h5>
                    <testimonial-design1
                        rating-size="md"
                        avatar-img-size="1"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1RatingSizeMd.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>Padding and Margin props</h5>
                    <testimonial-design1
                        rating-size="md"
                        px-lg="5"
                        py-lg="6"
                        avatar-img-size="1"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1MarginAndPaddingProps.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>top-section slot</h5>
                    <testimonial-design1
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer">
                        <template #top-section>
                            <div class="fl-x fl-a-c fl-j-c mb-3">
                                <icon-img :image="lightningIcon" size="8"></icon-img>
                                <span class="text-fs-2">Sisiphus</span>
                            </div>
                        </template>
                    </testimonial-design1>
                    <code-container :code-str="codeStore.design1TopSectionSlot.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h5>hide Star Rating</h5>
                    <testimonial-design1
                        :show-star-rating="false"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design1HideStarRating.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h3>Design 2</h3>
                    <testimonial-design2
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design2.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h3>Design 2 - Bg- Color</h3>
                    <testimonial-design2 bg-color="primary"
                                         content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                                         :avatar-img="avatarImage"
                                         title="Caityln King"
                                         sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design2BgColor.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h3>Design 2 - (Fonts Customized)</h3>
                    <testimonial-design2
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        content-color="2"
                        sub-title-color="2"
                        content-classes="font-weight-bold"
                        title-classes="text-3"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design2FontsCustomized.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h3>Design 3</h3>
                    <testimonial-design3
                        :avatar-img="avatarImage"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design3.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h3>Design 3 ( BgColor)</h3>
                    <testimonial-design3
                        bg-color="primary"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design3BgColor.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h3>Design 3 ( Fonts Customized)</h3>
                    <testimonial-design3
                        bg-color="primary"
                        content="Love the simplicity of the service and the prompt
                         customer support.We can't imagine working without it."
                        :avatar-img="avatarImage"
                        content-classes="font-weight-light"
                        title-classes="font-weight-light"
                        title="Caityln King"
                        sub-title="Head of Design,Layer"/>
                    <code-container :code-str="codeStore.design3FontCustomized.code" :show-code-no-toggle="true"></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
// import TestimonialItems from '../TestimonialItems';
// import TestimonialsItemsone from '../TestimonialsItemsone';
// import TestimonialItemTwo from '../TestimonialItemTwo';
import TestimonialDesign1 from 'lego-framework/src/web-components/testimonials/TestimonialDesign1';
import avatarImage from '../../../assets/img/avatar/avatar-img-nicolas-horn-MTZTGvDsHFY-unsplash.jpg';
import lightningIcon from '../../../assets/web/icons/icons8-lightning-64.png';
import TestimonialDesign2 from 'lego-framework/src/web-components/testimonials/TestimonialDesign2';
// import TestimonialDesign2 from '../testimonials/TestimonialDesign3';
import TestimonialDesign3 from 'lego-framework/src/web-components/testimonials/TestimonialDesign3';
import codeStore from '../../../data/codes/web-components/testimonials.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'TestimonialsVariantsPage',
    components: { TestimonialDesign3, TestimonialDesign2, TestimonialDesign1, PropTable },
    data () {
        return {
            avatarImage: avatarImage,
            lightningIcon: lightningIcon,
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'Testimonial message Text', '', 'lorem text'],
                ['text2', 'String', 'Author name', '', 'Caityln King'],
                ['text3', 'String', 'Author role', '', 'Head of Design,Layer'],
                ['align', 'String', 'horizontal alignment', ['left', 'center'], 'center'],
                ['design', 'String', 'design variants', ['basic-a', 'basic-b'], 'basic-a'],
                ['avatarImg', 'String', 'Image', '', ''],
                ['bgColor', 'String', 'Background Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], '2'],
                ['borderRadius', 'String', 'Border Radius', '', ''],
                ['shadow', 'String', 'Shadow Variants', ['1', '2', '3', '4', '5', '...upto', '29'], '5'],
                ['avatarImgSize', 'String', 'Avatar Image Size', ['0', '1', '2', '3', '4', '5', '6', '7', 'Only for Design 1'], '0'],
                ['text1FontSize', 'String', 'Author Name Font Size', ['0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text1Color', 'String', 'Author Name Font Size', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text1Classes', '[String, Array]', 'text1 additional classes', '', ''],
                ['text2FontSize', 'String', 'Author Name Font Size', ['0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text2Color', 'String', 'Author Name Font Size', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text2Classes', '[String, Array]', 'text2 additional classes', '', ''],
                ['text3FontSize', 'String', 'Author Name Font Size', ['0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['text3Color', 'String', 'Author Name Font Size', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['text3Classes', '[String, Array]', 'text 2 additional classes', '', ''],
                ['ratingSize', 'String', 'Star Rating Size', ['xs', 'sm', 'md', 'xl'], 'sm'],
                ['showStarRating', 'Boolean', 'Show or Hide Star Rating', ['true', 'false'], 'true'],
                ['pxLg', 'String', 'Horizontal padding on large screens', ['any padding value(1 - 9)'], '6'],
                ['pyLg', 'String', 'Vertical padding on large screens', ['any padding value(1 - 9)'], '6'],
                ['px', 'String', 'padding horizontal', ['any padding value(1 - 9)'], '5'],
                ['py', 'String', 'padding vertical', ['any padding value(1 - 9)'], '5']

            ]

        };
    }
};
</script>

<style scoped>

</style>
