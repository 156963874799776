<template>
    <div class="fl-x progress-step-item-3" :style="[setLineWidth]">
        <!--line Item with animation-->
        <div v-if="showLine" class="pos-r">
            <line-item bg-color="1" :width="lineWidth" mt="1.2rem" :ml="lineMl" :mr="lineMr"/>
            <line-item bg-color="1" :width="lineWidth" mt="1.2rem" :ml="lineMl" :mr="lineMr" class="pos-a top-0 progress-step-item-3-horizontal-line-animation" v-if="status==='done'"/>
        </div>
        <!--line Item with animation-->
        <!--status icon and text-->
        <custom-h-el class="text-center" :width="sectionWidth">
            <!--status icon-->
            <div class="fl-y fl-a-c">
                <custom-h-el width="2.5rem" height="2.5rem" class="b-1 round-2 fl-x-cc" v-if="status==='pending'">
                    <!--<icon-user-svg stroke="4"/>-->
                    <icon-img :image="iconImg"></icon-img>
                </custom-h-el>
                <custom-h-el width="2.5rem" height="2.5rem" class="b-1 round-2 fl-x-cc b-dark" v-else-if="status==='done'">
                    <!--                    <icon-user-svg stroke="1"/>-->
                    <icon-img :image="iconImg"></icon-img>
                </custom-h-el>
            </div>
            <!--status icon-->
            <!--text-->
            <div class="ml-2">
                <text-el tag="div" :text="text1" :fs="text1FontSize" :color="setFontColor" :font-weight="text1FontWeight" mb="0"/>
                <text-el m="0" tag="div" :text="text2" :fs="text2FontSize" :color="setFontColor"/>
            </div>
            <!--text-->
        </custom-h-el>
    </div>
</template>
<script>
import CustomHEl from 'lego-framework/src/containers/CustomHeightContainer';
import iconUser from '../assets/icon-user.svg';
import LineItem from '../line-item';
import IconImg from 'lego-framework/src/components/IconImage';
import TextEl from 'lego-framework/src/components/typography/TextElement';
export default {
    name: 'ProgressStepsDetailsItem5',
    components: { CustomHEl, LineItem, IconImg, TextEl },
    props: {
        text1: { type: String, default: 'Your Details' },
        text2: { type: String, default: 'Please provide your name and email' },
        status: { type: String, default: 'pending' },
        iconImg: { type: String, default: iconUser },
        text1FontWeight: { type: String, default: '500' },
        text1FontSize: { type: String, default: '1' },
        text2FontSize: { type: String, default: '0' },
        color: { type: String, default: 'muted' },
        borderColor: { type: String, default: '1' },
        lineWidth: { type: String, default: '12r' },
        lineMl: { type: String, default: '-3rem' },
        lineMr: { type: String, default: '-4.8rem' },
        showLine: { type: Boolean, default: true },
        sectionWidth: { type: String, default: '12r' }
    },
    computed: {
        setFontColor () {
            if (this.status === 'pending') {
                return 'muted';
            }
            return '1';
        },
        setLineWidth () {
            let lineWidthVal = this.lineWidth;
            if (this.lineWidth.slice('-1') === 'r') {
                lineWidthVal = this.lineWidth + 'em';
            }
            return {
                '--progress-step-item-3-line-width': lineWidthVal
            };
        }
    }
};
</script>

<style scoped lang="scss">
    .bg-fill-animation-dark {
        animation: bgColor 0.5s linear;
        animation-fill-mode: forwards;
        //padding: var(--spacer-1);
        //height: 100 %;
        background-color: var(--color-black);
    }

    @keyframes bgColor {
        from {
            width: 0;
        }
        to {
            width: 12rem;
        }
    }
</style>
