<template>
    <page-template class=" bg-2">
        <div class="container-fluid">
            <h3>Setting Global Components and Plugins</h3>
            <p>components and plugins are globally registered inside src\plugins\lego.js </p>
            <p>Importing component</p>
            <code-container show-code-no-toggle code-str="import Button from 'lego-framework/src/components/Button';"/>
            <!--            <img class="img-fluid" src="./assets/global-registration/component-import.png" alt="">-->
            <p>Registering globally</p>
            <code-container show-code-no-toggle :code-str="codeStore.register.code"/>

            <!--            <img class="img-fluid" src="./assets/global-registration/component-register.png" alt="">-->
            <p>src\plugins\lego.js</p>
            <p>Importing Plugin/Directive</p>
<!--            <img class="img-fluid" src="./assets/global-registration/import-plugin.png" alt="">-->
            <code-container show-code-no-toggle code-str="import ClickOutside from 'lego-framework/src/directives/click-outside';"/>
            <p>Registering globally</p>
            <code-container show-code-no-toggle :code-str="codeStore.registerPlugin.code"/>
<!--            <img class="img-fluid" src="./assets/global-registration/register-plugin.png" alt="">-->
        </div>
    </page-template>
</template>

<script>
import codeStore from './code-store/global-registration.json';
export default {
    name: 'ClickOutsideDirectiveDocs',
    data () {
        return {
            showContent: false,
            codeStore: codeStore
        };
    },
    methods: {
        toggleView () {
            this.showContent = !this.showContent;
        },
        hideContent () {
            this.showContent = false;
        }
    }
};
</script>

<style scoped>

</style>
