<template>
    <div class="card mu-p" @click="$emit('click')">
        <!--Settings Button-->
        <div class="fl-x-br">
            <drop-down-btn design="" enter-animation="fade" shadow="0" dropdown-alignment="right"
                           size="xs" exit-animation="fade">
                <template #title>
                    <slot name="button-title">
                        <options-vertical-svg :color="color"/>
                    </slot>
                </template>
                <template #default>
                    <slot name="button-dropdown">
                        <ul class="round-1 b-1 list-unstyled of-h w-15r bg-2">
                            <li class="p-2 fs-lg-0 bg-3-h mu-p">Rename</li>
                            <li class="p-2 fs-lg-0 bg-3-h mu-p">Change Dept. Admin</li>
                            <li class="p-2 fs-lg-0 bg-3-h mu-p">Delete</li>
                        </ul>
                    </slot>
                </template>
            </drop-down-btn>
        </div>
        <!--Card Title-->
        <div class="pr-4">
            <slot name="title">
                <h5 class="font-weight-bold" :class="[baseColor, titleClasses]" v-html="title"></h5>
            </slot>
        </div>
        <!--Card Body-->
        <custom-h-el :min-height="bodyMinHeight" class="bb-1 mb-3">
            <slot name="body">
            </slot>
        </custom-h-el>
        <!--Card Footer-->
        <div class="fl-x">
            <slot name="footer">
                <p class="text-4">Created on :</p>
                <p class="pl-2" :class="[baseColor]"> 25/05/2024</p>
            </slot>
        </div>
    </div>
</template>

<script>
import OptionsVerticalSvg from './OptionsVerticalSvg';
import DropDownBtn from '../../../../components/DropdownButton';
import CustomHEl from '../../../../containers/CustomHeightContainer';

export default {
    name: 'ListingCardItem',
    components: { OptionsVerticalSvg, CustomHEl, DropDownBtn },
    props: {
        color: { type: String, default: 'primary' }, // base color of the card
        title: { type: String, default: 'Income Tax May 2024' },
        body: { type: String, default: '' },
        footer: { type: String, default: '' },
        titleClasses: { type: [String, Array], default: '' }, // additional classes for card title
        bodyMinHeight: { type: String, default: '4rem' }// min height set to the body in rem
    },
    computed: {
        baseColor () {
            const classNames = [];
            if (this.color) {
                classNames.push('text-' + this.color);
            }
            return classNames;
        }
    }
};
</script>

<style scoped>

</style>
