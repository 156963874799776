<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Heights</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-40r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--full-height',
                    description: 'full height. Usually used for setting minimum height for a container element',
                    defaultvalue: '100vh'
                },
                {
                    slno: '2',
                    name: '--half-height',
                    description: 'half height. Usually used for setting minimum height for a container element',
                    defaultvalue: 'calc(var(--full-height) / 2)'
                },
                {
                    slno: '3',
                    name: '--one-third-height',
                    description: 'one-third height. Usually used for setting height for a container element',
                    defaultvalue: 'calc(var(--full-height) / 3)'
                },
                {
                    slno: '4',
                    name: '--quarter-height',
                    description: 'quarter-height. Usually used for setting height for a container element',
                    defaultvalue: 'calc(var(--full-height) / 4)'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
