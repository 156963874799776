import fbIcon from '../../../assets/images/icons/social-icons/fb-dark.png';
import instaIcon from '../../../assets/images/icons/social-icons/insta-dark.png';
import linkedIcon from '../../../assets/images/icons/social-icons/linkedin-dark.png';
import twitterIcon from '../../../assets/images/icons/social-icons/twitter-dark.png';
import whatsappIcon from '../../../assets/images/icons/social-icons/whatsapp-dark.png';
import teamsIcon from '../../../assets/images/icons/social-icons/teams-dark.png';
const getLinks = function () {
    const socialLinks = [
        {
            icon: fbIcon,
            link: 'https://www.facebook.com/'
        },
        {
            icon: instaIcon,
            link: 'https://www.instagram.com/'
        },
        {
            icon: linkedIcon,
            link: 'https://in.linkedin.com/'
        },
        {
            icon: twitterIcon,
            link: 'https://twitter.com/'
        },
        {
            icon: whatsappIcon,
            link: 'https://www.whatsapp.com/'
        },
        {
            icon: teamsIcon,
            link: 'https://www.microsoft.com/en-in/microsoft-teams/log-in'
        }
    ];
    const links = [
        {
            text: 'Product',
            link: '/',
            items: [
                {
                    text: 'Overview',
                    link: '/'
                },
                {
                    text: 'Features',
                    link: '/'
                },
                {
                    text: 'Solutions',
                    link: '/'
                },
                {
                    text: 'Tutorials',
                    link: '/'
                },
                {
                    text: 'Pricing',
                    link: '/'
                },
                {
                    text: 'Release',
                    link: '/'
                }
            ]
        },
        {
            text: 'Company',
            link: '/',
            items: [
                {
                    text: 'About Us',
                    link: '/'
                },
                {
                    text: 'Careers',
                    link: '/'
                },
                {
                    text: 'Press',
                    link: '/'
                },
                {
                    text: 'News',
                    link: '/'
                },
                {
                    text: 'Media Kit',
                    link: '/'
                },
                {
                    text: 'Contact',
                    link: '/'
                }
            ]
        },
        {
            text: 'Resources',
            link: '/',
            items: [
                {
                    text: 'Blog',
                    link: '/'
                },
                {
                    text: 'Newsletter',
                    link: '/'
                },
                {
                    text: 'Events',
                    link: '/'
                },
                {
                    text: 'Help Centre',
                    link: '/'
                },
                {
                    text: 'Tutorials',
                    link: '/'
                },
                {
                    text: 'Support',
                    link: '/'
                }
            ]
        },
        {
            text: 'Use Cases',
            link: '/',
            items: [
                {
                    text: 'Startups',
                    link: '/'
                },
                {
                    text: 'Enterprise',
                    link: '/'
                },
                {
                    text: 'Government',
                    link: '/'
                },
                {
                    text: 'Saas centre',
                    link: '/'
                },
                {
                    text: 'Marketplaces',
                    link: '/'
                },
                {
                    text: 'Ecommerce',
                    link: '/'
                }
            ]
        },
        {
            text: 'Social',
            link: '/',
            items: [
                {
                    text: 'Twitter',
                    link: '/'
                },
                {
                    text: 'LinkedIn',
                    link: '/'
                },
                {
                    text: 'Facebook',
                    link: '/'
                },
                {
                    text: 'GitHub',
                    link: '/'
                },
                {
                    text: 'AngelList',
                    link: '/'
                },
                {
                    text: 'Dribble',
                    link: '/'
                }
            ]
        },
        {
            text: 'Contact',
            link: '/',
            slot: 'contact'
        }
    ];
    return {
        links: links,
        socialLinks: socialLinks
    };
};

export default getLinks;
