<template>
    <page-template>
        <div class="container-fluid bg-2">
            <div>
                <h2 class="my-3">Display</h2>
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Class</th>
                        <th>Description</th>
                    </tr>
                    </thead>
                    <tr v-for="(value, index) in items" :key="index">
                        <td>
                            <span>{{ value.name }}</span>
                        </td>
                        <td><span>{{ value.info }}</span></td>
                    </tr>
                </table>
                <div class="mt-4 bg-4 p-4">
                    <p><strong>.d-block</strong>: Sets the display to <code>block</code>.</p>
                    <p><strong>.d-inline</strong>: Sets the display to <code>inline</code>.</p>
                    <p><strong>.d-inline-block</strong>: Sets the display to <code>inline-block</code>.</p>
                    <p><strong>.d-none</strong>: Hides the element by setting <code>display:none</code>.</p>
                    <p><strong>.d-flex</strong>: Sets the display to <code>flex</code>.</p>
                    <p><strong>.fl-x</strong>: Sets display to <code>flex</code> with <code>flex-direction: row</code>.
                    </p>
                    <p><strong>.fl-y</strong>: Sets display to <code>flex</code> with <code>flex-direction:
                        column</code>.
                    </p>

                    <p><strong>.d-lg-none</strong>, <strong>.d-md-none</strong>, <strong>.d-xl-none</strong>: Hides the
                        element on specified breakpoints. <code>d-md-none</code> applies <code>display:none</code> on
                        medium
                        and larger screens. To show the item on large screens, add <code>d-lg-block</code>.</p>

                    <p><strong>.d-lg-block</strong>, <strong>.d-md-block</strong>, <strong>.d-xl-block</strong>: Sets
                        the
                        display to <code>block</code> on specified breakpoints.</p>

                    <p><strong>.d-lg-flex</strong>, <strong>.d-md-flex</strong>, <strong>.d-xl-flex</strong>: Sets the
                        display to <code>flex</code> on specified breakpoints.</p>

                    <p><strong>.d-lg-inline</strong>, <strong>.d-md-inline</strong>, <strong>.d-xl-inline</strong>: Sets
                        the
                        display to <code>inline</code> on specified breakpoints.</p>

                    <p><strong>.d-lg-inline-block</strong>, <strong>.d-md-inline-block</strong>,
                        <strong>.d-xl-inline-block</strong>: Sets the display to <code>inline-block</code> on specified
                        breakpoints.</p>

                </div>
                <div class="mt-4">

                    <h2>Display Classes Example</h2>
                    <code-container show-code-no-toggle :code-str="codeStore.design.code"/>
                    <div class="p-4">
                        <div class="example d-block">This is a block element (d-block).</div>

                        <div class="example d-inline">This is an inline element (d-inline).</div>

                        <div class="example d-inline-block">This is an inline-block element (d-inline-block).</div>

                        <div class="example d-none">This element is hidden (d-none).</div>

                        <div class="example d-flex">This is a flex element (d-flex).</div>

                        <div class="example fl-x">
                            <div>Flex Row Item 1</div>
                            <div>Flex Row Item 2</div>
                        </div>

                        <div class="example fl-y">
                            <div>Flex Column Item 1</div>
                            <div>Flex Column Item 2</div>
                        </div>

                        <div class="example d-lg-none">This is hidden on large screens (d-lg-none).</div>
                        <div class="example d-md-none">This is hidden on medium and larger screens (d-md-none).</div>
                        <div class="example d-xl-none">This is hidden on extra-large screens (d-xl-none).</div>

                        <div class="example d-lg-block">This is a block element on large screens (d-lg-block).</div>
                        <div class="example d-md-block">This is a block element on medium screens (d-md-block).</div>
                        <div class="example d-xl-block">This is a block element on extra-large screens (d-xl-block).
                        </div>

                        <div class="example d-lg-flex">This is a flex element on large screens (d-lg-flex).</div>
                        <div class="example d-md-flex">This is a flex element on medium screens (d-md-flex).</div>
                        <div class="example d-xl-flex">This is a flex element on extra-large screens (d-xl-flex).</div>

                        <div class="example d-lg-inline">This is an inline element on large screens (d-lg-inline).</div>
                        <div class="example d-md-inline">This is an inline element on medium screens (d-md-inline).
                        </div>
                        <div class="example d-xl-inline">This is an inline element on extra-large screens (d-xl-inline).
                        </div>

                        <div class="example d-lg-inline-block">This is an inline-block element on large screens
                            (d-lg-inline-block).
                        </div>
                        <div class="example d-md-inline-block">This is an inline-block element on medium screens
                            (d-md-inline-block).
                        </div>
                        <div class="example d-xl-inline-block">This is an inline-block element on extra-large screens
                            (d-xl-inline-block).
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/displayhelper.json';

export default {
    name: 'DisplayHelpers',
    components: { CodeContainer },
    metaInfo: {
        path: 'helpers/display-helpers/',
        title: 'Display'
    },
    data () {
        return {
            codeStore: codeStore,
            items: [
                { name: 'd-block', info: 'display:block;' },
                { name: 'd-inline', info: 'display:inline;' },
                { name: 'd-inline-block', info: 'display:inline-block;' },
                { name: 'd-none', info: 'display:none;' },
                { name: 'd-flex', info: 'display:flex;' },
                { name: 'fl-x', info: 'display:flex;flex-direction:row;' },
                { name: 'fl-y', info: 'display:flex;flex-direction:column;' },
                {
                    name: 'd-lg-none, d-md-none, d-xl-none',
                    info: 'display:none; set on appropriate display breakpoints NB: d-md-none will apply display:none; property on medium and larger screens. If you want to show the item on large screens need to add d-lg-block class'
                },
                {
                    name: 'd-lg-block, d-md-block, d-xl-block',
                    info: 'display:block; set on appropriate display breakpoints'
                },
                { name: 'd-lg-flex, d-md-flex, d-xl-flex', info: 'display:flex; set on appropriate display breakpoints' },
                {
                    name: 'd-lg-inline, d-md-inline, d-xl-inline',
                    info: 'display:inline; set on appropriate display breakpoints'
                },
                {
                    name: 'd-lg-inline-block, d-md-inline-block, d-xl-inline-block',
                    info: 'display:inline-block; set on appropriate display breakpoints'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
