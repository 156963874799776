<template>
    <div class="fl-x fl-a-c">
        <custom-h-el class="b-round" width="8px" height="8px" :bg-color="color" :bg-color-thickness="colorThickness"/>
        <span class="text-fs-0 ml-1 mt-n1">{{text}}</span>
    </div>
</template>

<script>
import CustomHEl from '../../containers/CustomHeightContainer';

export default {
    name: 'SeriesItem',
    components: { CustomHEl },
    props: {
        color: { type: String, default: 'primary' },
        text: { type: String, default: 'primary' },
        colorThickness: { type: String, default: '' }
    }
};
</script>

<style scoped>

</style>
