<template>
    <page-template>
        <div class="container-fluid px-5 bg-2 py-4">
            <h2>Shadow Helpers</h2>
            <div class="row">
                <div class="col-md-3 my-5" v-for="i in shadowClasses" :key="i">
                    <div :class="'bs-' + i" class="card py-5 text-center">
                        Class: .bs-{{ i }} <br>
                        Number: {{ i }}
                    </div>
                </div>
            </div>
            <div class="bg-4 p-4">
                <h2>Shadow Helpers Note</h2>

                <p>Shadow helpers are utility classes that allow you to easily add shadow effects to elements in your
                    web
                    design.</p>
                <p>Understanding these classes can enhance your UI by creating depth and visual hierarchy.</p>
                <p>Here’s a breakdown:</p>

                <h3>Benefits of Using Shadow Helpers</h3>

                <p><strong>Visual Depth:</strong> Shadows add a sense of depth to elements, making them stand out from
                    the
                    background and improving the overall design aesthetics.</p>

                <p><strong>Focus Attention:</strong> By applying shadows, you can draw attention to specific components,
                    such as buttons or cards, guiding user interaction.</p>

                <p><strong>Easy Implementation:</strong> Shadow helpers simplify the process of adding shadow effects,
                    allowing you to apply predefined classes without writing custom CSS.</p>

                <p><strong>Consistent Design:</strong> Using a set of predefined shadow classes ensures uniformity
                    across
                    your project, maintaining a cohesive look and feel.</p>
            </div>
            <div class="mt-4">
                <h3>Example</h3>
                <code-container show-code-no-toggle :code-str="codeStore.shadow.code"/>

                <div class="bs-4 p-5">shadow bs-4</div>
                <div class="bs-6 p-5">shadow bs-6</div>
                <div class="bs-8 p-5">shadow bs-8</div>
            </div>
        </div>
    </page-template>
</template>

<script>
import DemoMixin from '../helpersMixin';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/shadow.json';

export default {
    extends: DemoMixin,
    metaInfo: {
        path: 'helpers/shadows/',
        title: 'Shadows'
    },
    name: 'colors.scss',
    components: { CodeContainer },
    data () {
        return {
            codeStore: codeStore
        };
    }

};
</script>
