<template>
    <page-template class="p-5">
        <h2>Input</h2>
        <p class="lead">A form input control.</p>
        <div class="mb-5">
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import FormInput from 'lego-framework/src/forms/FormInput';</p>
            <p>Vue.component('validated-input', FormInput);</p>
            <p>&#60;validated-input&#62;&#60;/validated-input&#62;</p>
        </div>
        <h3 class="my-3">Color Variations</h3>
        <p>Form only supports contextual colors now. More color options will be added soon</p>
        <component-wrapper :code="codeStore.colorVariations.code">
            <div class="row fl-wrap">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput :color="color"/>
                </div>
            </div>
        </component-wrapper>

        <div>
            <h4 class="mt-4">With Icons</h4>
            <component-wrapper :code="codeStore.withIcons.code">
                <div class="row fl-wrap">
                    <div class="col-md-3">
                        <b>Color: null</b>
                        <FormInput icon="fa fa-user"/>
                    </div>
                    <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                        <b>Color: {{ color }}</b>
                        <FormInput icon="fa fa-user" :color="color"/>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h3 class="mt-4">Disabled State</h3>
            <component-wrapper :code="codeStore.disabled.code">
                <div class="row fl-wrap">
                    <div class="col-md-3">
                        <b>Color: null</b>
                        <FormInput disabled label="Disabled"/>
                    </div>
                    <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                        <b>Color: {{ color }}</b>
                        <FormInput label="Disabled" disabled :color="color"/>
                    </div>
                </div>
            </component-wrapper>
            <h4 class="mt-4">Disabled State With Icons</h4>
            <component-wrapper :code="codeStore.disabledWithIcons.code">
                <div class="row fl-wrap">
                    <div class="col-md-3">
                        <b>Color: null</b>
                        <FormInput disabled icon="fa fa-user" label="Disabled"/>
                    </div>
                    <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                        <b>Color: {{ color }}</b>
                        <FormInput icon="fa fa-user" label="Disabled" disabled :color="color"/>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h4 class="mt-4">With Label and Icons</h4>
            <component-wrapper :code="codeStore.withLabelAndIcons.code">
                <div class="row fl-wrap">
                    <div class="col-md-3">
                        <b>Color: null</b>
                        <FormInput icon="fa fa-user" label="First Name"/>
                    </div>
                    <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                        <b>Color: {{ color }}</b>
                        <FormInput icon="fa fa-user" :color="color" label="First Name"/>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h4 class="mt-4">With Label, Icons and Error Messages</h4>
            <component-wrapper :code="codeStore.withLabelIconAndErrorMessage.code">
                <div class="row flx-b fl-wrap">
                    <div class="col-md-3">
                        <b>Color: null</b>
                        <FormInput icon="fa fa-user" label="First Name" error-message="First Name is required."/>
                    </div>
                    <div class="col-md-3">
                        <b>Color: null</b>
                        <FormInput icon="fa fa-user" label="First Name" success-message="First Name is required."/>
                    </div>
                </div>
                <alert color="info" border="l" icon-colored mode="border" border-thickness="4" shadow="5">
                    Note that giving a error message will automatically set the color to error. Likewise setting success message will set the color to success
                </alert>
            </component-wrapper>
        </div>

        <div>
            <h3 class="mt-4">Size Variations</h3>
            <component-wrapper :code="codeStore.sizeVariations.code">
                <div class="row fl-x-t">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <FormInput :label="size" :size="size"/>
                    </div>
                </div>
            </component-wrapper>
            <h4 class="mt-4">With Icons</h4>
            <component-wrapper :code="codeStore.sizeVariationWithIcons.code">
                <div class="row fl-x-b">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <FormInput :label="size" icon="fa fa-user" :size="size"/>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h3 class="mt-4">Size Variations Floating Label</h3>
            <component-wrapper :code="codeStore.sizeVariationsFlotingLabel.code">
                <div class="row fl-x-t">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <FormInput :label="'Label ' + size" :size="size" design="floating-top"/>
                    </div>
                </div>
            </component-wrapper>
            <h4 class="mt-4">With Icons</h4>
            <component-wrapper :code="codeStore.sizeVariationFloatingLabelIcons.code">
                <div class="row fl-x-b">
                    <div class="col-md-3" v-for="size in sizes" :key="size">
                        <FormInput :label="'Label ' + size" icon="fa fa-user" :size="size" design="floating-top"/>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h2 class="my-3">Props</h2>
            <prop-table :items="props"/>
        </div>

        <div>
            <h2>Slots</h2>
            <!--            <p class="lead">Currently no slots are implemented in form input</p>-->
            <h4 class="mt-4">append & prepend</h4>
            <component-wrapper :code="codeStore.slots.code">
                <div class="row fl-x-t">
                    <div class="col-md-3">
                        <FormInput>
                            <template #append>
                                <LegoButton size="sm" text="Add"></LegoButton>
                            </template>
                        </FormInput>
                    </div>
                    <div class="col-md-3">
                        <FormInput>
                            <template #prepend>
                                <span class="font-weight-bold mr-2">ID:  </span>
                            </template>
                        </FormInput>
                    </div>
                </div>
            </component-wrapper>
        </div>

        <div>
            <h2>Events</h2>
            <p class="lead">All the standard events are supported in button</p>
        </div>
        <div class="col-lg-12 mt-4">
            <h3>SCSS Variables</h3>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>

    </page-template>
</template>

<script>
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import helpersMixin from '../helpersMixin';
import theme from 'lego-framework/src/assets/theme.js';
import FormInput from 'lego-framework/src/forms/FormInput';
import codeStore from './code-storage/form-input.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';
import LegoButton from 'lego-framework/src/components/Button';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'components/input/',
        title: 'Inputs'
    },
    name: 'InputDocumentation',
    components: { LegoButton, ComponentWrapper, FormInput, PropTable },
    data () {
        const props = [
            ['type', 'String', 'The type of the control.', ['text', 'number', 'email']],
            ['disabled', 'Boolean', 'Is the control disabled or not', ''],

            ['color', 'String', 'The color of the form inputs.', theme['input-colors']],
            ['label', 'String', 'The label of the form control', ''],
            ['icon', 'String', 'The icon class. It can be from font awesome or some other font based library.', ''],
            ['size', 'String', 'The type of the button.', ['xs', 'sm', 'md (default)', 'lg']],

            ['name', 'String', 'The name used for Validation using vee-validate', ''],
            ['rules', 'Object', 'Validation rules for vee validate.', ''],

            ['errorMessage', 'String', 'The error message to be shown', ''],
            ['successMessage', 'String', 'The error message to be shown', ''],

            ['inputClasses', 'String', 'Additional css classes for input tag', ''],
            ['labelClasses', 'String', 'Additional css classes for label tag', ''],

            ['clearIcon', 'String', 'The icon for the clear text', ''],
            ['clearText', 'String', '', ''],

            ['shadow', 'String', 'The shadow number for the button. Default is 1', ''],
            ['hoverShadow', 'String', 'The shadow on hover number for the button. Default is 2', '']
        ];

        const textAreaProps = [
            ...props
        ];
        textAreaProps.splice(0, 1);
        textAreaProps.splice(12, 2);

        const vueSelectProps = [
            ['options', 'Array', 'An array of options', ''],
            ...textAreaProps
        ];

        const vueAjaxSelectProps = [
            ['url', 'String', 'The url from which data has to be fetched', ''],
            ...textAreaProps
        ];

        const maskedInputProps = [
            ['mask', 'String', 'The input mask', ''],
            ...textAreaProps
        ];

        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--form-separator',
                    description: '',
                    defaultvalue: '1rem'
                },
                {
                    slno: '2',
                    name: '--form-control-label-font-size',
                    description: '',
                    defaultvalue: '1rem * size factor = 0.875rem ( md)'
                },
                {
                    slno: '3',
                    name: '--form-control-padding-y',
                    description: '',
                    defaultvalue: 'var(--element-padding-y) = 0.325em'
                },
                {
                    slno: '4',
                    name: '--form-control-line-height',
                    description: '',
                    defaultvalue: 'var(--element-line-height) = 1.6rem'
                },
                {
                    slno: '5',
                    name: '--form-control-border-width',
                    description: '',
                    defaultvalue: 'var(--element-border-width) = var(--border-width) = 1px'
                },
                {
                    slno: '6',
                    name: '--form-control-border-color',
                    description: '',
                    defaultvalue: 'var(--element-border-color), var(--border-color)  = #E4E9F2'
                },
                {
                    slno: '7',
                    name: '--form-control-font-size',
                    description: '',
                    defaultvalue: 'var(--element-font-size) = var(--font-size-base) = 1rem'
                },
                {
                    slno: '8',
                    name: '--form-control-color',
                    description: '',
                    defaultvalue: 'Default Value : var(--body-text-color)  = var(--text-1) = #161616'
                },
                {
                    slno: '9',
                    name: '--form-control-padding-x',
                    description: '',
                    defaultvalue: 'var(--element-padding-x) = 0.75em'
                },
                {
                    slno: '10',
                    name: '--form-control-disabled-bg',
                    description: '',
                    defaultvalue: 'var(--color-gray-100) = #f8f9fa'
                },
                {
                    slno: '11',
                    name: '--form-control-border-color-focus',
                    description: '',
                    defaultvalue: 'var(--element-border-color-focus) = var(--color-primary)'
                },
                {
                    slno: '12',
                    name: '--form-control-bg-color',
                    description: '',
                    defaultvalue: 'transparent'
                }
            ],
            slots: [
                ['default', 'The default slot contains all the text and icon. If this slot is used then icon and text props wont work']
            ],
            maskedInputProps: maskedInputProps,
            vueAjaxSelectProps: vueAjaxSelectProps,
            vueSelectProps: vueSelectProps,
            textAreaProps: textAreaProps,
            props: props,
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
