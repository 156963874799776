<template>
    <div :class="{'fl-x': design ==='basic-b'}">
        <activity-gauge-circle-lg v-bind="circleAttributes"/>
        <series-items class="mt-3" :align="design ==='basic-b'?'vertical':'horizontal'"
                      :class="[{'mt-6':  design ==='basic-b'}]"/>
    </div>
</template>

<script>
import ActivityGaugeCircleLg from './circles/ActivityGaugeCircleLg';
import SeriesItems from './SeriesItems';

export default {
    name: 'ActivityGaugeLg',
    components: { SeriesItems, ActivityGaugeCircleLg },
    props: {
        value1: { type: Number, default: 75 },
        value2: { type: Number, default: 60 },
        value3: { type: Number, default: 80 },
        totalValue: { type: Number, default: 1000 },
        label: { type: String, default: 'Users' },
        color: { type: String, default: 'secondary' },
        size: { type: String, default: 'sm' },
        design: { type: String, default: 'basic-a' },
        labelFontSize: { type: String, default: '16px' },
        labelFontWeight: { type: String, default: 'bold' },
        totalValueFontSize: { type: String, default: '30px' },
        totalValueFontWeight: { type: String, default: 'bold' }
    },
    computed: {
        circleAttributes () {
            return {
                value1: this.value1,
                value2: this.value2,
                value3: this.value3,
                totalValue: this.totalValue,
                label: this.label,
                labelFontSize: this.labelFontSize,
                labelFontWeight: this.labelFontWeight,
                totalValueFontSize: this.totalValueFontSize,
                totalValueFontWeight: this.totalValueFontWeight
            };
        }
    }
};
</script>

<style scoped>

</style>
