<template>
    <div class="py-2">
        <div class="">
<!--            <div class="" :style="{ borderTop: hideBorder ? 'none' : '3px solid ' + borderColor }">-->
                <div class="fl-x ">
                    <img v-if="!hideImage"
                         :src="headerImage" class=""
                         alt="Header Image"/>
                    <h4 class="mt-2 mb-0 text-fs-2" :class="titleBgColor" :style="{ textAlign: titleAlignment  }">{{ Title }}</h4>
                </div>

            <div class="fl-x mt-n1">
                <custom-h-el class="ml-4"  width="1px" bg-color="grey" height="1.8r"/>
                <p class="pl-5 mt-n1 text-fs-1" :class="contentBgColor" :style="{ textAlign: contentAlignment }">{{ Content }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'progress-step-text-alignment',
    props: {
        Title: String,
        Content: String,
        contentColor: {
            type: String,
            default: 'black'
        },
        titleColor: {
            type: String,
            default: 'black'
        },
        borderColor: {
            type: String,
            default: 'black'
        },
        titleAlignment: {
            type: String,
            default: 'left'
        },
        contentAlignment: {
            type: String,
            default: 'left'
        },
        hideBorder: {
            type: Boolean,
            default: false
        },
        hideImage: Boolean,
        applyAdjustImage: {
            type: Boolean,
            default: false
        },
        headerImage: String,
        titleBgColor: String,
        contentBgColor: String
    }
};
</script>

<style scoped>

</style>
