<template>
    <page-template>
        <div class="bg-2 p-5">
            <div>
                <h3>Typography</h3>
            </div>
            <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        </div>
    </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--font-family-base',
                    description: 'Font family base set to body. Depended on other variable.<br>1. --font-family-sans-serif',
                    defaultvalue: 'apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'
                },
                {
                    slno: '2',
                    name: '--font-size-base',
                    description: 'Base font size set to body',
                    defaultvalue: '1rem'
                },
                {
                    slno: '3',
                    name: '--font-weight-base',
                    description: 'Base font weight set to the body. Depended on variables.<br>1. --font-weight-normal',
                    defaultvalue: '400'
                },
                {
                    slno: '4',
                    name: '--line-height-base',
                    description: 'Base line height set to body',
                    defaultvalue: '1.5'
                },
                {
                    slno: '5',
                    name: '--body-text-color',
                    description: 'Body text color set. Depended on variable.<br>1. --color-gray-900',
                    defaultvalue: '#212529'
                },
                {
                    slno: '6',
                    name: '--body-bg-color',
                    description: 'Body background color set. Depended on variable.<br>1. --color-white',
                    defaultvalue: '#FFFFFF'
                }
            ]
        };
    }
};
</script>

<style scoped>
</style>
