<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Metrics</h2>
                    <p class="lead">Matrices component for showing useful data to user on dashboard or other pages</p>
                    <h5 class="py-2">Import components</h5>
                    <code-container :code-str="codeStore.import" show-code-no-toggle></code-container>
                </div>
                <div class="col-12">
                    <heading tag="h3" class="my-4">MetricsItem1</heading>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">Default</h6>
                    <metrics-item1
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies"
                    />
                    <code-container :code-str="codeStore.item1.default" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">Default: with counting animation</h6>
                    <metrics-item1
                        show-counting
                        text1="600"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies"
                    />
                    <code-container :code-str="codeStore.item1.default" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">With link</h6>
                    <metrics-item1
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" show-link/>
                    <code-container :code-str="codeStore.item1.withLink" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">With Icon</h6>
                    <metrics-item1
                        icon="fa fa-rupee"
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies"/>
                    <code-container :code-str="codeStore.item1.withIcon" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4 class="my-4">Bordered</h4>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">Default</h6>
                    <metrics-item1
                        text1="600%"
                        border="1"
                        border-radius="2"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies"/>
                    <code-container :code-str="codeStore.item1.defaultWithBorder" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">With link</h6>
                    <metrics-item1
                        text1="600%"
                        border="1"
                        border-radius="2"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" show-link/>
                    <code-container :code-str="codeStore.item1.withLinkAndBorder" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">With Icon</h6>
                    <metrics-item1
                        text1="600%"
                        border="1"
                        border-radius="2"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" icon="fa fa-rupee"/>
                    <code-container :code-str="codeStore.item1.withIconAndBorder" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4 class="my-4">Card Designs</h4>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">Default</h6>
                    <metrics-item1
                        :card-design="true"
                        border="1"
                        border-radius="2"
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies"/>
                    <code-container :code-str="codeStore.item1.defaultCard" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">With link</h6>
                    <metrics-item1
                        card-design
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" show-link/>
                    <code-container :code-str="codeStore.item1.withLinkCard" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h6 class="mb-3">With icon</h6>
                    <metrics-item1
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" icon="fa fa-rupee" card-design/>
                    <code-container :code-str="codeStore.item1.withIconCard" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h6 class="mb-3">Font Customization</h6>
                    <metrics-item1
                        text1="600%"
                        title-font-size="6"
                        text2="Global download"
                        :sub-title-color="3"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" icon="fa fa-rupee"
                        content-color="2"
                        card-design/>
                    <code-container :code-str="codeStore.item1.fontCustomizationCard" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h4 class="my-4">Size Variants</h4>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">xs</h6>
                    <metrics-item1
                        border="1"
                        border-radius="2"
                        size="xs"
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">sm</h6>
                    <metrics-item1
                        border="1"
                        border-radius="2"
                        size="sm"
                        text1="600%"
                        text2="Global download"
                        show-link
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" :link-icon-img="iconRight" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">md</h6>
                    <metrics-item1
                        border="1"
                        border-radius="2"
                        size="md"
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">lg</h6>
                    <metrics-item1
                        :icon-img="iconStock"
                        border="1"
                        border-radius="2"
                        size="lg"
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" card-design/>
                </div>
                <div class="col-lg-4">
                    <h6 class="mb-3">xl</h6>
                    <metrics-item1
                        icon="fa fa-file"
                        border="1"
                        border-radius="2"
                        size="xl"
                        text1="600%"
                        text2="Global download"
                        text3="We've helped build over 400 projects and
                        have worked with some amazing companies" card-design/>
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStore.item1.sizeVariants" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <heading tag="h3" class="my-4">MetricsItem2</heading>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">Default</h6>
                    <metrics-item2
                        text1="600%"
                        text2="Global download"/>
                    <code-container :code-str="codeStore.item2.default" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">Default: with counting animation</h6>
                    <metrics-item2
                        show-counting-animation
                        text1="600"
                        text2="Global download"/>
                    <code-container :code-str="codeStore.item2.default" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">MetricsItem2 - cardDesign: true</h6>
                    <metrics-item2
                        text1="600%"
                        text2="Global download"
                        card-design/>
                    <code-container :code-str="codeStore.item2.default" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <heading tag="h3" class="my-4">Size Variants</heading>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">size : xs</h6>
                    <metrics-item2
                        size="xs"
                        text1="600%"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">size : sm</h6>
                    <metrics-item2
                        size="sm"
                        text1="600%"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">size : md</h6>
                    <metrics-item2
                        size="md"
                        text1="600%"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">size : lg</h6>
                    <metrics-item2
                        size="lg"
                        text1="600%"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">size : xl</h6>
                    <metrics-item2
                        size="xl"
                        text1="600%"
                        text2="Global download"/>
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStore.item2.sizeVariants" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-3">
                    <h6 class="mb-3">Font Customization</h6>
                    <metrics-item2
                        title-classes="font-arial-regular"
                        sub-title-classes="font-arial-regular"
                        size="lg"
                        text1="600%"
                        text2="Global download"/>
                    <code-container :code-str="codeStore.item2.fontCustomization" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <heading tag="h3" class="my-4">MetricsItem3</heading>
                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">Default</h6>
                    <metrics-item3
                        text1="600%"
                        icon="fa fa-file"
                        text2="Global download"/>
                    <code-container :code-str="codeStore.item3.default" show-code-no-toggle></code-container>

                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">Default: with counting animation</h6>
                    <metrics-item3
                        show-counting-animation
                        title="600"
                        icon="fa fa-file"
                        text2="Global download"/>
                    <code-container :code-str="codeStore.item3.default" show-code-no-toggle></code-container>

                </div>
                <div class="col-lg-6">
                    <h6 class="mb-3">With IconImg set</h6>
                    <metrics-item3
                        text1="600%"
                        :icon-img="iconStock"
                        text2="Global download"/>
                    <code-container :code-str="codeStore.item3.withIconImgSet" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <heading tag="h3" class="my-4">Size Variants</heading>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">xs</h6>
                    <metrics-item3
                        text1="600%"
                        size="xs"
                        icon="fa fa-file"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">sm</h6>
                    <metrics-item3
                        text1="600%"
                        size="sm"
                        icon="fa fa-file"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">md</h6>
                    <metrics-item3
                        text1="600%"
                        size="md"
                        icon="fa fa-file"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">lg</h6>
                    <metrics-item3
                        text1="600%"
                        size="lg"
                        icon="fa fa-file"
                        text2="Global download"/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">xl</h6>
                    <metrics-item3
                        text1="600%"
                        size="xl"
                        icon="fa fa-file"
                        text2="Global download"/>
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStore.item3.sizeVariants" show-code-no-toggle></code-container>
                </div>
                <!--                <div class="col-lg-3">-->
                <!--                    <h6 class="mb-3">With titleFontSize: 6</h6>-->
                <!--                    <metrics-item3-->
                <!--                        text1="600%"-->
                <!--                        title-font-size="6"-->
                <!--                        icon="fa fa-file"-->
                <!--                        text2="Global download"/>-->
                <!--                </div>-->
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <heading tag="h3" class="my-4">Size Variants ( iconImg set)</heading>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">xs</h6>
                    <metrics-item3
                        text1="600%"
                        size="xs"
                        :icon-img="iconStock"
                        text2="Global download"
                        align="center"
                        card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">sm</h6>
                    <metrics-item3
                        text1="600%"
                        size="sm"
                        :icon-img="iconStock"
                        text2="Global download" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">md</h6>
                    <metrics-item3
                        text1="600%"
                        size="md"
                        :icon-img="iconStock"
                        text2="Global download" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">lg</h6>
                    <metrics-item3
                        text1="600%"
                        size="lg"
                        :icon-img="iconStock"
                        text2="Global download" card-design/>
                </div>
                <div class="col-lg-4 mt-4">
                    <h6 class="mb-3">xl</h6>
                    <metrics-item3
                        text1="600%"
                        size="xl"
                        :icon-img="iconStock"
                        text2="Global download" card-design/>
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStore.item3.sizeVariantsIconImgSet" show-code-no-toggle></code-container>
                </div>
                <!--                <div class="col-lg-3">-->
                <!--                    <h6 class="mb-3">With titleFontSize: 6</h6>-->
                <!--                    <metrics-item3-->
                <!--                        text1="600%"-->
                <!--                        title-font-size="6"-->
                <!--                        icon="fa fa-file"-->
                <!--                        text2="Global download"/>-->
                <!--                </div>-->
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <h4 class="my-4">Card Designs</h4>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">Default</h6>
                    <metrics-item3
                        text1="600%"
                        icon="fa fa-file"
                        text2="Global download" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">With titleFontSize: 6</h6>
                    <metrics-item3
                        text1="600%"
                        title-font-size="6"
                        icon="fa fa-file"
                        text2="Global download" card-design/>
                </div>
                <div class="col-lg-3">
                    <h6 class="mb-3">Font Customization</h6>
                    <metrics-item3
                        text1="600%"
                        title-font-size="6"
                        title-color="secondary"
                        sub-title-color="2"
                        icon="fa fa-file"
                        text2="Global download" card-design/>
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStore.item3.cardDesigns" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="my-4">Section Layout - Demo</h3>
                </div>
                <div class="col-12">
                    <div class="">
                        <div class="text-center card bs-5 p-4 py-6">
                            <h3 class="font-weight-bold mx-10">Great products, faster than ever</h3>
                            <p class="text-2 pt-2">Everything you need to build modern UI and great products.</p>
                            <div class="row mt-4">
                                <div class="col-lg-3">
                                    <metrics-item1
                                        card-design
                                        text1="600%"
                                        text2="Global download"
                                        text3="We've helped build over 400 projects and
                                        have worked with some amazing companies"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item1
                                        card-design
                                        text1="600%"
                                        text2="Global download"
                                        text3="We've helped build over 400 projects and
                                        have worked with some amazing companies"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item1
                                        card-design
                                        text1="600%"
                                        text2="Global download"
                                        text3="We've helped build over 400 projects and
                                        have worked with some amazing companies"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item1
                                        card-design
                                        text1="600%"
                                        text2="Global download"
                                        text3="We've helped build over 400 projects and
                                        have worked with some amazing companies"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <code-container :code-str="codeStore.sectionLayout.item1" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h3 class="my-4">Section Layout - Demo 2</h3>
                </div>
                <div class="col-12">
                    <div class="">
                        <div class="text-center card bs-5 p-4 py-6">
                            <h3 class="font-weight-bold mx-10">Great products, faster than ever</h3>
                            <p class="text-2 pt-2">Everything you need to build modern UI and great products.</p>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <metrics-item2
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item2
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item2
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item2
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <code-container :code-str="codeStore.sectionLayout.item2" show-code-no-toggle></code-container>
                </div>
                <div class="col-12">
                    <h3 class="my-4">Section Layout - Demo 3</h3>
                </div>
                <div class="col-12">
                    <div class="">
                        <div class="text-center card bs-5 p-4 py-6">
                            <h3 class="font-weight-bold mx-10">Great products, faster than ever</h3>
                            <p class="text-2 pt-2">Everything you need to build modern UI and great products.</p>
                            <div class="row mt-5">
                                <div class="col-lg-3">
                                    <metrics-item3
                                        icon="fa fa-rupee"
                                        card-design
                                        title-font-size="6"
                                        spacing="3"
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item3
                                        icon="fa fa-rupee"
                                        card-design
                                        title-font-size="6"
                                        spacing="3"
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item3
                                        icon="fa fa-rupee"
                                        card-design
                                        title-font-size="6"
                                        spacing="3"
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                                <div class="col-lg-3">
                                    <metrics-item3
                                        icon="fa fa-rupee"
                                        card-design
                                        title-font-size="6"
                                        spacing="3"
                                        text1="600%"
                                        text2="Global download"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <code-container :code-str="codeStore.sectionLayout.item3" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
// import MetricsItem from '../metrics/MetricsItem';
import heading from 'lego-framework/src/components/Heading';
// import MetricsItem1 from '../metrics/MetricsItem1';
// import MetricsItem2 from '../metrics/MetricsItem2';
// import MetricsItem3 from '../metrics/MetricsItem3';
import MetricsItem1 from 'lego-framework/src/web-components/metrices/MetricsItem1';
import MetricsItem2 from 'lego-framework/src/web-components/metrices/MetricsItem2';
import MetricsItem3 from 'lego-framework/src/web-components/metrices/MetricsItem3';
import iconStock from '../../../assets/web/icons/icons8-stock-64.png';
import iconRight from '../../../assets/web/icons/icons8-arrow-right-24-green.png';
import codeStore from '../../../data/codes/web-components/metrics.json';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';

export default {
    name: 'MatricsVariantsPage',
    components: { MetricsItem3, MetricsItem2, MetricsItem1, heading, PropTable },
    data () {
        return {
            iconStock: iconStock,
            iconRight: iconRight,
            codeStore: codeStore,
            propsInfo: [
                ['text1', 'String', 'text on top with large font', '', ''],
                ['text2', 'String', 'text below the title', '', ''],
                ['text3', 'String', 'content text', '', ''],
                ['icon', 'String', 'top icon class', ['any fa class'], ''],
                ['icon-img', 'String', 'Top Icon Image', ['any icon image'], ''],
                ['showLink', 'Boolean', 'Show or hide link', ['true', 'false'], 'false'],
                ['linkText', 'String', 'Text on link', '', ''],
                ['linkUrl', 'String', 'link Url', '', ''],
                ['linkIconImg', 'String', 'Link Icon image', '', ''],
                ['color', 'String', 'Base Color of the card', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary'],
                ['size', 'String', 'size of the card', ['xs', 'sm', 'md', 'lg', 'xl'], 'md'],
                ['shadow', 'String', 'box shadow', ['1', '2', '3', '4', 'upto 29'], ''],
                ['border', 'String', 'border', ['1', '2', '3', '4', 'upto 10'], ''],
                ['borderRadius', 'String', 'border radius', ['1', '2', '3', '4', 'upto 10'], ''],
                ['cardDesign', 'Boolean', 'border and box shadow added and made to a card design', ['true', 'false'], 'false'],
                ['text1Color', 'String', 'title color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], 'primary(set from base color)'],
                ['text1Classes', 'String,Array', 'title additional classes', '', ''],
                ['text2Color', 'String', 'subTitle color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text2Classes', 'String,Array', 'subTitle additional classes', '', ''],
                ['text3Color', 'String', 'content color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info'], ''],
                ['text3Classes', 'String,Array', 'content additional classes', '', '']
            ]

        };
    }
};
</script>

<style scoped>

</style>
