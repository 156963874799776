<template>
    <div class="m-3 fl-x">
      <div class="col-lg-2 bl-4">
          <h1 class="font-weight-bold text-secondary">{{number}}</h1>
          <p class="font-weight-bold">{{text}}</p>
          <a class="text-secondary">View Projects
              <i class="fa fa-arrow-right"></i>
          </a>
      </div>
         <div class="col-lg-2 bl-4">
          <h1 class="font-weight-bold text-secondary">{{number}}</h1>
          <p class="font-weight-bold">{{text}}</p>
          <a class="text-secondary">View Projects
              <i class="fa fa-arrow-right"></i>
          </a>
      </div>
         <div class="col-lg-2 bl-4">
          <h1 class="font-weight-bold text-secondary">{{number}}</h1>
          <p class="font-weight-bold">{{text}}</p>
          <a class="text-secondary">View Projects
              <i class="fa fa-arrow-right"></i>
          </a>
      </div>
    </div>
</template>

<script>
export default {
    name: 'MatricsItemOne',
    props: {
        number: { type: String, default: '600%' },
        text: { type: String, default: 'Projects Completed' }
    }
};
</script>

<style scoped>

</style>
