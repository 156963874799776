<template>
    <page-template class="p-5">
        <h2>Select</h2>
        <p class="lead">Selection Field also supports all the properties of input</p>
        <div class="mb-5">
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import FormSelect from 'lego-framework/src/forms/FormSelect';</p>
            <p>Vue.component('validated-vue-select', FormSelect);</p>
            <p>&#60;validated-vue-select&#62;&#60;/validated-vue-select&#62;</p>
        </div>
        <h3 class="my-3">Color Variations</h3>
        <p>Form only supports contextual colors now. More color options will be added soon</p>
        <div class="row">
            <div class="col-md-3">
                <b>Color: null</b>
                <FormInput :options="options"/>
            </div>
            <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                <b>Color: {{ color }}</b>
                <FormInput :options="options" :color="color"/>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput :options="options" icon="fa fa-user"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput :options="options" icon="fa fa-user" :color="color"/>
                </div>
            </div>
        </div>

        <div>
            <h3 class="mt-4">Disabled State</h3>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput :options="options" disabled label="Disabled"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput :options="options" label="Disabled" disabled :color="color"/>
                </div>
            </div>
            <h4 class="mt-4">Disabled State With Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput :options="options" disabled icon="fa fa-user" label="Disabled"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput :options="options" icon="fa fa-user" label="Disabled" disabled :color="color"/>
                </div>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Label and Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput :options="options" icon="fa fa-user" label="First Name"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput :options="options" icon="fa fa-user" :color="color" label="First Name"/>
                </div>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Label, Icons and Error Messages</h4>
            <div class="row flx-b">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput :options="options" icon="fa fa-user" label="First Name" error-message="First Name is required."/>
                </div>
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput :options="options" icon="fa fa-user" label="First Name" success-message="First Name is required."/>
                </div>
            </div>
            <alert color="info" border="l" icon-colored mode="border" border-thickness="4" shadow="5">
                Note that giving a error message will automatically set the color to error. Likewise setting success message will set the color to success
            </alert>
        </div>

        <div>
            <h3 class="mt-4">Size Variations</h3>
            <div class="row fl-x-t">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput :options="options" :label="size" :size="size"/>
                </div>
            </div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row fl-x-b">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput :options="options" :label="size" icon="fa fa-user" :size="size"/>
                </div>
            </div>
        </div>

        <div>
            <h3 class="mt-4">Size Variations: Floating Labels</h3>
            <div class="row fl-x-t">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput :options="options" :label="size" :size="size" design="floating-top"/>
                </div>
            </div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row fl-x-b">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput :options="options" :label="size" icon="fa fa-user" design="floating-top" :size="size"/>
                </div>
            </div>
        </div>

        <div>
            <h2 class="my-3">Props</h2>
            <prop-table :items="vueSelectProps"/>
        </div>

        <div>
            <h2>Slots</h2>
            <p class="lead">Currently no slots are implemented in form input</p>
        </div>

        <div>
            <h2>Events</h2>
            <p class="lead">All the standard events are supported in button</p>
        </div>

    </page-template>
</template>

<script>
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import FormInput from 'lego-framework/src/forms/FormSelect';
import Input from './Input';

export default {
    extends: Input,
    metaInfo: {
        path: 'components/select/',
        title: 'Select'
    },
    data () {
        return {
            options: ['one', 'two', 'three']
        };
    },
    name: 'SelectDocumentation',
    components: { FormInput, PropTable }
};
</script>

<style scoped>

</style>
