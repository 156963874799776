<template>
    <page-template class="bg-2">
        <div class=" full-height ">
            <div class="mb-5">
                <h2>Top Navbar</h2>
                <p class="lead">Shows a navigation bar with logo or title. The items are provided as array.</p>
                <code-container show-code-no-toggle
                                code-str="import TopNav from 'lego-framework/src/components/NavBar';"/>
            </div>
            <div class="mb-5">
                <h2>Sample Top Nav</h2>
                <top-nav :items="items1" logo-text="Logo" shadow="4" has-separator dropdown-round="2"/>
                <strong class="">JSON of Items</strong>
                <pre>{{ JSON.stringify(items1, null, 4) }}</pre>
            </div>
            <div class="mt-4">
                <h3>SCSS Variables</h3>
                <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
            </div>

        </div>
    </page-template>
</template>

<script>
// import DemoMixin from '@lego/views/DemoMixin';
import TopNav from 'lego-framework/src/components/NavBar';
import img from '@lego-views/data/images';

export default {
    // extends : DemoMixin,
    data () {
        const thirdItems = [
            { text: 'Third Level 1', link: '#' },
            { text: 'Third Level 2', link: '#' },
            { text: 'Third Level 3', link: '#' },
            { text: 'Third Level 4', link: '#' },
            { text: 'Button Item', link: '#', type: 'button', btnAttrs: { design: 'basic', color: 'success' } },
            { text: 'Third Level 5', link: '#', image: img.randomThumb() }
        ];

        const subItems = [
            { text: 'Second Level 1', link: '#' },
            { text: 'Second Level 2', link: '#', items: thirdItems, dropdownPlacement: 'right' },
            { text: 'Second Level 3', link: '#', items: thirdItems },
            { text: 'Second Level 4', link: '#' },
            { text: 'Second Level 5', link: '#', image: img.randomThumb() }
        ];
        return {
            items1: [
                { text: 'First Menu Item', link: '#', items: subItems },
                { text: 'Second Menu Item', link: '#', items: subItems },
                { text: 'Button Item', link: '#', type: 'button', btnAttrs: { design: 'basic', color: 'success' } },
                { type: 'separator' },
                {
                    text: 'Fourth Menu Item',
                    link: '#',
                    items: subItems,
                    dropdownPlacement: 'right',
                    image: img.randomThumb(),
                    imageRound: '2'
                }
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--nav-bar-main-color',
                    description: 'Navbar main color',
                    defaultvalue: 'var(--color-primary)'
                },
                {
                    slno: '2',
                    name: '--nav-bar-contrast-color',
                    description: 'Navbar contrast color',
                    defaultvalue: ' var(--color-primary-contrast)'
                },
                {
                    slno: '3',
                    name: '--nav-bar-bg',
                    description: 'Navbar Background color',
                    defaultvalue: 'var(--nav-bar-main-color)'
                },
                {
                    slno: '4',
                    name: '--nav-bar-logo-text-color',
                    description: 'Navbar Logo text color',
                    defaultvalue: 'var(--nav-bar-contrast-color) --> var(--color-primary-contrast)'
                },
                {
                    slno: '5',
                    name: '--nav-bar-text-color',
                    description: 'Navbar text color',
                    defaultvalue: 'var(--nav-bar-contrast-color) -- > var(--color-primary-contrast)'
                },
                {
                    slno: '6',
                    name: '--nav-bar-hover-text-color',
                    description: 'Navbar hover text color',
                    defaultvalue: 'var(--nav-bar-contrast-color) ->  var(--color-primary-contrast)'
                },
                {
                    slno: '7',
                    name: '--nav-bar-dropdown-hover-bg',
                    description: 'Navbar dropdown hover background color',
                    defaultvalue: 'var(--nav-bar-main-color) -> var(--color-primary)'
                },
                {
                    slno: '8',
                    name: '--nav-bar-dropdown-hover-text-color',
                    description: 'Navbar dropdown hover text color',
                    defaultvalue: 'var(--nav-bar-contrast-color) ->  var(--color-primary-contrast)'
                },
                {
                    slno: '9',
                    name: '--nav-bar-hover-effect-color',
                    description: 'Navbar hover effect color',
                    defaultvalue: 'var(--nav-bar-contrast-color) ->  var(--color-primary-contrast)'
                },
                {
                    slno: '10',
                    name: '--nav-bar-dropdown-bg',
                    description: 'Navbar Dropdown Bg color.',
                    defaultvalue: 'var(--bg-2) -  #FFFFFF'
                },
                {
                    slno: '11',
                    name: '--nav-bar-drop-down-width',
                    description: 'Navbar drop down width',
                    defaultvalue: '16rem'
                },
                {
                    slno: '12',
                    name: '--border-color-light',
                    description: 'Border color light',
                    defaultvalue: '#120e08'
                },
                {
                    slno: '13',
                    name: '--nav-bar-item-line-height',
                    description: 'Navbar item line height',
                    defaultvalue: 'var(--line-height-base) - 1.5em'
                },
                {
                    slno: '14',
                    name: '--nav-bar-dropdown-text-color',
                    description: 'Navbar dropdown text color',
                    defaultvalue: 'var(--text-1) - #161616'
                },
                {
                    slno: '15',
                    name: '--nav-bar-logo-text-color',
                    description: 'Navbar logo text color',
                    defaultvalue: ''
                },
                {
                    slno: '16',
                    name: '--nav-bar-logo-font-size',
                    description: 'Navbar Logo Font size',
                    defaultvalue: '1.5rem'
                },
                {
                    slno: '17',
                    name: '--nav-bar-height',
                    description: 'Navbar height',
                    defaultvalue: '3.8rem'
                },
                {
                    slno: '18',
                    name: '--nav-bar-item-padding-y',
                    description: 'Navbar item vertical padding',
                    defaultvalue: '0.75rem'
                },
                {
                    slno: '19',
                    name: '--nav-bar-item-padding-x',
                    description: 'Navbar item horizontal padding',
                    defaultvalue: 'var(--spacer-3) - 1rem'
                }
            ]
        };
    },
    metaInfo: {
        path: 'navigations/top-nav/',
        title: 'Top Nav'
    },
    components: { 'top-nav': TopNav }
};
</script>
