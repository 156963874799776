<template>
    <page-template>
    <div class="bg-2 p-5">
        <div>
            <h3>Base Variables</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'BaseVariables',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--body-bg-color',
                    description: 'depended on variable ( --color-white )',
                    defaultvalue: 'var(--color-white), #FFFFFF'
                },
                {
                    slno: '2',
                    name: '--body-text-color',
                    description: 'depended on variable ( --color-gray-900 )',
                    defaultvalue: 'var(--color-gray-900), darker color of #adb5bd'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
