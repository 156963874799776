<template>
    <div class="bg-2">
        <div class="container">
            <h3 class="my-3">Alerts</h3>
            <btn text="Avatar Left : Text with image below" @click="$refs.avatarLeftIconModal.show()"></btn>
            <modal
                ref="avatarLeftIconModal"
                align-viewport-center
                width="30r"
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div>
                        <div class="pos-r">
                            <icon-img :image="avatar" size="11" class="pl-3"></icon-img>
                            <custom-h-el width="10px" height="10px" bg-color="primary" class="b-round pos-a mt-n2 ml-4"/>
                        </div>
                        <div class="fl-x mt-3">
                            <div class="ml-3">
                                <div class="fl-x">
                                    <h5 class="text-fs-1">Katherine Moss</h5>
                                    <p class="text-grey ml-3">2min ago</p></div>
                                <p>
                                    I've finished adding my notes.Happy for us to review whenever you're ready!
                                </p>
                                <div class="fl-x">
                                    <p class="font-weight-bold text-grey  mr-3">Dismiss</p>
                                    <p class=" font-weight-bold text-primary">Change log</p></div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>

            <btn text="Image Left alert : Text on right Modal" class="ml-3" @click="$refs.imageLeftIconAlert.show()"></btn>
            <modal
                ref="imageLeftIconAlert"
                align-viewport-center
                width="70p" class=""
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div class="fl-x">
                        <icon-img :image="tickIcon" size="7" class="b-2"></icon-img>
                        <h5 class="text-fs-1 pt-2 pl-3">We have just released a new update</h5>
                        <p class="text-fs-1 pt-2 pl-2">Lorem ipsum dolor sit amet, consectetur.</p>
                        <div class="fl-x ml-10">
                            <btn text="Dismiss"></btn>
                            <btn text="View changes" class="ml-2"></btn>
                        </div>
                    </div>
                </template>
            </modal>

            <btn text="Icon Left : Text on right Modal" class="ml-3" @click="$refs.iconLeftIconModal.show()"></btn>
            <modal
                ref="iconLeftIconModal"
                align-viewport-center
                width=""
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div class="fl-x">
                        <div class="ml-3">
                            <h5 class="text-fs-1">We have just released a new update!</h5>
                            <p class="w-20r">Checkout the all new dashboard view.
                                pages and exports now load faster.</p>
                            <img src="../../assets/img/ultra-hd/car-img-uhd.jpg" class="w-25r">

                            <div class="fl-x pt-2">
                                <div class="font-weight-bold text-grey mr-3">Dismiss</div>
                                <div class=" font-weight-bold text-primary">Change log</div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>

            <btn text="Image Left : Text on right Modal" class="ml-3" @click="$refs.imageLeftIconModal.show()"></btn>
            <modal
                ref="imageLeftIconModal"
                align-viewport-center
                width="30r"
                header-align-items="top"
                header-classes="pt-4 pb-0">
                <template #closeButton>
                    <icon-img class="mt-n8" :image="crossIcon" size="4"/>
                </template>
                <template #title>
                    <div>
                        <custom-h-el width="3r" height="3rem" class="b-1 round-2 fl-x-cc">
                            <icon-img :image="tickIcon" size="8"></icon-img>
                        </custom-h-el>
                        <div class="fl-x mt-3">
                            <div class="ml-3">
                                <div class="fl-x">
                                    <h5 class="text-fs-1">Uploading 'website-FINAL06.fig'</h5>
                                </div>
                                <p>please wait while we upload your file</p>
                                <progress-bar-html value="100" class="w-25r"></progress-bar-html>
                                <p class="pl-7 ml-10 mt-2">60% uploaded...</p>
                                <div class="fl-x">
                                    <div class="font-weight-bold text-grey  mr-3">Cancel</div>
                                    <div class=" font-weight-bold text-primary">Upload another</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </modal>
        </div>
    </div>
</template>

<script>

import modal from 'lego-framework/src/containers/ModalHeaderUpdated';
import IconImg from 'lego-framework/src/components/IconImage';
import tickIcon from '../../assets/web/icons/tick.png';
import crossIcon from '../../assets/web/icons/icons8-cross-24.png';
import avatar from '../../assets/img/avatar/image-avatar.png';
import ProgressBarHtml from '../dashboard-components/file-upload/ProgressBarHtml';

export default {
    name: 'AlertModal',
    components: { ProgressBarHtml, modal, IconImg },
    data () {
        return {
            tickIcon: tickIcon,
            crossIcon: crossIcon,
            avatar: avatar

        };
    }
};
</script>

<style scoped>

</style>
