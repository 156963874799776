<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Form Control Floating</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--translate-x',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-padding-x)) = var(--element-padding-x) = 0.75em  ( NB: there is an issue with the units of the adding parameters)'
                },
                {
                    slno: '1',
                    name: '--translate-y',
                    description: '',
                    defaultvalue: 'calc(var(--form-control-line-height) + var(--form-control-padding-y)) = calc(var(--element-line-height) + var(--element-padding-y)   = 1.6rem + 0.325em'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
