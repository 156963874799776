<template>
    <page-template>
        <div class="container py-3 bg-2">
            <div class="row">
                <div class="col-lg-12">
                    <h3>Click Outside Directive</h3>
                    <p class="lead">
                        method or piece of code runs when click outside an element
                    </p>
                    <h5>Usage</h5>
                    <p>Import Directive on plugins/lego.js</p>
                    <p>import ClickOutside from 'lego-framework/src/directives/click-outside';</p>
                    <p>Vue.use(ClickOutside);</p>
                    <p>&#60;div v-click-outside="method" &#62;&#60;/div&#62;</p>
                </div>
                <div class="col-12 mt-4">
                    <component-wrapper :code="codeStore.clickOutside.code">
                        <div class="p-8 bg-1">
                            <h6 class="my-4">Content will be hidden when clicked anywhere outside the card below</h6>
                            <div class="card bs-3 p-3 pos-r" ref="target" v-click-outside="hideContent">
                                <div class="fl-te-c">
                                    <h5>Title</h5>
                                    <div class="btn-group">
                                        <btn icon="fa fa-close" @click="toggleView" v-if="showContent">Hide</btn>
                                        <btn icon="fa fa-eye" @click="toggleView" v-else>view</btn>
                                    </div>
                                </div>
                                <div v-if="showContent">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. A eum repellendus veniam veritatis? Accusantium, aut dolore dolorum ea eius eos, ex
                                        facilis
                                        ipsam
                                        iste
                                        iure
                                        libero magni molestias nobis nulla, numquam perspiciatis quaerat quam quis quos ratione repellendus reprehenderit rerum sequi veniam
                                        voluptates. Amet
                                        aspernatur
                                        atque
                                        illo numquam officia, recusandae.</p>
                                </div>
                            </div>
                        </div>
                    </component-wrapper>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import codeStore from './code-store/directives.json';
import ComponentWrapper from 'lego-framework/src/components/doc-items/ComponentWrapper';

export default {
    name: 'ClickOutsideDirectiveDocs',
    components: { ComponentWrapper },
    metaInfo: {
        path: 'directives/click-outside/',
        title: 'Click Outside'
    },
    data () {
        return {
            showContent: false,
            codeStore: codeStore
        };
    },
    methods: {
        toggleView () {
            this.showContent = !this.showContent;
        },
        hideContent () {
            this.showContent = false;
        }
    }
};
</script>

<style scoped>

</style>
