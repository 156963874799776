<template>
    <div class="bg-2 p-5">
        <div>
            <h3>Tabs</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
    </div>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--tabs-selected-color',
                    description: 'Tab selected Color. Depending on variable.<br>1. --color-#{$name} (format) --color-primary',
                    defaultvalue: 'Primary Color'
                },
                {
                    slno: '2',
                    name: '--tabs-selected-text-color',
                    description: 'Tab selected Color. Depending on variable. Format.<br>1. --color-#{$name}-contrast',
                    defaultvalue: 'primary contrast color'
                },
                {
                    slno: '3',
                    name: '--tabs-border-color',
                    description: 'Tabs border color. Depending on variable.<br>1. --border-color',
                    defaultvalue: '#E4E9F2'
                },
                {
                    slno: '4',
                    name: '--border-radius-pill',
                    description: 'Border radius pill value set',
                    defaultvalue: '50rem'
                }
            ]
        };
    }
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
