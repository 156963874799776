<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2 class="">Faq Items</h2>
                    <p class="lead">Faq items variants</p>
                    <h4 class="my-3">Usage</h4>
                    <p class="lead">
                        import FaqItem from 'lego-framework/src/web-components/faq/FaqItem';
                    </p>
                    <h3 class="mt-5 mb-3">design: basic-a</h3>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="mb-4 font-weight-normal">Align center (default)</h5>
                    <faq-item
                        title="Is there a free trail available"
                        content="Yes you can try us for free for 30 days.
                        Our friendly team will work with you to get you up
                        and running as soon as possible"/>
                    <code-container :code-str="codeStore.defaultAlignCenter.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="mb-4 font-weight-normal">Align left</h5>
                    <faq-item
                        align="left"
                        title="Can I change my plan later?"
                        content="Of course our pricing scales with your company.
                        Chat to our friendly team to find a solution that works for you"/>
                    <code-container :code-str="codeStore.alignLeft.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h5 class="mb-4 font-weight-normal">Using Icon Image</h5>
                    <faq-item
                        :icon-img="officeIcon"
                        title="What is your Cancellation Policy?"
                        content="We understand that things change.
                        You can cancel your plan at any time and
                        we will refund you the difference already paid"/>
                    <code-container :code-str="codeStore.withIconImg.code" show-code-no-toggle></code-container>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 mt-4">
                    <h3 class="mt-5 mb-5">design: basic-b</h3>
                    <faq-item
                        design="basic-b"
                        title="Can other info be added to an invoice ?"
                        content="At the moment the only way to add additional information to
                                invoice is to add the information to the workspace's name."/>
                    <code-container :code-str="codeStore.designBasicB.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-lg-6 mt-4">
                    <h3 class="mt-5 mb-5">cardDesign: true</h3>
                    <faq-item
                        :card-design="true"
                        design="basic-b"
                        title="Is there a free trail available"
                        content="Yes you can try us for free for 30 days.
                        Our friendly team will work with you to get you up
                        and running as soon as possible"/>
                    <code-container :code-str="codeStore.cardDesign.code" show-code-no-toggle></code-container>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row mt-5">
                <div class="col-12">
                    <h4 class="mb-6 font-weight-bold">Section Demo</h4>
                    <h3 class="mb-3 font-weight-bold">Frequently Asked Questions</h3>
                    <p class="mb-6 text-2">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus asperiores dicta dolores eligendi explicabo molestias, nemo odio officiis sequi sunt?
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto, quos.
                    </p>
                </div>
                <div class="col-lg-6">
                    <faq-item
                        icon-color="primary"
                        icon-border-color="primary"
                        design="basic-b"
                        title="Is there a free trail available"
                        content="Yes you can try us for free for 30 days.
                        Our friendly team will work with you to get you up
                        and running as soon as possible"/>
                    <faq-item
                        design="basic-b"
                        class="mt-4"
                        :icon-img="iconDataTransfer"
                        title="Can I change my plan later?"
                        content="Of course our pricing scales with your company.
                        Chat to our friendly team to find a solution that works for you"/>
                    <faq-item
                        design="basic-b"
                        class="mt-4"
                        :icon-img="iconCancel"
                        title="What is your Cancellation Policy?"
                        content="We understand that things change.
                        You can cancel your plan at any time and
                        we will refund you the difference already paid"/>
                    <faq-item
                        design="basic-b"
                        class="mt-4"
                        :icon-img="iconFile"
                        title="Can other info be added to an invoice ?"
                        content="At the moment the only way to add additional information to
                        invoice is to add the information to the workspace's name."/>
                </div>
                <div class="col-lg-6">
                    <img src="../../assets/img/group-of-people/linkedin-sales-solutions-IjkIOe-2fF4-unsplash.jpg" class="img-fluid">
                </div>
                <div class="col-12">
                    <code-container :code-str="codeStore.faqSection.code" show-code-no-toggle></code-container>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import FaqItem from '../../views/web-components/faq/FaqItem';
import officeIcon from '../../assets/images/icons/office-icon.svg';
import chevronUp from '../../assets/images/icons/icons8-chevron-up-24.png';
import chevronDown from '../../assets/images/icons/icons8-chevron-down-24.png';
import iconCancel from '../../assets/images/icons/icons8-cancel-24.png';
import iconDataTransfer from '../../assets/images/icons/icons8-data-transfer-24.png';
import iconFile from '../../assets/images/icons/icons8-file-24.png';
import codeStore from './code-storage/faq-item.json';
import CodeContainer from '../../components/doc-items/CodeContainer';
import PropTable from '../docs-components/Props';

export default {
    name: 'FaqItemsVariantsDemo',
    metaInfo: {
        path: 'web-components/faq-item/',
        title: 'FAQ Item'
    },
    components: { FaqItem, CodeContainer, PropTable },
    data () {
        return {
            officeIcon: officeIcon,
            chevronUp: chevronUp,
            chevronDown: chevronDown,
            iconCancel: iconCancel,
            iconDataTransfer: iconDataTransfer,
            iconFile: iconFile,
            codeStore: codeStore,
            propsInfo: [
                ['title', 'String', 'Faq Question title text', '', ''],
                ['content', 'String', 'Answer Content text', '', ''],
                ['align', 'String', 'Horizontal alignment of items', ['left', 'center'], 'center'],
                ['icon', 'String', 'Icon Class', 'any fa icon class', 'fa fa-heart-o'],
                ['shadow', 'String', 'Box shadow variant', ['1', '2', '3', '4', '5', 'upto 29'], ''],
                ['iconImg', 'String', 'Icon Image', '', ''],
                ['iconWidth', 'String', 'Icon Width in rem', ['width value in rem unit, in short ending with r eg: 2.5r'], '2.5r'],
                ['iconHeight', 'String', 'Icon height in rem', ['height value in rem unit, in short ending with r eg: 2.5r'], '2.5r'],
                ['design', 'String', 'Design variant', ['basic-a', 'basic-b'], 'basic-a'],
                ['iconColor', 'String', 'Icon Color when using fa icon class', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['iconBorderColor', 'String', 'Icon border color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['cardDesign', 'Boolean', 'Set Card Design', ['true', 'false'], 'false'],
                ['titleFontSize', 'String/Number', 'Title Font Size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['titleFontWeight', 'String', 'Title Font Size', ['light', 'lighter', 'normal', 'bold', 'bolder'], 'bold'],
                ['titleColor', 'String', 'Title Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4', 'etc'], ''],
                ['titleClasses', 'String,Array', 'Title Additional Classes', '', ''],
                ['contentFontSize', 'String/Number', 'Content Font Size', ['-1', '0', '1', '2', '3', '4', '5', '6', '7'], ''],
                ['contentFontWeight', 'String', 'Content Font Size', ['light', 'lighter', 'normal', 'bold', 'bolder'], 'bold'],
                ['contentColor', 'String', 'Content Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4', 'etc'], ''],
                ['contentClasses', 'String,Array', 'Content Additional Classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
