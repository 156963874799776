<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Tag Item Plain with Bullet</h2>
                    <p class="lead">Tag item plain background with bullet</p>
                    <h4 class="my-4">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Default</h6>
                    <tag-item-plain text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border : 2</h6>
                    <tag-item-plain border="2" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.border2.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Shadow : 5</h6>
                    <tag-item-plain shadow="5" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.shadow5.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border Radius : 1</h6>
                    <tag-item-plain border-radius="1" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderRadius1.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Border Color : secondary</h6>
                    <tag-item-plain border-color="secondary" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.borderColorSecondary.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Bullet Color : danger</h6>
                    <tag-item-plain bullet-color="danger" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.bulletColor.code"/>
                </div>
                <div class="col-lg-6">
                    <h6 class="my-3">Font Customization</h6>
                    <tag-item-plain text-font-size="2" text-font-weight="light"
                                    text-color="2" text="premium"/>
                    <code-container show-code-no-toggle :code-str="codeStore.fontCustomization.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import TagItemPlain from 'lego-framework/src/web-components/tag-items/TagItemPlainWithBullet';
import codeStore from '../code-storage/tag-item-plain.json';
import PropTable from '../docs-components/Props';

export default {
    name: 'TagItemPlainWithBulletVariationsDocumentation',
    metaInfo: {
        path: 'ui-elements/tag-item-plain/',
        title: 'Tag Item Plain'
    },
    components: { TagItemPlain, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['text', 'String', 'Text alongside icon', '', ''],
                ['textColor', 'String', 'text color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['textFontSize', 'String', 'text font size', ['-1 - 7'], '1'],
                ['textFontWeight', 'String', 'font weight variants', ['light', 'lighter', 'normal', 'bold', 'bolder', '100 - 900'], '500'],
                ['textClasses', 'String, Array', ' text additional classes', '', ''],
                ['border', 'String', 'border variant', '1 - 10', ''],
                ['borderRadius', 'String', 'border radius', '0 - 10', ''],
                ['borderColor', 'String', 'bg Color', ['primary', 'secondary', 'third', 'success', 'danger', 'info', 'light', 'dark'], ''],
                ['shadow', 'String', 'shadow variant', ['1- 29'], ''],
                ['bulletColor', 'String', 'bullet color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['bgColor', 'String', 'Bg Color variant', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['containerClasses', 'String,Array', 'Container additional classes', '', '']
            ]
        };
    }
};
</script>

<style scoped>

</style>
