<template>
    <page-template class="p-5">
        <h2>File Input - Filename Separated</h2>
        <p class="lead">File Input also supports all the properties of input. Filename text is placed below the field.
        </p>
        <h6>Additional Props</h6>
        <p>fileTypesInfo - prop added for showing supported filenames or other info</p>
        <p>labelText - Replacing default placeholder text 'browse'</p>
        <p>Style Modifier Props - <br><br> labelTextClasses,fileNameClasses,fileNameContainerClasses,fileTypesContainerClasses</p>
        <div class="mb-5">
            <h5>Usage</h5>
            <p>Import Component on plugins/lego.js</p>
            <p>import FormFileInputFilenameSeparated from 'lego-framework/src/forms/FormFileInputFilenameSeparated';</p>
            <p>Vue.component('validated-file-input-type2', FormFileInputFilenameSeparated);</p>
            <p>&#60;validated-file-input-type2&#62;&#60;/validated-file-input-type2&#62;</p>
        </div>
        <h4 class="my-3">With Custom Placeholder replacing default text - 'browse'</h4>
        <component-wrapper :code="codeStore.fileInputType2.withLabelText.code">
            <div class="row">
                <div class="col-md-3">
                    <FormInput label-text="Upload Pdf"/>
                </div>
            </div>
        </component-wrapper>
        <h4 class="my-3">With File Types Info</h4>
        <component-wrapper :code="codeStore.fileInputType2.withFileTypesInfo.code">
            <div class="row">
                <div class="col-md-3">
                    <FormInput file-types-info="Supported file Types: pdf, jpg"/>
                </div>
            </div>
        </component-wrapper>
        <h3 class="my-3">Color Variations</h3>
        <p>Form only supports contextual colors now. More color options will be added soon</p>
        <div class="row">
            <div class="col-md-3">
                <b>Color: null</b>
                <FormInput/>
            </div>
            <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                <b>Color: {{ color }}</b>
                <FormInput :color="color"/>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput icon="fa fa-user"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput icon="fa fa-user" :color="color"/>
                </div>
            </div>
        </div>

        <div>
            <h3 class="mt-4">Disabled State</h3>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput disabled label="Disabled"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput label="Disabled" disabled :color="color"/>
                </div>
            </div>
            <h4 class="mt-4">Disabled State With Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput disabled icon="fa fa-user" label="Disabled"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput icon="fa fa-user" label="Disabled" disabled :color="color"/>
                </div>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Label and Icons</h4>
            <div class="row">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput icon="fa fa-user" label="First Name"/>
                </div>
                <div class="col-md-3" v-for="color in theme['input-colors']" :key="color">
                    <b>Color: {{ color }}</b>
                    <FormInput icon="fa fa-user" :color="color" label="First Name"/>
                </div>
            </div>
        </div>

        <div>
            <h4 class="mt-4">With Label, Icons and Error Messages</h4>
            <div class="row flx-b">
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput icon="fa fa-user" label="First Name" error-message="First Name is required."/>
                </div>
                <div class="col-md-3">
                    <b>Color: null</b>
                    <FormInput icon="fa fa-user" label="First Name" success-message="First Name is required."/>
                </div>
            </div>
            <alert color="info" border="l" icon-colored mode="border" border-thickness="4" shadow="5">
                Note that giving a error message will automatically set the color to error. Likewise setting success message will set the color to success
            </alert>
        </div>

        <div>
            <h3 class="mt-4">Size Variations</h3>
            <div class="row fl-x-t">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput :label="size" :size="size"/>
                </div>
            </div>
            <h4 class="mt-4">With Icons</h4>
            <div class="row fl-x-b">
                <div class="col-md-3" v-for="size in sizes" :key="size">
                    <FormInput :label="size" icon="fa fa-user" :size="size"/>
                </div>
            </div>
        </div>

        <div>
            <h2 class="my-3">Props</h2>
            <prop-table :items="textAreaProps"/>
        </div>

        <div>
            <h2>Slots</h2>
            <p class="lead">Currently no slots are implemented in form input</p>
        </div>

        <div>
            <h2>Events</h2>
            <p class="lead">All the standard events are supported in button</p>
        </div>

    </page-template>
</template>

<script>
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import FormInput from 'lego-framework/src/forms/FormFileInputFilenameSeparated';
import Input from './Input';
import codeStore from './code-storage/form-input.json';

export default {
    extends: Input,
    metaInfo: {
        path: 'components/file-input-filename-separated/',
        title: 'File Input - Filename Separated'
    },
    name: 'FileInputFilenameSeparatedDocumentation',
    components: { FormInput, PropTable },
    data () {
        return {
            codeStore: codeStore
        };
    }
};
</script>

<style scoped>

</style>
