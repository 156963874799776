<template>
    <div>
        <progress-steps-design3 :items="items"/>
        <div class="btn-group mt-3">
            <btn text="1st step" @click="statusChange(1)"/>
            <btn text="2nd step" @click="statusChange(2)"/>
            <btn text="3rd step" @click="statusChange(3)"/>
            <btn text="4th step" @click="statusChange(4)"/>
        </div>
    </div>
</template>

<script>
import ProgressStepsDesign3 from '../../progress-steps-updated/design-4/ProgressStepsDesign4';
import icon1 from '../../../assets/web/icons/progress-steps/icon-user.svg';
import icon2 from '../../../assets/web/icons/progress-steps/flag-05.svg';
import icon3 from '../../../assets/web/icons/progress-steps/users-plus.svg';
import icon4 from '../../../assets/web/icons/progress-steps/stars-02.svg';

export default {
    name: 'ProgressSteps3Usage',
    components: { ProgressStepsDesign3 },
    data () {
        return {
            items: [
                {
                    icon: icon1,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon2,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon3,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                },
                {
                    icon: icon4,
                    text1: 'Your Details',
                    text2: 'Name and Email',
                    status: 'pending'
                }
            ]
        };
    },
    methods: {
        statusChange (idx) {
            this.items[idx - 1].status = 'done';
        }
    }
};
</script>

<style scoped>

</style>
