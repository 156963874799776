<template>
    <page-template class="bg-2">
        <div class="">
            <div class="row">
                <div class="col-12">
                    <h2>Breadcrumb</h2>
                    <p class="lead">Breadcrumb component for navigation</p>
                    <h4 class="my-3">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                    <h5 class="my-3">Items format</h5>
                    <code-container show-code-no-toggle :code-str="codeStore.itemsFormat.code"/>
                </div>
                <div class="col-lg-6 mt-4">
                    <h4 class="my-4">Basic Breadcrumb</h4>
                    <base-breadcrumb :items="items"/>
                    <code-container show-code-no-toggle :code-str="codeStore.default.code"/>
                    <h6 class="my-4">design : basic-b</h6>
                    <base-breadcrumb :items="items" design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicB.code"/>
                    <h6 class="my-4">design : basic-c</h6>
                    <base-breadcrumb :items="items" design="basic-c"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicC.code"/>
                    <h6 class="my-4">design : basic-d</h6>
                    <base-breadcrumb :items="items" design="basic-d"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicD.code"/>
                    <h6 class="my-4">design : basic-e</h6>
                    <base-breadcrumb :items="items" design="basic-e"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicE.code"/>
                    <h6 class="my-4">design : basic-f</h6>
                    <base-breadcrumb :items="items" design="basic-f"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicF.code"/>

                    <h6 class="my-4">design : basic-g</h6>
                    <base-breadcrumb :items="items" design="basic-g"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicG.code"/>

                    <h6 class="my-4">design : basic-h</h6>
                    <base-breadcrumb :items="items" design="basic-h"/>
                    <code-container show-code-no-toggle :code-str="codeStore.basicH.code"/>

                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 mt-4">
                    <h4 class="mt-4">Breadcrumb - Minimized</h4>
                    <p class="lead mb-5">Items minimized when no of entries increased</p>
                    <base-breadcrumb-minimized :items="items"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.default.code"/>
                    <h6 class="my-4">design : basic-b</h6>
                    <base-breadcrumb-minimized :items="itemsLg" design="basic-b"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicB.code"/>
                    <h6 class="my-4">design : basic-c</h6>
                    <base-breadcrumb-minimized :items="items" design="basic-c"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicC.code"/>
                    <h6 class="my-4">design : basic-d</h6>
                    <base-breadcrumb-minimized :items="items" design="basic-d"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicD.code"/>
                    <h6 class="my-4">design : basic-e</h6>
                    <base-breadcrumb-minimized :items="items" design="basic-e"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicE.code"/>
                    <h6 class="my-4">design : basic-f</h6>
                    <base-breadcrumb-minimized :items="items" design="basic-f"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicF.code"/>
                    <h6 class="my-4">design : basic-g</h6>
                    <base-breadcrumb-minimized :items="items" design="basic-g"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicG.code"/>
                    <h6 class="my-4">design : basic-h</h6>
                    <base-breadcrumb-minimized :items="items" design="basic-h"/>
                    <code-container show-code-no-toggle :code-str="codeStore.minimized.basicH.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
            </div>
        </div>
    </page-template>
</template>

<script>
import BaseBreadcrumb from 'lego-framework/src/dashboard-components/breadcrumbs/BaseBreadcrumb';
import BaseBreadcrumbMinimized from 'lego-framework/src/dashboard-components/breadcrumbs/BreadcrumbMinimized';
// import BaseBreadcrumbMinimized from '../breadcrumb/BreadcrumbMinimizable';
import PropTable from 'lego-framework/src/views/docs/docs-components/Props';
import CodeContainer from 'lego-framework/src/components/doc-items/CodeContainer';
import codeStore from './code-store/breadcrumb.json';
export default {
    name: 'BreadcrumbComponentsDocsPage',
    metaInfo: {
        path: 'dashboard-components/breadcrumbs/',
        title: 'Breadcrumbs'
    },
    components: { BaseBreadcrumb, BaseBreadcrumbMinimized, CodeContainer, PropTable },
    data () {
        return {
            items: [
                { text: 'Settings', link: '/item1/' },
                { text: 'Department', link: '/item2/' },
                { text: 'Team', link: '/item3/' }
            ],
            itemsLg: [
                { text: 'Item1', link: '/item1/' },
                { text: 'Item2', link: '/item2/' },
                { text: 'Item3', link: '/item3/' },
                { text: 'Item4', link: '/item4/' },
                { text: 'Item5', link: '/item5/' },
                { text: 'Item6', link: '/item6/' },
                { text: 'Item7', link: '/item7/' },
                { text: 'Item8', link: '/item8/' },
                { text: 'Item9', link: '/item9/' },
                { text: 'Item10', link: '/item10/' },
                { text: 'Item11', link: '/item11/' },
                { text: 'Item12', link: '/item12/' }
            ],
            codeStore: codeStore,
            propsInfo: [
                ['items', 'Array', 'array of navigation item objects', '', ''],
                ['design', 'String', 'design variant', ['basic-a', 'basic-b', 'basic-c', 'basic-d', 'basic-e', 'basic-f', 'basic-g', 'basic-h'], 'basic-a'],
                ['homeUrl', 'String', 'url to the home page', '', '/'],
                ['fontSize', 'String', 'base font size of the breadcrumb', ['-1 - 7'], '1'],
                ['fontColor', 'String', 'base font color of the breadcrumb', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], '2'],
                ['activeFontWeight', 'String', 'active(current page) item font weight', ['normal', 'bold', 'bolder', 'light', '100 - 900'], '600'],
                ['fontColor', 'String', 'base font color of the breadcrumb', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], '2']
            ]
        };
    }

};
</script>

<style scoped>

</style>
