<template>
    <div class="pb-5 p-4 bg-2">
        <h2 class="mt-5">Flex</h2>
        <p class="lead">Flex Alignment component</p>
        <h5>Usage</h5>
        <p>Import Component on plugins/lego.js</p>
        <p>import Flex from 'lego-framework/src/containers/layout/Flex';</p>
        <p>Vue.component('flex', Flex);</p>
        <p>&#60;flex&#62;&#60;/flex&#62;</p>
        <h4>Props</h4>
        <prop-table :items="props"/>
        <row class="mt-4">
            <col-12>
                <lego-heading tag="h4" align="left" class="mb-4">Usage Examples</lego-heading>
            </col-12>

            <col-12 class="mt-4">
                <lego-heading align="left" class="mb-4" tag="h5">Flex Direction</lego-heading>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex dir="x">
                        <div class="bg-primary p-1 b-1">item1</div>
                        <div class="bg-primary p-1 b-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.direction.row"></code-container>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="sb" dir="y">
                        <div class="bg-primary p-1 b-1">item1</div>
                        <div class="bg-primary p-1 b-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.direction.column"></code-container>
            </col-12>
            <col-12 class="">
                <lego-heading align="left" class="mb-4" tag="h5">Horizontal Alignment</lego-heading>
            </col-12>
            <col-12 lg="6">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="center" lg-justify="start">
                        <div class="b-1 bg-primary p-1">Start</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.horizontalAlignment.start"></code-container>
            </col-12>
            <col-12 lg="6">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="center">
                        <div class="b-1 bg-primary p-1">Center</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.horizontalAlignment.center"></code-container>
            </col-12>
            <col-12 lg="6">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="end">
                        <div class="b-1 bg-primary p-1">End</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.horizontalAlignment.end"></code-container>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="sb">
                        <div class="bg-primary p-1">item1</div>
                        <div class="bg-primary p-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.horizontalAlignment.sb"></code-container>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="sa">
                        <div class="bg-primary p-1">item1</div>
                        <div class="bg-primary p-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.horizontalAlignment.sa"></code-container>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <flex justify="se">
                        <div class="bg-primary p-1">item1</div>
                        <div class="bg-primary p-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.horizontalAlignment.se"></code-container>
            </col-12>
            <col-12 class="mt-3">
                <lego-heading align="left" class="mb-4" tag="h5">Vertical Alignment</lego-heading>
            </col-12>
            <col-12 lg="6" class="mb-4">
                <div class="bg-2 b-1 h-25vh round-1 p-3">
                    <flex align="start" h100>
                        <div class="b-1 bg-primary p-1">Align - Start</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.verticalAlignment.start"></code-container>

            </col-12>
            <col-12 lg="6" class="mb-4">
                <div class="bg-2 b-1 h-25vh round-1 p-3">
                    <flex align="center" h100>
                        <div class="b-1 bg-primary p-1">Align - Center</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.verticalAlignment.center"></code-container>
            </col-12>
            <col-12 lg="6" class="mb-4">
                <div class="bg-2 b-1 h-25vh round-1 p-3">
                    <flex align="end" h100>
                        <div class="b-1 bg-primary p-1">Align - End</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.verticalAlignment.end"></code-container>
            </col-12>
            <col-12 lg="6" class="mb-4">
                <div class="bg-2 b-1 h-25vh round-1 p-3">
                    <flex align="st" h100>
                        <div class="b-1 bg-primary p-1">item 1</div>
                        <div class="b-1 bg-primary p-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.verticalAlignment.st"></code-container>
            </col-12>
            <col-12 lg="6" class="mb-4">
                <div class="bg-2 b-1 h-25vh round-1 p-3">
                    <flex align="bl" h100>
                        <div class="b-1 bg-primary p-1">item 1</div>
                        <div class="b-1 bg-primary p-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.verticalAlignment.bl"></code-container>
            </col-12>
            <col-12 lg="6" class="mb-4">
                <div class="bg-2 b-1 h-25vh round-1 p-3">
                    <flex dir="y" justify="sb" h100>
                        <div class="b-1 bg-primary p-1">item 1</div>
                        <div class="b-1 bg-primary p-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.dirColumnJustifySb"></code-container>
            </col-12>
            <col-12>
                <lego-heading tag="h5" align="left" class="mb-4">Flex Property changes with Responsive Breakpoints</lego-heading>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <lego-heading tag="h6" class="mb-3">Responsive Direction</lego-heading>
                    <flex dir="y" lg-dir="x">
                        <div class="bg-primary p-1 b-1">item1</div>
                        <div class="bg-primary p-1 b-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.directionResponsive"></code-container>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <lego-heading tag="h6" class="mb-3">Responsive Justify</lego-heading>
                    <flex justify="center" lg-justify="end">
                        <div class="bg-primary p-1 b-1">item1</div>
                        <div class="bg-primary p-1 b-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.justifyResponsive"></code-container>
            </col-12>
            <col-12 lg="6" class="mt-4">
                <div class="bg-2 b-1 round-1 p-3">
                    <lego-heading tag="h6" class="mb-3">Responsive Align</lego-heading>
                    <flex align="center" lg-align="start">
                        <div class="bg-primary p-1 b-1">item1</div>
                        <div class="bg-primary p-1 b-1">item2</div>
                    </flex>
                </div>
                <code-container class="bg-2 round-1" :code-str="codeStore.alignResponsive"></code-container>
            </col-12>
        </row>
    </div>
</template>

<script>
// import DemoMixin from '../../../views/DemoMixin';
import PropTable from '@lego-views/docs/docs-components/Props';
import Flex from '../../containers/layout/Flex';
import LegoHeading from '../../components/Heading';
import CodeContainer from '../../components/doc-items/CodeContainer';
import Col12 from '../../containers/grid/col-12';
import Row from '../../containers/Row';
import CodeStore from './codes/flex-items.json';

export default {
    // extends  : DemoMixin,
    metaInfo: {
        path: 'containers/flex/',
        title: 'Flex'
    },
    data () {
        return {
            props: [
                ['tag', 'String', 'html tag name', ['any html tag, eg:div,section,main, footer,aside,...'], 'div'],
                ['dir', 'String', 'flex direction', ['x', 'y']],
                ['justify', 'String', 'flex justify property', ['s', 'c', 'e', 'sb', 'sa', 'se']],
                ['align', 'String', 'flex justify property', ['s', 'c', 'e', 'st', 'bl']],
                ['mdDir', 'String', 'flex direction on medium screens', ['x', 'y']],
                ['mdJustify', 'String', 'flex justify property on medium screens', ['s', 'c', 'e', 'sb', 'sa', 'se']],
                ['mdAlign', 'String', 'flex Align property on medium screens', ['s', 'c', 'e', 'st', 'bl']],
                ['lgDir', 'String', 'flex direction on large screens', ['x', 'y']],
                ['lgJustify', 'String', 'flex justify property on large screens', ['s', 'c', 'e', 'sb', 'sa', 'se']],
                ['lgAlign', 'String', 'flex Align property on large screens', ['s', 'c', 'e', 'st', 'bl']]
            ],
            codeStore: CodeStore
        };
    },
    components: { PropTable, Flex, LegoHeading, CodeContainer, Col12, Row }
};
</script>
