<template>
    <div class="b-1 round-2">
        <div class="p-3">
            <div class="fl-te-c">
                <div class="fl-x fl-a-c">
                    <icon-img :image="iconImg" size="6"/>
                    <div class="ml-1 text-fs-0 font-weight-600">{{text1}}</div>
                </div>
                <div class="p-2 b-round">
                    <icon-img :image="dotsIcon" size="4"/>
                </div>
            </div>
            <div class="mt-3 font-weight-600 text-fs-4">{{text2}}</div>
            <div class="fl-x fl-a-c text-fs-0 mt-2">
                <icon-img :image="arrowIcon" size="1" rotate="-90deg"/>
                <div class="text-secondary text-fs-0 font-weight-500">{{text3}}</div>
                <div class="ml-1 text-muted font-weight-500">vs last month</div>
            </div>
        </div>
        <div class="p-2 px-3 bt-1 text-right">
            <div class="text-primary text-fs-1 font-weight-500">View Report</div>
        </div>
    </div>
</template>

<script>
import graphIcon from './assets/icons8-graph-64.png';
import dotsIcon from './assets/three-dots.png';
import arrowIcon from './assets/icons8-arrow-right-16-green.png';
import IconImg from '../../components/IconImage';

export default {
    name: 'MetricsItem5',
    components: { IconImg },
    props: {
        text1: { type: String, default: 'Views 24 hours' },
        iconImg: { type: String, default: graphIcon },
        text2: { type: String, default: '2,000' },
        text3: { type: String, default: '100%' }
    },
    data () {
        return {
            dotsIcon: dotsIcon,
            arrowIcon: arrowIcon
        };
    }
};
</script>

<style scoped>

</style>
