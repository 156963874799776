<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Table variables</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-15r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-40r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--table-header-color',
                    description: 'table header color in table class',
                    defaultvalue: '#000'
                },
                {
                    slno: '2',
                    name: '--table-data-color',
                    description: 'table data color in table class',
                    defaultvalue: '#000'
                },
                {
                    slno: '3',
                    name: '--table-margin-bottom',
                    description: 'table margin bottom spacing value,depended variables ' +
                        '--container-separator' +
                        '--spacer-5 ' +
                        '<br>refer : src/scss/variables/core.js</br>',
                    defaultvalue: '3rem'
                },
                {
                    slno: '4',
                    name: '--table-cell-padding',
                    description: 'table cell item base padding',
                    defaultvalue: '1rem'
                },
                {
                    slno: '5',
                    name: '--table-border-width',
                    description: 'Table border width',
                    defaultvalue: '1px'
                },
                {
                    slno: '6',
                    name: '--table-border-color',
                    description: 'table border color. Depended on other variable --border-color. Set on theme.json ' +
                        '<br>"border-color-levels": {' +
                        '    "border-color": "#E4E9F2",' +
                        '    "border-color-light": "#EDF1F7",' +
                        '    "border-color-dark": "#8F9BB3"' +
                        '}</br>',
                    defaultvalue: '#E4E9F2'
                },
                {
                    slno: '7',
                    name: '--table-cell-padding-sm',
                    description: 'table cell padding for small tables. class related is <br>table-sm</br>',
                    defaultvalue: '0.5rem'
                },
                {
                    slno: '8',
                    name: '--table-accent-bg',
                    description: 'background color for stripped table . <br>Related class : .table-striped depended variable : --bg-1  set on theme.json</br> ' +
                        '<br>"alternative-background-colors": {' +
                        '    "bg-1": "#111111",' +
                        '    "bg-2": "#212121",' +
                        '    "bg-3": "#0d0d0d",' +
                        '    "bg-4": "#191919"' +
                        '}</br>',
                    defaultvalue: '#111111'
                },
                {
                    slno: '9',
                    name: 'table-hover-color',
                    description: 'color for table hover . <br>Related class : .table-hover depended variable : --text-2  set on theme.json ' +
                        '<br>"text-colors": {' +
                        '    "text-1": "#161616",' +
                        '    "text-2": "#525252",' +
                        '    "text-3": "#666666",' +
                        '    "text-4": "#A8A8A8"' +
                        '}</br>',
                    defaultvalue: '#525252'
                },
                {
                    slno: '10',
                    name: '--table-hover-bg',
                    description: 'background for table hover .<br> Related class : .table-hover . depended variable : --bg-3 set on theme.json ' +
                        '<br>"alternative-background-colors": {' +
                        '    "bg-1": "#111111",' +
                        '    "bg-2": "#212121",' +
                        '    "bg-3": "#0d0d0d",' +
                        '    "bg-4": "#191919"' +
                        '}</br>',
                    defaultvalue: '#0d0d0d'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
