import { render, staticRenderFns } from "./LoginPages.vue?vue&type=template&id=baf51072&scoped=true&"
import script from "./LoginPages.vue?vue&type=script&lang=js&"
export * from "./LoginPages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baf51072",
  null
  
)

export default component.exports