<template>
    <page-template>
     <div class="bg-2 p-5">
        <div>
            <h3>Pagination</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'Animation',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: 'pagination-border-width',
                    description: 'Pagination border width',
                    defaultvalue: '1px'
                },
                {
                    slno: '2',
                    name: '--pagination-color',
                    description: 'Pagination color',
                    defaultvalue: 'var(--link-color) = var(--color-primary)'
                },
                {
                    slno: '3',
                    name: '--pagination-bg',
                    description: 'Pagination Bg',
                    defaultvalue: 'var(--body-bg-color) = var(--bg-1) = #EEEEEE'
                },
                {
                    slno: '4',
                    name: '--pagination-border-color',
                    description: 'Pagination Border color',
                    defaultvalue: 'var(--color-gray-300) = #dee2e6'
                },
                {
                    slno: '5',
                    name: '--pagination-hover-color',
                    description: 'Pagination Hover color',
                    defaultvalue: 'var(--link-hover-color) = var(--color-primary-600)'
                },
                {
                    slno: '6',
                    name: '--pagination-focus-outline',
                    description: '',
                    defaultvalue: '0'
                },
                {
                    slno: '7',
                    name: '--pagination-focus-box-shadow',
                    description: '',
                    defaultvalue: ''
                },
                {
                    slno: '8',
                    name: '--pagination-active-color',
                    description: 'active state color',
                    defaultvalue: 'var(--color-white) = #FFFFFF'
                },
                {
                    slno: '9',
                    name: '--pagination-active-bg',
                    description: '',
                    defaultvalue: 'var(--color-primary)'
                },
                {
                    slno: '10',
                    name: '--pagination-active-border-color',
                    description: '',
                    defaultvalue: 'var(--color-primary)'
                },
                {
                    slno: '11',
                    name: '--pagination-disabled-color',
                    description: '',
                    defaultvalue: 'var(--color-gray-600) = #6c757d'
                },
                {
                    slno: '12',
                    name: '--pagination-disabled-bg',
                    description: '',
                    defaultvalue: 'var(--color-white) - #FFFFFF'
                },
                {
                    slno: '13',
                    name: '--pagination-disabled-border-color',
                    description: '',
                    defaultvalue: 'var(--color-gray-300) = #dee2e6'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
