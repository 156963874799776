<template>
    <page-template>
    <div class="bg-2 p-5">
        <div>
            <h3>Headings & Others</h3>
        </div>
        <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
        <!--        </simple-table-header-colored>-->
    </div>
        </page-template>
</template>

<script>
export default {
    name: 'HeadingsandOthers',
    data () {
        return {

            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--h1-font-size',
                    description: 'h1 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*2.5)'
                },
                {
                    slno: '2',
                    name: '--h2-font-size',
                    description: 'h2 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*2)'
                },
                {
                    slno: '3',
                    name: '--h3-font-size',
                    description: 'h3 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.75)'
                },
                {
                    slno: '4',
                    name: '--h4-font-size',
                    description: 'h4 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.5)'
                },
                {
                    slno: '5',
                    name: '--h5-font-size',
                    description: 'h5 heading tag font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.25)'
                },
                {
                    slno: '6',
                    name: '--h6-font-size',
                    description: 'h6 heading tag font size',
                    defaultvalue: 'calc(--font-size-base)'
                },
                {
                    slno: '7',
                    name: '--headings-margin-bottom',
                    description: 'Headings margin bottom',
                    defaultvalue: 'calc(var(--spacer-3)/2)'
                },
                {
                    slno: '8',
                    name: '--headings-font-family',
                    description: 'Headings font family',
                    defaultvalue: 'inherit'
                },
                {
                    slno: '9',
                    name: '--hedings-font-weight',
                    description: 'Headings font weight',
                    defaultvalue: '500'
                },
                {
                    slno: '10',
                    name: '--headings-line-height',
                    description: 'Headings line height',
                    defaultvalue: '1.2'
                },
                {
                    slno: '11',
                    name: '--headings-color',
                    description: 'Headings color',
                    defaultvalue: 'inherit'
                },
                {
                    slno: '12',
                    name: '--display1-size',
                    description: 'Display 1 heading size',
                    defaultvalue: '6rem'
                },
                {
                    slno: '13',
                    name: '--display2-size',
                    description: 'Display 2 heading size',
                    defaultvalue: '5.5rem'
                },
                {
                    slno: '14',
                    name: '--display3-size',
                    description: 'Display 3 heading size',
                    defaultvalue: '4.5rem'
                },
                {
                    slno: '15',
                    name: '--display4-size',
                    description: 'Display 4 heading size',
                    defaultvalue: '3.5rem'
                },
                {
                    slno: '16',
                    name: '--display1-weight',
                    description: 'Display 1 heading Font Weight',
                    defaultvalue: '300'
                },
                {
                    slno: '17',
                    name: '--display2-weight',
                    description: 'Display 2 heading font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '18',
                    name: '--display3-weight',
                    description: 'Display 3 heading font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '19',
                    name: '--display4-size',
                    description: 'Display 4 heading font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '20',
                    name: '--display-line-height',
                    description: 'Display heading line height',
                    defaultvalue: 'var(--headings-line-height)(value:1.2)'
                },
                {
                    slno: '21',
                    name: '--lead-font-size',
                    description: 'Lead text font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.25)'
                },
                {
                    slno: '22',
                    name: '--lead-font-weight',
                    description: 'Lead text font weight',
                    defaultvalue: '300'
                },
                {
                    slno: '23',
                    name: '--small-font-size',
                    description: 'Lead text font size',
                    defaultvalue: '80%'
                },
                {
                    slno: '24',
                    name: '--text-muted',
                    description: 'Text muted color',
                    defaultvalue: 'var(--color-gray-600)'
                },
                {
                    slno: '25',
                    name: '--blockqoute-small-color',
                    description: 'Blockqoute small color',
                    defaultvalue: 'var(--color-gray-600)'
                },
                {
                    slno: '26',
                    name: '--blockqoute small font size',
                    description: 'blockqoute small font size',
                    defaultvalue: 'var(--small-font-size)'
                },
                {
                    slno: '27',
                    name: '--blockqoute-font-size',
                    description: 'Blockqoute font size',
                    defaultvalue: 'calc(var(--font-size-base)*1.25)'
                },
                {
                    slno: '28',
                    name: '--hr-border-color',
                    description: 'hr border color',
                    defaultvalue: 'var(--color-black-10)'
                },
                {
                    slno: '29',
                    name: '--hr-border-width',
                    description: 'hr border width',
                    defaultvalue: 'var(--border-width)'
                },
                {
                    slno: '30',
                    name: '--mark-padding',
                    description: '',
                    defaultvalue: '.2em'
                },
                {
                    slno: '31',
                    name: '--dt-font-weight',
                    description: '',
                    defaultvalue: 'var(--font-weight-bold)'
                },
                {
                    slno: '32',
                    name: '--kbd-box-shadow',
                    description: '',
                    defaultvalue: 'inset 0 - 1rem 0var(--color-black-30)'
                },
                {
                    slno: '33',
                    name: '--nested-kbd-font-weight',
                    description: '',
                    defaultvalue: 'var(--font-weight-bold)'
                },
                {
                    slno: '34',
                    name: '--list-inline-padding',
                    description: '',
                    defaultvalue: '.5rem'
                },
                {
                    slno: '35',
                    name: '--mark-bg',
                    description: '',
                    defaultvalue: '#fcf8e3'
                },
                {
                    slno: '36',
                    name: '--hr-margin-y',
                    description: 'hr vertical margin',
                    defaultvalue: 'var(--spacer-3)'
                }
            ]
        }
        ;
    }
};
</script>

<style scoped>

</style>
