<template>
    <svg :width="sizeVariants['lg'].width" :height="sizeVariants['lg'].height"
         :viewBox="sizeVariants['lg'].viewBox" :style="circleAttributesStyles" class="activity-gauge" style="transform: rotate(-90deg)">
        <circle
            :r="sizeVariants['lg'].radius"
            :cx="sizeVariants['lg'].cx"
            :cy="sizeVariants['lg'].cy"
            fill="transparent"
            stroke="#e0e0e0"
            :stroke-width="storkeWidth"/>
        <circle
            class="circle-3"
            :r="sizeVariants['lg'].radius"
            :cx="sizeVariants['lg'].cx"
            :cy="sizeVariants['lg'].cy"
            fill="transparent"
            :stroke="'var(--color-' + series3Color + ')'"
            :stroke-width="storkeWidth"
            :stroke-linecap="designVariants[design].strokeLinecap"
            :stroke-dasharray="circleAttributes('lg', value3).strokeDasharray"
            :stroke-dashoffset="circleAttributes('lg', value3).strokeDashoffset"/>
        <circle
            :r="sizeVariants['md'].radius"
            :cx="sizeVariants['md'].cx"
            :cy="sizeVariants['md'].cy"
            fill="transparent"
            stroke="#e0e0e0"
            :stroke-width="storkeWidth"/>
        <circle
            class="circle-2"
            :r="sizeVariants['md'].radius"
            :cx="sizeVariants['md'].cx"
            :cy="sizeVariants['md'].cy"
            fill="transparent"
            :stroke="'var(--color-' + series2Color + ')'"
            :stroke-width="storkeWidth"
            :stroke-linecap="designVariants[design].strokeLinecap"
            :stroke-dasharray="circleAttributes('md', value2).strokeDasharray"
            :stroke-dashoffset="circleAttributes('md', value2).strokeDashoffset"/>
        <circle
            :r="sizeVariants['sm'].radius"
            :cx="sizeVariants['sm'].cx"
            :cy="sizeVariants['sm'].cy"
            fill="transparent"
            stroke="#e0e0e0"
            :stroke-width="storkeWidth"/>
        <circle
            class="circle-1"
            :r="sizeVariants['sm'].radius"
            :cx="sizeVariants['sm'].cx"
            :cy="sizeVariants['sm'].cy"
            fill="transparent"
            :stroke="'var(--color-' + series1Color + ')'"
            :stroke-width="storkeWidth"
            :stroke-linecap="designVariants[design].strokeLinecap"
            :stroke-dasharray="circleAttributes('sm', value1).strokeDasharray"
            :stroke-dashoffset="circleAttributes('sm', value1).strokeDashoffset"/>
        <!--        label on center-->
        <text :x="sizeVariants['sm'].labelPositionX"
              :y="sizeVariants['sm'].labelPositionY"
              fill="'var(--text-1)"
              :font-size="sizeVariants['sm'].labelFontSize"
              :font-weight="sizeVariants['sm'].labelFontWeight"
              :style="sizeVariants['sm'].labelStyles">
            {{label}}
        </text>
        <!--        value on center-->
        <text :x="sizeVariants['sm'].totalValuePositionX"
              :y="sizeVariants['sm'].totalValuePositionY"
              :fill="'var(--color-' + totalValueColor + ')'"
              :font-size="sizeVariants['sm'].totalValueFontSize"
              :font-weight="sizeVariants['sm'].totalValueFontWeight"
              :style="sizeVariants['sm'].totalValueStyles">
            {{totalValue}}
        </text>
    </svg>
</template>

<script>
export default {
    name: 'ActivityGaugeCircleCustomizable',
    props: {
        value1: { type: Number, default: 75 },
        value2: { type: Number, default: 60 },
        value3: { type: Number, default: 80 },
        totalValue: { type: Number, default: 1000 },
        series1Color: { type: String, default: 'primary' },
        series2Color: { type: String, default: 'secondary-300' },
        series3Color: { type: String, default: 'third-400' },
        label: { type: String, default: 'Users' },
        totalValueColor: { type: String, default: 'secondary' },
        color: { type: String, default: 'secondary' },
        size: { type: String, default: 'sm' },
        design: { type: String, default: 'basic-a' },
        smRadius: { type: String, default: '69' },
        mdRadius: { type: String, default: '95' },
        lgRadius: { type: String, default: '118' },
        labelPositionX: { type: String, default: '92px' },
        labelPositionY: { type: String, default: '245px' },
        totalValuePositionX: { type: String, default: '58px' },
        totalValuePositionY: { type: String, default: '78px' },
        svgSize: { type: String, default: '330' },
        labelFontSize: { type: String, default: '14px' },
        labelFontWeight: { type: String, default: 'normal' },
        totalValueFontSize: { type: String, default: '30px' },
        totalValueFontWeight: { type: String, default: 'bold' },
        storkeWidth: { type: String, default: '17px' },
        labelTranslateX: { type: String, default: '' },
        valueTranslateX: { type: String, default: '' }
    },
    data () {
        return {
            sizeVariants: {
                sm: {
                    width: '154',
                    height: '154',
                    viewBox: '-19.25 -19.25 192.5 192.5',
                    radius: this.smRadius,
                    cx: '95',
                    cy: '95',
                    totalValueFontSize: this.totalValueFontSize,
                    totalValueFontWeight: this.totalValueFontWeight,
                    labelFontSize: this.labelFontSize,
                    labelFontWeight: this.labelFontWeight,
                    labelStyles: { transform: 'rotate(90deg) translate(-9%, -112%)' },
                    totalValueStyles: { transform: 'rotate(90deg) translate(0px, -150px)' },
                    // labelStyles          : { transform : 'rotate(90deg) translate(' + this.labelTranslateX !== '' ? this.labelTranslateX : '-9%' + ', -112%)' },
                    // totalValueStyles     : { transform : 'rotate(90deg) translate(' + this.valueTranslateX !== '' ? this.valueTranslateX : '0px' + ', -150px)' },
                    totalValuePositionX: this.totalValuePositionX,
                    totalValuePositionY: this.totalValuePositionY,
                    labelPositionX: this.labelPositionX,
                    labelPositionY: this.labelPositionY
                },
                md: {
                    width: '188',
                    height: '188',
                    viewBox: '-23.5 -23.5 235 235',
                    radius: this.mdRadius,
                    cx: '95',
                    cy: '95',
                    textFontSize: '55px',
                    labelStyles: { transform: 'rotate(90deg) translate(2%, -105%)' },
                    textStyles: { transform: 'rotate(90deg) translate(0px, -184px)' },
                    textX: '65px',
                    textY: '112px'
                },
                lg: {
                    width: this.svgSize,
                    height: this.svgSize,
                    viewBox: '-31.25 -31.25 312.5 312.5',
                    radius: this.lgRadius,
                    cx: '96',
                    cy: '96',
                    textFontSize: '66px',
                    labelStyles: { transform: 'rotate(90deg) translate(12%, -92%)' },
                    textStyles: { transform: 'rotate(90deg) translate(0px, -246px)' },
                    textX: '90px',
                    textY: '147px'
                }
            }
        };
    },
    computed: {
        // circleAttributes () {
        //     const strokeDasharray = 2 * Math.PI * this.sizeVariants[this.size].radius;
        //     return {
        //         strokeDasharray: strokeDasharray,
        //         strokeDashoffset: strokeDasharray * ((100 - this.value) / 100)
        //     };
        // },
        circleAttributesStyles () {
            const smStrokeDasharray = 2 * Math.PI * this.sizeVariants.sm.radius;
            const mdStrokeDasharray = 2 * Math.PI * this.sizeVariants.md.radius;
            const lgStrokeDasharray = 2 * Math.PI * this.sizeVariants.lg.radius;
            // offset
            const smStrokeDashoffset = smStrokeDasharray * ((100 - this.value1) / 100);
            const mdStrokeDashoffset = mdStrokeDasharray * ((100 - this.value2) / 100);
            const lgStrokeDashoffset = lgStrokeDasharray * ((100 - this.value3) / 100);
            return {
                '--activity-gauge-circle-sm-stoke-dasharray': smStrokeDasharray,
                '--activity-gauge-circle-md-stoke-dasharray': mdStrokeDasharray,
                '--activity-gauge-circle-lg-stoke-dasharray': lgStrokeDasharray,
                // offset
                '--activity-gauge-circle-sm-stoke-dash-offset': smStrokeDashoffset,
                '--activity-gauge-circle-md-stoke-dash-offset': mdStrokeDashoffset,
                '--activity-gauge-circle-lg-stoke-dash-offset': lgStrokeDashoffset
            };
        },
        designVariants () {
            return {
                'basic-a': {
                    strokeLinecap: 'round'
                },
                'basic-b': {
                    strokeLinecap: 'butt'
                }
            };
        },
        sizeAttributes () {
            return this.sizeVariants[this.size];
        }
    },
    methods: {
        circleAttributes (size, value) {
            const strokeDasharray = 2 * Math.PI * this.sizeVariants[size].radius;
            return {
                strokeDasharray: strokeDasharray,
                strokeDashoffset: strokeDasharray * ((100 - value) / 100),
                style: {
                    '--activity-gauge-circle-sm-stoke-dasharray': strokeDasharray,
                    '--activity-gauge-circle-sm-stoke-dash-offset': strokeDasharray * ((100 - value) / 100)
                }
            };
        }
    }
};
</script>

<style scoped>

</style>
