<template>
    <nav :class="className">
        <div class="logo-container">
            <div class="logo" @click="$emit('logo-clicked')">
                <slot name="logo">
                    {{ logoText }}
                </slot>
            </div>

            <div class="mobile-icons">
                <label class="burger-menu" @click="showMenu=!showMenu">
                    <span class="fa fa-bars"></span>
                </label>
            </div>
        </div>
        <slot name="top" v-if="showMenu"></slot>
        <ul :class="[{'show': showMenu},menuListUlClasses]" class="thin-scrollbar sb-color-white">
            <lego-nav-item-colored v-bind="item" v-for="(item, index) in items" :key="index"/>
        </ul>
        <slot name="bottom" v-if="showMenu"></slot>
    </nav>
</template>

<script>
import LegoNavItemColored from './NavItemColored';

export default {
    name: 'lego-side-bar-colored',
    components: { LegoNavItemColored },
    props: {
        logoText: { type: String, default: 'Xeoscript Technologies' },
        items: { type: Array, required: true },
        shadow: { type: String, default: '5' },
        transparent: { type: Boolean, default: false },
        colored: { type: Boolean, default: false },
        sticky: { type: Boolean, default: false },
        dropDownAnimation: { type: String, default: 'fade' },
        hoverEffect: { type: String, default: 'basic-1' },
        dropDownAnimationMobile: { type: String, default: 'fade' },
        menuListUlClasses: { type: [String, Array], default: '' }
    },
    data () {
        return {
            animate: false,
            showMenu: false
        };
    },

    mounted () {
        const that = this;
        setTimeout(function () {
            that.enableAnimations();
        }, 200);
    },

    computed: {
        className () {
            const names = ['side-bar-colored'];

            if (this.dropDownAnimation) {
                names.push(`nav-dd-${this.dropDownAnimation}`);
                if (this.animate) {
                    names.push('nav-dd-animate');
                }
            }

            if (this.sticky) {
                names.push('nav-sticky');
            }

            if (this.dropDownAnimationMobile) {
                names.push(`nav-mdd-${this.dropDownAnimationMobile}`);
                if (this.animate) {
                    names.push('nav-mdd-animate');
                }
            }

            return names;
        }
    },

    methods: {
        enableAnimations () {
            this.animate = true;
        }
    }
};
</script>

<style scoped>

</style>
