<template>
    <div>
        <div class="col-lg-7 bgc-info text-center mt-4 px-6">
            <h3 class="text-center font-weight-bold pt-6 text-white">{{headings}}</h3>
            <div class="pt-3">
                <img src="../../assets/img/avatar/avatar-img-nicolas-horn-MTZTGvDsHFY-unsplash.jpg" alt="" class="avatar-img-size-0 ava b-round">
                <h6 class="font-weight-bold text-white">{{name}}</h6>
                <p class="text-white">Head of Design,Layer</p>
            </div>
            <img src="../../assets/img/avatar/star.jpg" alt="" class="w-10p mb-5">
        </div>
    </div>
</template>

<script>
export default {
    name: 'TestimonialsItemsone',
    props: {
        headings: { type: String, default: 'Love the simplicity of the service and the prompt customer support.We can\'t imagine working without it.' },
        name: { type: String, default: 'Caityln King' }
    }
};
</script>

<style scoped>

</style>
