import { render, staticRenderFns } from "./TestimonialsVariantsPage.vue?vue&type=template&id=18cda43b&scoped=true&"
import script from "./TestimonialsVariantsPage.vue?vue&type=script&lang=js&"
export * from "./TestimonialsVariantsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18cda43b",
  null
  
)

export default component.exports