<template>
    <div class="d-inline-flex fl-a-c" :class="[marginClasses, paddingClasses, bgColorClasses, containerClasses, containerDefaultClasses]">
        <icon-img :image="img" :size="iconSize" :additional-classes="[reverseAlignment?'order-2':'order-1']"/>
        <text-el
            class="m-0"
            :ml="text?1:0"
            :color="textColor"
            :fs="textFontSize"
            :font-weight="textFontWeight"
            :additional-classes="[reverseAlignment?'order-1':'order-2', textClasses]">{{text}}
        </text-el>
    </div>
</template>
<script>
import IconImg from '../../components/IconImage';
import TextEl from '../../components/typography/TextElement';
import marginsAndPaddingsMixin from '../../mixins/margin-and-padding/marginsAndPaddingsMixin';
import backgroundColorMixin from '../../mixins/bg-color-thickness-mixin';

export default {
    name: 'IconText',
    components: { IconImg, TextEl },
    props: {
        img: { type: String, required: true },
        text: { type: String, required: true },
        textColor: { type: String, default: '' },
        textFontSize: { type: String, default: '' },
        textFontWeight: { type: String, default: '' },
        textClasses: { type: String, default: '' },
        iconSize: { type: String, default: '5' },
        border: { type: String, default: '' },
        borderRadius: { type: String, default: '' },
        borderColor: { type: String, default: '' },
        shadow: { type: String, default: '' },
        bgColor: { type: String, default: '' },
        reverseAlignment: { type: Boolean, default: false },
        containerClasses: { type: [String, Array], default: '' }
    },
    computed: {
        containerDefaultClasses () {
            const classNames = [];
            if (this.border) {
                classNames.push('b-' + this.border);
            }
            if (this.borderRadius) {
                classNames.push('round-' + this.borderRadius);
            }
            if (this.borderColor) {
                classNames.push('b-' + this.borderColor);
            }
            if (this.shadow) {
                classNames.push('bs-' + this.shadow);
            }
            if (this.bgColor) {
                classNames.push('bg-' + this.bgColor);
            }
            return classNames;
        }
    },
    mixins: [marginsAndPaddingsMixin, backgroundColorMixin]
};
</script>

<style scoped>

</style>
