import CustomSpacingEl from 'lego-framework/src/containers/CustomSpacingElement';

export default {
    extends: CustomSpacingEl,
    name: 'line-item',
    props: {
        height: { type: String, default: '1.5px' },
        width: { type: String, default: 'auto' }
    }
};
