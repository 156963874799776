<template>
    <page-template>
        <div class="container-fluid bg-2">
            <h2>Border Helpers</h2>
            <p class="lead">
                There is helper classes for borders.
            </p>

            <h3>Class Names</h3>
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th style="width: 10rem">All Borders</th>
                    <th style="width: 10rem">Top Border</th>
                    <th style="width: 10rem">Bottom Border</th>
                    <th style="width: 10rem">Left Border</th>
                    <th style="width: 10rem">Right Border</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in 10" :key="i">
                    <td>.b-{{ i }}</td>
                    <td>.bt-{{ i }}</td>
                    <td>.bb-{{ i }}</td>
                    <td>.bl-{{ i }}</td>
                    <td>.br-{{ i }}</td>
                    <td>Border with {{ i }}px width</td>
                </tr>
                </tbody>
            </table>
            <div class="bg-4 p-3">
                <h4 class="mb-4">Usage Notes</h4>
                <p><span class="pt-2 font-weight-bold text-black-50"> Uniform Borders: Use .b-* </span>to apply the same
                    border width on all sides of an element.</p>
                <p class="pt-2"><span class="text-black-50 font-weight-bold">Directional Borders: Use .bt-*, .bb-*, .bl-*, or .br-*</span>
                    to apply
                    specific border widths to the top,
                    bottom, left, or right sides respectively.
                    These classes provide flexibility in styling your elements, ensuring consistent and controlled
                    border widths throughout your design!</p>
            </div>

            <h3 class="mt-5">Border Color Classes</h3>
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th style="width: 10rem">Color</th>
                    <th>Description</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in theme['border-colors']" :key="i">
                    <td>.b-{{ i }}</td>
                    <td>Border color {{ i }}</td>
                </tr>
                </tbody>
            </table>
            <h3>
                Example
            </h3>
            <div>
                <code-container show-code-no-toggle :code-str="codeStore.design.code"/>

                <div class="p-3 pt-2 b-2  b-primary"></div>
                <div class="p-3 mt-2 b-1  b-danger"></div>
                <div class="p-3 mt-2 b-5  b-warning"></div>
                <div class="p-3 mt-2 b-6  b-info"></div>
            </div>
            <h3 class="mt-5">Borders Demo</h3>
            <div v-for="i in 10" :key="i">
                <h4>Border Width {{ i }}px</h4>
                <div class="fl-x fl-wrap">
                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'bt-' + i">
                            .bt-{{ i }}
                        </div>
                    </div>
                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'bb-' + i">
                            .bb-{{ i }}
                        </div>
                    </div>
                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'br-' + i">
                            .br-{{ i }}
                        </div>
                    </div>
                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'bl-' + i">
                            .bl-{{ i }}
                        </div>
                    </div>

                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'b-' + i">
                            .b-{{ i }}
                        </div>
                    </div>
                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'bx-' + i">
                            .bx-{{ i }}
                        </div>
                    </div>
                    <div class="bgc-primary-100 p-4 mr-2 mb-2 w-20p">
                        <div class="p-4 bgc-light" :class="'by-' + i">
                            .by-{{ i }}
                        </div>
                    </div>
                </div>
            </div>

            <h3>Border Colors</h3>
            <div class="fl-x fl-wrap">
                <div class="bgc-primary-100 p-4 mr-2 mb-2 w-15p" v-for="i in theme['border-colors']" :key="i">
                    <div class="p-4 bgc-light b-3" :class="'b-' + i">
                        .b-{{ i }} .b-3
                    </div>
                </div>
            </div>

            <h3>Border Colors <small>Dark Variant</small></h3>
            <div class="fl-x fl-wrap">
                <div class="bgc-primary-100 p-4 mr-2 mb-2 w-15p" v-for="i in theme['border-colors']" :key="i">
                    <div class="p-4 bgc-light b-3" :class="'b-' + i + '-dark'">
                        .b-{{ i }}-dark .b-3
                    </div>
                </div>
            </div>

            <h3>Border Colors <small>Light Variant</small></h3>
            <div class="fl-x fl-wrap">
                <div class="bgc-primary-100 p-4 mr-2 mb-2 w-15p" v-for="i in theme['border-colors']" :key="i">
                    <div class="p-4 bgc-light b-3" :class="'b-' + i + '-light'">
                        .b-{{ i }}-light .b-3
                    </div>
                </div>
            </div>
            <col-12 class="mt-4">
                <h3>SCSS Variables</h3>
                <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
            </col-12>

        </div>
    </page-template>
</template>

<script>
import helpersMixin from '../helpersMixin';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/border.json';

export default {
    extends: helpersMixin,
    metaInfo: {
        path: 'helpers/borders/',
        title: 'Borders'
    },
    name: 'colors.scss',
    components: { CodeContainer },
    data () {
        return {
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: ''
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--border-radius',
                    description: 'border radius',
                    defaultvalue: '.375rem'
                },
                {
                    slno: '2',
                    name: '--border-radius-sm',
                    description: 'border radius small',
                    defaultvalue: '.25rem'
                },
                {
                    slno: '3',
                    name: '--border-radius-lg',
                    description: 'border radius large',
                    defaultvalue: '.4375rem'
                },
                {
                    slno: '4',
                    name: '--border-radius-xl',
                    description: 'border radius extra large',
                    defaultvalue: '5rem'
                },
                {
                    slno: '5',
                    name: '--border-radius-pill',
                    description: 'border radius pill',
                    defaultvalue: '50rem'
                }
            ],
            codeStore: codeStore
        };
    }

};
</script>
