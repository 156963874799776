<template>
    <div class="py-5 bg-2">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="text-fs-4 my-3">Custom Height & Width Container</h1>
                    <p class="lead">element with custom height and width props</p>
                    <h4 class="my-3">Usage</h4>
                    <code-container show-code-no-toggle :code-str="codeStore.usage.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Height set </h5>
                    <custom-h-el class="b-1 text-center" height="10r">
                        height: 10r
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.heightSet.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Height and Width Set (re short used for rem) </h5>
                    <custom-h-el class="b-1" width="10r" height="3r">
                        width: 10r, height: 3r
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.reShortForm.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Height and Width Set (width and height value in rem) </h5>
                    <custom-h-el class="b-1" width="10rem" height="3rem">
                        width: 10rem, height: 3rem
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.widthInRem.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Height in vh and Width in rem </h5>
                    <custom-h-el class="b-1" width="10rem" height="20vh">
                        width: 10rem, height: 20vh
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.widthRemHeightVh.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Min Height set </h5>
                    <custom-h-el class="b-1" width="10rem" min-height="20vh">
                        width: 10rem, min-height: 20vh
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.minHeightInVh.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Max Height set </h5>
                    <custom-h-el class="b-1 of-a" width="10rem" max-height="8rem">
                        width: 10rem, max-height: 8rem
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Aspernatur dicta facilis hic nobis, odit reprehenderit?
                        Iusto officiis quaerat quos sunt!
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.maxHInRem.code"/>
                </div>
                <div class="col-12">
                    <h5 class="my-3">Bg Color and Bg Color Thickness set </h5>
                    <custom-h-el class="b-1" width="10rem"
                                 min-height="20vh" bg-color="primary" bg-color-thickness="200">
                        width: 10rem, min-height: 20vh, bgColor: primary
                        bgColorThickness: 200
                    </custom-h-el>
                    <code-container show-code-no-toggle :code-str="codeStore.bgColorAndBgColorThickness.code"/>
                </div>
                <div class="col-12 mt-4">
                    <h4 class="mt-3">Props</h4>
                    <prop-table :items="propsInfo"/>
                </div>
                <div class="col-12 mt-4">

                    <h3>Custom Height Container: SCSS Variables</h3>

                    <simple-table :fields="fields" :data="data" class="b-1"></simple-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomHEl from '../../containers/CustomHeightContainer';
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/custom-h-el.json';
import PropTable from '../docs-components/Props';

export default {
    name: 'CustomHeightContainerDocs',
    metaInfo: {
        path: 'ui-elements/custom-height-width-container/',
        title: 'Custom Size Container'
    },
    components: { CustomHEl, CodeContainer, PropTable },
    data () {
        return {
            codeStore: codeStore,
            propsInfo: [
                ['tag', 'String', 'html tag', ['any html tag'], 'div'],
                ['height', 'String', 'height value in rem (re short eg:10re), vh or other units', '', ''],
                ['width', 'String', 'width value in rem (re short eg:10re), vh or other units', '', ''],
                ['heightMd', 'String', 'Height in medium screens', '', ''],
                ['heightLg', 'String', 'height in large screens', '', ''],
                ['minHeight', 'String', 'Min Height value', '', ''],
                ['minHeightMd', 'String', 'Min Height in medium screens', '', ''],
                ['minHeightLg', 'String', 'Min height in large screens', '', ''],
                ['maxHeight', 'String', 'Max Height value', '', ''],
                ['maxHeightMd', 'String', 'Max Height in medium screens', '', ''],
                ['maxHeightLg', 'String', 'Max height in large screens', '', ''],
                ['bgColor', 'String', 'bg color variants', ['primary', 'secondary', 'third', 'success', 'danger', 'info', '1', '2', '3', '4'], ''],
                ['bgColorThickness', 'String', 'bg color thickness value', ['100', '200', '300', '400', '600', '700', '800', '900'], '']
            ],
            fields: [
                {
                    name: 'slno',
                    title: '',
                    titleClass: '',
                    dataClass: ''
                },
                {
                    name: 'name',
                    title: 'Name',
                    titleClass: '',
                    dataClass: 'w-30r'
                },
                {
                    name: 'description',
                    title: 'Description',
                    titleClass: '',
                    dataClass: 'w-30r'
                },
                {
                    name: 'defaultvalue',
                    title: 'Default Value',
                    titleClass: '',
                    dataClass: 'w-25r'
                }
            ],
            data: [
                {
                    slno: '1',
                    name: '--custom-height-container-height',
                    description: 'container height',
                    defaultvalue: '""'
                },
                {
                    slno: '2',
                    name: '--custom-height-container-height-md',
                    description: 'container height on medium size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '3',
                    name: '--custom-height-container-height-lg',
                    description: 'container height on large size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '4',
                    name: '--custom-height-container-min-height',
                    description: 'container min-height',
                    defaultvalue: '""'
                },
                {
                    slno: '5',
                    name: '--custom-height-container-min-height-md',
                    description: 'container min-height on medium size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '6',
                    name: '--custom-height-container-min-height-lg',
                    description: 'container min-height on large size screens',
                    defaultvalue: '""'
                },
                {
                    slno: '7',
                    name: '--custom-height-container-max-height',
                    description: 'container max-height',
                    defaultvalue: 'unset'
                },
                {
                    slno: '8',
                    name: '--custom-height-container-max-height-md',
                    description: 'container max-height on medium size screens',
                    defaultvalue: 'unset'
                },
                {
                    slno: '9',
                    name: '--custom-height-container-max-height-lg',
                    description: 'container max-height on large size screens',
                    defaultvalue: 'unset'
                },
                {
                    slno: '10',
                    name: '--custom-height-container-width',
                    description: 'container width',
                    defaultvalue: 'unset'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>
