<template>
    <div class="fl-x fl-a-c">
        <btn design="" shadow="0" v-if="design === 'basic'">
            <transition name="fade" mode="out-in">
                <icon-img v-if="enabled" :image="enabledIcon" alt="" @click="changeStatus"/>
                <icon-img v-else :image="disabledIcon" alt="" @click="changeStatus"/>
            </transition>
        </btn>
        <div :class="{'w-5r': $slots.status}" v-if="$slots.status">
            <transition name="fade" mode="out-in">
                <slot name="status"></slot>
            </transition>
        </div>
    </div>
</template>

<script>
import enabledIcon from './assets/_Toggle-base.svg';
import disabledIcon from './assets/Toggle_base_disabled.png';
import IconImg from '../../components/IconImage';
export default {
    name: 'ToggleButton',
    components: { IconImg },
    props: {
        enabled: {
            type: Boolean,
            default: true
        },
        enabledIcon: {
            type: String,
            default: enabledIcon
        },
        disabledIcon: {
            type: String,
            default: disabledIcon
        },
        iconSize: {
            type: String,
            default: '3'
        },
        setStatusSlotWidth: {
            type: Boolean,
            default: true
        },
        design: {
            type: String,
            default: 'basic'
        }
    },
    methods: {
        changeStatus () {
            this.enabled = !this.enabled;
            this.$emit('changeStatus');
        }
    }
};
</script>

<style scoped>
</style>
