<template>
    <div class="fl-x">
        <template v-if="items">
            <progress-step-item
                v-for="(item,i) in items" :key="i"
                :text1="item.text1" :text2="item.text2"
                :status="item.status" :show-line="i==0?false:true"
                v-bind="designProps"/>
        </template>
    </div>
</template>

<script>
import ProgressStepItem from './ProgressStepItem1';

export default {
    name: 'ProgressStepsDesign1',
    components: { ProgressStepItem },
    props: {
        items: { type: Array, default: null },
        lineMl: { type: String, default: '-5.5rem' },
        lineMr: { type: String, default: '-5.5rem' },
        lineWidth: { type: String, default: '12r' },
        sectionWidth: { type: String, default: '12r' }
    },
    computed: {
        designProps () {
            return {
                lineWidth: this.lineWidth,
                sectionWidth: this.sectionWidth,
                lineMl: this.lineMl,
                lineMr: this.lineMr
            };
        }
    }
};
</script>

<style scoped>

</style>
