<template>
    <page-template>
        <div class="container-fluid bg-2 py-3">
            <div>
                <h2 class="my-3">Entry-exit, hide animations Classes</h2>
                <pre>
                $entry-exit-animations:      'zoom' 'zoomIn' 'zoomOut',
                             'zoom-down' 'zoomInDown' 'zoomOutDown',
                             'zoom-left' 'zoomInLeft' 'zoomOutLeft',
                             'zoom-right' 'zoomInRight' 'zoomOutRight',
                             'zoom-up' 'zoomInUp' 'zoomOutUp',
                             'rotate' 'rotateIn' 'rotateOut',
                             'rotate-down-left' 'rotateInDownLeft' 'rotateOutDownLeft',
                             'rotate-down-right' 'rotateInDownRight' 'rotateOutDownRight',
                             'rotate-up-left' 'rotateInUpLeft' 'rotateOutUpLeft',
                             'rotate-up-right' 'rotateInUpRight' 'rotateOutUpRight',
                             'flip-x' 'flipInX' 'flipOutX',
                             'flip-y' 'flipInY' 'flipOutY',
                             'fade' 'fadeIn' 'fadeOut',
                             'fade-down' 'fadeInDown' 'fadeOutDown',
                             'fade-down-big' 'fadeInDownBig' 'fadeOutDownBig',
                             'fade-left' 'fadeInLeft' 'fadeOutLeft',
                             'fade-left-big' 'fadeInLeftBig' 'fadeOutLeftBig',
                             'fade-right' 'fadeInRight' 'fadeOutRight',
                             'fade-right-big' 'fadeInRightBig' 'fadeOutRightBig',
                             'fade-up' 'fadeInUp' 'fadeOutUp',
                             'fade-up-big' 'fadeInUpBig' 'fadeOutUpBig',
                             'fade-top-left' 'fadeInTopLeft' 'fadeOutTopLeft',
                             'fade-top-right' 'fadeInTopRight' 'fadeOutTopRight',
                             'fade-bottom-left' 'fadeInBottomLeft' 'fadeOutBottomLeft',
                             'fade-bottom-right' 'fadeInBottomRight' 'fadeOutBottomRight',
                             'bounce' 'bounceIn' 'bounceOut',
                             'bounce-down' 'bounceInDown' 'bounceOutDown',
                             'bounce-left' 'bounceInLeft' 'bounceOutLeft',
                             'bounce-right' 'bounceInRight' 'bounceOutRight',
                             'bounce-up' 'bounceInUp' 'bounceOutUp',
                             'back-down' 'backInDown' 'backOutDown',
                             'back-left' 'backInLeft' 'backOutLeft',
                             'back-right' 'backInRight' 'backOutRight',
                             'back-up' 'backInUp' 'backOutUp',
                             'slide-down' 'slideInDown' 'slideOutDown',
                             'slide-left' 'slideInLeft' 'slideOutLeft',
                             'slide-right' 'slideInRight' 'slideOutRight',
                             'slide-up' 'slideInUp' 'slideOutUp';

$entry-exit-hide-animations: 'zoom' 'zoomInHide' 'zoomOutHide',
                             'zoom-down' 'zoomInDownHide' 'zoomOutDownHide',
                             'zoom-left' 'zoomInLeftHide' 'zoomOutLeftHide',
                             'zoom-right' 'zoomInRightHide' 'zoomOutRightHide',
                             'zoom-up' 'zoomInUpHide' 'zoomOutUpHide',
                             'rotate' 'rotateInHide' 'rotateOutHide',
                             'rotate-down-left' 'rotateInDownLeftHide' 'rotateOutDownLeftHide',
                             'rotate-down-right' 'rotateInDownRightHide' 'rotateOutDownRightHide',
                             'rotate-up-left' 'rotateInUpLeftHide' 'rotateOutUpLeftHide',
                             'rotate-up-right' 'rotateInUpRightHide' 'rotateOutUpRightHide',
                             'flip-x' 'flipInXHide' 'flipOutXHide',
                             'flip-y' 'flipInYHide' 'flipOutYHide',
                             'fade' 'fadeInHide' 'fadeOutHide',
                             'fade-down' 'fadeInDownHide' 'fadeOutDownHide',
                             'fade-down-big' 'fadeInDownBigHide' 'fadeOutDownBigHide',
                             'fade-left' 'fadeInLeftHide' 'fadeOutLeftHide',
                             'fade-left-big' 'fadeInLeftBigHide' 'fadeOutLeftBigHide',
                             'fade-right' 'fadeInRightHide' 'fadeOutRightHide',
                             'fade-right-big' 'fadeInRightBigHide' 'fadeOutRightBigHide',
                             'fade-up' 'fadeInUpHide' 'fadeOutUpHide',
                             'fade-up-big' 'fadeInUpBigHide' 'fadeOutUpBigHide',
                             'fade-top-left' 'fadeInTopLeftHide' 'fadeOutTopLeftHide',
                             'fade-top-right' 'fadeInTopRightHide' 'fadeOutTopRightHide',
                             'fade-bottom-left' 'fadeInBottomLeftHide' 'fadeOutBottomLeftHide',
                             'fade-bottom-right' 'fadeInBottomRightHide' 'fadeOutBottomRightHide',
                             'bounce' 'bounceInHide' 'bounceOutHide',
                             'bounce-down' 'bounceInDownHide' 'bounceOutDownHide',
                             'bounce-left' 'bounceInLeftHide' 'bounceOutLeftHide',
                             'bounce-right' 'bounceInRightHide' 'bounceOutRightHide',
                             'bounce-up' 'bounceInUpHide' 'bounceOutUpHide',
                             'back-down' 'backInDownHide' 'backOutDownHide',
                             'back-left' 'backInLeftHide' 'backOutLeftHide',
                             'back-right' 'backInRightHide' 'backOutRightHide',
                             'back-up' 'backInUpHide' 'backOutUpHide',
                             'slide-down' 'slideInDownHide' 'slideOutDownHide',
                             'slide-left' 'slideInLeftHide' 'slideOutLeftHide',
                             'slide-right' 'slideInRightHide' 'slideOutRightHide',
                             'slide-up' 'slideInUpHide' 'slideOutUpHide';
            </pre>
            </div>
            <div class="bg-4 p-4 mt-4">
                <h2>Entry-Exit Animations</h2>
                <p>Entry-exit animations allow you to create engaging transitions for elements as they appear or
                    disappear
                    on the screen. These classes can enhance the user experience by adding visual interest.</p>

                <h5>Animation Classes</h5>
                <p>Here are some examples of entry and exit animation classes:</p>

                <h5>Zoom Animations</h5>
                <ul class="pl-3">
                    <li class="pt-2"><strong>.zoom</strong>: Basic zoom effect.</li>
                    <li class="pt-2"><strong>.zoomIn</strong>: Element zooms in when appearing.</li>
                    <li class="pt-2"><strong>.zoomOut</strong>: Element zooms out when disappearing.</li>
                </ul>

                <h5 class="pt-4">Fade Animations</h5>
                <ul class="pl-3">
                    <li class="pt-2"><strong>.fade</strong>: Basic fade effect.</li>
                    <li class="pt-2"><strong>.fadeIn</strong>: Element fades in when appearing.</li>
                    <li class="pt-2"><strong>.fadeOut</strong>: Element fades out when disappearing.</li>
                </ul>

                <h5 class="pt-4">Slide Animations</h5>
                <ul class="pl-3">
                    <li class="pt-2"><strong>.slideInDown</strong>: Element slides in from the top.</li>
                    <li class="pt-2"><strong>.slideOutDown</strong>: Element slides out to the bottom.</li>
                </ul>

                <h5 class="pt-4">Hide Animations</h5>
                <p>Hide animations provide a smooth way to hide elements while maintaining visual appeal:</p>

                <h5 class="pt-4">Hide Zoom Animations</h5>
                <ul class="pl-3">
                    <li class="pt-2"><strong>.zoomInHide</strong>: Zooms in and hides the element.</li>
                    <li class="pt-2"><strong>.zoomOutHide</strong>: Zooms out and hides the element.</li>
                </ul>

                <h4 class="pt-4">Hide Fade Animations</h4>
                <ul class="pl-3">
                    <li class="pt-2"><strong>.fadeInHide</strong>: Fades in and hides the element.</li>
                    <li class="pt-2"><strong>.fadeOutHide</strong>: Fades out and hides the element.</li>
                </ul>

                <h5 class="pt-4">Hide Slide Animations</h5>
                <ul class="pl-3">
                    <li class="pt-2"><strong>.slideInDownHide</strong>: Slides in from the top and hides.</li>
                    <li class="pt-2"><strong>.slideOutDownHide</strong>: Slides out to the bottom and hides.</li>
                </ul>
            </div>
            <div class="mt-4">
                <h3>Example Usage</h3>
                <p>Here's how to apply these classes in your HTML:</p>
                <code-container show-code-no-toggle :code-str="codeStore.entryexit.code"/>

                <div class="zoomIn">This element will zoom in when it appears.</div>
                <div class="fadeOut">This element will fade out when it disappears.</div>
                <div class="slideInDown">This element slides down when it appears.</div>

                <p>For hiding animations:</p>
                <div class="zoomInHide">This element will zoom in and hide.</div>
                <div class="fadeOutHide">This element will fade out and hide.</div>
                <div class="slideOutDownHide">This element will slide down and hide.</div>
            </div>
        </div>
    </page-template>
</template>

<script>
import CodeContainer from '../../components/doc-items/CodeContainer';
import codeStore from '../../views/code-storage/entryexit.json';

export default {
    name: 'EntryExitAnimationsHelpers',
    components: { CodeContainer },
    data () {
        return {
            codeStore: codeStore
        };
    },
    metaInfo: {
        path: 'helpers/entry-exit-animation/',
        title: 'Entry Exit Animation'
    }
};
</script>

<style scoped>

</style>
